
import { ApiResponse } from '../../creator-sdk';
import DbModule from '../db';
import AppCheckModule from '../app-check';
import AccountModule from '../account';
import { Flair } from './flair.model';
import { post } from '../../network/api-service';


export interface CreateFlairApiRequestPayload {
    tokenName: string;
    userId: string;
    flairColor: string;
    flairName: string;
}

export interface DeleteFlairApiRequestPayload {
    tokenName: string;
    userId: string;
    flairName: string;
}

export interface AttachFlairApiRequestPayload {
    userId: string;
    postId: string;
    tokenName: string;
    flairName: string;
}

export default class FlairService {
        private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private accountModule: AccountModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.accountModule = accountModule;
    }

    async createFlair(payload: CreateFlairApiRequestPayload): Promise<ApiResponse<Flair>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/flair/create', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data;
    }

    async deleteFlair(payload: DeleteFlairApiRequestPayload): Promise<ApiResponse<Flair>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/flair/delete', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data;
    }

    async attachFlair(payload: AttachFlairApiRequestPayload): Promise<ApiResponse<Flair>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/flair/attach', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data;
    }
}
