import React, { FC } from 'react';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { useTranslation } from 'react-i18next';
import { cn } from '@creator/ui/utils/ui';
import Button, { ButtonProps } from '@creator/ui/components/Button/ButtonV2';

export interface ApproveOrDeleteButtonsProps {
    className?: string;
    approveButtonProps?: ButtonProps;
    deleteButtonProps?: ButtonProps;
}

const ApproveOrDeleteButtons: FC<ApproveOrDeleteButtonsProps> = props => {
    const { className } = props;
    const { t } = useTranslation();

    function renderApproveButton() {
        const { approveButtonProps = {} } = props;

        const _props = mergeProps<ButtonProps>({
            color: 'primary',
            size: 'sm',
            className: 'w-[140px]'
        }, approveButtonProps);

        return <Button {..._props}>{t('approveOrDeleteButtons.approveButton')}</Button>;
    }

    function renderDeleteButton() {
        const { deleteButtonProps = {} } = props;

        const _props = mergeProps<ButtonProps>({
            color: 'base',
            size: 'sm',
            className: 'w-[140px]'
        }, deleteButtonProps);

        return <Button {..._props}>{t('approveOrDeleteButtons.deleteButton')}</Button>;
    }

    return (
        <div className={cn('flex space-x-12 justify-center', className)}>
            {renderApproveButton()}
            {renderDeleteButton()}
        </div>
    );
};

export default ApproveOrDeleteButtons;
