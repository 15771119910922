import { getDocs, limit, orderBy, OrderByDirection, query, QueryConstraint, QueryDocumentSnapshot, startAfter, where, WhereFilterOp } from 'firebase/firestore/lite';

import { Pagination as PaginationNew } from '../db/db.model';
import AppCheckModule from '../app-check';
import DbModule from '../db';
import { Wallet } from './wallet.model';

export interface GetHoldersOrderBy {
    field: 'balance' | string;
    direction: OrderByDirection;
}

export interface GetHoldersFilterBy {
    field: 'domain' | string;
    opStr: WhereFilterOp;
    value: string;
}

export default class WalletService {
        private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule) {
        
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
    }

    async getWallet(walletId: string): Promise<Wallet> {
        const wallet = await this.dbModule.getDocument<Wallet>('Wallet', walletId);
        if (!wallet) throw new Error(`wallet with id ${walletId} not found`);

        return wallet;
    }

    async getWallets(_startAfter: QueryDocumentSnapshot<Wallet> | null, _limit: number, _filterBy?: GetHoldersFilterBy[], _orderBy?: GetHoldersOrderBy[]): Promise<PaginationNew<Wallet>> {
        const colRef = this.dbModule.getCollectionReference<Wallet>('Wallet');
        const queryConstraints: QueryConstraint[] = [];

        _filterBy?.forEach((filter): void => {
            const { field, opStr, value } = filter;
            queryConstraints.push(where(field, opStr, value));
        });

        _orderBy?.forEach((order): void => {
            const { field, direction } = order;
            queryConstraints.push(orderBy(field, direction));
        });

        if (_startAfter)
            queryConstraints.push(startAfter(_startAfter));

        queryConstraints.push(limit(_limit));

        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);

        return { hasMore: items.length === _limit, items, lastDoc };
    }
}
