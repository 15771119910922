import React, { FC, PropsWithChildren, useState } from 'react';
import ApproveOrDeleteButtons, { ApproveOrDeleteButtonsProps } from '@src/basic-components/ApproveOrDeleteButtons/ApproveOrDeleteButtons';
import { useStoreActions } from '@src/model/hooks';
import { getUserId } from '@src/model/user/helpers';
import { useTranslation } from 'react-i18next';
import { cn } from '@creator/ui/utils/ui';
import {  noop } from '@src/utils/utils';

export interface PendingPostApproveDeleteProps {
    className?: string;
    postId: string;
    onDeleteClick?: () => void;
}

const PendingPostApproveDelete: FC<PropsWithChildren<PendingPostApproveDeleteProps>> = props => {
    const { postId, className = '', onDeleteClick = noop } = props;

    const approvePendingPost = useStoreActions(actions => actions.upvote.approvePendingPost);
    const [isApproving, setIsApproving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { t } = useTranslation();

    function mapError(error): void {
        alert(t('generalErrorMessage') + ' ' + error.message);
    }

    async function onApprovePostClick(postId: string, isApprove: boolean): Promise<void> {
        isApprove ? setIsApproving(true) : setIsDeleting(true);

        try {
            await approvePendingPost({
                approve: isApprove,
                postId,
                userId: getUserId()
            });

            isApprove ? setIsApproving(false) : setIsDeleting(false);
        } catch (error) {
            mapError(error);
            isApprove ? setIsApproving(false) : setIsDeleting(false);
            console.error(error);
        }

    }

    function renderApproveOrDeleteButtons() {
        const props: ApproveOrDeleteButtonsProps = {
            className: 'pt-4 mt-6 border-t border-gray-100 dark:border-gray-800 -mx-4 lg:-mx-5',
            approveButtonProps: {
                isLoading: isApproving,
                onClick: e => onApprovePostClick(postId, true)
            },
            deleteButtonProps: {
                isLoading: isDeleting,
                onClick: e => { onApprovePostClick(postId, false); onDeleteClick(); }
            }
        };
        return (
            <ApproveOrDeleteButtons {...props} />
        );
    }


    return (
        <div className={cn('', className)}>
            {renderApproveOrDeleteButtons()}
        </div>
    );
};

export default PendingPostApproveDelete;
