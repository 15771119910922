import React, { FC, useEffect } from 'react';
import { useStoreActions } from '@src/model/hooks';
import creatorSdk from '@src/services/creator-sdk';

export type ModalType = 'sellToken' | 'sendToken' | 'deposit' | 'auth';

export interface SystemBackdoorProps {
    isAppReady: boolean;
}

const SystemBackdoor: FC<SystemBackdoorProps> = props => {
    const { isAppReady } = props;

    const logout = useStoreActions(actions => actions.user.logout);


    useEffect(() => {
        if (!window.deWeb.onAppReady || !isAppReady) return;
        if (typeof window.deWeb.onAppReady !== 'function') return;

        window.deWeb.onAppReady();
    }, [isAppReady]);

    window.deWeb.logout = () => {
        logout();
    };

    window.deWeb.firebaseSignInWithCustomToken = async customToken => {
        await creatorSdk.accountModule.firebaseSignInWithCustomToken(customToken);
    };

    return <div></div>;
};

export default SystemBackdoor;