import React, { FC, memo } from 'react';
import { useStoreState } from '@src/model/hooks';
import CardHeader from '@creator/ui/components/Card/CardHeader';
import { propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import PostBasicInfo, { PostBasicInfoProps } from '../Upvote/PostBasicInfo/PostBasicInfo';
import PostMenuPopover, { PostMenuPopoverProps } from '../PostMenuPopover/PostMenuPopover';
import { cn } from '@creator/ui/utils/ui';
import { noop } from '@src/utils/utils';

export interface PostCardHeaderProps {
    className?: string;
    postId: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    postBasicInfoProps?: Partial<PostBasicInfoProps>,
    postMenuPopoverProps?: Partial<PostMenuPopoverProps>;
}

const PostCardHeader: FC<PostCardHeaderProps> = props => {
    const { onClick = noop, postId, className } = props;

    const post = useStoreState(state => state.upvote.getPost(postId), storeEqualityFn);

    const renderPostBasicInfo = () => {
        const { postBasicInfoProps = {} } = props;
        if (!post) return null;

        const infoProps = mergeProps<[PostBasicInfoProps, Partial<PostBasicInfoProps>]>({
            className: 'cursor-pointer',
            publisherName: post.publisherName || '',
            publisherProfileName: post.createdByWhitelistedUser ? post.publisherProfileName : '',
            publisherSlugUrl: post.publisherSlugUrl,
            createdAt: post.createdAt,
            tokenName: post.tokenName,
            showPartnerLabel: post.createdByWhitelistedUser,
            moderationScore: post.moderationScore
        }, postBasicInfoProps);

        return <PostBasicInfo {...infoProps} />;
    };

    const renderPostMenuPopover = () => {
        const { postMenuPopoverProps = {} } = props;
        if (!post) return null;

        const _props = mergeProps<[PostMenuPopoverProps, Partial<PostMenuPopoverProps>]>({
            className: 'absolute top-0 right-0',
            tokenName: post.tokenName,
            postId: postId
        }, postMenuPopoverProps);

        return (
            <PostMenuPopover {..._props} />
        );
    };

    return (
        <CardHeader onClick={onClick} className={cn('relative', className)}>
            {renderPostMenuPopover()}
            {renderPostBasicInfo()}
        </CardHeader>
    );
};

export default memo(PostCardHeader, propsEqualityFn);