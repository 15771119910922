import React from "react";

// extend lazy component with `preload` property
interface LazyPreload<Props>
    extends React.LazyExoticComponent<React.ComponentType<Props>> {
    preload: () => {};
}

export function ReactLazyPreload<Props>(importStatement: () => Promise<{ default: React.ComponentType<Props> }>) {
    // use Object.assign to set preload
    // otherwise it will complain that Component doesn't have preload
    const Component: LazyPreload<Props> = Object.assign(
        React.lazy(importStatement),
        {
            preload: importStatement
        }
    );

    return Component;
}
