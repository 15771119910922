import DomPurify from 'dompurify';

export function cleanEncodedString(string: string): string {
    if (!string) return '';
    return stripHtml(string);
}

export function cleanHtmlToPlainText(html: string): string {
    return stripHtml(html);
}

export function cleanHtml(html: string) {
    return DomPurify.sanitize(html);
}

export function stripHtml(html): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
}
