import React, { FC, useState, useContext, useEffect } from 'react';
import Button from '@creator/ui/components/Button/ButtonV2';
import Text from '@creator/ui/components/Text/Text';
import { useTranslation } from 'react-i18next';
import { getUserId } from '@src/model/user/helpers';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import FlairCardContent from '../FlairCard/FlairCardContent/FlairCardContent';
import { Flair } from '@creator/sdk/modules/flair/flair.model';

export interface EditFlairModalProps {
    className?: string;
    tokenName: string;
    postId: string;
}

const EditFlairModal: FC<EditFlairModalProps> = props => {
    const { postId, tokenName } = props;

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFlair, setSelectedFlair] = useState(-1);
    const [didFlairChange, setDidFlairChange] = useState(false);

    const token = useStoreState(state => state.token.getToken(tokenName));
    const flairs = token ? token.activeFlairs : [] as Flair[];
    const post = useStoreState(state => state.upvote.getPost(postId));
    const initialFlair = post && post.flairs ? post.flairs[0] : null;
    const { handleModal } = useContext<UseModal>(ModalContext);

    const attachFlair = useStoreActions(actions => actions.flair.attachFlair);
    const loadPost = useStoreActions(actions => actions.upvote.loadPost);

    useEffect(() => {
        setInitialFlair();
    }, []);

    function setInitialFlair(): void {
        const flairIndex = flairs.find(flairInd => flairInd.name === initialFlair);
        if (flairIndex)
            setSelectedFlair(flairs.indexOf(flairIndex));
    }

    async function editFlair(): Promise<void> {
        if (!post) return;
        setIsLoading(true);
        const res = await attachFlair({
            flairName: selectedFlair !== -1 ? flairs[selectedFlair].name : '',
            postId: post.id,
            tokenName: tokenName,
            userId: getUserId()
        });
        await loadPost(post.id);

        setIsLoading(false);
        handleModal(null);
    }

    function renderFlairSelection() {

        return (
            <FlairCardContent tokenName={tokenName} selectedFlair={selectedFlair} onFlairChange={flair => { setDidFlairChange(true); setSelectedFlair(flair === selectedFlair ? -1 : flair); }} />
        );
    }

    function renderActionButton() {

        return (
            <div className="flex items-center justify-center mt-8 space-x-4">
                <Button color="base" onClick={() => handleModal(null)}><Text>{t('cancelFlair')}</Text></Button>
                <Button
                    isDisabled={!didFlairChange}
                    isLoading={isLoading}
                    onClick={() => editFlair()}
                    className=""><Text>{t('updateFlair')}</Text></Button>
            </div>
        );
    }

    return (
        <div className="flex flex-col">
            <Text className="mb-5 text-2xl font-bold text-center text-gray-900 dark:text-gray-50">{t('editFlair')}</Text>
            {renderFlairSelection()}
            {renderActionButton()}
        </div>
    );
};

export default EditFlairModal;
