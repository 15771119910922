import { PermissionType } from '@creator/sdk/modules/account/account.model';
import { getToken } from '../token/helpers';
import { getUser, getUserId } from '../user/helpers';

export function canEditProfile(userId: string): boolean {
    const myUserId = getUserId();
    if (!myUserId) return false;

    if (userId !== myUserId) return false;

    return true;
}


// TODO - Move below functions to token/permission.ts?
export function hasTokenPermission(tokenName: string, type: PermissionType, userId?: string): boolean {
    const _userId = userId || getUserId(); // if userId not provided, use logged in user id

    const user = getUser(_userId);
    if (!user) return false;

    if (!user.tokenPermissions || !user.tokenPermissions[tokenName]) return false;
    return user.tokenPermissions[tokenName].includes(type);
}

export function hasSomeTokenPermission(tokenName: string, userId?: string) {
    const _userId = userId || getUserId(); // if userId not provided, use logged in user id

    const user = getUser(_userId);
    const token = getToken(tokenName);

    if (!user || !token) return false;

    // token creator and network admins have all permissions by default
    if (_userId === token.creatorID || Boolean(user.networkAdmin)) return true;

    if (!user.tokenPermissions || !user.tokenPermissions[tokenName]) return false;
    return user.tokenPermissions[tokenName].length > 0;
}

export function canSellTokens(userId: string, tokenName: string): boolean {
    const user = getUser(userId);
    if (!user || !user.enableSell) return false;
    return user.enableSell[tokenName];
}

export function canCashOutToken(userId: string, tokenName: string): boolean {
    const user = getUser(userId);
    const token = getToken(tokenName);

    if (!user || !token) return false;

    return userId === token.creatorID;
}

export function canDepositToTokenPool(userId: string, tokenName: string): boolean {
    const user = getUser(userId);
    const token = getToken(tokenName);

    if (!user || !token) return false;

    return userId === token.creatorID;
}

export function isPerrmisioned(tokenName: string, type: PermissionType, userId?: string) {
    const _userId = userId || getUserId(); // if userId not provided, use logged in user id
    const user = getUser(_userId);
    if (!user) return false;

    const isNetworkAdmin = Boolean(user.networkAdmin);
    if (isNetworkAdmin) return true;

    const isTokenOwner = user.tokenOwner && user.tokenOwner.includes(tokenName);
    if (isTokenOwner) return true;

    return hasTokenPermission(tokenName, type, userId);
}