import React, { FC, useEffect, useRef, memo, useState, Fragment, Suspense, lazy } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ensureTokenName } from '@src/model/config/helpers';
import { isCurrentPathFirstEverVisitPath } from '@src/utils/url-utils/url-utils';
import { hasActiveFlair } from '@src/model/token/helpers';
import storageService from '@src/services/storage-service';
import { useStoreState, useStoreActions } from '@src/model/hooks';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import TopCenterAd from './TopCenterAd';
import ContentLayout from './ContentLayout';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import { pushToDataLayer } from '@src/services/gtmService';
import usePageTitle from '@src/hooks/usePageTitle';
import TokenPageHeader from './Header/Header';
import PostTab from './PostTab/PostTab';
import TokenPagePostsTab from './PostsTab/PostsTab';
import Header from '@src/components/Header/Header';

const TokenPageHoldersTab = lazy(() => import('./HoldersTab/HoldersTab'));
const TokenPageAboutTab = lazy(() => import('./AboutTab/AboutTab'));
const ReportTab = lazy(() => import('./ActivityTab/ReportTab/ReportTab'));

type TokenPageTab = 'posts' | 'post' | 'about' | 'holders' | 'reports';

const TokenPage: FC = () => {
    const { tokenSlugOrName, identifier, tab: tabUrlParam, commentId } = useParams<Record<string, string>>();
    const tokenName = ensureTokenName(tokenSlugOrName);
    const history = useHistory();
    const location = useLocation();

    const isFirstSetReferredTokenName = useRef(true);
    const sendDailyVisitRequest = useStoreActions(actions => actions.user.sendDailyVisitRequest);
    const loadToken = useStoreActions(actions => actions.token.loadToken);
    const loadTokenAbout = useStoreActions(actions => actions.token.loadTokenAbout);
    const loadPostComment = useStoreActions(actions => actions.upvote.loadPostComment);
    const tab = getTab();
    const token = useStoreState(state => state.token.getToken(tokenName), storeEqualityFn);
    usePageTitle(getPageTitle()); 
    
    const [isLoadingToken, setIsLoadingToken] = useState(true);

    useEffect(() => {
        if (commentId) loadPostComment({ commentId });
    }, [commentId, loadPostComment]);

    useEffect(() => {
        if (tab !== 'post') trackPageView();
    }, [location.pathname, tab]);

    useEffect(() => {
        sendDailyVisitRequest({ tokenName });
        _loadToken();
        loadTokenAbout(tokenName);
    }, [tokenName]);

    useEffect(() => {
        if (token && isFirstSetReferredTokenName.current) {
            _setReferredTokenName();
            isFirstSetReferredTokenName.current = false;
        }
    }, [token]);

    function getPageTitle() {
        if (!token) return '';
        return `${token?.title} News and Community`;
    }

    function trackPageView() {
        pushToDataLayer({ event: 'non__article_view', path: location.pathname });
    }

    function getTab(): TokenPageTab {
        if (tabUrlParam) return tabUrlParam as TokenPageTab;
        if (identifier && !hasActiveFlair(identifier, tokenName)) return 'post';
        return 'posts';
    }

    async function _loadToken(): Promise<void> {
        console.log(`loading token ${tokenName}`);
        try {
            setIsLoadingToken(true);
            await loadToken(tokenName);
            setIsLoadingToken(false);
        } catch (e: any) {
            setIsLoadingToken(false);
            if (e.message.includes('not found')) history.push('/bbs/404');
        }
    }

    function _setReferredTokenName() {
        if (!token) return;
        const referredBy = storageService.localStorage.get('referredTokenName');
        if (!referredBy && isCurrentPathFirstEverVisitPath())
            storageService.localStorage.set('referredTokenName', token.tokenName);
    }

    function renderTabBody() {
        switch (tab) {
            case 'posts':
                return (
                    <TokenPagePostsTab flairId={identifier} tokenName={tokenName} />
                );
            case 'about':
                return (
                    <TokenPageAboutTab tokenName={tokenName} />
                );
            case 'holders':
                return (
                    <TokenPageHoldersTab tokenName={tokenName} />
                );
            default:
                return null;
        }
    }

    function renderTokenPageContent() {
        if (isLoadingToken && !token) return <Spinner className="mx-auto my-10" />;
        if (tab === 'post') {
            return (
                <PostTab commentId={commentId} className="mt-4" identifier={identifier} tokenSlugOrName={tokenSlugOrName} />
            );
        }
        if (tab === 'reports') {
            return (
                <Suspense fallback={<Spinner className="mx-auto my-10" />}>
                    <ReportTab tokenName={tokenName} />
                </Suspense>
            );
        }
        return (
            <Fragment>
                <TokenPageHeader tokenName={tokenName} />
                <TopCenterAd tokenName={tokenName} />
                <ContentLayout tokenName={tokenName} showSidebar={tab !== 'about'}>
                    {renderTabBody()}
                </ContentLayout>
            </Fragment>
        );
    }

    return (
        <div className="transition bg-white page dark:bg-gray-950">
            <Header tokenName={tokenName} />
            <div className="space-y-4" >
                {renderTokenPageContent()}
            </div>
        </div>
    );
};

export default memo(TokenPage);

