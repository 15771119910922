import creatorSdk from '@src/services/creator-sdk';
import { DeleteSuperCommentPayload, EditSuperCommentPayload, ReactionPayload } from '@creator/sdk/modules/super-comment';
import { SuperComment } from '@creator/sdk/modules/super-comment/super-comment.model';
import { GetDocumentsPayload } from '@creator/sdk/modules/db';
import { Pagination } from '@creator/sdk/modules/db/db.model';


export async function editSuperComment(payload: EditSuperCommentPayload): Promise<SuperComment> {
    return creatorSdk.superCommentModule.editSuperComment(payload);
}

export async function reaction(payload: ReactionPayload): Promise<string> {
    return creatorSdk.superCommentModule.reaction(payload);
}

export async function deleteSuperComment(payload: DeleteSuperCommentPayload): Promise<SuperComment> {
    return creatorSdk.superCommentModule.deleteSuperComment(payload);
}

export async function loadSuperComment(commentId: string): Promise<SuperComment> {
    return creatorSdk.superCommentModule.getSuperComment(commentId);
}

export async function loadSuperComments(payload: GetDocumentsPayload<SuperComment>): Promise<Pagination<SuperComment>> {
    return creatorSdk.superCommentModule.getSuperComments(payload);
}

