// Extend the Window interface so TypeScript knows about dataLayer and our custom flag.
declare global {
    interface Window {
        dataLayer: any[];
    }
}


// gtmService.ts
let isInitialized = false;
let debug = false;

export function initializeGTM(gtmId: string, enableDebug = false) {
    if (isInitialized) {
        if (enableDebug) 
            console.debug('[GTM] Already initialized. Skipping script injection.');
        
        return;
    }

    isInitialized = true;
    debug = enableDebug;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    });

    const scriptId = 'gtm-script';
    if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
        document.head.appendChild(script);
        if (enableDebug) 
            console.debug('[GTM] Script injected with ID:', gtmId);
        
    } else if (enableDebug) {
        console.debug('[GTM] Script element already exists.');
    }
}

export function pushToDataLayer(event: Record<string, any>) {
    if (window.dataLayer) {
        window.dataLayer.push(event);
        if (debug) 
            console.debug('[GTM] Event pushed:', event);
        
    } else if (debug) {
        console.warn('[GTM] dataLayer is not defined.');
    }
}
