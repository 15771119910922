import store from '@src/store';
import { getMyUser } from '../user/helpers';
import { Notification } from '@creator/sdk/modules/notification/notification.model';

export function getNotification(id: string): Notification | undefined {
    return store.getState().notification.getNotification(id);
}

export function isUnreadNotification(id: string): boolean {
    const user = getMyUser();
    if (!user) return false;


    // if (!user.notificationViewedAt) return true;

    const notification = getNotification(id);
    if (!notification) return false;
    return !(notification.readRecipients && notification.readRecipients.includes(user.id));
    // return convertFirebaseTimestampToDate(notification.createdAt).getTime() > convertFirebaseTimestampToDate(user.notificationViewedAt).getTime();
}
