import React, { FC, Suspense } from 'react';
import Icon from '../../../basic-components/Icon/Icon';
const NewNotificationsIndicator = React.lazy(() => import('./NewNotificationsIndicator'));

export interface BellNotificationProps {
    href?: string;
    className?: string;
    onClick?: () => void;
}

const BellNotification: FC<BellNotificationProps> = ({ onClick, className }) => {

    function renderIcon() {
        return <Icon name="bellNotification" />;
    }

    return (
        <div className={`flex relative items-center transition text-gray-50 ${className}`} onClick={onClick}>
            {renderIcon()}
            <Suspense fallback={null}>
                <NewNotificationsIndicator className="absolute top-px right-0.5 flex w-[6px] h-[6px]" />
            </Suspense>
        </div>
    );
};

export default BellNotification;
