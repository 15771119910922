import React, { FC, useState,  useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Report } from '@creator/sdk/modules/report/report.model';
import { getMyUser } from '@src/model/user/helpers';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import { useStoreActions } from '@src/model/hooks';

import  SelectReason  from '@src/components/SelectReason/SelectReason';
import { splitCamelCaseToString } from '@src/utils/string-utils/string_utils';

export interface ReportModalProps {
    className?: string;
    options: string[];
    // onClick: (option: string) => void;
    type: 'Post' | 'Comment' | 'SuperComment' | 'User' | 'Community';
    tokenName: string;
    commentId?: string;
    postId?: string;
    userId?: string;
}

const ReportModal: FC<ReportModalProps> = props => {
    const { userId, postId, commentId, tokenName, type, options } = props;
    const entity = type;
    const { t } = useTranslation();
    const myUser = getMyUser();
    const sendReport = useStoreActions(actions => actions.report.sentReport);
    const { handleModal } = useContext<UseModal>(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [optionSelected, setOptionSelected] = useState('');
    const [otherReason, setOtherReason] = useState('');

    async function createReport(option: string, setOption = false): Promise<void> {
        if (setOption)
            setOptionSelected(option);
        setIsLoading(true);
        if (!myUser) return;
        const report: Report = {
            reportReason: optionSelected === t('other') ? t('other') : option,
            // reportText: option,
            reportedType: type === 'SuperComment' ? 'Comment' : type,
            reporterId: myUser.id,
            tokenName: tokenName,
            isSuperComment: type === 'SuperComment'
        };

        if (optionSelected === t('other'))
            report.reportText = option;

        if (commentId)
            report.reportedCommentId = commentId;
        if (postId)
            report.reportedPostId = postId;
        if (userId)
            report.reportedUserId = userId;

        const res = await sendReport(report);
        setIsLoading(true);
        handleModal(null);
    }

    return (
        <SelectReason
            options={options}
            optionSelected={optionSelected}
            setOptionSelected={setOptionSelected}
            otherReason={otherReason}
            setOtherReason={setOtherReason}
            subTitle={t('selectReasonToReport', { entity: splitCamelCaseToString(entity).toLowerCase() })}
            onCancle={() => handleModal(null)}
            onSendReson={createReport}
            isLoading={isLoading}
        />
    );

};

export default ReportModal;
