import creatorSdk from '@src/services/creator-sdk';
import { AttachFlairApiRequestPayload, CreateFlairApiRequestPayload, DeleteFlairApiRequestPayload } from '@creator/sdk/modules/flair/flair.service';
import { Flair } from '@creator/sdk/modules/flair/flair.model';


export function createFlair(payload: CreateFlairApiRequestPayload): Promise<Flair> {
    return creatorSdk.flairModule.createFlair(payload);
}

export function deleteFlair(payload: DeleteFlairApiRequestPayload): Promise<Flair> {
    return creatorSdk.flairModule.deleteFlair(payload);
}

export function attachFlair(payload: AttachFlairApiRequestPayload): Promise<Flair> {
    return creatorSdk.flairModule.attachFlair(payload);
}