import { action, Action } from 'easy-peasy';

export interface AppModel {
    isPostView: boolean;

    setIsPostView: Action<AppModel, boolean>;
}

const appModel: AppModel = {
    isPostView: false,

    setIsPostView: action((state, payload) => {
        state.isPostView = payload;
    })
};

export default appModel;