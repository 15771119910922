import creatorSdk from '@src/services/creator-sdk';
import { Pagination } from '@creator/sdk/utils/utils';
import { GetUsersInfoOrderBy, GetUsersInfoFilterBy, BroadcastEmailReceipt } from '@creator/sdk/modules/admin/admin.service';
import { getPrivateKey } from '../user/helpers';
import { ClaimFundsPayload, BroadcastEmailPayload } from '@creator/sdk/modules/admin';
import { DocumentSnapshot } from '@creator/sdk/modules/db/db.model';
import { UserInfo } from '@creator/sdk/modules/account/account.model';
import { TxReceipt } from '../common';


export async function loadUsersInfo(startAfter: DocumentSnapshot | null, limit: number | null, filterBy?: GetUsersInfoFilterBy[], orderBy?: GetUsersInfoOrderBy): Promise<Pagination<UserInfo>> {
    return creatorSdk.adminModule.getUsersInfo(startAfter, limit, filterBy, orderBy);
}

export async function claimFunds(payload: ClaimFundsPayload): Promise<TxReceipt<ClaimFundsPayload>> {
    const privateKey = getPrivateKey();
    if (!privateKey) throw new Error('invalid private key');

    const res = await creatorSdk.adminModule.claimFunds(privateKey, payload);

    return {
        txId: res,
        payload: payload
    };
}

export async function broadcastEmail(payload: BroadcastEmailPayload): Promise<BroadcastEmailReceipt> {
    const res = await creatorSdk.adminModule.broadcastEmail(payload);
    return res;
}
