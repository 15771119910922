import React, { Children, ReactNode } from 'react';
import { cn } from '../../utils/ui';

export interface TooltipProps {
    className?: string;
    contentClassName?: string;
    children: ReactNode;
    content?: ReactNode | string;
}

const Tooltip: React.FC<TooltipProps> = (props) => {
    const { children, content, contentClassName, className } = props;

    function getBaseClassName() {
        return `relative group cursor-pointer`;
    }
    
    function getContentClassName() {
        return `transition text-gray-50 transition-discrete w-max absolute bottom-4 max-w-[300px] right-1/2 translate-x-1/2 z-10 rounded-lg bg-gray-800 hidden group-hover:block px-2 py-1`;
    }

    return (
        <div className={cn(getBaseClassName(), className)}>
            <div className={cn(getContentClassName(), contentClassName)}>
                {content}
            </div>
            {children}
        </div>
    );
};

export default Tooltip;
