import React, { FC } from 'react';
import { ensureHttpProtocol, getUrlHostname, prettyUrl } from '@src/utils/url-utils/url-utils';
import { isObjectEmpty } from '@src/utils/object-utils/object-utils';
import ExternalLinkPreview, { ExternalLinkPreviewProps } from '@src/basic-components/ExternalLinkPreview/ExternalLinkPreview';
import { cleanHtmlToPlainText } from '@src/utils/html-utils/html-utils';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';

type Meta = {
    image?: { url: string };
    description?: string;
    title?: string;
    url?: string;
};

type BlockData = {
    meta: Meta;
    link: string;
};
export interface EditorViewOnlyLinkToolProps {
    className?: string;
    blockData: BlockData;
    enablePreview?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    externalLinkPreviewProps?: ExternalLinkPreviewProps;
}

const EditorViewOnlyLinkTool: FC<EditorViewOnlyLinkToolProps> = props => {
    const { enablePreview = true, blockData, className } = props;

    function onClick(e: React.MouseEvent<HTMLElement>): void {
        const { onClick } = props;
        if (onClick) return onClick(e);

        window.open(getUrl());
    }

    function getExternalLinkPreviewProps() {
        const { externalLinkPreviewProps = {} } = props;
        return mergeProps<[ExternalLinkPreviewProps, ExternalLinkPreviewProps]>({
            caption: getDescription(),
            image: getImage(),
            title: getTitle(),
            hostname: getUrlHostname(getUrl())
        }, externalLinkPreviewProps);
    }

    function getImage(): string {
        const { meta } = blockData;
        const { image } = meta;

        if (!image) return '';

        return image.url;
    }

    function getUrl(): string {
        const { meta, link } = blockData;
        return meta.url || ensureHttpProtocol(link);
    }

    function getTitle(): string {
        const { meta } = blockData;
        const { title = '' } = meta;
        return cleanHtmlToPlainText(title);
    }

    function getDescription(): string {
        const { meta } = blockData;
        const { description = '' } = meta;
        return cleanHtmlToPlainText(description);
    }

    function renderContent() {
        const { meta, link } = blockData;

        if (!enablePreview) return <div className="break-words">{window.decodeURI(link)}</div>;
        if (!meta || isObjectEmpty(meta)) return <a className="break-words" target="_blank" rel="noopener nofollow noindex noreferrer" href={ensureHttpProtocol(link)}>{prettyUrl(link)}</a>;

        return <ExternalLinkPreview {...getExternalLinkPreviewProps()} />;
    }

    return (
        <div className={`link-tool ${className}`} onClick={onClick} >
            {renderContent()}
        </div>
    );
};

export default EditorViewOnlyLinkTool;
