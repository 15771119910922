
import { ApiResponse } from '../../creator-sdk';
import { post } from '../../network/api-service';
import DbModule from '../db';
import { UserInfo } from '../account/account.model';
import AppCheckModule from '../app-check';
import { getDocs, limit, orderBy, OrderByDirection, query, QueryConstraint, QueryDocumentSnapshot, startAfter, where, WhereFilterOp } from 'firebase/firestore/lite';
import { Pagination } from '../db/db.model';
import AccountModule from '../account';

export interface ClaimFundsApiRequestPayload {
    holder_name: string;
    withdraw_to: string;
    withdraw_amount: string; // 4.000 USDB
    memo: string;
    nonce: number;
}

export interface BroadcastEmailApiRequestPayload {
    userId: string;
    sender: string; // - the name that will appear in the "from"
    emailSubject: string;
    emailContent: string;
}

export interface BroadcastEmailReceipt {
    accepted: string[];
    rejected: string[];
    envelopeTime: number;
    messageTime: number;
    messageSize: number;
    response: string;
    envelope: {
        from: string;
        to: string[];
    };
    messageId: string;
}

export interface GetUsersInfoOrderBy {
    field: 'username' | 'displayName';
    direction: OrderByDirection;
}
export interface GetUsersInfoFilterBy {
    field: 'displayName' | 'username' | 'providerId' | 'providerEmail';
    opStr: WhereFilterOp;
    value: any;
}

export default class AdminService {
        private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private accountModule: AccountModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, accountModule: AccountModule, dbModule: DbModule) {
        
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.accountModule = accountModule;
    }

    async getUsersInfo(_startAfter: QueryDocumentSnapshot<UserInfo> | null, _limit: number, _filterBy?: GetUsersInfoFilterBy[], _orderBy?: GetUsersInfoOrderBy): Promise<Pagination<UserInfo>> {
        const colRef = this.dbModule.getCollectionReference<UserInfo>('UserInfo');
        const queryConstraints: QueryConstraint[] = [where('domain', '==', this.domainName)];

        _filterBy?.forEach((filter): void => {
            const { field, opStr, value } = filter;
            queryConstraints.push(where(field, opStr, value));
        });

        if (_orderBy) // createdAt will be added in the end anyway
            queryConstraints.push(orderBy(_orderBy.field, _orderBy.direction));

        if (_startAfter)
            queryConstraints.push(startAfter(_startAfter));

        queryConstraints.push(limit(_limit));

        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);

        return { hasMore: items.length === _limit, items, lastDoc };
    }

    async broadcastEmail(payload: BroadcastEmailApiRequestPayload): Promise<ApiResponse<BroadcastEmailReceipt>> {
        const token = await this.accountModule.getIdToken();
        if (!token) throw new Error('auth required - inavlid firebase user');

        const res = await post<ApiResponse<any>>('/broadcastEmail', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        });
        return res.data;
    }
}