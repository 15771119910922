
import DbModule from '../db';
import AccountModule from '../account';
import { Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import SearchService, { QueryPayload } from './search.service';
import { CommentSearchResult, PostSearchResult, PostSearchResultItem, SearchResult, TokenSearchResult, UserSearchResult } from './search.model';
import { FirebaseApp } from 'firebase/app';

export type SearchPayload = QueryPayload;
export default class SearchModule {
    accountModule: AccountModule;
    searchService: SearchService;
    private dbModule: DbModule;
        private domainName: string;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        this.dbModule = dbModule;
        this.searchService = new SearchService(contractName, domainName, appCheckModule, dbModule);
        this.accountModule = accountModule;
        
        this.domainName = domainName;
        this.appCheckModule = appCheckModule;
    }

    // async search<T = SearchResult>(payload: QueryPayload): Promise<Pagination<T>> {
    //     const res = await this.searchService.query<T>(payload);
    //     return res.payload;

    //     // return { hasMore: true, items };
    // }

    async searchPosts(payload: QueryPayload): Promise<PostSearchResult> {
        const res = await this.searchService.queryPosts<PostSearchResult>(payload);
        return res.payload;
    }

    async searchComments(payload: QueryPayload): Promise<CommentSearchResult> {
        const res = await this.searchService.queryComments<CommentSearchResult>(payload);
        return res.payload;
    }

    async searchUsers(_payload: QueryPayload): Promise<UserSearchResult> {
        const res = await this.searchService.queryUsers<UserSearchResult>(_payload);
        return res.payload;
    }

    async searchTokens(_payload: QueryPayload): Promise<TokenSearchResult> {
        const res = await this.searchService.queryTokens<TokenSearchResult>(_payload);
        return res.payload;
    }
}