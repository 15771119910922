import React, { FC } from 'react';
import { Redirect, useLocation, Route } from 'react-router-dom';
import { useStoreState } from '@src/model/hooks';

const NetworkAdminRequiredRoute: FC<any> = ({ component: Component, ...rest }) => {
    const location = useLocation();
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const isAuthenticating = useStoreState(state => state.user.isAuthenticating);

    const myUser = useStoreState(state => state.user.myUser);
    return (
        <Route {...rest} render={props => {
            const nextUrl = location.pathname + location.search;
            if (isAuthenticating === null || isAuthenticating === true) return <Spinner className="mx-auto my-10 " />;
            if (isAuthenticating === false && !isLoggedIn) return <Redirect to={`/bbs/login?next=${encodeURIComponent(nextUrl)}`} />;
            if (!myUser || !myUser.networkAdmin) return <Redirect to="/bbs/404" />;
            return <Component {...props} />;
        }} />
    );
};

import Spinner from '@creator/ui/components/Spinner/Spinner';

export default NetworkAdminRequiredRoute;