import { getDomainName } from '@src/config';
import store from '@src/store';
import { DomainPublic } from '@creator/sdk/modules/domain/domain.model';

export function getPublicData(): DomainPublic | undefined {
    return store.getState().domain.getPublicData(getDomainName());
}

export function isSingleTokenDomain(): boolean {
    const publicData = getPublicData();
    if (!publicData) return false;

    return Boolean(publicData.isSingleTokenDomain);
}

export function getDomainSymbol(): string {
    const publicData = getPublicData();
    if (!publicData) return '';

    return publicData.symbol;
}

export function getSignleTokenName(): string {
    const publicData = getPublicData();
    if (!publicData) return '';

    return publicData.singleTokenName || '';
}

export function getFeePercent(): number {
    const publicData = getPublicData();
    if (!publicData) return 0;

    return publicData.royalties / 100;
}

export function getFeeAmount(inputAmount: number): number {
    const publicData = getPublicData();
    if (!publicData) return 0;

    const feePercent = getFeePercent();
    const feeAmount = Number(inputAmount) * feePercent;
    return feeAmount;
}

