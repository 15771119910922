import { FirebaseApp } from 'firebase/app';
import { CollectionReference, doc, DocumentData, DocumentReference, Firestore, getFirestore, WhereFilterOp } from 'firebase/firestore/lite';
import { collection, } from 'firebase/firestore/lite';
export interface FindManyOptions {
    where: {
        fieldPath: string;
        oprStr: WhereFilterOp;
        value: any;
    }[];
    startAfter: string;
    orderBy: string;
    limit?: number;
}

export interface FindOneOptions {
    where: {
        fieldPath: string;
        oprStr: WhereFilterOp;
        value: any;
    };
}

export default class DbService {
    private db: Firestore;
    constructor(app: FirebaseApp) {
        this.db = getFirestore(app);
    }

    getCollectionReference<T = DocumentData>(collectionName: string): CollectionReference<T> {
        return collection(this.db, collectionName) as CollectionReference<T>;
    }

    getSubCollectionReference<T = DocumentData>(collectionName: string, docName: string, subCollectionName: string): CollectionReference<T> {
        return collection(this.db, collectionName, docName, subCollectionName) as CollectionReference<T>;
    }

    getDocReference<T = DocumentData>(collectionName: string, docName: string): DocumentReference<T> {
        const docRef = doc(this.db, collectionName, docName);
        return docRef as DocumentReference<T>;
    }

    getDocReferenceByPath<T = DocumentData>(path: string): DocumentReference<T> {
        const docRef = doc(this.db, path);
        return docRef as DocumentReference<T>;
    }

    generateDocId(collectionName: string): string {
        return doc(collection(this.db, collectionName)).id;
    }
}
