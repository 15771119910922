import { Computed, Action, Thunk, computed, thunk, action } from 'easy-peasy';
import { loadDraft } from './actions';
import { StoreModel } from '..';
import { getUserId } from '../user/helpers';
import { generatePostDraftId } from './helpers';
import { CreateOrEditPostFormValues } from '@src/components/CreateOrEditPost/CreateOrEditPostForm/CreateOrEditPostForm';
import storageService from '@src/services/storage-service';


export interface PostDraft extends CreateOrEditPostFormValues {
    // 
}

export interface SavePostDraftPayload {
    draft: Partial<PostDraft>;
    tokenName: string;
    postId?: string;
    parentPostId?: string;
    userId: string;
}

export interface SavePostDraftByIdPayload {
    draft: Partial<PostDraft>;
    id: string;
}

export interface PostDraftModel {
    drafts: Record<string, Partial<PostDraft> | null>;
    save: Thunk<PostDraftModel, SavePostDraftPayload, null, StoreModel>;
    saveById: Thunk<PostDraftModel, SavePostDraftByIdPayload, null, StoreModel>;
    delete: Thunk<PostDraftModel, string, null, StoreModel>;
    getDraft: Computed<PostDraftModel, (id) => Partial<PostDraft> | null>;
    getMyDraft: Computed<PostDraftModel, (postId: string, tokenName: string) => Partial<PostDraft> | null>;
    loadDraft: Thunk<PostDraftModel, string, null, StoreModel, Promise<Partial<PostDraft> | null>>
    setDraft: Action<PostDraftModel, { id: string, draft: Partial<PostDraft> | null }>
}

const postdraftModel: PostDraftModel = {
    drafts: {},

    save: thunk(async (actions, payload) => {
        const { draft, userId, tokenName, postId, parentPostId } = payload;
        const id = generatePostDraftId(userId, tokenName, postId, parentPostId);
        return actions.saveById({ id, draft });
    }),

    saveById: thunk(async (actions, payload) => {
        const { draft, id } = payload;
        actions.setDraft({ id, draft });
        storageService.localStorage.set(id, JSON.stringify(draft));
    }),

    delete: thunk(async (actions, id) => {
        actions.setDraft({ id, draft: null });
        storageService.localStorage.remove(id);
    }),

    loadDraft: thunk(async (actions, id, helpers) => {
        const localDraft = helpers.getState().getDraft(id);
        if (localDraft) return localDraft;

        // Load from storage
        const draft = await loadDraft(id);
        if (!draft) return null;

        actions.setDraft({ id, draft });
        return draft;
    }),

    setDraft: action((state, payload) => {
        const { id, draft } = payload;
        state.drafts[id] = draft;
    }),

    getDraft: computed(state => id => state.drafts[id] || null),
    getMyDraft: computed(state => (postId, tokenName) => state.getDraft(generatePostDraftId(getUserId(), tokenName, postId)))
};

export default postdraftModel;