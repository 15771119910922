import React, { type FC } from 'react'
import { cn } from '../../utils/ui';
import { PropsWithChildren } from 'react';

interface HrProps extends PropsWithChildren {
    className?: string;
}

const Hr: FC<HrProps> = (props) => {
    const { children, className = '' } = props;

    return (


        <div className={cn('transition h-px my-8 bg-gray-200 border-0 dark:bg-gray-800', className)}>
            {children}
        </div>
    )
}

export default Hr;