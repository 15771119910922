
import DbModule from '../db';
import AccountModule from '../account';
import StorageModule from '../storage';
import TokenModule from '../token';
import TimeModule from '../time';
import WalletService, { GetHoldersFilterBy, GetHoldersOrderBy } from './wallet.service';
import { Wallet } from './wallet.model';
import { Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';
import { QueryDocumentSnapshot } from 'firebase/firestore/lite';

export default class WalletModule {
    accountModule: AccountModule;
    tokenModule: TokenModule;
    walletService: WalletService;
    timeModule: TimeModule;
    domainName: string;
        private dbModule: DbModule;
    private storageModule: StorageModule;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule, accountModule: AccountModule, tokenModule: TokenModule, timeModule: TimeModule) {
        
        this.dbModule = dbModule;
        this.storageModule = storageModule;
        this.domainName = domainName;
        this.walletService = new WalletService(contractName, domainName, appCheckModule, dbModule);
        this.accountModule = accountModule;
        this.tokenModule = tokenModule;
        this.timeModule = timeModule;
        this.appCheckModule = appCheckModule;
    }

    async getWallet(walletId: string): Promise<Wallet | null> { // walletId = userBlockchainId
        const res = await this.walletService.getWallet(walletId)
            .catch((e: Error) => {
                console.error('Error in getWallet ', e);
                throw e;
            });


        return res;
    }

    async getWallets(startAfter: QueryDocumentSnapshot<Wallet> | null, limit: number, filterBy?: GetHoldersFilterBy[], orderBy?: GetHoldersOrderBy[]): Promise<Pagination<Wallet>> {
        return this.walletService.getWallets(startAfter, limit, filterBy, orderBy);
    }
}

