import { action, Action, computed, Computed, Thunk, thunk } from 'easy-peasy';
import { Pagination } from '@creator/sdk/utils/utils';
import { DocumentSnapshot } from '@creator/sdk/modules/db/db.model';
import { ModActivity } from '@creator/sdk/modules/mod-activity/mod-activity.model';
import { loadModActivity } from './actions';
import { GetModActivityFilterBy, GetModActivityOrderBy } from '@creator/sdk/modules/mod-activity/mod-activity.service';

export interface LoadModActivityPayload {
    lowerBound: DocumentSnapshot | null;
    limit: number;
    filterBy: GetModActivityFilterBy[];
    orderBy: GetModActivityOrderBy
    tokenName: string;
}

export interface ModActivityModel {
    modActivity: Record<string, ModActivity>;
    modActivitySearchResultLastDoc: Record<string, unknown>;
    modActivitySearchResult: string[];

    loadModActivity: Thunk<ModActivityModel, LoadModActivityPayload, Promise<Pagination<ModActivity>>>;

    setModActivity: Action<ModActivityModel, Record<string, ModActivity>>;
    setModActivitySearchResult: Action<ModActivityModel, { result: string[]; isConcat?: boolean }>;
    setModActivityLasDoc: Action<ModActivityModel, Record<string, unknown> | DocumentSnapshot>;
    
    getModActivity: Computed<ModActivityModel, (id: string) => ModActivity | undefined>;
    getModActivitySearchResult: Computed<ModActivityModel, (ModActivity | undefined)[]>;

    clearModActivitySearchResult: Action<ModActivityModel>;
}

const modActivityModel: ModActivityModel = {
    modActivity: {},
    modActivitySearchResult: [],
    modActivitySearchResultLastDoc: {},

    getModActivity: computed(state => id => state.modActivity[id]),
    getModActivitySearchResult: computed(state => state.modActivitySearchResult.map(id => state.getModActivity(id))),

    loadModActivity: thunk(async (actions, payload) => {
        const { lowerBound, limit, filterBy, orderBy, tokenName } = payload;

        const { hasMore, items, lastDoc } = await loadModActivity(tokenName, lowerBound, limit, filterBy, orderBy);
        const ids: string[] = [];
        const modActivity: Record<string, ModActivity> = {};
        items.forEach(item => {
            modActivity[item.id] = item;
            ids.push(item.id);
        });

        if (lastDoc)
            actions.setModActivityLasDoc(lastDoc);

        actions.setModActivity(modActivity);
        actions.setModActivitySearchResult({ result: ids, isConcat: Boolean(lowerBound) });
        return { hasMore, items, lastDoc };
    }),

    setModActivity: action((state, payload) => {
        state.modActivity = { ...state.modActivity, ...payload };
    }),

    setModActivitySearchResult: action((state, payload) => {
        if (!payload.isConcat)
            state.modActivitySearchResult = payload.result;
        else
            state.modActivitySearchResult = state.modActivitySearchResult.concat(payload.result);
    }),

    setModActivityLasDoc: action((state, lastDoc) => {
        state.modActivitySearchResultLastDoc = lastDoc;
    }),

    clearModActivitySearchResult: action(state => {
        state.modActivitySearchResult = [];
        state.modActivitySearchResultLastDoc = {};
    })
};

export default modActivityModel;