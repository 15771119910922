import React, { FC } from 'react';
import Tooltip from '../Tooltip/Tooltip';
import { cn } from '../../utils/ui';

export interface HelpTipProps {
    className?: string;
    text?: string;
    children?: React.ReactNode;
}

const HelpTip: FC<HelpTipProps> = props => {
    const { children, className } = props;

    function getBaseClassName() {
        return cn('text-xs cursor-pointer');
    }

    function renderIcon() {
        return (
            <svg
                className="text-base w-[1em] h-[1em]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}>
                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                <path d="M12 9h.01" />
                <path d="M11 12h1v4h1" />
            </svg>
        );
    }

    function renderTooltip() {
        const { text } = props;
        return (
            <Tooltip className={cn(getBaseClassName(), className)} content={children || text}>
                {renderIcon()}
            </Tooltip>
        );
    }

    return renderTooltip();
};

export default HelpTip;
