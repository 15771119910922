import React, { FC } from 'react';
import GoogleAdSlot from '@src/components/GoogleAdSlot/GoogleAdSlot';
import { getYahooAdLocatioByTokenName, getYahooAdByLocation, getYahooAdTargetingByTokenName } from '@src/model/config/helpers';
import { isMobile } from '@src/utils/utils';

interface TopCenterAdProps {
    tokenName: string;
}

const TopCenterAd: FC<TopCenterAdProps> = ({ tokenName }) => {
    const location = getYahooAdLocatioByTokenName(tokenName, 'top_center');
    if (!location) return null;

    const sizes = isMobile() ? [320, 50] : [728, 90];
    const yahooAd = getYahooAdByLocation(location);
    if (!yahooAd) return null;
    const { adUnitPath, divId } = yahooAd;
    
    return (
        <GoogleAdSlot
            targeting={getYahooAdTargetingByTokenName(tokenName)}
            className="mx-auto"
            adUnitPath={adUnitPath}
            sizes={sizes as [number, number]}
            divId={divId}
        />
    );
};

export default TopCenterAd;
