import { cn } from '@creator/ui/utils/ui';
import React, { FC } from 'react';

export type PopoverAlignment = 'bottom' | 'left' | 'top' | 'right' | 'center';
export type PopoverPosition = 'bottom' | 'left' | 'top' | 'right';

export interface PopoverProps {
    className?: string;
    contentClassName?: string;
    trigger: React.ReactNode;
    align?: PopoverAlignment;
    position?: PopoverPosition;
    isVisible?: boolean;
    children?: React.ReactNode;
}

const Popover: FC<PopoverProps> = props => {
    const { isVisible, contentClassName, trigger, children, className, position = 'bottom', align = 'right' } = props;
    
    const containerClasses = `relative flex ${className ?? ''}`;

    let contentClasses =
        'absolute min-w-full transition-all duration-150 ease pointer-events-none z-20 rounded-lg bg-white text-gray-900 shadow dark:bg-gray-900 dark:text-gray-50';

    // Toggle visibility styles
    
    contentClasses += isVisible
        ? ' visible opacity-100 pointer-events-auto'
        : ' invisible opacity-0 pointer-events-none';

    let posClass = '';
    if (position === 'top') 
        posClass = 'bottom-full';
    else if (position === 'bottom') 
        posClass = 'top-full';
    else if (position === 'left') 
        posClass = 'right-full';
    else if (position === 'right') 
        posClass = 'left-full';
    

    // Determine alignment class.
    // For horizontal popovers (position top or bottom), if align is top/bottom/center then center horizontally.
    // For vertical popovers (position left or right), if align is left/right/center then center vertically.
    let alignClass = '';
    if (position === 'top' || position === 'bottom') {
        if (align === 'top' || align === 'bottom' || align === 'center') 
            alignClass = 'left-1/2 -translate-x-1/2';
        else if (align === 'left') 
            alignClass = 'left-0';
        else if (align === 'right') 
            alignClass = 'right-0';
        
    } else {
        if (align === 'left' || align === 'right' || align === 'center') 
            alignClass = 'top-1/2 -translate-y-1/2';
        else if (align === 'top') 
            alignClass = 'top-0';
        else if (align === 'bottom') 
            alignClass = 'bottom-0';
        
    }

    // Combine dynamic positioning and alignment classes.
    contentClasses += ` ${posClass} ${alignClass}`;

    return (
        <div className={containerClasses}>
            {trigger}
            <div className={cn(contentClasses, contentClassName)}>
                {children}
            </div>
        </div>
    );
};

export default Popover;
