export const millisecondsInDay: number = 24 * 60 * 60 * 1000;
export const millisecondsInWeek: number = 7 * millisecondsInDay;

export default class DateSplitter {
    date: Date;

    constructor(date: Date) {
        this.date = date;
    }

    getDay(hour?: number): number {
        if (hour) {
            const day = Math.floor(this.date.getTime() / millisecondsInDay);
            if (this.date.getHours() < hour)
                return day - 1;
            return day;
        }
        return Math.floor(this.date.getTime() / millisecondsInDay);
    }
    getWeek(): number {
        return Math.floor(this.date.getTime() / millisecondsInWeek);
    }

    getYearAndMonth(): string {
        const month = `${this.date.getMonth() + 1}`.padStart(2, '0');
        return `${this.date.getFullYear()}-${month}`;
    }
}
