import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { FirebaseApp } from 'firebase/app';
import AccountModule from '../account';
import AppCheckModule from '../app-check';

export default class AnalyticsModule {
    private domainName: string;
    private analytics: Analytics;

    constructor(app: FirebaseApp, domainName: string, appCheckModule: AppCheckModule, accountModule: AccountModule) {
        this.domainName = domainName;
        console.log(`AnalyticsModule initialized for domain ${domainName}`);
        this.analytics = getAnalytics(app);
    }

    logEvent(eventName: string, params: Record<string, any>): void {
        logEvent(this.analytics, eventName, { ...params, domain: this.domainName });
    }
}

