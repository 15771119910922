import store from '@src/store';
import { PostComment } from '@creator/sdk/modules/upvote/upvote.model';
import { getMyUser } from '../user/helpers';
import { SuperComment } from '@creator/sdk/modules/super-comment/super-comment.model';

export function getSuperComment(commentId: string): SuperComment | null {
    return store.getState().superComment.getSuperComment(commentId);
}

export function getPostCommentReply(commentId: string, replyId: string): PostComment | undefined {
    return store.getState().upvote.getPostCommentReply(commentId, replyId);
}

export function getPostTitleMaxLength(): number {
    return 100;
}

export function isSuperCommentDeleted(superCommentId: string): boolean {
    const comment = getSuperComment(superCommentId);
    if (!comment) return false;

    return [1, 2, 3, 4].includes(comment.status);
}

export function getSuperCommentReactionCount(superCommentId: string, reactionName: string): number {
    const comment = getSuperComment(superCommentId);
    if (!comment) return 0;
    if (!comment.reactionsCount) return 0;

    return comment.reactionsCount[reactionName] || 0;
}

export function isReactedToSuperComment(userId: string, superCommentId: string, reactionName: string): boolean {
    if (!userId) return false;
    const myUser = getMyUser();
    const comment = getSuperComment(superCommentId);
    if (!comment || !comment.reactionsUsers) return false;
    if (!comment.reactionsUsers[reactionName]) return false;
    let isReactedByForwardAccount = false;
    if (myUser && myUser.ForwardedFromArray) {
        myUser.ForwardedFromArray.map(forwardedId => {
            if (Boolean(comment.reactionsUsers[reactionName].includes(forwardedId)))
                isReactedByForwardAccount = true;

        });
    }
    if (isReactedByForwardAccount) return true;
    return Boolean(comment.reactionsUsers[reactionName].find(_userId => _userId === userId));
}