
import DbModule from '../db';
import AccountModule from '../account';
import { Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import CashierService, { GetCashierQueueFilterBy, GetCashierQueueOrderBy } from './cashier.service';
import { CashierQueue } from './cashier.model';
import { FirebaseApp } from 'firebase/app';
import { QueryDocumentSnapshot } from 'firebase/firestore/lite';

export default class CashierModule {
    accountModule: AccountModule;
    cashierService: CashierService;
    private dbModule: DbModule;
        private domainName: string;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        this.dbModule = dbModule;
        this.cashierService = new CashierService(contractName, domainName, appCheckModule, dbModule);
        this.accountModule = accountModule;
        
        this.domainName = domainName;
        this.appCheckModule = appCheckModule;
    }

    async getCashierQueue(tokenName: string, startAfter: QueryDocumentSnapshot<CashierQueue> | null, limit: number, filterBy?: GetCashierQueueFilterBy[], orderBy?: GetCashierQueueOrderBy): Promise<Pagination<CashierQueue>> {
        return this.cashierService.getCashierQueue(tokenName, startAfter, limit, filterBy, orderBy);
    }
}