import React, { FC, memo } from 'react';
import { noop } from '@src/utils/utils';
import Text from '@creator/ui/components/Text/Text';
import { cleanHtmlToPlainText } from '@src/utils/html-utils/html-utils';
import { getMediaPoster } from '@src/model/upvote/helpers';
import Media, { MediaProps } from '@src/basic-components/Media/Media';
import MediaPreview, { MediaPreviewProps } from '@src/basic-components/Media/MediaPreview';
import ResponseFullScreenButton from '@src/basic-components/ResponseFullScreenButton/ResponseFullScreenButton';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { getYahooAdTargetingByTokenName } from '@src/model/config/helpers';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

type VideoToolFile = {
    url: string;
};
type BlockData = {
    url: string;
    withBorder: boolean;
    stretched: boolean;
    file: VideoToolFile;
    caption: string;
};
export interface EditorViewOnlyVideoToolProps {
    className?: string;
    showAsPreview?: boolean;
    blockData: BlockData;
    tokenName?: string;
    mediaPreivewProps?: Partial<MediaProps>;
    onResponseFullScreenButtonClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    mediaProps?: Partial<MediaProps>;
}

const EditorViewOnlyVideoTool: FC<EditorViewOnlyVideoToolProps> = props => {
    const { showAsPreview, onClick = noop, blockData, className, tokenName } = props;

    function renderMedia() {
        const { mediaProps = {} } = props;

        const { url, image, cdn } = blockData;

        const _props = mergeProps<[MediaProps, Partial<MediaProps>]>({
            poster: image ? image.url : getMediaPoster(url),
            src: cdn && cdn.mp4Url ? cdn.mp4Url : url,
            type: 'video',
            adTargeting: tokenName ? getYahooAdTargetingByTokenName(tokenName) : undefined
        }, mediaProps);

        return <Media {..._props} />;
    }

    function renderCaption() {
        const { caption } = blockData;
        if (!caption) return;
        return (
            <Text enableMultiline={true} className="block mt-1 text-sm text-gray-500" data-placeholder="Caption">
                {cleanHtmlToPlainText(caption)}
            </Text>
        );
    }

    function renderContent() {
        if (showAsPreview) return renderVideoPreview();

        const { stretched, withBorder } = blockData;

        const withBorderClassName = withBorder ? 'video-tool--withBorder' : '';
        const stretchedClassName = stretched ? 'video-tool--stretched' : '';

        return (
            <div className={`cdx-block video-tool video-tool--filled ${withBorderClassName} ${stretchedClassName}`}>
                <div className="video-tool__video ">
                    <div className="video-tool__video-preloader"></div>
                    {renderMedia()}
                </div>
                {renderCaption()}
            </div>
        );
    }

    function renderVideoPreview() {
        const { mediaPreivewProps = {} } = props;
        const { url, image = {}, cdn } = blockData;

        const _props = mergeProps<[MediaPreviewProps, Partial<MediaPreviewProps>]>({
            image: image.url,
            animation: cdn ? cdn.previewAnimationUrl : undefined,
            className: 'aspect-video',
            url
        }, mediaPreivewProps);

        return <MediaPreview {..._props} />;
    }

    function renderResponseFullScreenButton() {
        const { onResponseFullScreenButtonClick } = props;
        if (!onResponseFullScreenButtonClick) return;

        return <ResponseFullScreenButton className="absolute z-10 right-4 top-4" onClick={onResponseFullScreenButtonClick} />;
    }

    return (
        <div onClick={onClick} className={`relative video-tool ${className}`}>
            {renderContent()}
            {renderResponseFullScreenButton()}
        </div>
    );
};

export default memo(EditorViewOnlyVideoTool, propsEqualityFn);

