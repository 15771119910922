
import NotificationService, { BlockUserNotificationsApiRequest, DeleteNotificationsApiRequest, GetNotificationsFilterBy, GetNotificationsOrderBy, SetNotificationsReadApiRequest, SetNotificationsSettingsApiRequest } from './notification.service';
import DbModule from '../db';
import AccountModule from '../account';
import { Notification, NotificationDbTableRow } from './notification.model';
import { Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';
import { QueryDocumentSnapshot } from 'firebase/firestore/lite';


export type SetNotificationsReadPayload = SetNotificationsReadApiRequest;
export type DeleteNotificationsPayload = DeleteNotificationsApiRequest;
export type BlockUserNotificationsPayload = BlockUserNotificationsApiRequest;
export type SetNotificationsSettingsPayload = SetNotificationsSettingsApiRequest;

export default class NotificationModule {
    accountModule: AccountModule;
    notificationService: NotificationService;
    private dbModule: DbModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        this.dbModule = dbModule;
        this.notificationService = new NotificationService(contractName, domainName, appCheckModule, dbModule, accountModule);
        this.accountModule = accountModule;
    }

    async getNotifications(userId: string, startAfter: QueryDocumentSnapshot<NotificationDbTableRow> | null, limit: number, filterBy?: GetNotificationsFilterBy[], orderBy?: GetNotificationsOrderBy): Promise<Pagination<Notification>> {
        return this.notificationService.getNotifications(userId, startAfter, limit, filterBy, orderBy);
    }

    async blockUserNotifications(payload: BlockUserNotificationsPayload): Promise<string> {
        const res = await this.notificationService.blockUserNotifications(payload);
        return res.payload;
    }

    async setNotificationsSettings(payload: SetNotificationsSettingsPayload): Promise<string> {
        const res = await this.notificationService.setNotificationsSettings(payload);
        return res.payload;
    }

    async setNotificationRead(payload: SetNotificationsReadPayload): Promise<string> {
        const res = await this.notificationService.setNotificationRead(payload);
        return res.payload;
    }

    async deleteNotification(payload: DeleteNotificationsPayload): Promise<string> {
        const res = await this.notificationService.deleteNotification(payload);
        return res.payload;
    }
}