import creatorSdk from '@src/services/creator-sdk';
import { GetTokensOrderBy } from '@creator/sdk/modules/token';
import { BbsToken, Token } from '@creator/sdk/modules/token/token.model';
import { GetTokensFilterBy } from '@creator/sdk/modules/token/token.service';
import { Pagination, PaginationLastDoc } from '@creator/sdk/modules/db/db.model';

export async function loadToken(tokenName: string): Promise<Token> {
    return creatorSdk.tokenModule.getToken(tokenName);
}

export async function loadBbsToken(tokenName: string): Promise<BbsToken> {
    return creatorSdk.tokenModule.getBbsToken(tokenName);
}

export async function loadTokenAbout(tokenName: string): Promise<string> {
    return creatorSdk.tokenModule.getTokenAbout(tokenName);
}

export async function loadTokens(lowerBound: PaginationLastDoc<Token> | null, limit = 20, orderBy?: GetTokensOrderBy, filterBy?: GetTokensFilterBy[]): Promise<Pagination<Token>> {
    return creatorSdk.tokenModule.getTokens(lowerBound, limit, orderBy, filterBy);
}
