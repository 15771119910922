import React, { FC, memo } from 'react';
import { noop } from '@src/utils/utils';
import { Post, Post as PostModel } from '@creator/sdk/modules/upvote/upvote.model';
import PostCard from '../../PostCard/PostCard';
import HorizontalScrollable from '@src/basic-components/HorizontalScrollable/HorizontalScrollable';

export interface PostListProps {
    className?: string;
    tokenName: string;
    pinnedPosts: (PostModel | undefined)[];
    onPostClick?: (e: React.MouseEvent<HTMLElement>, postId: string, post?: Post) => void;
}

const PinnedPosts: FC<PostListProps> = props => {
    const { onPostClick = noop, pinnedPosts, tokenName } = props;

    function renderPostCard(post: PostModel | undefined) {
        if (!post) return null;

        return (
            <PostCard
                key={post.id}
                hideFooter={true}
                // bodyProps={{ className: 'p-2 lg:p-2' }}
                className="shrink-0 w-[310px] h-[310px] overflow-hidden"
                onClick={onPostClick}
                editorProps={{
                    hideMainTextIfMediaExists: true,
                    imagePreviewProps: { className: '-mx-3 -mt-3 lg:-mx-5' },
                    editorViewOnlyVideoToolProps: {
                        className: '-mx-3 -mt-3 lg:-mx-5'
                    }
                }}
                postCardHeaderProps={{
                    // className: 'p-2 lg:p-2',
                    postMenuPopoverProps: {
                        // className: 'right-2'
                    },
                    postBasicInfoProps: {
                        createdAt: '' // hide created at time for pinned posts
                    }
                }}
                postId={post.id}
                showFooter={false}
                tokenName={tokenName}
            />
        );
    }

    function renderPinnedPosts() {
        if (pinnedPosts.length === 0) return null;
        return pinnedPosts.map(renderPostCard);
    }

    return (
        <HorizontalScrollable
            isLoadingContent={false}
            scrollOffset={330}  // Adjust based on the visual size of PostCard
            scrollContainerClassname="space-x-5"
            className=""
        >
            {renderPinnedPosts()}
        </HorizontalScrollable>
    );
};

export default memo(PinnedPosts);
