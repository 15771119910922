import React, { FC } from 'react';

export interface CharacterCounterProps {
    className?: string;
    maxChars: number;
    value: string;
}

const CharacterCounter: FC<CharacterCounterProps> = props => {
    const { className } = props;

    function getText(): string {
        const { value, maxChars } = props;
        const length = value.length;
        return `${length}/${maxChars}`;
    }

    return (
        // font-mono - monospace font so each character in the counter will have the same width, thus preventing the text from shifting
        <span className={`font-mono	${className}`}>
            {getText()}
        </span>
    );
};

export default CharacterCounter;
