export function splitCamelCaseToString(s: string): string {
    return s.split(/(?=[A-Z])/).join(' ');
}

export function toCamelCase<T extends string>(string: string): T {
    return string.replace(/\W+(.)/g, (y, x) => x.toUpperCase()) as T;
}

export function isStringJson(string: string): boolean {
    let parsedStr = string;
    try {
        parsedStr = JSON.parse(string);
    } catch (e: any) {
        return false;
    }
    return typeof parsedStr === 'object';
}

export async function copyTextToClipboard(text: string): Promise<boolean> {
    await navigator.clipboard.writeText(text).catch(() => {
        return false;
    });
    return true;
}
