import { getMyForwardedFromUserIds, getMyUser, getUserId } from '../user/helpers';
import { isPerrmisioned } from '../user/permission';
import { getToken } from './helpers';

export enum TokenPermissions {
    hideContent = 'hideContent',
    pinPost = 'pinPost',
    banUsers = 'banUser',
    blockChat = 'blockChat',
    approveAdmin = 'approveAdmin',
    whitelistUser = 'whitelistUser',
    editBBS = 'editBBS',
    viewUserEmail = 'viewUserEmail',
}


export function canManageFlairs(tokenName: string): boolean {
    const token = getToken(tokenName);
    if (!token) return false;

    const user = getMyUser();
    if (!user) return false;

    const isTokenCreator = user.id === token.creatorID;
    const isNetworkAdmin = Boolean(user.networkAdmin);

    return isTokenCreator || isNetworkAdmin;
}

export function canEditToken(tokenName: string): boolean {
    const userId = getUserId();
    if (!userId) return false;

    const token = getToken(tokenName);
    if (!token) return false;

    const user = getMyUser();
    if (!user) return false;

    return isPerrmisioned(tokenName, 'editBBS');
}

export function canTransferOwnership(tokenName: string): boolean {
    const userId = getUserId();
    if (!userId) return false;

    const token = getToken(tokenName);
    if (!token) return false;

    const user = getMyUser();
    if (!user) return false;

    const isTokenCreator = userId === token.creatorID;
    return isTokenCreator;
}

export function canDeleteToken(tokenName: string): boolean {
    const token = getToken(tokenName);
    if (!token) return false;

    const user = getMyUser();
    if (!user) return false;

    const isTokenCreator = user.id === token.creatorID;

    return Boolean(user.domainAdmin || isTokenCreator);
}

export function canCancelSellToken(tokenName: string, sellerId: string): boolean {
    const userId = getUserId();
    if (!userId) return false;

    if (userId === sellerId) return true;

    const forwardedFrom = getMyForwardedFromUserIds();
    if (forwardedFrom.includes(sellerId)) return true;

    const token = getToken(tokenName);
    if (!token) return false;

    const isTokenCreator = userId === token.creatorID;
    return isTokenCreator;
}
