import { Computed, Action, Thunk, computed, thunk, action } from 'easy-peasy';
import { StoreModel, StoreModelSetPayload } from '..';
import { deleteSuperComment, editSuperComment, loadSuperComment, loadSuperComments } from './actions';
import { SuperComment } from '@creator/sdk/modules/super-comment/super-comment.model';
import { Pagination, PaginationLastDoc } from '@creator/sdk/modules/db/db.model';
import { DeleteSuperCommentPayload, EditSuperCommentPayload } from '@creator/sdk/modules/super-comment';
import { GetDocumentsPayload } from '@creator/sdk/modules/db';

export interface SuperCommentModel {
    superComments: { [commentId: string]: SuperComment };

    superCommentSearchResult: Record<string, string[]>; // postId (parent) -> superCommentId[];
    superCommentSearchResultLastDoc: Record<string, PaginationLastDoc<SuperComment>>;

    getSuperComment: Computed<SuperCommentModel, (postId: string) => SuperComment | null>;
    getSuperCommentSearchResult: Computed<SuperCommentModel, (postId: string) => SuperComment[]>;

    editSuperComment: Thunk<SuperCommentModel, EditSuperCommentPayload>;
    deleteSuperComment: Thunk<SuperCommentModel, DeleteSuperCommentPayload>;

    set: Action<SuperCommentModel, StoreModelSetPayload<SuperCommentModel>>;
    setSuperComment: Action<SuperCommentModel, SuperComment>;
    setSuperCommentSearchResult: Action<SuperCommentModel, { postId: string; ids: string[] }>;
    setSuperCommentSearchResultLastDoc: Action<SuperCommentModel, { lastDoc: PaginationLastDoc<SuperComment>; postId: string }>;

    concatSuperCommentSearchResult: Action<SuperCommentModel, { postId: string, ids: string[] }>;
    clearSuperCommentSearchResult: Action<SuperCommentModel, string>; // postId -> SuperComment

    loadSuperComment: Thunk<SuperCommentModel, string, null, StoreModel, Promise<SuperComment>>;
    loadSuperComments: Thunk<SuperCommentModel, GetDocumentsPayload<SuperComment>, null, StoreModel, Promise<Pagination<SuperComment>>>;
}
const superCommentModel: SuperCommentModel = {
    superComments: {},
    superCommentSearchResult: {},
    superCommentSearchResultLastDoc: {},

    getSuperComment: computed(state => id => state.superComments[id]),
    getSuperCommentSearchResult: computed(state => postId => (state.superCommentSearchResult[postId] || []).map(superCommentId => state.getSuperComment(superCommentId)) as SuperComment[]),

    loadSuperComments: thunk(async (actions, payload, helpers) => {
        const res = await loadSuperComments(payload);

        const { items, lastDoc } = res;

        if (!items.length) return res;

        const superComments = {};
        const ids: string[] = [];
        items.forEach(superComment => {
            superComments[superComment.id] = superComment;
            ids.push(superComment.id);
        });

        const postId = items[0].postId;
        actions.set({ key: 'superComments', value: { ...helpers.getState().superComments, ...superComments } });
        actions.concatSuperCommentSearchResult({ postId, ids });

        if (lastDoc)
            actions.setSuperCommentSearchResultLastDoc({ postId, lastDoc });

        return res;
    }),

    loadSuperComment: thunk(async (actions, superCommentId) => {
        const res = await loadSuperComment(superCommentId);
        actions.setSuperComment(res);
        return res;
    }),

    editSuperComment: thunk(async (actions, payload, helpers) => {
        const superComment = await editSuperComment(payload);
        actions.setSuperComment(superComment);
        return superComment;
    }),

    deleteSuperComment: thunk(async (actions, payload, helpers) => {
        const superComment = await deleteSuperComment(payload);
        actions.setSuperComment(superComment);
        return superComment;
    }),

    concatSuperCommentSearchResult: action((state, payload) => {
        const { ids, postId } = payload;

        const oldSearchResult = state.superCommentSearchResult[postId] || [];
        state.superCommentSearchResult[postId] = oldSearchResult.concat(ids);
    }),

    set: action((state, payload) => {
        const { key, value } = payload;
        state[key] = value;
    }),

    setSuperComment: action((state, superComment) => {
        state.superComments[superComment.commentId] = superComment;
    }),

    setSuperCommentSearchResult: action((state, { postId, ids }) => {
        state.superCommentSearchResult[postId] = ids;
    }),

    setSuperCommentSearchResultLastDoc: action((state, { postId, lastDoc }) => {
        state.superCommentSearchResultLastDoc[postId] = lastDoc;
    }),

    clearSuperCommentSearchResult: action((state, postId) => {
        delete state.superCommentSearchResult[postId];
        delete state.superCommentSearchResultLastDoc[postId];
    })
};

export default superCommentModel;