import AccountModule from './modules/account';
import * as apiService from './network/api-service';
import { signUrl } from './modules/moonpay';
import DbModule from './modules/db';
import VotingModule from './modules/voting';
import TokenModule from './modules/token';
import StorageModule from './modules/storage';
import UpvoteModule from './modules/upvote';
import ReportModule from './modules/report';
import ConfigModule from './modules/config';
import NotificationModule from './modules/notification';
import AdminModule from './modules/admin';
import DomainModule from './modules/domain';
import AnalyticsModule from './modules/analytics';
import TimeModule from './modules/time';
import DirectMessageModule from './modules/direct-message';
import PublicSettingsModule from './modules/public-settings';
import AdServerModule from './modules/ad-server';
import WalletModule from './modules/wallet';
import TokenPriceModule from './modules/token-price';
import AppCheckModule from './modules/app-check';
import CashierModule from './modules/cashier';
import { FirebaseApp, initializeApp } from 'firebase/app';
import SearchModule from './modules/search';
import FollowModule from './modules/follow';
import FlairModule from './modules/flair';
import ModActivityModule from './modules/mod-activity';
import ResponseModule from './modules/response';
import SuperCommentModule from './modules/super-comment';
import ShortUrlModule from './modules/short-url';


export interface ApiResponse<T> {
    status: 'ok' | 'error';
    context: string;
    message: string;
    payload: T;
}

export interface FirebaseConfig {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
}

export interface CreatorSDKConfig {
    rpcBaseUrl: string;
    operatorName: string;
    domainName: string;
    operatorSalt: string;
    contractName: string;
    registryContractName: string;

    cloudRunBaseUrl: string;
    functionsBaseUrl: string;
    firebaseConfig: FirebaseConfig;
    enableAnalytics: boolean;
    appCheckRecaptchaKey: string;
    enableAppCheckDebugToken: boolean;

    // upvote - need to be a daaplet
    upvote: {
        contractName: string;
    };
}

export default class CreatorSDK {
    operatorName: string;
    domainName: string;
    operatorSalt: string;
    app: FirebaseApp;

    // Modules
    accountModule: AccountModule;
    appCheckModule: AppCheckModule;
    analyticsModule: AnalyticsModule | undefined;
    timeModule: TimeModule;
    private dbModule: DbModule;
    storageModule: StorageModule;
    configModule: ConfigModule;
    votingModule: VotingModule;
    tokenModule: TokenModule;
    tokenPriceModule: TokenPriceModule;
    upvoteModule: UpvoteModule;
    superCommentModule: SuperCommentModule;
    flairModule: FlairModule;
    followModule: FollowModule;
    adServerModule: AdServerModule;
    wallet: WalletModule;
    responseModule: ResponseModule;
    notificationModule: NotificationModule;
    directMessageModule: DirectMessageModule;
    cashierModule: CashierModule;
    searchModule: SearchModule;
    adminModule: AdminModule;
    domainModule: DomainModule;
    publicSettingsModule: PublicSettingsModule;
    reportModule: ReportModule;
    modActivityModule: ModActivityModule;
    shortUrlModule: ShortUrlModule;

    constructor(config: CreatorSDKConfig) {
        const { appCheckRecaptchaKey, enableAnalytics = false, enableAppCheckDebugToken, rpcBaseUrl, operatorName, domainName, operatorSalt, contractName, registryContractName, functionsBaseUrl, firebaseConfig, upvote, cloudRunBaseUrl } = config;
        this.domainName = domainName;
        this.operatorName = operatorName;
        this.operatorSalt = operatorSalt;
        this.app = initializeApp(firebaseConfig);


        this.appCheckModule = new AppCheckModule(this.app, appCheckRecaptchaKey, enableAppCheckDebugToken);

        apiService.init(functionsBaseUrl, cloudRunBaseUrl);

        this.dbModule = new DbModule(this.app);
        this.accountModule = new AccountModule(this.app, contractName, domainName, this.appCheckModule, this.dbModule );
        this.storageModule = new StorageModule(this.app, domainName, firebaseConfig.storageBucket, this.accountModule);
        this.configModule = new ConfigModule(this.app, domainName, this.storageModule);

        if (enableAnalytics)
            this.analyticsModule = new AnalyticsModule(this.app, domainName, this.appCheckModule, this.accountModule);
        
        this.timeModule = new TimeModule(this.app, domainName);

        this.votingModule = new VotingModule(this.app, contractName, domainName, this.appCheckModule, this.dbModule, this.accountModule);
        this.notificationModule = new NotificationModule(this.app, contractName, domainName, this.appCheckModule, this.dbModule, this.accountModule);
        this.followModule = new FollowModule(this.app, contractName, domainName, this.appCheckModule, this.dbModule, this.accountModule);
        this.directMessageModule = new DirectMessageModule(this.app, contractName, domainName, this.appCheckModule, this.dbModule, this.accountModule);
        this.cashierModule = new CashierModule(this.app, contractName, domainName, this.appCheckModule, this.dbModule, this.accountModule);
        this.searchModule = new SearchModule(this.app, contractName, domainName, this.appCheckModule, this.dbModule, this.accountModule);
        this.tokenModule = new TokenModule(this.app, contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule, this.accountModule, );
        this.tokenPriceModule = new TokenPriceModule(this.dbModule);
        this.upvoteModule = new UpvoteModule(this.app, upvote.contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule, this.accountModule, this.tokenModule, this.timeModule);
        this.superCommentModule = new SuperCommentModule(this.app, upvote.contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule, this.accountModule, this.tokenModule, this.timeModule);
        this.flairModule = new FlairModule(this.app, upvote.contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule, this.accountModule, this.tokenModule, this.timeModule);
        this.adServerModule = new AdServerModule(this.app, upvote.contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule, this.accountModule, this.tokenModule, this.timeModule);
        this.wallet = new WalletModule(this.app, upvote.contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule, this.accountModule, this.tokenModule, this.timeModule);
        this.responseModule = new ResponseModule(this.app, upvote.contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule, this.accountModule, this.tokenModule, this.timeModule);
        this.adminModule = new AdminModule(this.app, contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule, this.accountModule, this.tokenModule);
        this.domainModule = new DomainModule(this.app, contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule);
        this.publicSettingsModule = new PublicSettingsModule(this.app, contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule);
        this.reportModule = new ReportModule(this.app, upvote.contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule, this.accountModule, this.tokenModule, this.timeModule);
        this.modActivityModule = new ModActivityModule(this.app, upvote.contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule, this.accountModule, this.tokenModule, this.timeModule);
        this.shortUrlModule = new ShortUrlModule(this.app, upvote.contractName, domainName, this.appCheckModule, this.dbModule, this.storageModule, this.accountModule, this.tokenModule, this.timeModule);
    }

    // TODO - should be part of the SDK?
    async signMoonpayUrl(url: string): Promise<string> {
        const appCheckToken = await this.appCheckModule.getAppCheckToken();
        const token = await this.accountModule.getIdToken();
        const res = await signUrl(url, appCheckToken, token);
        return res.payload.signedUrl;
    }

    getApp() {    
        return this.app;
    }

    getAuth() {
        return this.accountModule.getAuth();
    }

    getOperatorName() {
        return this.operatorName;
    }

    getOperatorSalt() {
        return this.operatorSalt;
    }
}
