import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '@src/model/hooks';
import Helptip from '@creator/ui/components/Helptip/Helptip';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import Card, { CardProps } from '@creator/ui/components/Card/CardV2';
import Text from '@creator/ui/components/Text/Text';
import Linkify from 'linkify-react';
import ProfileImage from '@src/components/ProfileImage/ProfileImage';
import { useHistory } from 'react-router-dom';
import { convertFirebaseTimestampToDate, formatDate } from '@creator/ui/utils/date-utils';
import { getBackedToken } from '@src/config';
import TokenPrice from '@src/components/Token/TokenPrice/TokenPrice';
import { propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { memo } from 'react';
import { abbreviatedFormatter, trancateToDecimalDigitsAmounts } from '@src/utils/number-utils/number-utils';
import UserDisplayName from '@creator/ui/components/UserDisplayName/UserDisplayName';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { cn } from '@creator/ui/utils/ui';

export interface TokenPageAboutCardProps {
    enableMultiline?: boolean;
    tokenName: string;
    className?: string;
    cardProps?: CardProps;
    hideBalnceInfo?: boolean;
}

const TokenPageAboutCard: FC<TokenPageAboutCardProps> = props => {
    const { hideBalnceInfo, cardProps = {}, tokenName, className } = props;

    const { t } = useTranslation();

    const history = useHistory();
    const token = useStoreState(state => state.token.getToken(tokenName), storeEqualityFn);
    const tokenAbout = useStoreState(state => state.token.getAbout(tokenName), storeEqualityFn);
    const backedToken = getBackedToken();


    function clickRedirectelement(url: string): void {
        history.push(url);
    }

    function renderAbout() {
        if (!token) return <Spinner className="mx-auto mt-4 mb-4 " />;

        return (
            <Linkify>
                <Text loadingSkeletonProps={{ count: 2 }} isLoading={!tokenAbout} className="block text-sm whitespace-pre-line" enableMultiline={true}>
                    {tokenAbout}
                </Text>
            </Linkify>
        );
    }

    function renderBasicInfo() {
        if (!token) return null;
        const { creatorName, createdAt = '' } = token ?? {};

        return (
            <div className="mt-4">
                <div className="flex text-sm font-bold">
                    <Text className="flex-1">{t('aboutTokenCard.adminTitle')}</Text>
                    <Text className="">{t('aboutTokenCard.createdAtTitle')}</Text>
                </div>
                <div className="flex">
                    <div className="flex items-center flex-1 mt-2 cursor-pointer" onClick={(): void => clickRedirectelement(`/${ensureTokenUrl(tokenName)}/author/${creatorName}`)}>
                        <ProfileImage tokenName={tokenName} className="w-6 h-6 mr-2" displayName={creatorName} />
                        <UserDisplayName displayName={creatorName} textProps={{ className: 'link overflow-hidden text-sm font-bold' }} />
                    </div>
                    <Text className="block mt-3 text-sm">{formatDate(convertFirebaseTimestampToDate(createdAt))}</Text>
                </div>
            </div>
        );
    }

    function renderHolders() {
        if (!token) return null;
        const { numberOfMembers = 0 } = token ?? {};

        return (
            <div className="flex items-center pt-4 text-sm border-t border-gray-100 cursor-pointer" onClick={() => clickRedirectelement(`/${ensureTokenUrl(tokenName)}/tab/holders`)}>
                <Text className="flex-1 link">{t('aboutTokenCard.totalHolders')}</Text>
                <Text className="font-bold link">{abbreviatedFormatter(numberOfMembers)}</Text>
            </div>
        );
    }


    function renderTotalPosts() {
        if (!token) return null;
        const { totalPosts = 0 } = token ?? {};

        return (
            <div className="flex items-center text-sm">
                <Text className="flex-1">{t('aboutTokenCard.totalPosts')}</Text>
                <Text className="font-bold">{abbreviatedFormatter(totalPosts)}</Text>
            </div>
        );
    }

    function renderCashierBalance() {
        if (!token) return null;
        const { cashierBalance = 0 } = token ?? {};
        return (
            <div className="flex items-center pt-4 text-sm">
                <div className="flex items-center flex-1">
                    <Text>{t('aboutTokenCard.cashierBalance')}</Text>
                    <Helptip text={t('aboutTokenCard.cashierBalanceHelpTip')} className="ml-1" />
                </div>
                <TokenPrice tokenName={backedToken} tokenAmount={cashierBalance[backedToken] || 0} textProps={{ className: 'font-bold' }} />
            </div>
        );
    }

    function renderCashierQueueTotal() {
        if (!token) return null;
        const { cashierQtotal = 0 } = token ?? {};

        const backedToken = getBackedToken();
        const total = cashierQtotal && cashierQtotal[backedToken] && cashierQtotal[backedToken] > 0 ? cashierQtotal[backedToken] : 0;

        return (
            <div className="flex items-center pt-4 text-sm">
                <div className="flex items-center flex-1 link" onClick={() => clickRedirectelement(`/${ensureTokenUrl(tokenName)}/tab/transactions?tab=cashierQueue`)}>
                    <Text>{t('aboutTokenCard.cashierQueue')}</Text>
                </div>
                <Text className="mr-1 font-bold">{trancateToDecimalDigitsAmounts(total)} {backedToken}</Text>
                <Text className="font-bold transition text-green2 dark:text-green">
                    (~<TokenPrice tokenName={backedToken} tokenAmount={total} displayCurrency="USD" textProps={{ className: 'font-bold' }} />)
                </Text>
            </div>
        );
    }

    function renderExchangeRate() {
        if (!token) return null;

        return (
            <div className="flex items-center pt-4 text-sm">
                <Text className="flex-1">{t('aboutTokenCard.exchangeRate')}</Text>
                <Text className="font-bold">
                    {`1 ${backedToken} = `}
                    <TokenPrice tokenName={backedToken} tokenAmount={1} displayCurrency="USD" textProps={{ className: 'font-bold' }} />
                </Text>
            </div>
        );
    }

    function renderBalnceInfo() {
        if (hideBalnceInfo) return;
        return (
            <div className="">
                {renderCashierBalance()}
                {renderCashierQueueTotal()}
                {renderExchangeRate()}
            </div>
        );
    }

    function renderTitle() {
        if (!token) return null;
        return <Text enableMultiline={true} className="text-base font-bold leading-none">{t('aboutTokenCard.title', { tokenName: token.title })}</Text>;
    }

    return (
        <Card className={cn('p-4 space-y-4', className)} {...cardProps}>
            {renderTitle()}
            {renderAbout()}
            {renderBasicInfo()}
            {renderHolders()}
            {renderTotalPosts()}
            {renderBalnceInfo()}
        </Card>
    );
};

export default memo(TokenPageAboutCard, propsEqualityFn);
