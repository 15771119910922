import storageService from '@src/services/storage-service';
import store from '@src/store';
import { PostDraft } from '.';

export async function loadDraft(id: string): Promise<PostDraft | null> {
    const draftJson = storageService.localStorage.get(id);
    if (!draftJson) return null;

    try {
        return JSON.parse(draftJson);
    } catch (error) {
        console.error(`Couldnt parse saved draft with the id '${id}'. deleting it from local storage`);
        // delete the bad one from local storage
        store.getActions().postDraft.delete(id);
        return null;
    }
}
