import store from '@src/store';
import { trancateToDecimalDigitsAmounts } from '@src/utils/number-utils/number-utils';

export function getMyAvailableBalance(tokenName: string): number {
    const creditBalance = store.getState().wallet.getMyCreditBalance(tokenName);
    const tokenBalance = store.getState().wallet.getMyBalance(tokenName);

    const minBalance = Math.min(creditBalance, tokenBalance);

    return trancateToDecimalDigitsAmounts(minBalance, true);
}

export function getMyBalance(tokenName: string): number {
    const tokenBalance = store.getState().wallet.getMyBalance(tokenName);
    return tokenBalance;
}
