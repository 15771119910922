import { useRef, useEffect } from 'react';

// like regular useEffect but skips initial render. just like the good old componentDidUpdate
export default function useDidUpdateEffect(fn: () => void, inputs: unknown[]): void {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) fn();
        else didMountRef.current = true;
    }, inputs);
}