import creatorSdk from '@src/services/creator-sdk';
import { DocumentDataWithId } from '@creator/sdk/modules/db/db.model';
import { FollowDbTableRow, FollowersResponse } from '@creator/sdk/modules/follow/follow.model';
import { FollowUserApiRequest, GetUserFollowersPayload } from '@creator/sdk/modules/follow/follow.service';
import { Pagination } from '@creator/sdk/utils/utils';

export async function followUser(payload: FollowUserApiRequest): Promise<string> {
    return creatorSdk.followModule.followUser(payload);
}

export async function getUserFollowers(payload: GetUserFollowersPayload): Promise<Promise<FollowersResponse>> {
    return creatorSdk.followModule.getUserFollowers(payload);
}