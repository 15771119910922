
import { Pagination } from '../db/db.model';
import DbModule, { GetDocumentsPayload } from '../db';
import { ReactionApiRequestPayload, DeleteSuperCommentApiRequestPayload, EditSuperCommentApiRequestPayload } from './super-comment.service';
import AccountModule, { } from '../account';
import StorageModule from '../storage';
import TokenModule from '../token';
import TimeModule from '../time';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';
import { SuperComment } from './super-comment.model';
import SuperCommentService from './super-comment.service';


export type EditSuperCommentPayload = EditSuperCommentApiRequestPayload;
export type DeleteSuperCommentPayload = DeleteSuperCommentApiRequestPayload;
export type ReactionPayload = ReactionApiRequestPayload;


export default class SuperCommentModule {
    accountModule: AccountModule;
    tokenModule: TokenModule;
    superCommentService: SuperCommentService;
    timeModule: TimeModule;
    domainName: string;
        private dbModule: DbModule;
    private storageModule: StorageModule;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule, accountModule: AccountModule, tokenModule: TokenModule, timeModule: TimeModule) {
        
        this.dbModule = dbModule;
        this.storageModule = storageModule;
        this.domainName = domainName;
        this.superCommentService = new SuperCommentService(contractName, domainName, appCheckModule, dbModule, accountModule);
        this.accountModule = accountModule;
        this.tokenModule = tokenModule;
        this.timeModule = timeModule;
        this.appCheckModule = appCheckModule;
    }

    async getSuperComments(payload: GetDocumentsPayload<SuperComment>): Promise<Pagination<SuperComment>> {
        return this.dbModule.getDocuments<SuperComment>('SuperComment', payload);
    }

    async deleteSuperComment(payload: DeleteSuperCommentPayload): Promise<SuperComment> {
        const res = await this.superCommentService.deleteSuperComment(payload);
        return res.payload;
    }

    async editSuperComment(payload: EditSuperCommentPayload): Promise<SuperComment> {
        const res = await this.superCommentService.editSuperComment(payload);
        return res.payload;
    }

    async getSuperComment(commentId: string): Promise<SuperComment> {
        const comment: SuperComment = await this.dbModule.getDocument('SuperComment', commentId);
        if (!comment) throw new Error(`super-comment with id ${commentId} not found`);

        return comment;
    }

    async reaction(payload: ReactionPayload): Promise<string> {
        const res = await this.superCommentService.reaction(payload);
        return res.payload;
    }
}
