import React, { FC, memo } from 'react';
import HorizontalScrollable from '@src/basic-components/HorizontalScrollable/HorizontalScrollable';
import TopicChip from '@src/basic-components/TopicChip/TopicChip';
import { getDomainConfigValue } from '@src/model/config/helpers';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';
import { useHistory } from 'react-router-dom';
import { Flair } from '@creator/sdk/modules/flair/flair.model';
import ForumPickerButton from './ForumPickerButton';

interface TopicsBarProps {
    tokenName: string;
    activeFlairs?: Flair[];
    flairId: string;
    onFlairClick: (flairId: string) => void;
}

const TopicsBar: FC<TopicsBarProps> = props => {
    const { activeFlairs, flairId, onFlairClick } = props;

    const history = useHistory();

    function renderForumPickerButton() {
        if (!getDomainConfigValue('enableForumPicker')) return null;
        return <ForumPickerButton onClick={() => history.push('/bbs/picker')} />;
    }

    function renderActiveFlairs() {
        if (!activeFlairs) return null;
        return activeFlairs.map(({ id, name }) =>
            <TopicChip
                isActive={id === flairId}
                key={id}
                topicId={name}
                onClick={() => onFlairClick(id)}
            />
        );
    }

    return (
        <HorizontalScrollable
            scrollOffset={200}
            scrollContainerClassname="px-2 lg:px-0 space-x-3"
            arrowClassName="h-6 w-6">
            {renderForumPickerButton()}
            {renderActiveFlairs()}
        </HorizontalScrollable>
    );
};

export default memo(TopicsBar, propsEqualityFn);
