import { useStoreActions, useStoreState } from '@src/model/hooks';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { useEffect, useState } from 'react';
import { getUserId } from '@src/model/user/helpers';
import { isReactedToComment, isReactedToPost } from '../helpers';
import { isReactedToSuperComment } from '@src/model/super-comment/helpers';
import { useTranslation } from 'react-i18next';
import creatorSdk from '@src/services/creator-sdk';

export interface UseReactionPayload {
    postId?: string;
    commentId?: string;
    superCommentId?: string;
    replyId?: string;
}

export interface UseReaction {
    isReacting: boolean;
    isReacted: (reactionName: string) => boolean;
    onReaction: (reactionName: string) => Promise<void>;
}

function useReaction(payload: UseReactionPayload): UseReaction {
    const { postId = '', superCommentId = '', replyId = '', commentId = '' } = payload;

    const { t } = useTranslation();

    const [isReacting, setIsReacting] = useState(false);
    const post = useStoreState(state => state.upvote.getPost(postId), storeEqualityFn);
    const comment = useStoreState(state => state.upvote.getPostComment(commentId), storeEqualityFn);
    const superComment = useStoreState(state => state.superComment.getSuperComment(superCommentId), storeEqualityFn);
    const user = useStoreState(state => state.user.getUser(getUserId()));
    const myUser = useStoreState(state => state.user.myUser);
    const reaction = useStoreActions(actions => actions.upvote.reaction);

    useEffect(() => {
        // TODO - should update hook?
    }, [postId, superCommentId, replyId, commentId]);

    function mapError(error): void {
        if (error.message.includes('banned')) return alert(t('errors.bannedFromCommunity'));
        alert(t('generalErrorMessage') + ' ' + error.message);
    }

    function isReacted(reactionName: string): boolean {
        if (!user) return false;

        const userId = user.id;
        if (post)
            return isReactedToPost(userId, postId, reactionName);
        if (superComment)
            return isReactedToSuperComment(userId, superCommentId, reactionName);
        if (comment)
            return isReactedToComment(userId, commentId, reactionName, replyId);

        return false;
    }

    async function onReaction(reactionName: string): Promise<void> {
        setIsReacting(true);
        try {
            await reaction({
                userId: getUserId() ?? '',
                postId: postId || '',
                commentId: superCommentId || commentId || '',
                replyId: replyId || '',
                reactionName,
                isSuperComment: Boolean(superCommentId)
            });
        } catch (error) {
            mapError(error);
            console.error(error);
        } finally {
            setIsReacting(false);
        }
    }

    return { isReacting, isReacted, onReaction };

}

export default useReaction;