import React from 'react';
import { cn } from '@creator/ui/utils/ui';

export interface LoadingSkeletonProps {
    className?: string;
    count?: number; // Optional prop to specify number of skeletons
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({ className, count = 1 }) => {
    // Ensure count is at least 1
    const skeletonCount = Math.max(count, 1);

    return (
        <>
            {Array.from({ length: skeletonCount }).map((_, index) => (
                <div
                    key={index}
                    className={cn(
                        'animate-pulse w-full bg-gray-200 rounded dark:bg-gray-700 transition',
                        // Apply height based on className or default
                        className,
                        // Add margin-bottom except for the last skeleton
                        index !== skeletonCount - 1 ? 'mb-1' : ''
                    )}
                />
            ))}
        </>
    );
};

export default LoadingSkeleton;
