import isDeepEqualReact from 'react-fast-compare';
import isDeepEqual from 'fast-deep-equal/es6';

export function isObjectEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
}

export function isObject(item: any): boolean {
    return item && typeof item === 'object' && !Array.isArray(item) && item !== null;
}

/**
 * Deeply merges multiple objects into one.
 * @param objects An array of objects to merge.
 * @returns A new object that is the result of a deep merge.
 */
export function deepMerge<T>(...objects: Partial<T>[]): T {
    if (objects.length === 0) return {} as T;

    return objects.reduce((prev, obj) => merge(prev, obj), {}) as T;
}

/**
 * Recursively merges two values.
 * - If both values are objects, their keys are merged recursively.
 * - If the source value is an array, it is shallow-copied.
 * - Otherwise, the source value overwrites the target.
 *
 * @param target The target value.
 * @param source The source value.
 * @returns The merged result.
 */
function merge(target: any, source: any): any {
    if (typeof source !== 'object' || source === null) 
        return source;
    

    // If source is an array, return a shallow copy.
    if (Array.isArray(source)) 
        return source.slice();
    

    // Ensure target is an object.
    if (typeof target !== 'object' || target === null) 
        target = {};
    

    // Recursively merge each key.
    for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
            const sourceValue = source[key];
            const targetValue = target[key];
            target[key] = typeof sourceValue === 'object' && sourceValue !== null
                ? merge(targetValue, sourceValue)
                : sourceValue;
        }
    }

    return target;
}

export function storeEqualityFn(prev: unknown, next: unknown): boolean {
    if (prev === next) return true;
    const _isDeepEqual = isDeepEqual(prev, next);
    return _isDeepEqual;
}

// deep compare functionn for memo components, relevant for deep props like nested objects
export function propsEqualityFn(prev: unknown, next: unknown): boolean {
    if (prev === next) return true;
    const isDeepEqual = isDeepEqualReact(prev, next);
    return isDeepEqual;
}

