import React, { ReactNode } from "react";
import { mergeProps } from "../../utils/merge-props/merge-props";
import { cn } from "../../utils/ui";
import Spinner from "../Spinner/Spinner";

export type ButtonVariant = "filled" | "outline" | "ghost";
export type ButtonType = "round" | "button"; // Changed to "round"
export type ButtonColor = "primary" | "base" | "primary-alternative";
export type ButtonSize = "xl" | "lg" | "md" | "sm" | "xs";
export type HtmlButtonsProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface ButtonProps {
    size?: ButtonSize;
    variant?: ButtonVariant;
    type?: ButtonType;
    color?: ButtonColor;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
    className?: string;
    buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
    isLoading?: boolean;
    isDisabled?: boolean;
    children?: ReactNode;
}

function getBaseStyles() {
    return "flex transition items-center justify-center rounded font-medium";
}

function getSizeStyles(size: ButtonSize, variant: ButtonVariant) {
    if (variant === "ghost") return "h-fit space-x-2 ";
    if (size === "xl") return "h-12 px-4 space-x-2";
    if (size === "lg") return "h-11 px-4 space-x-2 ";
    if (size === "md") return "h-8 px-3 space-x-2";
    if (size === "sm") return "h-7 px-2 space-x-1";
    if (size === "xs") return "h-6 px-2 space-x-1";
    return "";
}

function getTextSize(size: ButtonSize) {
    if (size === "xl") return "text-lg";
    if (size === "lg") return "text-base";
    if (size === "md") return "text-sm";
    if (size === "sm") return "text-sm";
    if (size === "xs") return "text-xs";
    return "";
}

function getSpinnerSize(size: ButtonSize, variant: ButtonVariant) {
    if (variant === "ghost") {
        if (size === "xl") return "w-7 h-7";
        if (size === "lg") return "w-5 h-5";
        if (size === "md") return "w-4 h-4";
        if (size === "sm") return "w-3 h-3";
        if (size === "xs") return "w-2 h-2";
    } else {
        if (size === "xl") return "w-8 h-8";
        if (size === "lg") return "w-6 h-6";
        if (size === "md") return "w-5 h-5";
        if (size === "sm") return "w-4 h-4";
        if (size === "xs") return "w-3 h-3";
    }
    return "";
}

function getVariantStyles(variant: ButtonVariant, color: ButtonColor, isDisabled: boolean) {
    if (isDisabled) return "opacity-70 cursor-not-allowed bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-50";
    if (color === "primary") {
        if (variant === "filled")
            return "bg-[linear-gradient(328deg,_#F59E0B_-1%,_#FACC15_70.64%)] hover:bg-yellow-500 text-gray-900";
        if (variant === "outline")
            return "border border-primary-500 text-primary-500 dark:border-primary-400 dark:text-primary-400";
        if (variant === "ghost")
            return "text-primary-500 dark:text-primary-400";
    }

    if (color === "primary-alternative") {
        if (variant === "filled")
            return "bg-primary-500 hover:bg-primary-700 text-gray-50";
        if (variant === "outline")
            return "border border-primary-500 text-primary-500 dark:border-primary-400 dark:text-primary-400";
        if (variant === "ghost")
            return "text-primary-500 dark:text-primary-400";
    }

    // Default to baseColors
    if (variant === "filled")
        return "bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-50";
    if (variant === "outline")
        return "border border-gray-900 text-gray-900 dark:border-gray-50 dark:text-gray-50";
    if (variant === "ghost")
        return "text-gray-900 dark:text-gray-50";

    return ""; // Fallback return if none of the conditions match
}
function getTypeStyles(type: ButtonType) {
    if (type === "round") return "rounded-full"; // Changed to "round"
    return "";
}
function getLoadingStyles(variant: ButtonVariant, isLoading: boolean) {
    if (!isLoading || variant !== 'ghost') return "";

    return "";
}

function getClassName(
    size: ButtonSize,
    variant: ButtonVariant,
    type: ButtonType,
    color: ButtonColor,
    isDisabled: boolean,
    isLoading: boolean,
    className: string
) {
    return cn(
        getBaseStyles(),
        getSizeStyles(size, variant),
        getTextSize(size),
        getVariantStyles(variant, color, isDisabled || isLoading),
        getTypeStyles(type),
        getLoadingStyles(variant, isLoading),
        className
    );
}

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
    const {
        size = "md",
        variant = "filled",
        type = "button",
        color = "primary",
        onClick,
        isDisabled = false,
        className = "",
        buttonProps = {},
        isLoading = false,
        children,
    } = props;

    function renderSpinner(): ReactNode {
        if (!isLoading || variant === "ghost") return null;
        return <Spinner className={getSpinnerSize(size, variant)} />;
    }

    const mergedProps = mergeProps<[HtmlButtonsProps, HtmlButtonsProps]>({
        className: getClassName(
            size,
            variant,
            type,
            color,
            isDisabled,
            isLoading,
            className
        ),
        onClick,
        disabled: isDisabled || isLoading,
    }, buttonProps);

    return (
        <button {...mergedProps}>
            {renderSpinner()}
            {children}
        </button>
    );
};

export default Button;
