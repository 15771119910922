import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface PlaywireAdProps {
    /** Your Playwire Publisher ID */
    PUB_ID: string;
    /** Your Playwire Website ID */
    WEBSITE_ID: string;
}

/**
 * A React component that integrates the Playwire RAMP script into your SPA.
 *
 * Usage:
 * ```tsx
 * import PlaywireAd from './PlaywireAd';
 *
 * // Provide your actual Publisher ID and Website ID
 * const PUB_ID = "XXXXXX";
 * const WEBSITE_ID = "YYYYYY";
 *
 * function App() {
 *   return (
 *     <BrowserRouter>
 *       <PlaywireAd PUB_ID={PUB_ID} WEBSITE_ID={WEBSITE_ID} />
 *       // your other components
 *     </BrowserRouter>
 *   );
 * }
 * ```
 */
const PlaywireAd: React.FC<PlaywireAdProps> = ({ PUB_ID, WEBSITE_ID }) => {
    const [isRanoeLoaded, setisRanoeLoaded] = useState(false);
    const location = useLocation();

    useEffect(() => {
        // If PUB_ID or WEBSITE_ID is missing, log a warning and do nothing
        if (!PUB_ID || !WEBSITE_ID) {
            console.warn(
                '[PlaywireAd] Missing required PUB_ID or WEBSITE_ID. RAMP script will not be loaded.'
            );
            return;
        }

        // Load the RAMP script only once
        if (!isRanoeLoaded) {
            setisRanoeLoaded(true);

            // Initialize the ramp object if it doesn’t exist
            (window as any).ramp = (window as any).ramp || {};
            (window as any).ramp.que = (window as any).ramp.que || [];
            (window as any).ramp.passiveMode = true;

            // Create script element for RAMP
            const rampScript = document.createElement('script');

            rampScript.src = `https://cdn.intergient.com/${PUB_ID}/${WEBSITE_ID}/ramp.js`;
            rampScript.async = true;

            // Once the script is loaded, signal the first page
            rampScript.onload = () => {
                (window as any).ramp.que.push(() => {
                    (window as any).ramp.spaNewPage(location.pathname);
                    console.log('[PlaywireAd] RAMP script loaded; spaNewPage called for:', location.pathname);
                });
            };

            document.body.appendChild(rampScript);
        } else {
            // If the script is already loaded, just push a spaNewPage call on route change
            (window as any).ramp.que.push(() => {
                (window as any).ramp.spaNewPage(location.pathname);
                console.log('[PlaywireAd] spaNewPage called for:', location.pathname);
            });
        }

        // // Cleanup on unmount - optional in most cases.
        // // This re-notifies RAMP about the last known path upon component unmount.
        // return () => {
        //     console.log('[PlaywireAd] Component unmounted');
        //     if ((window as any).ramp?.que) {
        //         (window as any).ramp.que.push(() => {
        //             (window as any).ramp.spaNewPage(location.pathname);
        //             console.log('[PlaywireAd] Component unmounted; spaNewPage called for:', location.pathname);
        //         });
        //     }
        // };
    }, [location.pathname, PUB_ID, WEBSITE_ID, isRanoeLoaded]);

    // This component does not render any visible UI.
    // It's solely responsible for loading and managing the RAMP script.
    return null;
};

export default PlaywireAd;
