import { action, Action, computed, Computed, Thunk, thunk } from 'easy-peasy';
import { Pagination } from '@creator/sdk/utils/utils';
import { DocumentSnapshot } from '@creator/sdk/modules/db/db.model';
import { CashierQueue } from '@creator/sdk/modules/cashier/cashier.model';
import { loadCashierQueue } from './actions';
import { GetCashierQueueFilterBy, GetCashierQueueOrderBy } from '@creator/sdk/modules/cashier/cashier.service';

export interface LoadCashierQueuePayload {
    lowerBound: DocumentSnapshot | null;
    limit: number;
    filterBy: GetCashierQueueFilterBy[];
    orderBy: GetCashierQueueOrderBy;
    tokenName: string;
}

export interface CashierModel {
    cashierQueue: Record<string, CashierQueue>;
    cashierQueueSearchResultLastDoc: Record<string, unknown>;
    cashierQueueSearchResult: string[];

    getCashierQueue: Computed<CashierModel, (id: string) => CashierQueue | undefined>;
    getCashierQueueSearchResult: Computed<CashierModel, (CashierQueue | undefined)[]>;

    setCashierQueue: Action<CashierModel, Record<string, CashierQueue>>;
    setCashieQueueSearchResult: Action<CashierModel, { result: string[]; isConcat?: boolean }>;
    setCashierQueueLasDoc: Action<CashierModel, Record<string, unknown> | DocumentSnapshot>;
    clearCashierQueueSearchResult: Action<CashierModel>;

    loadCashierQueue: Thunk<CashierModel, LoadCashierQueuePayload, Promise<Pagination<CashierQueue>>>;
}

const cashierModel: CashierModel = {
    cashierQueue: {},
    cashierQueueSearchResult: [],
    cashierQueueSearchResultLastDoc: {},

    getCashierQueue: computed(state => id => state.cashierQueue[id]),
    getCashierQueueSearchResult: computed(state => state.cashierQueueSearchResult.map(id => state.getCashierQueue(id))),

    loadCashierQueue: thunk(async (actions, payload) => {
        const { lowerBound, limit, filterBy, orderBy, tokenName } = payload;

        const { hasMore, items, lastDoc } = await loadCashierQueue(tokenName, lowerBound, limit, filterBy, orderBy);
        const ids: string[] = [];
        const cashierQueue: Record<string, CashierQueue> = {};
        items.forEach(item => {
            cashierQueue[item.id] = item;
            ids.push(item.id);
        });

        if (lastDoc)
            actions.setCashierQueueLasDoc(lastDoc);

        actions.setCashierQueue(cashierQueue);
        actions.setCashieQueueSearchResult({ result: ids, isConcat: Boolean(lowerBound) });
        return { hasMore, items, lastDoc };
    }),

    setCashierQueue: action((state, payload) => {
        state.cashierQueue = { ...state.cashierQueue, ...payload };
    }),

    setCashieQueueSearchResult: action((state, payload) => {
        if (!payload.isConcat)
            state.cashierQueueSearchResult = payload.result;
        else
            state.cashierQueueSearchResult = state.cashierQueueSearchResult.concat(payload.result);
    }),

    setCashierQueueLasDoc: action((state, lastDoc) => {
        state.cashierQueueSearchResultLastDoc = lastDoc;
    }),

    clearCashierQueueSearchResult: action(state => {
        state.cashierQueueSearchResult = [];
        state.cashierQueueSearchResultLastDoc = {};
    })
};

export default cashierModel;