import React, { FC, Fragment, useContext } from 'react';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { useTranslation } from 'react-i18next';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import { ButtonProps } from '@creator/ui/components/Button/ButtonV2';
import ConfirmModalLib, { ConfirmModalProps as ConfirmModalLibProps } from '@creator/ui/components/ConfirmModal/ConfirmModal';
export interface ConfirmModalProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    title: string;
    subtitle?: string;
    okButtonProps: ButtonProps;
    cancelButtonProps?: ButtonProps;
    children?: React.ReactNode;
}

const ConfirmModal: FC<ConfirmModalProps> = props => {
    const { t } = useTranslation();

    const { handleModal } = useContext<UseModal>(ModalContext);

    function renderContent() {
        const { cancelButtonProps = {} } = props;

        const _props = mergeProps<ConfirmModalLibProps>({
            okButtonText: t('confirmModal.okButton'),
            cancelButtonText: t('confirmModal.cancelButton')
        }, props);

        const _cancelButtonProps = mergeProps<ButtonProps>({
            onClick: () => handleModal(null)
        }, cancelButtonProps);

        _props.cancelButtonProps = _cancelButtonProps;

        return <ConfirmModalLib {..._props} />;
    }

    return (
        <Fragment>
            {renderContent()}
        </Fragment>
    );
};

export default ConfirmModal;
