
import { Pagination } from '../db/db.model';
import DbModule from '../db';
import AccountModule, {  } from '../account';
import StorageModule from '../storage';
import TokenModule from '../token';
import TimeModule from '../time';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';
import { DecryptReportPayload, GetReportsOrderBy, Report, ReportDB, UpdateReportPayload } from './report.model';
import ReportService from './report.service';
import { QueryDocumentSnapshot } from 'firebase/firestore/lite';


export default class ReportModel {
    accountModule: AccountModule;
    tokenModule: TokenModule;
    reportService: ReportService;
    timeModule: TimeModule;
    domainName: string;
        private dbModule: DbModule;
    private storageModule: StorageModule;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule, accountModule: AccountModule, tokenModule: TokenModule, timeModule: TimeModule) {
        
        this.dbModule = dbModule;
        this.storageModule = storageModule;
        this.domainName = domainName;
        this.reportService = new ReportService(contractName, domainName, appCheckModule, dbModule, accountModule);
        this.accountModule = accountModule;
        this.tokenModule = tokenModule;
        this.timeModule = timeModule;
        this.appCheckModule = appCheckModule;
    }

    async report(payload: Report): Promise<Report> {
        const res = await this.reportService.report(payload);
        return res.payload;
    }

    async decryptReport(payload: DecryptReportPayload): Promise<Report> {
        const res = await this.reportService.decryptReport(payload);
        return res.payload;
    }

    async updateReport(payload: UpdateReportPayload): Promise<Report> {
        const res = await this.reportService.updateReport(payload);
        return res.payload;
    }

    async getReports(tokenName: string, startAfter: QueryDocumentSnapshot<ReportDB> | null, limit: number, filterBy?: any[], orderBy?: GetReportsOrderBy): Promise<Pagination<ReportDB>> {
        return this.reportService.getReports(tokenName, startAfter, limit, filterBy, orderBy);
    }
}
