import { post } from '../../network/api-service';
import { ApiResponse } from '../../creator-sdk';


export async function signUrl(url: string, appCheckToken: string, accessToken: string) {
    const res = await post<ApiResponse<any>>('/moonpay/signUrl', { url }, {
        Authorization: `Bearer ${accessToken}`,
        'X-Firebase-AppCheck': appCheckToken,
    }, false);
    return res.data;
}