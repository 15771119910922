import { useState, useEffect, RefObject } from 'react';

export interface Size {
  width: number;
  height: number;
}

function useResizeObserver<T extends HTMLElement>(ref: RefObject<T>): Size | undefined {
  const [size, setSize] = useState<Size>();

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    // Create a ResizeObserver instance that updates the state with the new dimensions.
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setSize({ width, height });
      }
    });

    observer.observe(element);

    // Cleanup the observer on component unmount or if ref changes.
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return size;
}

export default useResizeObserver;
