// usePageTitle.ts
import { useLayoutEffect, useRef } from 'react';

// Module-level title stack
const titleStack: string[] = [];

// Helper function to update the document title and log the current stack
const updateDocumentTitle = () => {
    const newTitle = titleStack.length > 0 ? titleStack[titleStack.length - 1] : '';
    document.title = newTitle;
};

const usePageTitle = (title: string) => {
    // Track the current title for this hook instance
    const currentTitleRef = useRef(title);

    useLayoutEffect(() => {
        // If the title changes for this component, remove the old one first
        if (currentTitleRef.current !== title) {
            const oldTitleIndex = titleStack.indexOf(currentTitleRef.current);
            if (oldTitleIndex !== -1) 
                titleStack.splice(oldTitleIndex, 1);
            
            currentTitleRef.current = title;
        }

        titleStack.push(title);
        updateDocumentTitle();

        // Cleanup: Remove the title from the stack when the component unmounts or before updating
        return () => {
            const index = titleStack.indexOf(title);
            if (index !== -1) 
                titleStack.splice(index, 1);
            
            updateDocumentTitle();
        };
    }, [title]);
};

export default usePageTitle;
