import { thunk, Action, Thunk, action, Computed, computed } from 'easy-peasy';
import { followUser, getUserFollowers } from './actions';


import { FollowUserApiRequest, GetUserFollowersPayload } from '@creator/sdk/modules/follow/follow.service';
import { FollowDbTableRow, FollowersResponse } from '@creator/sdk/modules/follow/follow.model';

export interface FollowModel {
    followers: Record<string, FollowersResponse>;
    getUserFollowers: Computed<FollowModel, (userId: string) => FollowersResponse | undefined>;

    followUser: Thunk<FollowModel, FollowUserApiRequest>;
    loadUserfollowers: Thunk<FollowModel, GetUserFollowersPayload>;

    setFollowing: Action<FollowModel, { userId: string, result: FollowersResponse }>;

}

const followModel: FollowModel = {
    followers: {},
    getUserFollowers: computed(state => userId => state.followers[userId]),

    followUser: thunk(async (actions, payload, helpers) => {
        const res = await followUser(payload);
    }),

    loadUserfollowers: thunk(async (actions, payload, helpers) => {
        const res = await getUserFollowers(payload);
        actions.setFollowing({ userId: payload.userId, result: res });
        return res as FollowersResponse;
    }),

    setFollowing: action((state, payload) => {
        state.followers[payload.userId] = payload.result;
    })

};

export default followModel;
