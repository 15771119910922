import storageService from '@src/services/storage-service';

export function setDarkMode(isDarkModeOn: boolean) {
    if (!document.startViewTransition) return switchTheme(isDarkModeOn);
    document.startViewTransition(() => switchTheme(isDarkModeOn));
}

function switchTheme(isDarkModeOn: boolean) {
    if (!isDarkModeOn) {
        document.documentElement.classList.remove('dark');
        storageService.localStorage.remove('isDarkModeOn');
        return;
    }

    document.documentElement.classList.add('dark');
    storageService.localStorage.set('isDarkModeOn', 'true');
}