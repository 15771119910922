import React, { FC, Fragment } from 'react';
import { noop } from '@src/utils/utils';
import Text, { TextProps } from '@creator/ui/components/Text/Text';
import ReactMarkdown, { MarkdownToJSX } from 'markdown-to-jsx';
import { AProps } from '../A/A';
import Link from '../Link/Link';
import { cleanHtml } from '@src/utils/html-utils/html-utils';
import UserDisplayName, { UserDisplayNameProps } from '@creator/ui/components/UserDisplayName/UserDisplayName';

import ProfileImage, { ProfileImageProps } from '@src/components/ProfileImage/ProfileImage';

const elements = [
    'a',
    'abbr',
    'address',
    'area',
    'article',
    'aside',
    'audio',
    'b',
    'base',
    'bdi',
    'bdo',
    'big',
    'blockquote',
    'body',
    'br',
    'button',
    'canvas',
    'caption',
    'cite',
    'code',
    'col',
    'colgroup',
    'data',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'div',
    'dl',
    'dt',
    'em',
    'embed',
    'fieldset',
    'figcaption',
    'figure',
    'footer',
    'form',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'head',
    'header',
    'hgroup',
    'hr',
    'html',
    'i',
    'iframe',
    'img',
    'input',
    'ins',
    'kbd',
    'keygen',
    'label',
    'legend',
    'li',
    'link',
    'main',
    'map',
    'mark',
    'marquee',
    'menu',
    'menuitem',
    'meta',
    'meter',
    'nav',
    'noscript',
    'object',
    'ol',
    'optgroup',
    'option',
    'output',
    'p',
    'param',
    'picture',
    'pre',
    'progress',
    'q',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'script',
    'section',
    'select',
    'small',
    'source',
    'span',
    'strong',
    'style',
    'sub',
    'summary',
    'sup',
    'table',
    'tbody',
    'td',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'title',
    'tr',
    'track',
    'u',
    'ul',
    'var',
    'video',
    'wbr',

    // SVG
    'circle',
    'clipPath',
    'defs',
    'ellipse',
    'foreignObject',
    'g',
    'image',
    'line',
    'linearGradient',
    'marker',
    'mask',
    'path',
    'pattern',
    'polygon',
    'polyline',
    'radialGradient',
    'rect',
    'stop',
    'svg',
    'text',
    'tspan'
];

const allowedElements = ['hr', 'br', 'b', 'span', 'strong', 'em', 'a', 'img', 'p'];

export interface MarkdownProps {
    target?: string;
    href?: string;
    className?: string;
    onClick?: () => void;
    textProps?: TextProps;
    aProps?: AProps;
    source?: string;
    allowDangerousHtml?: boolean;
    onEmptyLinkClick?: (e: React.MouseEvent<HTMLElement>, id: string, clickPayload?: any) => void;
}

const Markdown: FC<MarkdownProps> = props => {
    const { allowDangerousHtml = false, source, textProps = {}, className } = props;

    function preventDefault(e): void {
        e.preventDefault();
        e.stopPropagation();
    }

    function renderEmptyLink(_props): JSX.Element {
        const { onEmptyLinkClick = noop } = props;
        const { className, children, id, rawText, clickPayload = null } = _props;
        return <a onClick={e => { preventDefault(e); onEmptyLinkClick(e, id, clickPayload); }} className={`${className}`}>{cleanHtml(rawText) || children}</a>;
    }

    function renderUserProfileImage(_props: ProfileImageProps): JSX.Element {
        return <ProfileImage {..._props} />;
    }

    function renderUserDisplayName(_props: UserDisplayNameProps): JSX.Element {
        return <UserDisplayName {..._props} />;
    }


    function renderReactMarkdown() {
        const options: MarkdownToJSX.Options = {
            disableParsingRawHTML: !allowDangerousHtml,
            wrapper: Fragment,
            overrides: {
                Link: {
                    component: Link
                },
                EmptyLink: {
                    component: renderEmptyLink
                },
                UserProfileImage: {
                    component: renderUserProfileImage
                },
                UserDisplayName: {
                    component: renderUserDisplayName
                }
            }
        };

        // https://github.com/probablyup/markdown-to-jsx/issues/328
        elements.forEach(element => {
            // React v16 lets you return strings directly
            if (!allowedElements.includes(element))
                options.overrides[element] = Object.assign(({ children }) => <Fragment>{children}</Fragment>, { displayName: element }); // eslint-disable-line react/jsx-no-useless-fragment

        });

        const value = source ? source.replace(/\n/gi, '\n\n') : '';
        return <ReactMarkdown options={options}>{value}</ReactMarkdown>;
    }

    return (
        <Text className={`transition ${className}`} {...textProps}>
            {renderReactMarkdown()}
        </Text>
    );
};

export default Markdown;
