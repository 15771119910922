import React, { FC, useState, useEffect, memo, useCallback, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from '@src/model/hooks';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { useHistory } from 'react-router-dom';
import BackLinkListenToUrl from '@src/basic-components/BackLink/BackLinkListenToUrl';
import { Post } from '@creator/sdk/modules/upvote/upvote.model';
import { isResponsePost } from '@src/model/upvote/helpers';
import { ensureTokenName, ensureTokenUrl } from '@src/model/config/helpers';
import TopCenterAd from './TopCenterAd'; // Import the extracted component
import { cn } from '@creator/ui/utils/ui';
import ContentLayout from '../ContentLayout';
import PostView from '@src/components/Post/PostView/PostView';
import { getCloudRunBaseUrl } from '@src/config';
import usePageTitle from '@src/hooks/usePageTitle';

export interface PostTabProps {
    tokenSlugOrName: string;
    identifier: string;
    commentId?: string;
    replyId?: string;
    className?: string;
}

const PostTab: FC<PostTabProps> = props => {
    const { tokenSlugOrName, identifier = '', commentId = '', replyId = '', className = '' } = props;
    const { t } = useTranslation();
    const tokenName = ensureTokenName(tokenSlugOrName);
    if (!tokenName) throw new Error('invalid tokenname');

    const postSlugOrId = identifier;
    const loadPostByIdOrSlugUrl = useStoreActions(actions => actions.upvote.loadPostByIdOrSlugUrl);
    const loadResponse = useStoreActions(actions => actions.response.loadResponse);
    const loadTokenAbout = useStoreActions(actions => actions.token.loadTokenAbout);
    const [isLoadingPost, setIsLoadingPost] = useState(false);
    const [isLoadingResponse, setIsLoadingResponse] = useState(false);
    const sendDailyVisitRequest = useStoreActions(actions => actions.user.sendDailyVisitRequest);
    const post = useStoreState(state => state.upvote.getPostBySlugUrlOrId(postSlugOrId), storeEqualityFn);
    usePageTitle(getPageTitle());
    const postId = post?.id;

    const token = useStoreState(state => state.token.getToken(tokenName), storeEqualityFn);
    const setPostSearchResult = useStoreActions(actions => actions.upvote.setPostSearchResult);
    const setPostSearchResultLastDoc = useStoreActions(actions => actions.upvote.setPostSearchResultLastDoc);
    const history = useHistory();

    const recordVisit = () => {
        fetch(getCloudRunBaseUrl() + '/manager/getHeader', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                postId: postId,
                tokenName: tokenName
            })
        });
    }

    function getPageTitle() {
        if (!post) return '';
        if (post.createdByWhitelistedUser) return post.title;
        return `${post.title} - Community Post`;
    }

    useEffect(() => {
        if (!postId) return;
        recordVisit();
    }, [postId]);


    useEffect(() => {
        if (!postSlugOrId) return;
        sendDailyVisitRequest({ tokenName });
        loadContent();
        loadTokenAbout(tokenName);
    }, [postSlugOrId]);

    async function loadContent(): Promise<void> {
        const post = await _loadPost();
        if (!post || !isResponsePost(post)) return;
        _loadResponse(post.id);
    }

    async function _loadPost(): Promise<Post | void> {
        try {
            setIsLoadingPost(true);
            const post = await loadPostByIdOrSlugUrl(postSlugOrId);
            setIsLoadingPost(false);
            console.log('postId', post.id);
            return post;
        } catch (e: any) {
            setIsLoadingPost(false);
            if (e.message.includes('not found')) history.push('/bbs/404');
        }
    }

    async function _loadResponse(responseId: string): Promise<void> {
        try {
            setIsLoadingResponse(true);
            await loadResponse(responseId);
        } catch (e: any) {
            console.error(e);
        } finally {
            setIsLoadingResponse(false);
        }
    }

    function renderPost() {
        if (!post) return null;
        return (
            <PostView
                showRecommendedPostList={true}
                loadCommentId={commentId}
                loadReplyId={replyId}
                tokenName={tokenName}
                className=""
                postId={post.id}
            />
        );
    }

    function renderSpinner() {
        if (isLoadingPost && !post) return <Spinner className="mx-auto mt-8" />;
        return null;
    }

    function renderBackLink() {
        return (
            <div className="px-4 lg:px-0">
                <BackLinkListenToUrl
                    defaultBackLinkUrl={`/${ensureTokenUrl(tokenName)}`}
                    defaultBackLinkLabel={t('backToTokenPage', { tokenName: token?.title })}
                />
            </div>
        );
    }

    return (
        <div className={cn('space-y-4 pb-4', className)}>
            <TopCenterAd tokenName={tokenName} variant="mobile" />
            <TopCenterAd tokenName={tokenName} variant="desktop" />
            <ContentLayout tokenName={tokenName} tokenSidebarProps={{ className: 'sticky top-20' }}>
                {renderBackLink()}
                {renderPost()}
                {renderSpinner()}
                {renderBackLink()}
            </ContentLayout>
        </div>
    );
};

export default memo(PostTab);
