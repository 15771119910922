import { Action, action } from 'easy-peasy';

export interface NotificationSettingsModel {
    isDarkModeOn: boolean;

    seIsDarkModeOn: Action<NotificationSettingsModel, boolean>;
}

const notificationSettingsModel: NotificationSettingsModel = {
    isDarkModeOn: false,

    seIsDarkModeOn: action((state, payload) => {
        state.isDarkModeOn = payload;
    })
};

export default notificationSettingsModel;