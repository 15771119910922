import { FirebaseApp } from 'firebase/app';
import { Timestamp } from 'firebase/firestore/lite';
import DateSplitter, { millisecondsInDay, millisecondsInWeek } from '../../utils/date-splitter';


export default class TimeModule {
    private domainName: string;

    constructor(app: FirebaseApp, domainName: string) {
        this.domainName = domainName;
    }

    getMillisecondsInDay(): number {
        return millisecondsInDay;
    }

    getMillisecondsInWeek(): number {
        return millisecondsInWeek;
    }

    getServerTime(): Timestamp {
        return Timestamp.now();
    }

    getUTCTime(): string {
        return new Date().toUTCString();
    }

    getServerTimeDay(): number {
        const dateSpliter = new DateSplitter(this.getServerTime().toDate());
        return dateSpliter.getDay();
    }

    getUTCTimeDay(): number {
        const dateSpliter = new DateSplitter(new Date(this.getUTCTime()));
        return dateSpliter.getDay();
    }

    getServerTimeWeek(): number {
        const dateSpliter = new DateSplitter(this.getServerTime().toDate());
        return dateSpliter.getWeek();
    }

    getServerTimeYearAndMonth(): string {
        const dateSpliter = new DateSplitter(this.getServerTime().toDate());
        return dateSpliter.getYearAndMonth();
    }
}
