type RequestOptions = {
    method: 'GET' | 'POST' | 'PUT';
    path: string;
    body?: Record<string, any>;
    queryParams?: Record<string, any>;
    headers?: HeadersInit;
    useFunctionsBaseUrl?: boolean;
};

let functionsBaseUrl = '';
let cloudRunBaseUrl = '';

function generateContextId() {
    return Math.random().toString(16).slice(2);
}

export function init(functionsBase: string, cloudRunBase: string) {
    functionsBaseUrl = functionsBase;
    cloudRunBaseUrl = cloudRunBase;
}

function resolveBaseUrl(useFunctionsBaseUrl = true) {
    return useFunctionsBaseUrl ? functionsBaseUrl : cloudRunBaseUrl;
}

async function makeRequest<T>({ method, path, body, queryParams, headers = {}, useFunctionsBaseUrl = true }: RequestOptions) {
    const url = new URL(resolveBaseUrl(useFunctionsBaseUrl) + path);

    if (queryParams) {
        Object.entries(queryParams).forEach(([key, value]) => {
            url.searchParams.append(key, String(value));
        });
    }

    const options: RequestInit = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'X-Context-ID': generateContextId(),
            ...headers,
        },
        body: body ? JSON.stringify(body) : undefined,
    };

    try {
        const response = await fetch(url.toString(), options);
        const result = await response.json();

        if (!response.ok || result.status === 'error')
            throw new Error(result.message || response.statusText);

        return {
            data: result as T,
            headers: response.headers,
        };
    } catch (error: any) {
        throw new Error(`Error in ${method} request to ${path}: ${error.message || 'Unknown error'}`);
    }
}

export async function get<T>(path = '/', queryParams = {}, headers = {}, useFunctionsBaseUrl = true) {
    return makeRequest<T>({ method: 'GET', path, queryParams, headers, useFunctionsBaseUrl });
}

export async function post<T>(path = '/', body = {}, headers = {}, useFunctionsBaseUrl = true) {
    const visitorId = 'webapp-unknown';
    const payload = { ...body, visitorId };

    return makeRequest<T>({ method: 'POST', path, body: payload, headers, useFunctionsBaseUrl });
}

export async function put<T>(path = '/', body = {}, headers = {}) {
    return makeRequest<T>({ method: 'PUT', path, body, headers });
}
