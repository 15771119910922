
import { Pagination } from '../db/db.model';
import { ApiResponse } from '../../creator-sdk';
import { post } from '../../network/api-service';
import DbModule from '../db';
import { Post, PostComment, PostCommentStatus } from './upvote.model';
import AppCheckModule from '../app-check';
import { getDocs, limit, orderBy, OrderByDirection, query, QueryConstraint, QueryDocumentSnapshot, startAfter, where, WhereFilterOp } from 'firebase/firestore/lite';
import AccountModule from '../account';
import { LinkMeta } from '.';

export interface CreatePostApiRequestPayload {
    externalArticleUrl?: string;
    externalArticleId?: string;
    parentPostId?: string;
    publisherId?: string;
    title: string;
    content: string;
    tokenName: string;
    uploadedVideo?: boolean;
    flairName?: string;
    pollOptions?: string[];
}

export interface CreateAnonymousPostApiRequestPayload {
    title: string;
    content: string;
    tokenName: string;
    flairName?: string;
    pollOptions?: string[];
}
export interface CreatePostCommentApiRequestPayload {
    publisherId?: string;
    tokenName: string;
    postId: string;
    replyToCommentId: string;
    content: string;
    taggedUsers?: { username: string; id: string; }[];
    linkMeta?: LinkMeta | null;
}

export interface CreateAnonymousPostCommentApiRequestPayload {
    tokenName: string;
    postId: string;
    replyToCommentId: string;
    content: string;
}

export interface EditPostApiRequestPayload {
    publisherId?: string;
    postId: string;
    title?: string;
    uploadedVideo?: boolean;
    flairName?: string;
    content: string;
    pollId?: string;
}

export interface DeletePostApiRequestPayload {
    userId?: string;

    postId: string;
    tokenName: string;
    hidden: boolean;
    reason?: string;
    reasonText?: string;
}
export interface DeleteUserContentApiRequestPayload {
    userId?: string;

    tokenName: string;
    bannedUserId: string;
    reason?: string;
    reasonText?: string;

}

export interface PinPostApiRequestPayload {
    postId: string;
    isPinned: boolean;
    tokenName: string;
    userId: string;
}

export interface SyndicatePostApiRequestPayload {
    userId: string;
    postId: string;
    tokenName: string;
    syndicate: boolean;
}

export interface ReactionApiRequestPayload {
    userId?: string;
    postId?: string;
    commentId?: string;
    reactionName: string;
    isSuperComment?: boolean;
}
export interface TipCommentApiRequestPayload {
    userId: string;

    tokenName: string;
    commentId: string;
    amount: number;
}

export interface TipPostApiRequestPayload {
    userId: string;

    tokenName: string;
    postId: string;
    amount: number;
}


export interface ApprovePendingCommentApiRequestPayload {
    userId: string; // moderator id
    commentId: string;
    approve: boolean;
}

export interface ApprovePendingPostApiRequestPayload {
    userId: string; // moderator id
    postId: string;
    approve: boolean;
}

export interface DeletePostCommentApiRequestPayload {
    userId?: string;
    commentId: string;
    tokenName: string;
    hidden: boolean;
    reason?: string;
    reasonText?: string;
}

export interface EditPostCommentApiRequestPayload {
    userId?: string;
    commentId: string;
    tokenName: string;
    content: string;
    taggedUsers?: { username: string; id: string; }[];
    linkMeta?: LinkMeta | null;
}

export interface GetPostsOrderBy {
    field: 'id' | 'isPinned' | 'upvoteTime' | 'hotPostTime' | 'upvoteAmount' | 'createdAt' | 'lastActivity' | 'pinnedTime' | 'totalImpressions' | 'totalViews';
    direction: OrderByDirection;
}
export interface GetPostsFilterBy {
    field: 'id' | 'isPinned' | 'upvoteAmount' | 'publisherId' | 'upvoterId' | 'status' | 'prevUpvoters' | 'upvoteWeekTimestamp' | 'upvoteDayTimestamp' | 'upvoteYearAndMonth' | 'hotPostDayTimestamp' | 'hotPostWeekTimestamp' | 'hotPostYearAndMonth' | 'publishedDayTimestamp' | 'publishedWeekTimestamp' | 'publishedYearAndMonth' | 'flairs' | 'createdByWhitelistedUser';
    opStr: WhereFilterOp;
    value: any;
}

export interface GetPostsCommentFilterBy {
    field: 'publisherId' | 'postId' | 'status' | 'replyToCommentId' | 'tokenName';
    opStr: WhereFilterOp;
    value: any;
}

export interface GetPostCommentsOrderBy {
    field: 'id' | 'createdAt';
    direction: OrderByDirection;
}

export default class UpvoteService {
        private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private accountModule: AccountModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.accountModule = accountModule;
    }

    async createPost(payload: CreatePostApiRequestPayload) {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<Post>>('/post/create', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data;
    }

    async createAnonymousPost(payload: CreateAnonymousPostApiRequestPayload): Promise<ApiResponse<Post>> {
        const { data } = await post<ApiResponse<any>>('/post/anonymous', payload, {
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken()
        }, false);
        return data;
    }

    async createPostComment(payload: CreatePostCommentApiRequestPayload): Promise<ApiResponse<PostComment>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/comment/create', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken()
        }, false);
        return data;
    }

    async createAnonymousPostComment(payload: CreateAnonymousPostCommentApiRequestPayload): Promise<ApiResponse<PostComment>> {
        const { data } = await post<ApiResponse<any>>('/comment/anonymous', payload, {
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken()
        }, false);
        return data;
    }

    async editPost(payload: EditPostApiRequestPayload): Promise<ApiResponse<Post>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/post/edit', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async editPostComment(payload: EditPostCommentApiRequestPayload): Promise<ApiResponse<PostComment>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/comment/edit', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async deletePost(payload: DeletePostApiRequestPayload): Promise<ApiResponse<Post>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/post/delete', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async deleteUserContent(payload: DeleteUserContentApiRequestPayload): Promise<ApiResponse<string>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/content/delete', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async deletePostComment(payload: DeletePostCommentApiRequestPayload): Promise<ApiResponse<PostComment>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/comment/delete', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data;
    }

    async getPostsCache(tokenName: string, _startAfter: QueryDocumentSnapshot<Post> | null, _limit: number, _filterBy?: GetPostsFilterBy[], _orderBy?: GetPostsOrderBy[]): Promise<Pagination<Post>> {
        const colRef = this.dbModule.getCollectionReference<Post>('Post');
        const queryConstraints: QueryConstraint[] = [where('tokenName', '==', tokenName)];

        _filterBy?.forEach((filter): void => {
            const { field, opStr, value } = filter;
            queryConstraints.push(where(field, opStr, value));
        });

        _orderBy?.forEach((_orderBy): void => {
            queryConstraints.push(orderBy(_orderBy.field, _orderBy.direction));
        });

        if (_startAfter)
            queryConstraints.push(startAfter(_startAfter));

        queryConstraints.push(limit(_limit));

        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);

        return { hasMore: items.length === _limit, items, lastDoc };
    }

    async getPostsByIds(ids: string[]): Promise<Post[]> {
        const colRef = this.dbModule.getCollectionReference<Post>('Post');
        const queryConstraints: QueryConstraint[] = [where('id', 'in', ids)];
        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);

        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        return Object.values(data);
    }

    async getPostCommentsCache(_startAfter: QueryDocumentSnapshot<PostComment> | null, _limit: number, _filterBy?: GetPostsCommentFilterBy[], _orderBy?: GetPostCommentsOrderBy): Promise<Pagination<PostComment>> {
        const colRef = this.dbModule.getCollectionReference<PostComment>('Comment');

        const queryConstraints: QueryConstraint[] = [];
        _filterBy?.forEach((filter): void => {
            const { field, opStr, value } = filter;
            queryConstraints.push(where(field, opStr, value));
        });

        if (!_orderBy) // default
            queryConstraints.push(orderBy('createdAt', 'asc'));

        if (_orderBy) // createdAt will be added in the end anyway
            queryConstraints.push(orderBy(_orderBy.field, _orderBy.direction));

        if (_startAfter)
            queryConstraints.push(startAfter(_startAfter));

        queryConstraints.push(limit(_limit));
        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);

        return { hasMore: items.length === _limit, items, lastDoc };
    }

    async pinPost(payload: PinPostApiRequestPayload): Promise<ApiResponse<string>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/post/pin', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async syndicatePost(payload: SyndicatePostApiRequestPayload): Promise<ApiResponse<Post>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/post/syndicate', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async reaction(payload: ReactionApiRequestPayload): Promise<ApiResponse<string>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/post/reaction', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async tipComment(payload: TipCommentApiRequestPayload): Promise<ApiResponse<PostComment>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/comment/tipping', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async tipPost(payload: TipPostApiRequestPayload): Promise<ApiResponse<Post>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/post/tipping', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async approvePendingComment(payload: ApprovePendingCommentApiRequestPayload): Promise<ApiResponse<PostComment | null>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/comment/approve', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async approvePendingPost(payload: ApprovePendingPostApiRequestPayload): Promise<ApiResponse<Post | null>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/post/approve', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }
}
