import React, { FC, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import { cleanHtmlToPlainText } from '@src/utils/html-utils/html-utils';
import { PostComment } from '@creator/sdk/modules/upvote/upvote.model';

import SelectReason from '@src/components/SelectReason/SelectReason';

export interface ModActivityModalProps {
    type?: 'hidePost' | 'hideComment' | 'banUser' | 'blockChatUser';
    className?: string;
    options: string[];
    subTitle?: string;
    onSendReason?: (reason?: string, reasonText?: string) => void;
    onDeleteComment?: (deleteComment: PostComment, reason?: string, reasonText?: string) => void;
    onBlockChatUser?: (userToBlock: string, reason: string, reasonText?: string) => void;
    deleteComment?: PostComment | null;
    chatUserToBlockId?: string
}

const ModActivityModal: FC<ModActivityModalProps> = props => {
    const { type, subTitle, options, onSendReason } = props;

    const { t } = useTranslation();

    //const myUser = getMyUser();
    const { handleModal } = useContext<UseModal>(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [optionSelected, setOptionSelected] = useState('');
    const [otherReason, setOtherReason] = useState('');

    async function onModSelectReason(option: string, setOption = false): Promise<void> {
        const { deleteComment, onDeleteComment, onBlockChatUser, chatUserToBlockId } = props;
        if (setOption)
            setOptionSelected(option);

        const reason = optionSelected === t('other') ? t('other') : option;
        const reasonText = optionSelected === t('other') ? otherReason : '';

        if (type === 'hideComment' && deleteComment && onDeleteComment !== undefined)
            onDeleteComment(deleteComment, reason, cleanHtmlToPlainText(reasonText));
        else if (type === 'blockChatUser' && chatUserToBlockId !== undefined && onBlockChatUser !== undefined)
            onBlockChatUser(chatUserToBlockId, reason, reasonText);
        else if (onSendReason !== undefined)
            onSendReason(reason, cleanHtmlToPlainText(reasonText));

        handleModal(null);
    }

    return (
        <SelectReason
            options={options}
            optionSelected={optionSelected}
            setOptionSelected={setOptionSelected}
            otherReason={otherReason}
            setOtherReason={setOtherReason}
            subTitle={subTitle}
            onCancle={() => handleModal(null)}
            onSendReson={onModSelectReason}
            isLoading={isLoading}
        />
    );

};

export default ModActivityModal;
