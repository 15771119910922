import React, { FC, Fragment, memo } from 'react';
import EditorViewOnlyLinkTool from './LinkTool/LinkTool';
import EditorViewOnlyParagraphTool from './ParagraphTool/ParagraphTool';
import EditorViewOnlyHeaderTool from './HeaderTool/HeaderTool';
import EditorViewOnlyListTool from './ListTool/ListTool';
import EditorViewOnlyEmbedTool from './EmbedTool/EmbedTool';
import EditorViewOnlyWarningTool from './WarningTool/WarningTool';
import EditorViewOnlyImageTool from './ImageTool/ImageTool';
import EditorViewOnlyDelimiterTool from './DelimiterTool/DelimiterTool';
import EditorViewOnlyCodeTool from './CodeTool/CodeTool';
import Linkify from 'linkify-react';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';
import EditorViewOnlyVideoTool, { EditorViewOnlyVideoToolProps } from './VideoTool/VideoTool';
import EditorViewOnlyAudioTool, { EditorViewOnlyAudioToolProps } from './AudioTool/AudioTool';
import { noop } from '@src/utils/utils';
import { OutputBlockData } from '@editorjs/editorjs';
import EditorViewOnlyQuoteTool from './QuoteTool/QuoteTool';
import { getYahooAdByLocation, getYahooAdLocatioByTokenName, getYahooAdTargetingByTokenName } from '@src/model/config/helpers';
import GoogleAdSlot from '@src/components/GoogleAdSlot/GoogleAdSlot';
import { getTextBasedBlockContent, isParagraphBasedBlockType, isTextBasedBlockType } from '@src/utils/editorjs-utils/editorjs-utils';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';

export interface EditorViewOnlyProps {
    className?: string;
    tokenName: string;
    data: EditorJS.OutputData;
    editorViewOnlyVideoToolProps?: Partial<EditorViewOnlyVideoToolProps>;
    editorViewOnlyAudioToolProps?: Partial<EditorViewOnlyAudioToolProps>;
    onClick?: () => void;
}

const EditorViewOnly: FC<EditorViewOnlyProps> = props => {
    const { data, className, tokenName } = props;

    function renderBlockContent(type: string, blockData: OutputBlockData['data']) {
        const { onClick = noop, editorViewOnlyVideoToolProps = {}, editorViewOnlyAudioToolProps = {} } = props;

        if (type === 'paragraph')
            return <EditorViewOnlyParagraphTool onClick={onClick} blockData={blockData} />;

        if (type === 'linkTool')
            return <EditorViewOnlyLinkTool blockData={blockData} />;

        if (type === 'image' || type === 'simpleImage')
            return <EditorViewOnlyImageTool onClick={onClick} blockData={blockData} />;

        if (type === 'video' && blockData.url) {
            const _props = mergeProps<[EditorViewOnlyVideoToolProps, Partial<EditorViewOnlyVideoToolProps>]>({
                onClick,
                blockData,
                tokenName
            }, editorViewOnlyVideoToolProps);
            return <EditorViewOnlyVideoTool {..._props} />;
        }

        if (type === 'audio') {
            const _props = mergeProps<[EditorViewOnlyAudioToolProps, Partial<EditorViewOnlyAudioToolProps>]>({
                onClick,
                blockData
            }, editorViewOnlyAudioToolProps);

            return <EditorViewOnlyAudioTool {..._props} />;
        }

        if (type === 'header')
            return <EditorViewOnlyHeaderTool onClick={onClick} blockData={blockData} />;

        if (type === 'list')
            return <EditorViewOnlyListTool onClick={onClick} blockData={blockData} />;

        if (type === 'delimiter')
            return <EditorViewOnlyDelimiterTool onClick={onClick} blockData={blockData} />;

        if (type === 'embed')
            return <EditorViewOnlyEmbedTool blockData={blockData} />;

        if (type === 'warning')
            return <EditorViewOnlyWarningTool onClick={onClick} blockData={blockData} />;

        if (type === 'quote')
            return <EditorViewOnlyQuoteTool onClick={onClick} blockData={blockData} />;

        return <div></div>;
    }

    function renderAd(i: number) {
        const { tokenName } = props;
        const location = getYahooAdLocatioByTokenName(tokenName, 'mid_center');
        if (!location) return;
        const yahooAd = getYahooAdByLocation(location);
        if (!yahooAd) return;

        const { adUnitPath, divId } = yahooAd;
        return <GoogleAdSlot targeting={getYahooAdTargetingByTokenName(tokenName)} className="mx-auto" adUnitPath={adUnitPath} sizes={[300, 250]} divId={`${divId}-${i}`} />;
    }

    function renderBlock(block: OutputBlockData<string, any>, i: number) {
        return (
            <div key={block.id || i} className="ce-block">
                <div className="ce-block__content">
                    {renderBlockContent(block.type, block.data)}
                </div>
            </div>
        );
    }

    function renderBlocks() {
        let textBlockCount = 0;
        let totalCharCount = 0;
        let lastAdIndex = -1;

        return (
            data.blocks.map((block, i) => {
                const isTextBlock = isParagraphBasedBlockType(block.type);
                if (isTextBlock) {
                    textBlockCount++;
                    totalCharCount += getTextBasedBlockContent(block).length;
                }

                if (isTextBlock && textBlockCount % 3 === 0 && totalCharCount - lastAdIndex >= 500) {
                    lastAdIndex = totalCharCount;
                    return (
                        <Fragment key={i}>
                            {renderBlock(block, i)}
                            {renderAd(textBlockCount / 3)}
                        </Fragment>
                    );
                }

                return renderBlock(block, i);
            })
        );
    }

    return (
        <div className={`editorjs-container ${className}`}>
            <Linkify>
                {renderBlocks()}
            </Linkify>
        </div>
    );
};

export default memo(EditorViewOnly, propsEqualityFn);