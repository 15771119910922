
import { ApiResponse } from '../../creator-sdk';
import { post } from '../../network/api-service';
import DbModule from '../db';
import AppCheckModule from '../app-check';
import AccountModule from '../account';

export interface SendDirectMessagePayload {
    senderId: string;
    receiverId?: string;
    content: string;
    tokenName: string;
}
export interface BlockUserMessaging { userId: string; blockedUserId: string; blockDM: boolean; tokenName?: string }
export interface DirectMessage {
    content: string;
    createdAt: number;
    receiverId: string;
    id: string;
    senderId: string;
}
export interface GetConversationPayload {
    limit?: number;
    userId: string;
    otherUserId: string;
    callback: (args: Record<string, DirectMessage>, hasMore: boolean) => void;
}
export interface GetConversationPreviewPayload {
    limit?: number;
    userId: string;
    callback: (args: DirectMessage[], hasMore: boolean) => void;
}

export interface SubscribeConversationPreviewPayload {
    limit?: number;
    userId: string;
    callback: (args: Record<string, DirectMessage>, hasMore: boolean, isUpdated?: boolean) => void;
}
export interface GetConversationPreviousPayload {
    limit: number;
    userId: string;
    otherUserId: string;
    endAt: string;
    callback: (args: Record<string, DirectMessage>, hasMore: boolean) => void;
}

export interface GetDirectMessagesOrderBy {
    field: 'createdAt';
}

export default class DirectMessageService {
        private contractName: string;
    private domainName: string;
    dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private accountModule: AccountModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.accountModule = accountModule;
    }

    async sendDirectMessage(payload: SendDirectMessagePayload): Promise<ApiResponse<{ user: string }>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/dm/send', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async block(payload: BlockUserMessaging): Promise<string> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/dm/block', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data.status;
    }
}

