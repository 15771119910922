import React, { FC, PropsWithChildren } from 'react';
import { noop } from '@src/utils/utils';
import Image from '@creator/ui/components/Image/Image';

export interface ImageGridPostPreviewProps {
    className?: string;
    images: string[]; // urls
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const ImageGridPostPreview: FC<PropsWithChildren<ImageGridPostPreviewProps>> = props => {
    const { onClick = noop, images, className = '' } = props;

    function renderSingleImageLayout() {
        return (
            <div className="aspect-[1/1] overflow-hidden">
                <Image src={images[0]} />
            </div>
        );
    }

    function renderDoubleImageLayout() {
        return (
            <div className="grid grid-cols-2 gap-1 lg:gap-2">
                {images.map((image, index) =>
                    <div key={index} className="aspect-[1/1] overflow-hidden">
                        <Image src={image} />
                    </div>
                )}
            </div>
        );
    }

    // Updated Triple Image Layout:
    // One image on the left spanning full height,
    // Two images on the right each taking half the height.
    function renderTripleImageLayout() {
        return (
            <div className="flex gap-1 lg:gap-2">
                {/* Left image takes full height */}
                <div className="flex-1 overflow-hidden">
                    <Image src={images[0]} className="object-cover w-full h-full" />
                </div>
                {/* Right column split into two equal parts */}
                <div className="flex flex-col flex-1 gap-1 lg:gap-2">
                    <div className="flex-1 overflow-hidden">
                        <Image src={images[1]} className="object-cover w-full h-full" />
                    </div>
                    <div className="flex-1 overflow-hidden">
                        <Image src={images[2]} className="object-cover w-full h-full" />
                    </div>
                </div>
            </div>
        );
    }

    function renderMoreOverlay() {
        const more = images.length - 4;
        if (!more) return null;
        return (
            <div className="absolute inset-0 flex items-center justify-center text-2xl font-bold bg-black bg-opacity-50 text-gray-50">
                +{more}
            </div>
        );
    }

    function renderMultipleImageLayout() {
        return (
            <div className="grid grid-cols-2 grid-rows-2 gap-2">
                {images.slice(0, 4).map((image, index) =>
                    <div key={index} className="relative overflow-hidden">
                        <div className="aspect-[1/1] overflow-hidden">
                            <Image src={image} />
                            {index === 3 && images.length > 4 && renderMoreOverlay()}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    function renderContent() {
        if (images.length === 1) return renderSingleImageLayout();
        if (images.length === 2) return renderDoubleImageLayout();
        if (images.length === 3) return renderTripleImageLayout();
        return renderMultipleImageLayout();
    }

    return (
        <div className={`cursor-pointer ${className}`} onClick={onClick}>
            {renderContent()}
        </div>
    );
};

export default ImageGridPostPreview;
