
import DbModule from '../db';
import AccountModule from '../account';
import DirectMessageService, { BlockUserMessaging, SendDirectMessagePayload } from './direct-message.service';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';

export default class DirectMessageModule {
    accountModule: AccountModule;
    directMessageService: DirectMessageService;
    private dbModule: DbModule;
        private domainName: string;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        this.dbModule = dbModule;
        this.directMessageService = new DirectMessageService(contractName, domainName, appCheckModule, dbModule, accountModule);
        this.accountModule = accountModule;
        
        this.domainName = domainName;
        this.appCheckModule = appCheckModule;
    }

    async sendDirectMessage(payload: SendDirectMessagePayload): Promise<Partial<{ user: string }>> {
        const res = await this.directMessageService.sendDirectMessage(payload);
        return res.payload;
    }

    async block(payload: BlockUserMessaging): Promise<string> {
        const res = await this.directMessageService.block(payload);
        return res;
    }
}