import React, { FC, PropsWithChildren } from 'react';
import { noop } from '@src/utils/utils';
import Icon from '../Icon/Icon';
import { cn } from '@creator/ui/utils/ui';
import Spinner from '@creator/ui/components/Spinner/Spinner';

export interface MenuItemProps extends PropsWithChildren {
    className?: string;
    showSeparator?: boolean;
    showSelectedIcon?: boolean;
    onClick?: () => void;
    isLoading?: boolean;
    isDisabled?: boolean;
}

const MenuItem: FC<MenuItemProps> = props => {
    const { isDisabled, isLoading, children, className, showSeparator, showSelectedIcon, onClick = noop } = props;

    // Base container classes using Tailwind.
    // h-[45px] sets a fixed height of 45px, relative for positioning.
    // Transition, flex, cursor, font and padding classes as defined.
    let containerClasses =
        'h-[45px] relative transition flex cursor-pointer items-center font-medium text-sm pl-4 pr-12';

    // Add a bottom border if the separator should be shown.
    if (showSeparator)
        containerClasses += ' border-b border-gray-100 dark:border-gray-800';


    // When disabled or loading, apply disabled background, text, and pointer-events-none.
    if (isDisabled || isLoading)
        containerClasses += ' bg-gray-100 text-gray-500 pointer-events-none';


    // Handle click events.
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        if (isDisabled) return;
        e.preventDefault();
        e.stopPropagation();
        onClick();
    };

    const renderSelectedIcon = () => {
        if (!showSelectedIcon) return null;
        return <Icon className="ml-2 text-black transition dark:text-gray-50" name="checkmark" />;
    };

    // The spinner is positioned absolutely to the right with a fixed size.
    const renderSpinner = () => {
        if (!isLoading) return null;
        return <Spinner className="absolute right-4 w-[14px] h-[14px]" />;
    };

    return (
        <div className={cn(containerClasses, className)} onClick={handleClick}>
            {children}
            {renderSelectedIcon()}
            {renderSpinner()}
        </div>
    );
};

export default MenuItem;
