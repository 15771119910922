import creatorSdk from '@src/services/creator-sdk';
import { DocumentSnapshot } from '@creator/sdk/modules/db/db.model';
import { GetPostsFilterBy, GetPostsOrderBy } from '@creator/sdk/modules/upvote/upvote.service';
import { Wallet } from '@creator/sdk/modules/wallet/wallet.model';
import { Pagination } from '@creator/sdk/utils/utils';

export async function loadWallet(walletId: string): Promise<Wallet | null> {
    return creatorSdk.wallet.getWallet(walletId);
}

export async function loadWallets(lowerBound: DocumentSnapshot | null, limit = 20, filterBy: GetPostsFilterBy[], orderBy: GetPostsOrderBy[]): Promise<Pagination<Wallet>> {
    return creatorSdk.wallet.getWallets(lowerBound, limit, filterBy, orderBy);
}
