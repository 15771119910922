
import DbModule from '../db';
import AccountModule from '../account';
import StorageModule from '../storage';
import TokenModule from '../token';
import DomainService from './domain.service';
import { DomainPublic } from './domain.model';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';

export default class DomainModule {
    domainService: DomainService;
    domainName: string;
        private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    // private storageModule: StorageModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule) {
        
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        // this.storageModule = storageModule;
        this.domainName = domainName;
        this.domainService = new DomainService(contractName, domainName, appCheckModule, dbModule);
    }

    async getPublicData(id: string): Promise<DomainPublic | undefined> {
        return this.domainService.getPublicData(id);
    }
}
