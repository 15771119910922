import { action, Action, computed, Computed, Thunk, thunk } from 'easy-peasy';
import { DecryptReportPayload, GetReportsOrderBy, Report, ReportDB, UpdateReportPayload } from '@creator/sdk/modules/report/report.model';
import { decryptReport, getReports, sendReport, updateReport } from './actions';
import { DocumentSnapshot, Pagination } from '@creator/sdk/modules/db/db.model';
import { LoadTxLogPayload } from '../txLog';


export interface ReportModel {
    reports: Record<string, ReportDB>;
    reportsSearchResult: string[];
    reportsSearchResultLastDoc: Record<string, unknown>;
    loadReports: Thunk<ReportModel, LoadTxLogPayload, Promise<Pagination<ReportDB>>>;
    decryptReport: Thunk<ReportModel, DecryptReportPayload, Promise<ReportDB>>;
    updateReportStatus: Thunk<ReportModel, UpdateReportPayload, Promise<ReportDB>>;
    sentReport: Thunk<ReportModel, Report>;
    // loadReports: Thunk<ReportModel, { tokenName: string, startAfter: DocumentSnapshot | null, limit: number, filterBy?: any[], orderBy?: GetReportsOrderBy }, Promise<Pagination<ReportDB>>>;

    getReport: Computed<ReportModel, (id: string) => ReportDB | undefined>;
    getReportsSearchResult: Computed<ReportModel, (ReportDB | undefined)[]>;
    setReports: Action<ReportModel, Record<string, ReportDB>>;
    updateReport: Action<ReportModel, { reportId: string, content: Record<string, any> }>;
    setReportSearchResult: Action<ReportModel, { result: string[]; isConcat?: boolean }>;
    setReportsLasDoc: Action<ReportModel, Record<string, unknown> | DocumentSnapshot>;
}

const ReportModel: ReportModel = {
    reports: {},
    reportsSearchResult: [],
    reportsSearchResultLastDoc: {},

    getReport: computed(state => id => state.reports[id]),
    getReportsSearchResult: computed(state => state.reportsSearchResult.map(id => state.getReport(id))),

    sentReport: thunk(async (actions, payload) => {
        await sendReport(payload);
    }),

    decryptReport: thunk(async (actions, payload) => {
        const res = await decryptReport(payload);
        actions.updateReport({ reportId: payload.reportId, content: { reporterName: res.reporterName } });
    }),

    updateReportStatus: thunk(async (actions, payload) => {
        const res = await updateReport(payload);
        actions.updateReport({ reportId: payload.reportId, content: { status: res.status } });
    }),

    loadReports: thunk(async (actions, payload) => {
        const { limit = 10, lowerBound, tokenName, filterBy, orderBy } = payload;
        const { items, hasMore, lastDoc } = await getReports(tokenName, lowerBound, limit, filterBy, orderBy);
        // return { items: ['1', '2', '3'] };
        const res = {};
        items.map(item => { res[item.id] = item; });
        actions.setReports(res);
        actions.setReportSearchResult({ result: Object.keys(res), isConcat: Boolean(lowerBound) });
        actions.setReportsLasDoc(lastDoc);
        return { items, hasMore, lastDoc };
    }),

    setReports: action((state, payload) => {
        state.reports = { ...state.reports, ...payload };
    }),

    updateReport: action((state, payload) => {
        state.reports[payload.reportId] = { ...state.reports[payload.reportId], ...payload.content };
    }),

    setReportSearchResult: action((state, payload) => {
        if (!payload.isConcat)
            state.reportsSearchResult = payload.result;
        else
            state.reportsSearchResult = state.reportsSearchResult.concat(payload.result);
    }),

    setReportsLasDoc: action((state, lastDoc) => {
        state.reportsSearchResultLastDoc = lastDoc;
    })
};

export default ReportModel;
