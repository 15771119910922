import { action, Action } from 'easy-peasy';
import { setDarkMode } from './actions';

export interface AppearanceSettingsModel {
    isDarkModeOn: boolean;

    seIsDarkModeOn: Action<AppearanceSettingsModel, boolean>;
}

const AppearanceSettingsModel: AppearanceSettingsModel = {
    isDarkModeOn: false,

    seIsDarkModeOn: action((state, payload) => {
        state.isDarkModeOn = payload;
        setDarkMode(payload);
    })
};

export default AppearanceSettingsModel;