import { Vote } from '@creator/sdk/modules/voting/voting.model';
import creatorSdk from '@src/services/creator-sdk';
import { VotePayload } from '@creator/sdk/modules/voting/voting.service';

export async function vote(payload: VotePayload): Promise<Vote> {
    return creatorSdk.votingModule.vote(payload);
}

export async function loadVote(postId: string): Promise<Vote | null> {
    return creatorSdk.votingModule.getVote(postId);
}
