import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@creator/ui/components/Text/Text';
import Icon, { IconType } from '@src/basic-components/Icon/Icon';
import { useStoreState } from '@src/model/hooks';
import { SuperComment } from '@creator/sdk/modules/super-comment/super-comment.model';
import { Post, PostComment } from '@creator/sdk/modules/upvote/upvote.model';
import { toCamelCase } from '@src/utils/string-utils/string_utils';
import ProfileImageWithLink from '@src/components/ProfileImage/ProfileImageWithLink';
import UserDisplayNameWithLink from '@src/basic-components/UserDisplayName/UserDisplayNameWithLink';

type ReactionsListType = 'thumb-up' | 'thumb-down';
export interface ReactionsListProps {
    tokenName: string;
    className?: string;
    postId?: string;
    commentId?: string;
    superCommentId?: string;
    tab?: ReactionsListType;
}

const ReactionsList: FC<ReactionsListProps> = props => {
    const { tokenName, className, postId = '', commentId = '', superCommentId = '', tab = 'thumb-up' } = props;
    const post = useStoreState(state => state.upvote.getPost(postId));
    const comment = useStoreState(state => state.upvote.getPostComment(commentId));
    const superComment = useStoreState(state => state.superComment.getSuperComment(superCommentId));
    const [selectedTab, setSelectedTab] = useState<ReactionsListType>(tab);
    const { t } = useTranslation();

    function getEntity(): Post | SuperComment | PostComment | undefined | null {
        if (postId) return post;
        if (superCommentId) return superComment;
        if (commentId) return comment;
    }

    function getCount(type: ReactionsListType): number {
        const entity = getEntity();
        if (!entity || !entity.reactionsCount) return 0;

        return entity.reactionsCount[type] || 0;
    }

    function getList(type: ReactionsListType): string[] {
        const entity = getEntity();
        if (!entity || !entity.reactionsUsernames) return [];
        return entity.reactionsUsernames[type] || [];
    }

    function renderListItem(type: ReactionsListType, displayName: string) {
        return (
            <div key={displayName} className="flex items-center justify-between w-full mb-4">
                <div className="flex items-center space-x-2 cursor-pointer">
                    <ProfileImageWithLink profileImageProps={{ displayName, className: 'w-6 h-6' }} />
                    <UserDisplayNameWithLink tokenName={tokenName} displayName={displayName} linkProps={{ className: 'font-bold transition' }} />
                </div>
                <Icon svgFill={false} className="text-xl" name={toCamelCase(type)} />
            </div>
        );
    }

    function renderEmptyState() {
        return <Text className="text-sm">{t('reactionsList.emptyState')}</Text>;
    }

    function renderList(type: ReactionsListType) {
        const items = getList(type).map(name => renderListItem(type, name));
        if (!items.length) return renderEmptyState();
        return items;
    }

    function renderTab(type: ReactionsListType) {
        return (
            <div className={`cursor-pointer flex items-start justify-center flex-1 pb-3 space-x-1 border-b-2 ${selectedTab === type ? 'border-primary-500' : ''}`} onClick={() => setSelectedTab(type)}>
                <Icon svgFill={false} className="text-lg" name={toCamelCase(type) as IconType} />
                <Text>{t(`reactionsList.tabTitle.${toCamelCase(type)}`, { count: getCount(type) })}</Text>
            </div>
        );
    }

    function renderTabs() {
        return (
            <div className="flex flex-1">
                {renderTab('thumb-up')}
                {renderTab('thumb-down')}
            </div>
        );
    }
    function renderContent() {
        return (
            <div className="">
                {renderTabs()}
                <div className="mt-6 overflow-y-auto text-center max-h-96">
                    {renderList(selectedTab)}
                </div>
            </div>
        );
    }

    return (
        <div className={`rounded-md overflow-hidden ${className}`} >
            {renderContent()}
        </div>
    );
};

export default ReactionsList;
