import React, { FC, useContext, useEffect } from 'react';
import Text from '@creator/ui/components/Text/Text';
import { noop } from '@src/utils/utils';
import ProgressBar from '@src/basic-components/ProgressBar/ProgressBar';
import Icon from '@src/basic-components/Icon/Icon';
import { roundToDecimalDigits } from '@src/utils/number-utils/number-utils';
import { useStoreState, useStoreActions } from '@src/model/hooks';
import { PostPollLastVoters } from '@creator/sdk/modules/upvote/upvote.model';
import ProfileImage from '@src/components/ProfileImage/ProfileImage';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import { getUrlPath } from '@src/utils/url-utils/url-utils';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import { isUserJoinedToToken } from '@src/model/user/helpers';
import JoinButtonModal from '@src/components/JoinButtonModal/JoinButtonModal';
import { isPostDeleted } from '@src/model/upvote/helpers';
import { ensureTokenUrl } from '@src/model/config/helpers';

export interface PollOptionsProps {
    className?: string;
    tokenName: string;
    onOptionClick?: (i: number) => void;
    isVoting?: number; // the option index currently voting (waiting for server call)
    postId: string;
    isPin?: boolean;
}

const PollOptions: FC<PollOptionsProps> = props => {
    const { postId, tokenName, isPin = false, className } = props;

    const { t } = useTranslation();
    const history = useHistory();
    const { handleModal } = useContext<UseModal>(ModalContext);

    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const post = useStoreState(state => state.upvote.getPost(postId));
    const loadVote = useStoreActions(actions => actions.voting.loadVote);
    const myVote = useStoreState(state => state.voting.getMyVote(postId));

    useEffect(() => {
        _loadVote();
    }, []);

    async function _loadVote(): Promise<void> {
        if (!postId) return;
        await loadVote({ postId });
    }

    function getProgressFillClassName(isSelected: boolean): string {
        const base = 'transition';
        return isSelected
            ? `${base} bg-primary-100 dark:bg-primary-900`
            : `${base} bg-gray-100 dark:bg-gray-800`;
    }

    function getValue(i): PostPollLastVoters | null {
        if (!post || !post.pollValues) return null;
        return post.pollValues[i];
    }

    function onUserClick(username: string): void {
        history.push(`/${ensureTokenUrl(tokenName)}/author/${username}`);
    }

    function onOptionClick(i: number): void {
        const { onOptionClick = noop } = props;

        if (!isLoggedIn) return history.push(`/bbs/login?next=${getUrlPath()}`);

        if (!isUserJoinedToToken(tokenName))
            return handleModal(<JoinButtonModal tokenName={tokenName} />);

        onOptionClick(i);
    }

    function renderUsernameProfileImage(username: string, showHasMore: boolean) {
        return (
            <div
                key={username}
                className={`transition flex-shrink-0 relative rounded-full ${!showHasMore ? 'cursor-pointer' : ''
                }`}
                onClick={!showHasMore ? () => onUserClick(username) : noop}
            >
                <ProfileImage className="w-8 h-8" displayName={username} />
                {showHasMore && 
                    <Text className="absolute inset-0 flex items-center justify-center text-white bg-black bg-opacity-50 rounded-full text-md dark:text-gray-800">
                        +
                    </Text>
                }
            </div>
        );
    }

    function renderUsers(i: number) {
        if (isPin) return null;

        const value = getValue(i);
        if (!value) return null;

        const { lastVoters } = value;
        if (!lastVoters || !lastVoters.length) return null;

        return (
            <div className="hidden ml-4 -space-x-2 lg:flex min-w-max">
                {lastVoters.map((lastVoter, idx) =>
                    renderUsernameProfileImage(lastVoter, idx > 1 && lastVoters.length > 2)
                )}
            </div>
        );
    }

    function renderOptionTotalUsers(i: number) {
        if (isPin) return null;
        const value = getValue(i);
        if (!value) return null;

        const { count = 0 } = value;
        if (!count) return null;

        return (
            <div className="mt-3 ml-4 text-xs lg:mt-0">
                <Text className="block">{t('pollOptions.totalUsers', { count })}</Text>
            </div>
        );
    }

    function renderSpinner(i: number) {
        const { isVoting } = props;
        if (isVoting !== i) return null;

        return <Spinner className="relative w-5 h-5" />;
    }

    function renderVotingRate(i: number, votingRate: number) {
        const { isVoting } = props;
        if (isVoting === i) return null;

        return (
            <Text className="relative flex-shrink-0 ml-1 text-sm font-bold text-right">
                {roundToDecimalDigits(votingRate * 100, 2)}%
            </Text>
        );
    }

    function renderText(text: string) {
        return (
            <Text className="text-sm font-bold">{text}</Text>
        );
    }

    function renderOption(option: string, i: number) {
        const value = getValue(i);
        if (!value) return null;

        const votingRate = post?.pollTotalVoters ? value.count / post.pollTotalVoters : 0;
        const isSelected = i === myVote;

        const wrapperClasses = `${isPin ? 'mb-2' : 'mb-4'} ${!isPin && post.pollTotalVoters !== 0 ? 'lg:flex' : ''
        }`;

        return (
            <div className={wrapperClasses} key={i}>
                <ProgressBar
                    onClick={() => onOptionClick(i)}
                    progressFillClasName={getProgressFillClassName(isSelected)}
                    progress={votingRate}
                    className={`flex items-center relative px-4 ${isPostDeleted(postId) ? '' : 'cursor-pointer'
                    } ${isPin ? 'relative w-full h-6 rounded-lg overflow-hidden' : 'w-full'}`}
                >
                    <div className="flex items-center flex-1 w-full overflow-hidden">
                        <div
                            className={`relative flex items-center flex-1 ${isPostDeleted(postId) ? '' : 'cursor-pointer'
                            } overflow-hidden whitespace-nowrap overflow-ellipsis hover:whitespace-normal hover:overflow-visible`}
                        >
                            {renderText(option)}
                            {isSelected && <Icon svgFill={false} name="checkmark" className="ml-2" />}
                        </div>
                        {renderSpinner(i)}
                        {renderVotingRate(i, votingRate)}
                    </div>
                </ProgressBar>
                {renderOptionStats(option, i)}
            </div>
        );
    }

    function renderOptions() {
        if (!post) return null;
        const options = post.pollOptions;

        return <div>{options && options.map(renderOption)}</div>;
    }

    function renderOptionStats(option: string, i: number) {
        return (
            <div className="flex lg:min-w-[150px] flex-shrink-0 items-center">
                {renderUsers(i)}
                {renderOptionTotalUsers(i)}
            </div>
        );
    }

    return <div className={className}>{renderOptions()}</div>;
};

export default PollOptions;
