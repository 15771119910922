import React, { FC, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { getQueryParamFromLocation } from '@src/utils/url-utils/url-utils';
import BackLink, { BackLinkProps } from './BackLink';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

export interface BackLinkListenToUrlProps {
    backLinkProps?: BackLinkProps;
    defaultBackLinkUrl?: string;
    defaultBackLinkLabel?: string;
}

const BackLinkListenToUrl: FC<BackLinkListenToUrlProps> = props => {
    const { backLinkProps = {}, defaultBackLinkUrl = '', defaultBackLinkLabel = '' } = props;

    const location = useLocation();
    const backLinkUrl = getQueryParamFromLocation(location.search, 'backLinkUrl') || defaultBackLinkUrl;
    const backLinkLabel = getQueryParamFromLocation(location.search, 'backLinkLabel') || defaultBackLinkLabel;

    function getBackLinkProps(): BackLinkProps {
        return {
            ...backLinkProps,
            to: backLinkUrl,
            label: backLinkLabel
        };
    }

    return <BackLink {...getBackLinkProps()} />;
};

export default memo(BackLinkListenToUrl, propsEqualityFn);
