import { useEffect, useState } from 'react';

const useKeyPress = ({ key, onKeyUp, onKeyDown }: { key: string, onKeyUp?: (args?) => void; onKeyDown?: (args?) => void; }): boolean => {
    const [isPressed, setPressed] = useState(false);

    useEffect(() => {
        const handleDown = (event): void => {
            const { key: pressedKey } = event;
            if (key === pressedKey) {
                if (onKeyDown) onKeyDown();

                setPressed(true);
            }
        };

        const handleUp = (event): void => {
            const { key: releasedKey } = event;
            if (key === releasedKey) {
                if (onKeyUp) onKeyUp(event);

                setPressed(false);
            }
        };

        window.addEventListener('keyup', handleUp);
        window.addEventListener('keydown', handleDown);

        return () => {
            window.removeEventListener('keyup', handleUp);
            window.removeEventListener('keydown', handleDown);
        };
    }, []);

    return isPressed;
};

export default useKeyPress;