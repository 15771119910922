import { action, Action, Thunk, thunk } from 'easy-peasy';
import { SSRData } from './ssr-data';
import { StoreModel } from '..';


export interface SSRDataModel {
    ssrData: SSRData | null;
    proecessSSRData: Thunk<SSRDataModel, SSRData, null, StoreModel, Promise<SSRData>>;
    setSSRData: Action<SSRDataModel, SSRData>;
}

const SSRModel: SSRDataModel = {
    ssrData: null,

    proecessSSRData: thunk(async (actions, ssrData, helpers) => {
        actions.setSSRData(ssrData);
        const { tokens = {}, tokensAbout, users, posts, pinnedPosts, whitelistedUsers, domainSymbols } = ssrData;

        const tokenName = Object.keys(tokens)[0];
        if (posts) helpers.getStoreActions().upvote.setPosts(posts);
        if (pinnedPosts) helpers.getStoreActions().upvote.setPosts(pinnedPosts);
        if (tokenName && pinnedPosts) 
            helpers.getStoreActions().upvote.setPinnedPostSearchResult({ tokenName, searchResult: Object.values(pinnedPosts).map(pinnedPost => pinnedPost.id) });
        if (tokens) helpers.getStoreActions().token.setTokens(tokens);
        if (tokensAbout) helpers.getStoreActions().token.setTokensAbout(tokensAbout);
        if (users) helpers.getStoreActions().user.setUsersDict(users);
        if (whitelistedUsers) helpers.getStoreActions().token.setTokenPartners(whitelistedUsers);
        if (domainSymbols) helpers.getStoreActions().publicSettings.set({ key: 'domainsSymbols', value: domainSymbols });
        return ssrData;
    }),

    setSSRData: action((state, payload) => {
        state.ssrData = payload;
    })
};

export default SSRModel;
