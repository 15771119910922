import React, { FC, ChangeEvent, InputHTMLAttributes } from 'react';
import { cn } from '../../../utils/ui';
import { getTotalDecimalDigits, isNumber } from '../../../utils/number-utils';

export interface InputProps{
    className?: string;
    type?: InputHTMLAttributes<HTMLInputElement>['type'];
    isReadOnly?: boolean;
    isDisabled?: boolean;
    pattern?: string;
    maxLength?: number;
    autoComplete?: string;
    min?: number; // input number
    max?: number; // input number
    decimalDigits?: number; // input number
    autoFocus?: boolean;
    hasError?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    onClick?: () => void;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    onEnterPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    value?: string;
    placeholder?: string;
    name?: string;
}

const Input: FC<InputProps> = props => {
    const { className, autoFocus, isReadOnly, isDisabled, type, value = '', placeholder, autoComplete, name, hasError } = props;

    function getClassName(): string {
        const base = 'transition w-full px-4 rounded-lg bg-white dark:bg-gray-900';

        // Apply error styles if hasError is true
        if (hasError)
            return `${base} border border-red-500`;

        if (isDisabled) return `${base} bg-gray-100 dark:bg-gray-800`;

        // Default styling
        return `${base} text-gray-950 focus:border-gray-500 dark:focus:border-gray-500 border border-gray-200 dark:text-white dark:border-gray-700`;
    }

    function preventDefault(e): void {
        e.preventDefault();
        e.stopPropagation();
    }

    function onClick(): void {
        const { isDisabled, onClick } = props;
        if (isDisabled || !onClick) return;
        onClick();
    }

    function onFocus(): void {
        const { isDisabled, isReadOnly, onFocus } = props;
        if (isDisabled || isReadOnly || !onFocus) return;
        onFocus();
    }

    function onBlur(): void {
        const { isDisabled, isReadOnly, onBlur } = props;
        if (isDisabled || isReadOnly || !onBlur) return;
        onBlur();
    }

    function onKeyPress(e: React.KeyboardEvent<HTMLDivElement>): void {
        const { decimalDigits, onKeyPress, onEnterPress } = props;
        const { key } = e;

        if (type === 'number' && decimalDigits === 0 && key === '.')
            preventDefault(e);

        if (key === 'Enter' && onEnterPress) return onEnterPress(e);

        if (onKeyPress)
            onKeyPress(e);
    }

    function isValid(value: string): boolean {
        const { type, maxLength, pattern } = props;

        if (value === '' || value === '-') return true;
        if (maxLength && value.length > maxLength || pattern && !new RegExp(pattern).test(value)) return false;

        if (type === 'number') return isInputNumberValid(value);

        return true;
    }

    function isInputNumberValid(value: string): boolean {
        const { min, max, decimalDigits } = props;

        if (decimalDigits && decimalDigits > 0 && value === '.') return true;
        if (!isNumber(value)) return false;

        const inputDecimalDigits = getTotalDecimalDigits(value);
        if (decimalDigits && inputDecimalDigits > decimalDigits) return false;

        if (min !== null && Number(value) < Number(min) || max !== null && Number(value) > Number(max)) return false;

        return true;
    }

    function onChange(e: ChangeEvent<HTMLInputElement>): void {
        const { isDisabled, isReadOnly, onChange } = props;
        if (isReadOnly || isDisabled) return;

        const _isValid = isValid(e.target.value);
        if (!_isValid || !onChange) return;

        onChange(e);
    }

    function getPattern(): string | undefined {
        const { type, pattern } = props;
        if (type === 'number') return '[0-9.]*';
        return pattern;
    }

    const inputProps: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> = {
        type,
        className: cn(getClassName(), className),
        value,
        onChange,
        onFocus,
        onClick,
        onBlur,
        onKeyPress,
        pattern: getPattern(),
        readOnly: isReadOnly,
        disabled: isDisabled,
        placeholder,
        name,
        autoFocus,
        autoComplete
    };

    return (
        <input {...inputProps} />
    );
};

export default Input;
