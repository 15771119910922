import React, { FC } from 'react';
import { noop } from '@src/utils/utils';
import Hr from '@creator/ui/components/Hr/Hr';

type BlockData = Record<string, unknown>;

export interface EditorViewOnlyDelimiterToolProps {
    className?: string;
    blockData: BlockData;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const EditorViewOnlyDelimiterTool: FC<EditorViewOnlyDelimiterToolProps> = props => {
    const { onClick = noop, className } = props;

    function renderContent() {
        return (
            <div className="ce-block">
                <div className="ce-block__content">
                    <Hr />
                </div>
            </div>
        );
    }

    return (
        <div className={`delimiter-tool cursor-pointer ${className}`} onClick={onClick}>
            {renderContent()}
        </div>
    );
};

export default EditorViewOnlyDelimiterTool;
