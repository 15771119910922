import React, { type FC, Fragment, memo, useEffect, useState } from 'react';
import { cn } from '../../utils/ui';
import LoadingSkeleton, { LoadingSkeletonProps } from '../LoadingSkeleton/LoadingSkeleton';
import { mergeProps } from '../../utils/merge-props/merge-props';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

export interface ImageProps {
    className?: string;
    src?: string;
    fallbackSrc?: string;
    alt?: string;
    isLoading?: boolean; // Optional external loading control
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    loadingSkeletonProps?: Partial<LoadingSkeletonProps>;
    fetchPriority?: 'auto' | 'high' | 'low'; // New prop to set image fetch priority
}

const TRANSPARENT_PIXEL = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'; // Transparent pixel

const Image: FC<ImageProps> = (props) => {
    const { className = '', src, fallbackSrc, isLoading, onClick, loadingSkeletonProps = {} } = props;
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        setHasError(false);
    }, [src]);

    // Determine the source to display
    const getSrc = () => {
        if (!src) return TRANSPARENT_PIXEL;
        if (hasError && fallbackSrc) return fallbackSrc;
        if (hasError) return TRANSPARENT_PIXEL;
        return src;
    };

    // Render loading skeleton if isLoading and enableLoadingSkeleton are true
    const renderLoadingSkeleton = () => {
        const mergedProps = mergeProps<[Partial<LoadingSkeletonProps>, Partial<LoadingSkeletonProps>]>(
            {
                className: cn('w-full h-full', className)
            },
            loadingSkeletonProps
        );

        return <LoadingSkeleton {...mergedProps} />;
    };

    // Render the image or fallback content
    const renderContent = () => {
        const { alt = '', fetchPriority = 'auto' } = props;

        const currentSrc = getSrc();

        return (
            <img
                src={currentSrc}
                alt={alt}
                onError={() => {
                    setHasError(true);
                }}
                className={cn('w-full h-full object-cover object-center transition', className, { 'hidden': isLoading })}
                onClick={onClick}
            />
        );
    };

    return (
        <Fragment>
            {isLoading && renderLoadingSkeleton()}
            {renderContent()}
        </Fragment>
    );
};

export default memo(Image, propsEqualityFn);
