import { TagUser } from '@creator/sdk/modules/account/account.model';

export function onSelectUser(newList: TagUser[], value: string): TagUser[] {
    if (newList.length > 0) {
        const list = [...newList];
        let newVal = value;
        const listWithoutDuplicates = [] as TagUser[];
        list.map((val, index) => {
            if (newVal.includes(val.username)) {
                newVal = newVal.replace('@' + val.username, '');
                listWithoutDuplicates.push(val);
            }
        });
        return listWithoutDuplicates;
    }
    return [];
}