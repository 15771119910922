import { FacebookAuthProvider, GithubAuthProvider, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { AuthProviderId } from './account.model';

export function getAuthProvider(providerId: AuthProviderId): any {
    if (providerId === 'google.com') return GoogleAuthProvider;
    if (providerId === 'facebook.com') return FacebookAuthProvider;
    if (providerId === 'twitter.com') return TwitterAuthProvider;
    if (providerId === 'github.com') return GithubAuthProvider;

    return '';
}

export function displayNameToUsername(displayName: string): string {
    const nameSplit = displayName.split('@');
    return nameSplit[0].toLowerCase();
}

export function isUsernameValid(username: string): boolean {
    if(RESERVED_WORDS.includes(username)) return false;
    return /^[a-zA-Z0-9_]{3,20}$/i.test(username);
}

export function isEmailValid(email: string): boolean {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email);
}

export function isPasswordValid(password: string): boolean {
    // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
    const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/;
    return regex.test(password);
}


export const RESERVED_WORDS = [
    'admin',
    'administrator',
    'support',
    'staff',
    'team',
    'deweb',
    'bancor',
    'creatoreco',
    'alert',
    'notice',
    'update',
    'official',
    'webmaster',
    'notification',
    'security',
    'moderator',
    'careers',
    'career',
    'sales',
    'marketing',
    'help',
    'privacy',
    'settings',
    'wiki',
    'developer',
    'operator',
    'sysadmin',
    'postmaster',
    'replacekeys',
    'replacekey',
    'eos',
    'eosio',
    'eth',
    'ethereum'
];
