
import { ApiResponse } from '../../creator-sdk';
import { post } from '../../network/api-service';
import { /*Poll,*/ Vote } from './voting.model';
import DbModule from '../db';
import { Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import { getDocs, limit, orderBy, query, QueryConstraint, QueryDocumentSnapshot, startAfter, where } from 'firebase/firestore/lite';
import AccountModule from '../account';

export interface VotePayload {
    userId: string;
    postId: string;
    selectedOptionIndex: number;
}

export default class VotingService {
    private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private accountModule: AccountModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {

        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.accountModule = accountModule;
    }

    async vote(payload: VotePayload) {
        const token = await this.accountModule.getIdToken();
        const res = await post<ApiResponse<string>>('/post/vote', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return res.data;
    }

    async getVote(postId: string): Promise<Vote | null> {
        try {
            const userVote: Vote = await this.dbModule.getDocument('Vote', postId);
            return userVote;
        }
        catch (e) {
            return null;
        }
    }
}