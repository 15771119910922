import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import { GetUsersInfoOrderBy } from '@creator/sdk/modules/admin/admin.service';
import { GetUsersInfoFilterBy } from '@creator/sdk/modules/admin/admin.service';
import { TagUser, User } from '@creator/sdk/modules/account/account.model';
import { useDebounce } from '@src/hooks/use-debounce';
import useKeyPress from '@src/hooks/use-key-press';
import { onSelectUser } from './userSearchUtil';
import UsersSearchDropDown from '@src/components/UsersSearchDropDown/UsersSearchDropDown';


export interface UsersSearchDropDownProps {
    value: string;
    className?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onUserClick: (username: TagUser[]) => void;
    tokenName: string;
    previouslyTagged?: TagUser[];
}

const USERS_INFO_PAGE_SIZE = 5;

const TagSearchDropdown: FC<UsersSearchDropDownProps> = props => {
    const { tokenName, value, onUserClick, previouslyTagged = [] } = props;
    const searchResultLastDoc = useStoreState(state => state.admin.usersInfoSearchResultLastDoc);
    const loadUsers = useStoreActions(actions => actions.user.loadUsers);
    const setUsers = useStoreActions(actions => actions.user.setUsers);
    const users = useStoreState(state => state.user.userSearchResult);
    const [isActive, setIsActive] = useState(false);
    const valueDebounced = useDebounce(value, 200);
    const [targetedUser, setTargetedUser] = useState(0);
    const arrowUp = useKeyPress({ key: 'ArrowUp' });
    const arrowDown = useKeyPress({ key: 'ArrowDown' });
    const enter = useKeyPress({ key: 'Enter' });
    const backspace = useKeyPress({ key: 'Backspace' });
    const [taggedUsers, setTaggedUsers] = useState([...previouslyTagged] as TagUser[]);
    const [lastMatch, setLastMatch] = useState('');
    const [lastTaggedUserIndex, setLastTaggedUserIndex] = useState(-1);


    useEffect(() => {
        if (!value)
            setUsers([]);
    }, [value]);

    useEffect(() => {
        if (!valueDebounced || backspace) return;
        loadMatchingUsers(searchResultLastDoc);
    }, [valueDebounced, backspace]);

    useEffect(() => {
        if (!value || lastTaggedUserIndex === -1 || !backspace || !props.onChange || !taggedUsers || taggedUsers.length === 0) return;

        const username = taggedUsers[taggedUsers.length - 1].username;
        if (value.length === lastTaggedUserIndex + username.length) {
            const newValue = value.replace('@' + username.slice(0, username.length - 1), '');
            const user = { displayName: username, username: username, id: taggedUsers[taggedUsers.length - 1].id } as User;
            onUserClickAction(user);
            props.onChange({ target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>);
        }
    }, [backspace, lastTaggedUserIndex, value]);

    useEffect(() => {
        if (!taggedUsers || taggedUsers.length === 0) {
            setLastTaggedUserIndex(-1);
            return;
        }
        const lastTaggedUser = taggedUsers[taggedUsers.length - 1];
        const indexOfLastTaggedUser = value.indexOf('@' + lastTaggedUser.username);
        setLastTaggedUserIndex(indexOfLastTaggedUser);
    }, [taggedUsers, value]);

    useEffect(() => {
        if (!isActive) return;
        if (arrowUp)
            onArrowUpDown(true);
        if (arrowDown)
            onArrowUpDown(false);
    }, [arrowUp, arrowDown, isActive]);

    useEffect(() => {
        if (enter && isActive && users[targetedUser]) {
            const user = { displayName: users[targetedUser].displayName, username: users[targetedUser].displayName, id: users[targetedUser].id } as User;
            onUserClickAction(user);
        }

    }, [enter]);

    useEffect(() => {
        if (taggedUsers.length === 0) return;
        if (onUserClick)
            onUserClick(taggedUsers);
    }, [taggedUsers]);

    function onUserSelected(newList: TagUser[], newValue?: string): void {
        setTaggedUsers(onSelectUser(newList, newValue || value));
    }

    function onArrowUpDown(isUp: boolean): void {
        if (isUp && targetedUser > 0) {
            const number = targetedUser - 1;
            setTargetedUser(number);
        }
        if (!isUp && targetedUser < USERS_INFO_PAGE_SIZE - 1) {
            const number = targetedUser + 1;
            setTargetedUser(number);
        }
    }

    function getFilterBy(searchValue: string): GetUsersInfoFilterBy[] {
        if (!searchValue) return [];

        return [{ field: 'username', 'opStr': '>=', value: searchValue.toLowerCase() }, { field: 'username', 'opStr': '<=', value: searchValue.toLowerCase() + '\uf8ff' }];
    }

    function getOrderBy(): GetUsersInfoOrderBy {
        return { field: 'username', direction: 'asc' };
    }

    function onUserClickAction(user: User): void {
        let newVal = '';
        if (!user) return;
        if (lastMatch !== user.displayName) {
            const valueWithoutTaggedUser = value.substring(0, value.length - lastMatch.length);
            newVal = valueWithoutTaggedUser + '@' + user.displayName;
            if (props.onChange)
                props.onChange({ target: { value: newVal } } as React.ChangeEvent<HTMLInputElement>);
            // props.onChange({ target: { value: valueWithoutTaggedUser + '@' + username } } as React.ChangeEvent<HTMLInputElement>);
        }
        const obj = taggedUsers.find(userSearch => userSearch.username === user.username);
        if (obj && taggedUsers.indexOf(obj) !== -1) {
            const indexOfUser = taggedUsers.indexOf(obj);
            const newArray = [...taggedUsers];
            newArray.splice(indexOfUser, 1);
            // setTaggedUsers(newArray);
            onUserSelected(newArray, newVal);
        }
        else { onUserSelected([...taggedUsers, { username: user.displayName, id: user.id }], newVal); }
        setUsers([]);
        // setTaggedUsers([...taggedUsers, { username: username, id: id }]);
    }

    async function loadMatchingUsers(): Promise<void> {
        const userRegex = /(?:^|\s)(@)(?!\.)(?!\S*\.\.)(?!\S*\.[\s|$])([^\s<>]+)(?=\s|$)/g;
        const matches = valueDebounced.match(userRegex);
        setIsActive(false);
        if (!matches || matches.length === 0 || valueDebounced.lastIndexOf(matches[matches.length - 1]) + matches[matches.length - 1].length < valueDebounced.length) return;
        setIsActive(true);
        let name = '';
        const unComputedName = matches[matches.length - 1];
        if (unComputedName.includes('\n'))
            name = matches[matches.length - 1].substring(2);

        else if (matches[matches.length - 1].includes(' '))
            name = matches[matches.length - 1].substring(2);
        else
            name = matches[matches.length - 1].substring(1);

        setLastMatch('@' + name);
        const filterBy = getFilterBy(name);
        await loadUsers({ filterBy: filterBy, orderBy: getOrderBy(), limit: USERS_INFO_PAGE_SIZE, lowerBound: null });
    }

    if (!isActive) return null;
    return <UsersSearchDropDown isActive={isActive} className={`rounded-md absolute bottom-10 z-1 left-0 ${props.className}`} onUserClick={onUserClickAction} tokenName={tokenName} users={users} />;
};

export default TagSearchDropdown;
