
import { Pagination } from '../db/db.model';
import DbModule from '../db';
import { Post, PostComment } from './upvote.model';
import UpvoteService, { CreatePostApiRequestPayload, EditPostApiRequestPayload, DeletePostApiRequestPayload, CreatePostCommentApiRequestPayload, DeletePostCommentApiRequestPayload, EditPostCommentApiRequestPayload, GetPostsOrderBy, GetPostsFilterBy, ReactionApiRequestPayload, TipCommentApiRequestPayload, TipPostApiRequestPayload, CreateAnonymousPostCommentApiRequestPayload, GetPostsCommentFilterBy, CreateAnonymousPostApiRequestPayload, ApprovePendingCommentApiRequestPayload, ApprovePendingPostApiRequestPayload, DeleteUserContentApiRequestPayload, GetPostCommentsOrderBy, SyndicatePostApiRequestPayload, PinPostApiRequestPayload } from './upvote.service';
import AccountModule, {  } from '../account';
import StorageModule from '../storage';
import { v4 as uuidv4 } from 'uuid';
import TokenModule from '../token';
import TimeModule from '../time';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';
import { QueryDocumentSnapshot } from 'firebase/firestore/lite';

export interface CreatePostPayload {
    title: string;
    parentPostId?: string;
    tokenName: string;
    publisher: string;
    content?: string;
    flairName?: string;
    externalArticleUrl?: string;
    externalArticleId?: string;
    pollOptions?: string[];
}

export interface LinkMeta {
    url: string;
    title?: string;
    site_name?: string;
    description?: string;
    image?: {
        url: string;
        width?: string | null;
        height?: string | null;
    }

    twitterUrl?: string;
    twitterPlayer?: string;
    videoWidth?: string;
    videoHeight?: string;
    trackId?: string;
}

export interface CreatePostCommentPayload {
    tokenName: string;
    postId: string;
    replyToCommentId: string;
    publisher: string;
    content: string;
    taggedUsers?: { username: string, id: string }[];
    linkMeta?: LinkMeta | null;
}
export type CreateAnonymousPostCommentPayload = CreateAnonymousPostCommentApiRequestPayload;
export type CreateAnonymousPostPayload = CreateAnonymousPostApiRequestPayload;
export type DeleteUserContentPayload = DeleteUserContentApiRequestPayload;

export interface EditPostPayload {
    postId: string;
    title: string;
    uploadedVideo?: boolean;
    publisher: string;
    content?: string;
    pollId?: string;
    flairName?: string;
}

export interface DeletePostPayload {
    postId: string;
    userId: string;
    isDelete: boolean; // true = delete / false = undelete
    tokenName: string;
    reason?: string;
    reasonText?: string;
}

export type DeletePostCommentPayload = DeletePostCommentApiRequestPayload;

export interface EditPostCommentPayload {
    commentId: string;
    userId: string;
    content: string;
    tokenName: string;
    taggedUsers?: { username: string; id: string; }[];
    linkMeta?: LinkMeta | null;
}

export interface PurchasePostPayload {
    postId: string;
    upvoter: string;
    quantity: number;
}

export type ReactionPayload = ReactionApiRequestPayload;
export type SyndicatePostPayload = SyndicatePostApiRequestPayload;
export type PinPostPayload = PinPostApiRequestPayload;
export type TipCommentPayload = TipCommentApiRequestPayload;
export type TipPostPayload = TipPostApiRequestPayload;
export type ApprovePendingCommentPayload = ApprovePendingCommentApiRequestPayload;
export type ApprovePendingPostPayload = ApprovePendingPostApiRequestPayload;

export default class UpvoteModule {
    accountModule: AccountModule;
    tokenModule: TokenModule;
    upvoteService: UpvoteService;
    timeModule: TimeModule;
    domainName: string;
        private dbModule: DbModule;
    private storageModule: StorageModule;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule, accountModule: AccountModule, tokenModule: TokenModule, timeModule: TimeModule) {
        
        this.dbModule = dbModule;
        this.storageModule = storageModule;
        this.domainName = domainName;
        this.upvoteService = new UpvoteService(contractName, domainName, appCheckModule, dbModule, accountModule);
        this.accountModule = accountModule;
        this.tokenModule = tokenModule;
        this.timeModule = timeModule;
        this.appCheckModule = appCheckModule;
    }

    async createPost(payload: CreatePostPayload): Promise<Post> {
        const { externalArticleUrl, externalArticleId, publisher, parentPostId, content, title, tokenName, flairName, pollOptions } = payload;

        const data: CreatePostApiRequestPayload = {
            externalArticleUrl,
            externalArticleId,
            title,
            content: content || '',
            tokenName,
            publisherId: publisher,
            parentPostId,
            flairName: flairName
        };
        if (pollOptions)
            data.pollOptions = pollOptions;

        const res = await this.upvoteService.createPost(data)
            .catch((e: Error) => {
                // if (e.message.includes('Token with this name already exists')) throw new Error('tokenAlreadyExists');
                throw e;
            });

        return res.payload;
    }

    async createAnonymousPost(payload: CreateAnonymousPostPayload): Promise<Post> {
        const { content, title, tokenName, flairName, pollOptions } = payload;

        const data: CreatePostApiRequestPayload = {
            title,
            content: content || '',
            tokenName,
            flairName: flairName
        };
        if (pollOptions)
            data.pollOptions = pollOptions;

        const res = await this.upvoteService.createAnonymousPost(data);
        return res.payload;
    }

    async createPostComment(payload: CreatePostCommentPayload): Promise<PostComment> {
        const { content, postId, replyToCommentId, publisher, tokenName, taggedUsers, linkMeta } = payload;

        const data: CreatePostCommentApiRequestPayload = {
            content,
            tokenName,
            publisherId: publisher,
            postId: postId,
            replyToCommentId: replyToCommentId,
            linkMeta
        };

        if (taggedUsers)
            data.taggedUsers = taggedUsers;

        const res = await this.upvoteService.createPostComment(data);
        return res.payload;
    }

    async createAnonymousPostComment(payload: CreateAnonymousPostCommentPayload): Promise<PostComment> {
        const { content, postId, replyToCommentId, tokenName } = payload;

        const data: CreatePostCommentApiRequestPayload = {
            content,
            tokenName,
            postId: postId,
            replyToCommentId: replyToCommentId,
        };

        const res = await this.upvoteService.createAnonymousPostComment(data);
        return res.payload;
    }

    async getPostComments(startAfter: QueryDocumentSnapshot<PostComment> | null, limit: number, filterBy?: GetPostsCommentFilterBy[], orderBy?: GetPostCommentsOrderBy): Promise<Pagination<PostComment>> {
        return this.upvoteService.getPostCommentsCache(startAfter, limit, filterBy, orderBy);
    }

    async editPost(payload: EditPostPayload): Promise<Post> {
        const { postId, publisher, content, title, flairName, pollId, uploadedVideo } = payload;

        const data: EditPostApiRequestPayload = {
            title,
            postId,
            content: content || '',
            publisherId: publisher,
            uploadedVideo,
            pollId: pollId || '',
            flairName: flairName || ''
        };

        const res = await this.upvoteService.editPost(data)
            .catch((e: Error) => {
                throw e;
            });

        return res.payload;
    }

    async deletePost(payload: DeletePostPayload): Promise<Post> {
        const { isDelete, postId, userId, tokenName, reason, reasonText } = payload;

        const data: DeletePostApiRequestPayload = {
            tokenName,
            userId,
            hidden: isDelete,
            postId,
            reason: reason || '',
            reasonText: reasonText || ''
        };

        const res = await this.upvoteService.deletePost(data)
            .catch((e: Error) => {
                // if (e.message.includes('Token with this name already exists')) throw new Error('tokenAlreadyExists');
                throw e;
            });

        return res.payload;
    }

    async deleteUserContent(payload: DeleteUserContentPayload): Promise<string> {
        const res = await this.upvoteService.deleteUserContent(payload);
        return res.payload;
    }

    async deletePostComment(payload: DeletePostCommentPayload): Promise<PostComment> {
        const res = await this.upvoteService.deletePostComment(payload);
        return res.payload;
    }

    async editPostComment(payload: EditPostCommentPayload): Promise<PostComment> {
        const { content, commentId, userId, tokenName, taggedUsers, linkMeta } = payload;
        const data: EditPostCommentApiRequestPayload = {
            tokenName,
            userId,
            content,
            commentId,
            taggedUsers,
            linkMeta
        };

        const res = await this.upvoteService.editPostComment(data)
            .catch((e: Error) => {
                // if (e.message.includes('Token with this name already exists')) throw new Error('tokenAlreadyExists');
                throw e;
            });

        return res.payload;
    }

    async getPosts(tokenName: string, startAfter: QueryDocumentSnapshot<Post> | null, limit: number, filterBy?: GetPostsFilterBy[], orderBy?: GetPostsOrderBy[]): Promise<Pagination<Post>> {
        return this.upvoteService.getPostsCache(tokenName, startAfter, limit, filterBy, orderBy);
    }

    async getPostByExternalArticleId(articleId: string) {
        const res = await this.dbModule.getDocuments<Post>('Post', { limit: 1, filterBy: [{ field: 'externalArticleId', opStr: '==', value: articleId }] });
        if (!res.items.length) return;
        return res.items[0];
    }

    async getPostsByIds(ids: string[]): Promise<Post[]> {
        return this.upvoteService.getPostsByIds(ids);
    }

    async getPost(postId: string): Promise<Post> {
        const post: Post = await this.dbModule.getDocument('Post', postId);
        if (!post) throw new Error(`post with id ${postId} not found`);

        return post;
    }

    // Get post by slugUrl field
    async getPostBySlugUrl(slugUrl: string) {
        const res = await this.dbModule.getDocuments<Post>('Post', { limit: 1, filterBy: [{ field: 'slugUrl', opStr: '==', value: slugUrl }] });
        if (!res.items.length) return;
        return res.items[0];
    }

    async getPostComment(commentId: string): Promise<PostComment> {
        const comment: PostComment = await this.dbModule.getDocument('Comment', commentId);
        if (!comment) throw new Error(`post comment with id ${commentId} not found`);

        return comment;
    }

    async pinPost(payload: PinPostPayload): Promise<string> {
        const res = await this.upvoteService.pinPost(payload)
            .catch((e: Error) => {
                throw e;
            });

        return res.payload;
    }

    async syndicatePost(payload: SyndicatePostPayload): Promise<Post> {
        const res = await this.upvoteService.syndicatePost(payload);
        return res.payload;
    }

    async reaction(payload: ReactionPayload): Promise<string> {
        const res = await this.upvoteService.reaction(payload)
            .catch((e: Error) => {
                throw e;
            });

        return res.payload;
    }

    async tipComment(payload: TipCommentPayload): Promise<PostComment> {
        const { userId, commentId, tokenName, amount } = payload;

        const data: TipCommentApiRequestPayload = {
            commentId,
            tokenName,
            amount,
            userId
        };

        const res = await this.upvoteService.tipComment(data);
        return res.payload;
    }

    async tipPost(payload: TipPostPayload): Promise<Post> {
        const { userId, postId, tokenName, amount } = payload;

        const data: TipPostApiRequestPayload = {
            postId,
            tokenName,
            amount,
            userId
        };

        const res = await this.upvoteService.tipPost(data);
        return res.payload;
    }

    async approvePendingComment(payload: ApprovePendingCommentPayload): Promise<PostComment | null> {
        const res = await this.upvoteService.approvePendingComment(payload);
        return res.payload;
    }

    async approvePendingPost(payload: ApprovePendingPostPayload): Promise<Post | null> {
        const res = await this.upvoteService.approvePendingPost(payload);
        return res.payload;
    }
}
