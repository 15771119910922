import React, { type FC } from 'react'
import { cn } from '../../utils/ui';
export interface SpinnerProps {
    className?: string;
}

const Spinner: FC<SpinnerProps> = (props) => {
    const { className = '' } = props;

    function getClassName() {
        return cn(
            'w-5 h-5 rounded-full animate-spin border border-t-2 border-solid border-primary-400 dark:border-primary-600 border-t-primary-600 dark:border-t-primary-400 shadow-md',
            className
        )
    }

    return (
        <div className={getClassName()} />
    )
}

export default Spinner;