import { thunk, Action, Thunk, action, computed, Computed } from 'easy-peasy';
import { loadRTBPrice } from './actions';
import { StoreModel } from '..';
import { getPrice } from './helpers';
import { RTBPrice } from '@creator/sdk/modules/token-price/token-price.model';

export interface TokenPriceModel {
    rtbPrice: RTBPrice | null;
    getPrice: Computed<TokenPriceModel, (tokenName: string) => number, StoreModel>;

    set: Action<TokenPriceModel, { key: keyof TokenPriceModel; value: unknown }>;

    loadRTBPrice: Thunk<TokenPriceModel, undefined, null, StoreModel, Promise<RTBPrice>>;
}

const tokenPriceModel: TokenPriceModel = {
    rtbPrice: null,
    getPrice: computed(state => tokenName => {
        const price = getPrice(tokenName);
        if (!price) return 0;
        return price;
    }),

    loadRTBPrice: thunk(async actions => {
        const rtbPrice = await loadRTBPrice();
        actions.set({ key: 'rtbPrice', value: rtbPrice });
        return rtbPrice;
    }),

    set: action((state, payload) => {
        const { key, value } = payload;
        state[key] = value;
    })
};

export default tokenPriceModel;
