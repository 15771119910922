import React, { FC, useState, useEffect, memo, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Post } from '@creator/sdk/modules/upvote/upvote.model';
import { GetPostsFilterBy, GetPostsOrderBy } from '@creator/sdk/modules/upvote/upvote.service';
import { ensurePostUrl, getPostListWeekFilter } from '@src/model/upvote/helpers';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import PostCard from '../PostCard/PostCard';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { useHistory } from 'react-router-dom';
import { isMobile } from '@src/utils/utils';
import { cn } from '@creator/ui/utils/ui';

export interface RecommendedPostListProps {
    tokenName: string;
    className?: string;
    excludePostIds?: string[];
}

const RecommendedPostList: FC<RecommendedPostListProps> = props => {
    const { tokenName, className = '', excludePostIds = [] } = props;
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const loadPosts = useStoreActions(actions => actions.upvote.loadPosts);
    const posts = useStoreState(state => state.upvote.getRecommendedPostSearchResult(tokenName), storeEqualityFn);
    const history = useHistory();

    function getPostList() {
        const filteredPosts = posts.filter(post => !excludePostIds.includes(post?.id));

        // Return first 3 posts for mobile view
        if (isMobile()) return filteredPosts.slice(0, 3);

        return filteredPosts.slice(0, 6);
    }

    useEffect(() => {
        _loadPosts();
    }, [loadPosts, tokenName]);

    const onPostClick = useCallback((e, postId) => {
        history.push(`/${ensureTokenUrl(tokenName)}/${ensurePostUrl(postId)}`);
    }, [tokenName]);

    async function _loadPosts() {
        const filterBy: GetPostsFilterBy[] = [{ field: 'status', opStr: '==', value: 0 }, ...getPostListWeekFilter(false)];
        const orderBy: GetPostsOrderBy[] = [
            { field: 'totalViews', direction: 'desc' },
            { field: 'createdAt', direction: 'desc' }
        ];

        await loadPosts({ tokenName, lowerBound: null, filterBy, orderBy, limit: 20, saveTo: 'recommendedPosts' });

        setIsLoading(false);
    }

    function renderPost(post: Post) {
        const isPartner = post.createdByWhitelistedUser;

        return (
            <PostCard
                key={post.id}
                onClick={onPostClick}
                editorProps={{
                    hideMainTextIfMediaExists: true,
                    imagePreviewProps: {
                        className: isPartner ? '-mx-3 -mt-3 lg:-mx-5' : ''
                    }
                }}
                postId={post.id}
                showFooter={false}
                tokenName={tokenName} />
        );
    }

    function renderPosts() {
        return (
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                {getPostList().map(renderPost)}
            </div>
        );
    }

    function renderContent() {
        if (isLoading) return <Spinner className='mx-auto my-10 ' />;
        if (getPostList().length === 0) return;

        return (
            <Fragment>
                <div className={'text-lg font-bold text-gray-500'}>{t('recommendedPostList.title')}</div>
                {renderPosts()}
            </Fragment>
        );
    }

    return (
        <div className={cn('transition pb-4 space-y-4', className)}>
            {renderContent()}
        </div>
    );
};

export default memo(RecommendedPostList);