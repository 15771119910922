
export function getQueryParam<T extends string>(name: string): T | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name) as T | null;
}

export function getQueryParamFromLocation<T = string>(location: string, name: string): T | null {
    const urlParams = new URLSearchParams(location);
    return urlParams.get(name) as T | null;
}

export function getUrlHostname(url: string): string {
    return new URL(ensureHttpProtocol(url)).hostname;
}

export function getUrlPath(): string {
    return location.pathname;
}

export function getCurrentUrl(): string {
    return window.location.href;
}

export function getBaseUrl(): string {
    return window.location.protocol + '//' + window.location.host;
}

export function isLocalHost(): boolean {
    return window.location.origin.includes('localhost') || window.location.origin.includes('127.0.0.1');
}

export function stripUrl(url: string): string {
    // Remove www. if present
    let strippedURL = url.replace(/^(https?:\/\/)?/, '');

    // Remove query params and hash
    strippedURL = strippedURL.replace(/(\?.*|#.*)$/, '');

    return strippedURL;
}

export function removeQueryParam(sourceURL: string, key: string): string {
    let rtn = sourceURL.split('?')[0],
        param,
        params_arr = [] as string[];
    const queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';

    if (queryString !== '') {
        params_arr = queryString.split('&');
        for (let i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split('=')[0];
            if (param === key)
                params_arr.splice(i, 1);

        }
        if (params_arr.length) rtn = rtn + '?' + params_arr.join('&');
    }
    return rtn;
}

export function removeQueryParamFromUrl(param: string): void {
    const newUrl = removeQueryParam(window.location.href, param);
    window.history.replaceState({}, document.title, newUrl);
}


export function ensureHttpProtocol(url: string): string {
    if (url.startsWith('http') || url.startsWith('//:')) return url;
    return `https://${url}`;
}

export function stripHttpProtocol(url: string): string {
    return url.replace(/(^\w+:|^)\/\//, '');
}

export function getPathAndSearchFromUrl(url: string): string {
    try {
        const _url = new URL(url);
        return _url.pathname + _url.search;
    } catch (error) {
        return url; // relative URL
    }
}

export function getSearchFromUrl(url: string): string {
    try {
        const _url = new URL(url);
        return _url.search;
    } catch (error) {
        return url; // relative URL
    }
}

// converts special characters back to human readable text ie: google.com/%41%41%41 to google.com/AAAA
export function prettyUrl(url: string): string {
    try {
        return decodeURIComponent(url);
    } catch (error) {
        return url;
    }
}

export function getCurrentPathAndSearch(): string {
    const url = getCurrentUrl();
    return getPathAndSearchFromUrl(url);
}

export function getCurrentSearch(): string {
    const url = getCurrentUrl();
    return getSearchFromUrl(url);
}

export function getHostname(url: string): string {
    const _url = ensureHttpProtocol(url);
    return new URL(_url).hostname;
}

export function isExternal(url: string): boolean {
    try {
        new URL(url);
    } catch (error) {
        return false; // relative URL
    }
    return getHostname(location.href) !== getHostname(url);
}

export function isValidUrl(str: string): boolean {
    if (str.includes('localhost')) return true;
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~,+@]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
}

// extract URLs from a given text
export function extractUrls(text: string): string[] {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Match the URLs and return as an array
    const matches = text.match(urlRegex);

    // Return the matches, or an empty array if no matches are found
    return matches || [];
}

/**
* Removes trailing slashes from a string.
* @param {string} str - The input string (e.g., a URL or path).
* @returns {string} - The string without trailing slashes.
*/
export function removeTrailingSlashes(str: string) {
    return str.replace(/\/+$/, '');
}

export function slugify(text: string): string {
    return text
        .toString()
        .toLowerCase()
        .trim()
        // Replace spaces and underscores with hyphens
        .replace(/[\s_]+/g, '-')
        // Remove all non-alphanumeric characters except hyphens
        .replace(/[^a-z0-9-]/g, '')
        // Replace multiple hyphens with a single hyphen
        .replace(/-+/g, '-')
        // Remove leading and trailing hyphens
        .replace(/^-+|-+$/g, '');
}
