import React, { FC, memo } from 'react';
import UserDisplayName from '@creator/ui/components/UserDisplayName/UserDisplayName';
import ProfileImage, { ProfileImageProps } from '@src/components/ProfileImage/ProfileImage';
import { cn } from '@creator/ui/utils/ui';

export interface PartnerCardProps {
    displayName: string;
    profileName?: string;
    tokenName: string;
    className?: string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    isLoading?: boolean; // Add skeleton prop
}

const PartnerCard: FC<PartnerCardProps> = props => {
    const { profileName, displayName, tokenName, className = '', onClick, isLoading = false } = props;

    function renderProfileImage() {
        const baseProps: ProfileImageProps = {
            showBadge: false,
            tokenName,
            displayName,
            imageProps: {
                isLoading
            }
        };

        return (
            <ProfileImage {...baseProps} className="w-[52px] h-[52px] lg:w-[72px] lg:h-[72px]" />
        );
    }

    function renderDisplayName() {
        return (
            <UserDisplayName
                hideDomainSymbol={true}
                displayName={profileName || displayName}
                textProps={{ className: 'line-clamp-2 w-full font-medium mt-2', enableMultiline: true, isLoading }} />
        );
    }

    return (
        <div className={cn('flex flex-col text-center text-xs lg:text-sm items-center transition text-gray-500', className)} onClick={onClick}>
            {renderProfileImage()}
            {renderDisplayName()}
        </div>
    );
};

export default memo(PartnerCard);