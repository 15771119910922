import { getMyForwardedFromUserIds, getMyUser, getUserId, isUserModerator } from '../user/helpers';
import { getPost, getPostComment } from './helpers';
import { getToken } from '../token/helpers';
import { PostComment } from '@creator/sdk/modules/upvote/upvote.model';
import { hasSomeTokenPermission, hasTokenPermission, isPerrmisioned } from '../user/permission';
import { getDomainName } from '@src/config';

export enum PostStatus {
    PUBLISHED = 0,
    DELETED_BY_TOKEN_ADMIN = 1,
    DELETED_BY_PUBLISHER = 2,
    DELETED_BY_DOMAIN_ADMIN = 3,
    DELETED_BY_CATEGORY_ADMIN = 4, // deprecated
    PENDING = 5,
    PENDING_TRANSCRIPT = 6,
    PUBLISHED_UNLISTED = 7
}

export declare enum PostCommentStatus {
    PUBLISHED = 0,
    DELETED_BY_ADMIN = 1,
    DELETED_BY_PUBLISHER = 2,
    PENDING = 5
}

export type PendingOrDeletedContentType = 'pendingPosts' | 'pendingComments' | 'deletedPosts';


export function canEditPost(postId: string, tokenName: string): boolean {
    const myUser = getMyUser();
    if (!myUser) return false;

    const post = getPost(postId);
    if (!post) return false;

    const token = getToken(tokenName);
    if (!token) return false;

    if (token.approvePosts) return false;

    if (myUser.id === post.publisherId) return true;

    const forwardedFrom = getMyForwardedFromUserIds();
    if (forwardedFrom.includes(post.publisherId)) return true;

    return false;
}

export function canEditPostComment(commentId: string): boolean {
    const userId = getUserId();
    const myUser = getMyUser();
    if (!userId) return false;

    const comment = getPostComment(commentId);

    if (!comment) return false;
    if (myUser && myUser.ForwardedFromArray && myUser.ForwardedFromArray.includes(comment.publisherId)) return true;
    if (userId !== comment.publisherId) return false;

    return true;
}

export function canEditPostCommentReply(reply: PostComment): boolean {
    if (!reply) return false;

    const userId = getUserId();
    const myUser = getMyUser();
    if (!userId) return false;
    if (myUser && myUser.ForwardedFromArray && myUser.ForwardedFromArray.includes(reply.publisherId)) return true;
    if (userId !== reply.publisherId) return false;

    return true;
}

export function canDeletePostCommentReply(reply?: PostComment, tokenName?: string): boolean {
    if (!reply) return false;
    const myUser = getMyUser();
    if (myUser && myUser.ForwardedFromArray && myUser.ForwardedFromArray.includes(reply.publisherId)) return true;


    const userId = getUserId();
    if (!userId) return false;
    if (userId === reply.publisherId) return true;

    const user = getMyUser();
    if (!user) return false;

    if (tokenName && canDeleteContent(tokenName)) return true;
    // if (tokenName && user.moderator && user.moderator[tokenName]) return true;
    return user.domainAdmin;
}

export function canDeletePostComment(commentId: string, tokenName: string): boolean {
    const userId = getUserId();
    const myUser = getMyUser();

    if (!userId) return false;

    const comment = getPostComment(commentId);
    if (!comment) return false;

    if (myUser && myUser.ForwardedFromArray && myUser.ForwardedFromArray.includes(comment.publisherId)) return true;

    const token = getToken(tokenName);
    if (!token) return false;

    if (canDeleteContent(tokenName)) return true;
    if (userId === comment.publisherId) return true;

    const user = getMyUser();
    if (!user) return false;

    // if (tokenName && user.moderator && user.moderator[tokenName]) return true;
    if (user.domain === getDomainName() && user.domainAdmin) return true;
    return false;
}

export function canViewDeletedPosts(tokenName: string): boolean {
    const userId = getUserId();
    const user = getMyUser();
    const token = getToken(tokenName);

    if (token && token.creatorID === userId) return true;
    if (user && user.domainAdmin) return true;
    if (isUserModerator(userId, tokenName)) return true;

    return false;
}

export function canViewPendingContent(tokenName: string): boolean {
    const userId = getUserId();
    const user = getMyUser();

    if (user && user.domainAdmin) return true;
    return isPerrmisioned(tokenName, 'hideContent', userId);
}

export function canViewPendingTranscriptContent(postId: string): boolean {
    const userId = getUserId();

    const post = getPost(postId);
    if (!post) return false;

    return post.publisherId === userId;
}

export function canViewDeletedPostDetails(tokenName: string, postId: string): boolean {
    const myUser = getMyUser();
    if (!myUser) return false;

    const post = getPost(postId);
    if (!post) return false;

    if (post.status === PostStatus.PUBLISHED || post.status === PostStatus.PUBLISHED_UNLISTED) return true;
    if (post.publisherName === myUser?.displayName) return true;
    if (post.status === PostStatus.DELETED_BY_PUBLISHER && post.publisherName !== myUser?.displayName) return false;

    const userId = getUserId();
    const token = getToken(tokenName);
    if (!token) return false;
    if (token.creatorID === userId) return true;
    if (myUser.domainAdmin) return true;
    if (isUserModerator(userId, tokenName)) return true;

    return false;
}

export function canDeleteContent(tokenName: string) {
    const token = getToken(tokenName);
    if (!token) return false;

    return isPerrmisioned(tokenName, 'hideContent');
}

export function canDeletePost(postId: string): boolean {
    const userId = getUserId();
    if (!userId) return false;

    const post = getPost(postId);
    if (!post) return false;

    const { tokenName } = post;
    const token = getToken(tokenName);
    if (!token) return false;

    const forwardedFrom = getMyForwardedFromUserIds();

    const user = getMyUser();
    if (!user) return false;

    const isTokenCreator = userId === token.creatorID;
    const isModerator = Boolean(user.moderator?.[tokenName]);
    const isPostPublisher = post.publisherId === userId || forwardedFrom.includes(post.publisherId);
    const isDomainAdmin = user.domainAdmin;

    if (canDeleteContent(tokenName)) return true;

    if (post.status === 1 && !(isTokenCreator || isModerator)) return false; // if post already deleted by token creator, and user is nither token creator or mooderator
    if (post.status === 2 && !isPostPublisher) return false; // if post already deleted by post publisher, and user is not that publisher

    if (isDomainAdmin || isPostPublisher || isModerator || isTokenCreator) return true;
    return false;
}

export function canPinPost(postId: string): boolean {
    const userId = getUserId();
    const user = getMyUser();
    if (!userId || !user) return false;

    const post = getPost(postId);
    if (!post) return false;

    const { tokenName } = post;

    const token = getToken(tokenName);
    if (!token) return false;

    return isPerrmisioned(tokenName, 'pinPost');
}

export function canSuperComment(postId: string, entitiyType: 'post' | 'comment' = 'post'): boolean {
    const userId = getUserId();
    const user = getMyUser();
    if (!userId || !user) return false;

    const entitiy = entitiyType === 'post' ? getPost(postId) : getPostComment(postId);
    if (!entitiy) return false;

    const { publisherId } = entitiy;
    if (publisherId === 'anonymous' || publisherId === getUserId()) return false;

    return true;
}

export function canViewReports(tokenName: string) {
    return hasSomeTokenPermission(tokenName);
}