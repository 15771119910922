function getDatePlusDays(days = 0): string {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date.toUTCString();
}

window.memoryStorage = {};

export default {
    localStorage: {
        set(key: string, data: string): void {
            const targetKey = key;
            if (!data) return; // undefined is saved as string "undefined", so we better not save it
            window.localStorage.setItem(targetKey, data);
        },

        remove(key: string): void {
            window.localStorage.removeItem(key);
        },

        get(key: string): string | null {
            const targetKey = key;
            return window.localStorage.getItem(targetKey);
        }
    },

    sessionStorage: {
        set(key: string, data: string): void {
            window.sessionStorage.setItem(key, data);
        },

        remove(key: string): void {
            window.sessionStorage.removeItem(key);
        },

        get(key: string): string | null {
            const targetKey = key;
            return window.sessionStorage.getItem(targetKey);
        }
    },

    memoryStorage: {
        set(key: string, data: any): void {
            window.memoryStorage[key] = data;
        },

        remove(key: string): void {
            delete window.memoryStorage[key];
        },

        get(key: string): string | null {
            return window.memoryStorage[key];
        }
    },

    cookie: {
        set(key: string, data: string, expiresInDays: number): void {
            document.cookie = `${key}=${data}; expires=${getDatePlusDays(expiresInDays)}`;
        },

        get(key: string): string | null {
            const match = document.cookie.match(new RegExp(key + '=([^;]+)'));
            if (!match) return null;
            return match[1];
        },

        remove(key: string): void {
            document.cookie = `${key}; expires=${getDatePlusDays(-365)}`;
        }
    }
};
