import creatorSdk from '@src/services/creator-sdk';
import { GetDocumentsPayload } from '@creator/sdk/modules/db';
import { Pagination } from '@creator/sdk/modules/db/db.model';
import { Response } from '@creator/sdk/modules/response/response.model';

export async function loadResponses(payload: GetDocumentsPayload<Response>): Promise<Pagination<Response>> {
    return creatorSdk.responseModule.getResponses(payload);
}

export async function loadResponse(responseId: string): Promise<Response> {
    return creatorSdk.responseModule.getResponse(responseId);
}