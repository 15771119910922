import React, { FC, PropsWithChildren } from 'react';
import { cn } from '@creator/ui/utils/ui';
import Icon from '../Icon/Icon';

export interface ResponseFullScreenButtonProps {
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const ResponseFullScreenButton: FC<PropsWithChildren<ResponseFullScreenButtonProps>> = props => {
    const { children, onClick, className = ''} = props;

    function getBaseClassname(): string {
        return cn('cursor-pointer text-[20px] py-0.5 px-4 flex items-center justify-center bg-gradient-to-t to-[#6FE5ED] from-[#18B5BF] rounded-full text-gray-50', className); 
    }

    return (
        <div onClick={onClick} className={getBaseClassname()}>
            <Icon name="response2" />
        </div>
    );
};

export default ResponseFullScreenButton;
