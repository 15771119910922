import { roundToDecimalDigitsAmounts } from '@src/utils/number-utils/number-utils';
import { DisplayCurrency } from '@creator/sdk/modules/token';
import { getToken } from '../token/helpers';
import store from '@src/store';

export type FiatCode = 'USD' | 'EUR';
export function convertToFiat(tokenAmount: number, tokenName: string, to: FiatCode): number {
    if (to !== 'USD') throw new Error('only USD supported');

    const token = getToken(tokenName);
    if (!token) return 0;

    const price = 1;
    return tokenAmount * price;
}

export function getDisplayCurrencySymbol(displayCurrency: DisplayCurrency = 'USD'): string {
    if (displayCurrency === 'USD') return '$';
    if (displayCurrency === 'ILS') return '₪';
    if (displayCurrency === 'EUR') return '€';
    return '$';
}

export function displayTokenPrice(price: number, tokenAmount: number, displayCurrency: DisplayCurrency = 'USD'): string {
    const totalPrice = tokenAmount * price;
    return `${roundToDecimalDigitsAmounts(totalPrice)}`;
}

export function displayTokenFiatPrice(price: number, tokenAmount: number, displayCurrency: DisplayCurrency = 'USD'): string {
    const totalPrice = tokenAmount * price;
    return `${getDisplayCurrencySymbol(displayCurrency)}${roundToDecimalDigitsAmounts(totalPrice)}`;
}

export function getPrice(tokenName: string, displayCurrency: DisplayCurrency = 'USD'): number {
    if (displayCurrency !== 'USD') throw new Error(`getPrice - ${displayCurrency} is not supported yet`);

    if (tokenName === 'BBS') return store.getState().tokenPrice.rtbPrice?.price || 0;
    return 1;
}