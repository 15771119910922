import React, { FC, useState } from 'react';
import Text from '@creator/ui/components/Text/Text';
import { useTranslation } from 'react-i18next';
import MenuPopover from '@src/basic-components/MenuPopover/MenuPopover';
import MenuItem from '@src/basic-components/MenuItem/MenuItem';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import { getMyUser, getUserId, isUserModerator } from '@src/model/user/helpers';
import { canEditToken, canDeleteToken, canManageFlairs } from '@src/model/token/permission';
import { isTokenDeleted } from '@src/model/token/helpers';
import Button from '@creator/ui/components/Button/ButtonV2';
import Icon, { IconType } from '@src/basic-components/Icon/Icon'; // Ensure IconType is exported
import { canViewDeletedPosts, canViewPendingContent } from '@src/model/upvote/permission';
import { useHistory } from 'react-router-dom';
import { getCurrentPathAndSearch } from '@src/utils/url-utils/url-utils';
import { ensureTokenUrl } from '@src/model/config/helpers';

export interface TokenMenuPopoverProps {
    className?: string;
    tokenName: string;
}

interface MenuItemConfig {
    isVisible: boolean;
    icon: IconType;
    label: string;
    onClick: () => void;
    isLoading?: boolean;
}

const TokenMenuPopover: FC<TokenMenuPopoverProps> = ({ tokenName, className }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const token = useStoreState(state => state.token.getToken(tokenName));
    const deleteToken = useStoreActions(actions => actions.token.deleteToken);

    const userId = getUserId() ?? '';
    const currentUser = getMyUser();
    const isModerator = isUserModerator(currentUser?.id || '', tokenName);

    const mapError = (error: any): void => {
        if (error.message.includes('Bad nonce')) {
            alert(t('badNonceErrorMessage'));
            return;
        }
        alert(`${t('generalErrorMessage')} ${error.message}`);
    };

    const handleDelete = async (isDelete: boolean): Promise<void> => {
        if (!token) return;

        setIsDeleting(true);

        try {
            await deleteToken({
                isDelete,
                userId,
                tokenName
            });
        } catch (error) {
            mapError(error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleNavigation = (path: string) => {
        history.push(path);
        setIsMenuVisible(false);
    };

    const getAdminSettingsLink = (menuTab: string, tab?: string): string => {
        const backLinkLabel = t('backToTokenPage', { tokenName: token?.title });
        const backLinkUrl = encodeURIComponent(getCurrentPathAndSearch());
        let fullLink = `/${ensureTokenUrl(tokenName)}/admin-settings/${menuTab}`;

        if (tab) fullLink += `?tab=${tab}&`;
        else fullLink += '?';
        fullLink += `backLinkUrl=${backLinkUrl}&backLinkLabel=${backLinkLabel}`;
        return fullLink;
    };

    const menuItems: MenuItemConfig[] = [
        {
            // About Menu Item
            isVisible: true,
            icon: 'info',
            label: t('tokenMenuPopover.aboutMenuItem'),
            onClick: () => handleNavigation(`/${ensureTokenUrl(tokenName)}/tab/about`)
        },
        {
            // Edit Token
            isVisible: canEditToken(tokenName),
            icon: 'template',
            label: t('adminSettings.editBoard'),
            onClick: () => handleNavigation(getAdminSettingsLink('editBBS'))
        },
        {
            // Manage Flairs
            isVisible: canManageFlairs(tokenName),
            icon: 'tag',
            label: t('manageFlairs'),
            onClick: () => handleNavigation(getAdminSettingsLink('editFlairs'))
        },
        {
            // View Pending or Deleted Content
            isVisible: canViewPendingContent(tokenName) || canViewDeletedPosts(tokenName),
            icon: 'edit',
            label: t('adminSettings.viewPendingContent'),
            onClick: () => {
                let pendingTab = '';
                if (canViewPendingContent(tokenName)) pendingTab = 'pendingPosts';
                else if (canViewDeletedPosts(tokenName)) pendingTab = 'deletedPosts';
                handleNavigation(getAdminSettingsLink('pending', pendingTab));
            }
        },
        {
            // Reports
            isVisible: isModerator,
            icon: 'danger',
            label: t('tokenMenuPopover.reportsMenuItem'),
            onClick: () => handleNavigation(`/${ensureTokenUrl(tokenName)}/tab/reports`)
        },
        {
            // Moderators Table
            isVisible: isModerator,
            icon: 'table',
            label: t('adminSettings.moderatorsTables'),
            onClick: () => handleNavigation(getAdminSettingsLink('moderators', 'modAct'))
        },
        {
            // Delete or Undelete Token
            isVisible: canDeleteToken(tokenName) && !!token,
            icon: isTokenDeleted(tokenName) ? 'show' : 'hide',
            label: isTokenDeleted(tokenName) ? t('undeleteTokenLabel') : t('deleteTokenLabel'),
            onClick: () => handleDelete(isTokenDeleted(tokenName) ? false : true),
            isLoading: isDeleting
        }
    ];

    const renderMenuItem = (item: MenuItemConfig, index: number) => {
        if (!item.isVisible) return null;

        return (
            <MenuItem
                className="space-x-2"
                key={index}
                onClick={item.onClick}
                isLoading={item.isLoading}
                showSeparator={index !== menuItems.length - 1} // Adjust as needed
            >
                <Icon className="text-lg" name={item.icon} svgFill={false} />
                <Text>{item.label}</Text>
            </MenuItem>
        );
    };

    const renderTrigger = () =>
        <Button
            size="lg"
            type="round"
            color="base"
            variant="ghost"
            onClick={() => setIsMenuVisible(prev => !prev)}>
            <Icon name="dotsVertical" />
        </Button>;

    return (
        <MenuPopover
            renderTrigger={renderTrigger}
            onClickOutside={() => setIsMenuVisible(false)}
            className={className}
            popoverProps={{ isVisible: isMenuVisible }}
        >
            {menuItems.map(renderMenuItem)}
        </MenuPopover>
    );
};

export default TokenMenuPopover;
