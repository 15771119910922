import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import React, { FC, Fragment } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import ProfileImage, { ProfileImageProps } from './ProfileImage';
import { ensureTokenUrl } from '@src/model/config/helpers';

export interface ProfileImageWithLinkProps {
    className?: string;
    profileImageProps: ProfileImageProps;
    linkProps?: Partial<LinkProps>;
}

const ProfileImageWithLink: FC<ProfileImageWithLinkProps> = props => {
    const { profileImageProps } = props;

    function renderContent() {
        const { linkProps = {} } = props;
        const { displayName, tokenName = '' } = profileImageProps;

        if (displayName === 'anonymous') return renderProfileImage();

        const _linkProps = mergeProps({
            className: 'flex-shrink-0',
            to: `/${ensureTokenUrl(tokenName)}/author/${displayName}`
        }, linkProps);

        return (
            <Link {..._linkProps}>
                {renderProfileImage()}
            </Link>
        );
    }

    function renderProfileImage() {
        return (
            <ProfileImage {...profileImageProps}></ProfileImage>
        );
    }

    return <Fragment>{renderContent()}</Fragment>;
};

export default ProfileImageWithLink;
