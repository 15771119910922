
import { ApiResponse } from '../../creator-sdk';
import { post } from '../../network/api-service';
import DbModule from '../db';
import { DocumentDataWithId, Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import { getDocs, query, QueryDocumentSnapshot } from 'firebase/firestore/lite';
import AccountModule from '../account';
import { FollowDbTableRow, FollowersResponse } from './follow.model';

export interface FollowUserApiRequest {
    userId: string;
    followUserId: string;
    tokenName: string;
    follow: boolean;
}

export interface GetUserFollowersPayload {
    userId: string
}

export default class FollowService {
        private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private accountModule: AccountModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.accountModule = accountModule;
    }

    async getUserFollowers(paylod: GetUserFollowersPayload): Promise<FollowersResponse> {
        const { userId } = paylod;

        const colRef = this.dbModule.getSubCollectionReference<FollowDbTableRow>('User', userId, 'Follow');
        const q = query(colRef);
        const querySnapshot = await getDocs(q);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);
        const followers = items.length > 1 || items[0] && items[0].id === 'followers' ? items[0] : { userIds: [], userNames: [] };
        let followings = items.length === 1 && items[0] && items[0].id === 'followings' ? items[0] : { userIds: [], userNames: [] };
        if (items.length > 1)
            followings = items[1];


        return { followers: followers, followings: followings };
    }

    async followUser(payload: FollowUserApiRequest): Promise<ApiResponse<string>> {
        const token = await this.accountModule.getIdToken();
        const res = await post<ApiResponse<any>>('/user/follow', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return res.data;
    }
}