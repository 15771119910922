import React, { FC, Suspense, lazy, memo, useMemo } from 'react';
import { cn } from '@creator/ui/utils/ui';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

const iconImportMap = {
    warning: () => import('@src/assets/icons/warning.svg'),
    template: () => import('@src/assets/icons/template.svg'),
    about: () => import('@src/assets/icons/about.svg'),
    info: () => import('@src/assets/icons/info.svg'),
    logoutNew: () => import('@src/assets/icons/logout-new.svg'),
    circleAccept: () => import('@src/assets/icons/circle-accept.svg'),
    circleCancel: () => import('@src/assets/icons/circle-cancel.svg'),
    close: () => import('@src/assets/icons/close.svg'),
    helpTip: () => import('@src/assets/icons/help-tip.svg'),
    edit: () => import('@src/assets/icons/edit.svg'),
    arrowLeft: () => import('@src/assets/icons/arrow-left.svg'),
    arrowLeft2: () => import('@src/assets/icons/arrow-left-2.svg'),
    arrowRight: () => import('@src/assets/icons/arrow-right.svg'),
    arrowRight2: () => import('@src/assets/icons/arrow-right-2.svg'),
    arrowUp2: () => import('@src/assets/icons/arrow-up-2.svg'),
    arrowDown2: () => import('@src/assets/icons/arrow-down-2.svg'),
    arrowUpWithBase: () => import('@src/assets/icons/arrow-up-with-base.svg'),
    arrowDownWithBase: () => import('@src/assets/icons/arrow-down-with-base.svg'),
    dotsVertical: () => import('@src/assets/icons/dots-vertical.svg'),
    plus: () => import('@src/assets/icons/plus.svg'),
    minus: () => import('@src/assets/icons/minus.svg'),
    trash: () => import('@src/assets/icons/trash.svg'),
    checkmark: () => import('@src/assets/icons/checkmark.svg'),
    checkmarkRound: () => import('@src/assets/icons/checkmark-round.svg'),
    sort: () => import('@src/assets/icons/sort.svg'),
    tip: () => import('@src/assets/icons/tip.svg'),
    hide: () => import('@src/assets/icons/hide.svg'),
    show: () => import('@src/assets/icons/show.svg'),
    caretUp: () => import('@src/assets/icons/caret-up.svg'),
    caretDown: () => import('@src/assets/icons/caret-down.svg'),
    caretLeft: () => import('@src/assets/icons/caret-left.svg'),
    caretRight: () => import('@src/assets/icons/caret-right.svg'),
    post: () => import('@src/assets/icons/post.svg'),
    search: () => import('@src/assets/icons/search.svg'),
    searchLight: () => import('@src/assets/icons/search-light.svg'),
    bellNotification: () => import('@src/assets/icons/bell-notification.svg'),
    pin: () => import('@src/assets/icons/pin.svg'),
    unpin: () => import('@src/assets/icons/unpin.svg'),
    chat: () => import('@src/assets/icons/chat.svg'),
    thumbUp: () => import('@src/assets/icons/thumb-up.svg'),
    thumbDown: () => import('@src/assets/icons/thumb-down.svg'),
    send: () => import('@src/assets/icons/send.svg'),
    sun: () => import('@src/assets/icons/sun.svg'),
    moon: () => import('@src/assets/icons/moon.svg'),
    user: () => import('@src/assets/icons/user.svg'),
    goTo: () => import('@src/assets/icons/go-to.svg'),
    paperClip: () => import('@src/assets/icons/paper-clip.svg'),
    copy: () => import('@src/assets/icons/copy.svg'),
    txLog: () => import('@src/assets/icons/tx-log.svg'),
    settings: () => import('@src/assets/icons/settings.svg'),
    envelopeAlt: () => import('@src/assets/icons/envelope-alt.svg'),
    sendTransparent: () => import('@src/assets/icons/send-transparent.svg'),
    bbs: () => import('@src/assets/icons/bbs.svg'),
    circle: () => import('@src/assets/icons/circle.svg'),
    sendArrow: () => import('@src/assets/icons/send-arrow.svg'),
    cup: () => import('@src/assets/icons/cup.svg'),
    danger: () => import('@src/assets/icons/danger.svg'),
    video: () => import('@src/assets/icons/video.svg'),
    play: () => import('@src/assets/icons/play.svg'),
    pause: () => import('@src/assets/icons/pause.svg'),
    cancel: () => import('@src/assets/icons/cancel.svg'),
    share: () => import('@src/assets/icons/share.svg'),
    menu: () => import('@src/assets/icons/menu.svg'),
    error: () => import('@src/assets/icons/error.svg'),
    bin: () => import('@src/assets/icons/bin.svg'),
    tag: () => import('@src/assets/icons/tag.svg'),
    buy: () => import('@src/assets/icons/buy.svg'),
    shield: () => import('@src/assets/icons/shield.svg'),
    poll: () => import('@src/assets/icons/poll.svg'),
    response: () => import('@src/assets/icons/response.svg'),
    response2: () => import('@src/assets/icons/respone-2.svg'),
    voice: () => import('@src/assets/icons/voice.svg'),
    flip: () => import('@src/assets/icons/flip.svg'),
    table: () => import('@src/assets/icons/table.svg'),
    community: () => import('@src/assets/icons/community.svg'),
    pictureInPicture: () => import('@src/assets/icons/picture-in-picture.svg'),
    quote: () => import('@src/assets/icons/quote.svg'),
    wallet2: () => import('@src/assets/icons/wallet2.svg'),
    lockPassword: () => import('@src/assets/icons/lock-password.svg'),
    list: () => import('@src/assets/icons/list.svg'),
    image: () => import('@src/assets/icons/image.svg'),
    volume: () => import('@src/assets/icons/volume.svg'),
    follow: () => import('@src/assets/icons/follow.svg'),
    githubFlat: () => import('@src/assets/icons/github-flat.svg'),
    googleFlat: () => import('@src/assets/icons/google-flat.svg'),
    twitterFlat: () => import('@src/assets/icons/twitter-flat.svg')
};

export type IconType = keyof typeof iconImportMap;

export function getIconAsComponent(name: IconType): React.FC | null {
    if (!iconImportMap[name]) {
        console.warn(`Icon ${name} not found`);
        return null;
    };
    const IconComponent = lazy(iconImportMap[name]);
    return props =>
        <Suspense fallback={<div className="w-[1em] h-[1em]"></div>}>
            <IconComponent {...props} />
        </Suspense>;
}

export interface IconProps {
    className?: string;
    name: IconType;
    svgFill?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Icon: FC<IconProps> = props => {
    const { className, onClick, name, svgFill = false } = props;
    const Svg = useMemo(() => getIconAsComponent(name), [name]);

    const _props = {
        onClick,
        'aria-label': `icon-${name}`,
        className: cn(`w-[1em] h-[1em] shrink-0 ${svgFill ? 'fill' : ''}`, className)
    };

    return <Svg {..._props} />;
};

export default memo(Icon, propsEqualityFn);
