import { Computed, Action, Thunk, computed, thunk, action } from 'easy-peasy';
import { Post, PostComment } from '@creator/sdk/modules/upvote/upvote.model';
import { CreatePostPayload, CreateAnonymousPostPayload, DeletePostPayload, EditPostPayload, CreatePostCommentPayload, DeletePostCommentPayload, EditPostCommentPayload, PinPostPayload, ReactionPayload, TipCommentPayload, TipPostPayload, ApprovePendingCommentPayload, ApprovePendingPostPayload, DeleteUserContentPayload, LinkMeta, SyndicatePostPayload } from '@creator/sdk/modules/upvote';
import { getPost, getPostComment, getPostCommentReply, getPostsByPublisherId, isResponsePost } from './helpers';
import { GetPostsFilterBy, GetPostsOrderBy, GetPostsCommentsOrderBy, GetPostsCommentFilterBy } from '@creator/sdk/modules/upvote/upvote.service';
import { StoreModel } from '..';
import { DocumentSnapshot, Pagination } from '@creator/sdk/modules/db/db.model';
import { TagUser } from '@creator/sdk/modules/account/account.model';
import { getUserId } from '../user/helpers';
import creatorSdk from '@src/services/creator-sdk';
import { getSuperComment } from '../super-comment/helpers';
import { SuperComment } from '@creator/sdk/modules/super-comment/super-comment.model';
import { PostStatus } from './permission';
export interface LoadPostsPayload {
    lowerBound: DocumentSnapshot;
    limit: number;
    tokenName: string;
    filterBy: GetPostsFilterBy[];
    orderBy: GetPostsOrderBy[];
    saveTo?: 'user' | 'pinnedPosts' | 'pendingPosts' | 'deletedPosts' | 'recommendedPosts' | 'partnerPosts';
    includeResponses?: boolean;
    includeSuperComment?: boolean;
}

export interface LoadPostsCommentsPayload {
    lowerBound: DocumentSnapshot;
    limit: number;
    filterBy: GetPostsCommentFilterBy[];
    orderBy?: GetPostsCommentsOrderBy;
    saveTo?: 'user';
}
export interface LoadPostsCommentsRepliesPayload extends LoadPostsCommentsPayload {
    commentId: string;
}

export interface UpvoteModel {
    posts: { [postId: string]: Post };
    postComments: { [commentId: string]: PostComment };
    repliesToPostComments: { [commentId: string]: string[] };
    postSearchResult: Record<string, string[]>; // { tokenName: postId[]}
    pendingPostSearchResult: Record<string, string[]>;
    recommendedPostSearchResult: Record<string, string[]>;
    partnerPostSearchResult: Record<string, string[]>;
    deletedPostSearchResult: Record<string, string[]>;
    pinnedPostSearchResult: Record<string, string[]>; // { tokenName: postId[]}

    postSearchResultLastDoc: Record<string, Record<string, unknown>>;
    pendingPostSearchResultLastDoc: Record<string, Record<string, unknown>>;
    partnerPostSearchResultLastDoc: Record<string, Record<string, unknown>>;
    deletedPostSearchResultLastDoc: Record<string, Record<string, unknown>>;
    userPostSearchResult: string[];
    userPostSearchResultLastDoc: Record<string, unknown>;
    userCommentSearchResult: string[];
    userCommentSearchResultLastDoc: Record<string, unknown>;

    getPost: Computed<UpvoteModel, (postId: string) => Post | undefined>;
    getPostBySlugUrlOrId: Computed<UpvoteModel, (postSlugOrId: string) => Post | undefined>;
    getPostSearchResult: Computed<UpvoteModel, (tokenName: string) => (Post | undefined)[]>;
    getPendingPostSearchResult: Computed<UpvoteModel, (tokenName: string) => (Post | undefined)[]>;
    getRecommendedPostSearchResult: Computed<UpvoteModel, (tokenName: string) => (Post | undefined)[]>;
    getPartnerPostSearchResult: Computed<UpvoteModel, (tokenName: string) => (Post | undefined)[]>;
    getDeletedPostSearchResult: Computed<UpvoteModel, (tokenName: string) => (Post | undefined)[]>;
    getPinnedPostSearchResult: Computed<UpvoteModel, (tokenName: string) => (Post | undefined)[]>;
    getUserPostSearchResult: Computed<UpvoteModel, (Post | undefined)[]>;
    getUserCommentSearchResult: Computed<UpvoteModel, (PostComment | undefined)[]>;
    getPostCommentsResult: Computed<UpvoteModel, (PostComment | undefined)[]>;
    getPostCommentRepliesResult: Computed<UpvoteModel, (commentId: string) => (PostComment | undefined)[]>;
    getPostCommentReplies: Computed<UpvoteModel, (commentId: string) => (PostComment | undefined)[]>;

    setPost: Action<UpvoteModel, Partial<Post>>;
    setPosts: Action<UpvoteModel, Record<string, Post>>;
    setPostComment: Action<UpvoteModel, Partial<PostComment>>;
    setPostComments: Action<UpvoteModel, Record<string, PostComment>>;
    setPostStatus: Action<UpvoteModel, { id: string; status: number }>;
    setPostSearchResult: Action<UpvoteModel, { searchResult: string[]; tokenName: string }>;
    setPostSearchResultLastDoc: Action<UpvoteModel, { lastDoc: DocumentSnapshot; tokenName: string }>;
    setUserPostSearchResult: Action<UpvoteModel, string[]>;
    setUserPostSearchResultLastDoc: Action<UpvoteModel, DocumentSnapshot>;
    setUserCommentSearchResult: Action<UpvoteModel, string[]>;
    setUserCommentSearchResultLastDoc: Action<UpvoteModel, DocumentSnapshot>;
    setPendingPostSearchResult: Action<UpvoteModel, { searchResult: string[]; tokenName: string }>;
    setPendingPostSearchResultLastDoc: Action<UpvoteModel, { lastDoc: DocumentSnapshot; tokenName: string }>;
    setRecommendedPostSearchResult: Action<UpvoteModel, { searchResult: string[]; tokenName: string }>;
    setPartnerPostSearchResult: Action<UpvoteModel, { searchResult: string[]; tokenName: string }>;
    setPartnerPostSearchResultLastDoc: Action<UpvoteModel, { lastDoc: DocumentSnapshot; tokenName: string }>;
    setDeletedPostSearchResult: Action<UpvoteModel, { searchResult: string[]; tokenName: string }>;
    setDeletedPostSearchResultLastDoc: Action<UpvoteModel, { lastDoc: DocumentSnapshot; tokenName: string }>;
    clearPostSearchResult: Action<UpvoteModel, { tokenName: string }>;

    setPostCommentStatus: Action<UpvoteModel, { id: string; status: number }>;
    setPostCommentReplyStatus: Action<UpvoteModel, { id: string; replyToCommentId: string; status: number }>;
    updatePostCommentNumComments: Action<UpvoteModel, { id: string }>;
    updatePostCommentContent: Action<UpvoteModel, { id: string; content: string; taggedUsers?: TagUser[], linkMeta?: LinkMeta }>;
    updatePostCommentReplyContent: Action<UpvoteModel, { parentCommentId: string; id: string; content: string; taggedUsers?: TagUser[], linkMeta?: LinkMeta }>;
    updatePostCommentReply: Action<UpvoteModel, { id: string; comment: PostComment }>;
    concatPostSearchResult: Action<UpvoteModel, { searchResult: string[]; tokenName: string }>;
    concatPendingPostSearchResult: Action<UpvoteModel, { searchResult: string[]; tokenName: string; }>;
    concatRecommendedPostSearchResult: Action<UpvoteModel, { searchResult: string[]; tokenName: string; }>;
    concatPartnerPostSearchResult: Action<UpvoteModel, { searchResult: string[]; tokenName: string; }>;
    concatDeletedPostSearchResult: Action<UpvoteModel, { searchResult: string[]; tokenName: string; }>;
    setPinnedPostSearchResult: Action<UpvoteModel, { searchResult: string[], tokenName: string; }>;
    concatPostSearchResultToBeginning: Action<UpvoteModel, { searchResult: string[]; tokenName: string }>;
    concatUserPostSearchResult: Action<UpvoteModel, string[]>;
    concatUserCommentSearchResult: Action<UpvoteModel, string[]>;
    createPost: Thunk<UpvoteModel, CreatePostPayload, null, StoreModel, Promise<Post>>;
    createAnonymousPost: Thunk<UpvoteModel, CreateAnonymousPostPayload>;
    editPost: Thunk<UpvoteModel, EditPostPayload, null, StoreModel, Promise<Post>>;
    deletePost: Thunk<UpvoteModel, DeletePostPayload, null, StoreModel, Promise<Post>>;
    pinPost: Thunk<UpvoteModel, PinPostPayload>;
    syndicatePost: Thunk<UpvoteModel, SyndicatePostPayload>;
    tipComment: Thunk<UpvoteModel, TipCommentPayload, null, StoreModel, Promise<PostComment>>;
    tipPost: Thunk<UpvoteModel, TipPostPayload, null, StoreModel, Promise<Post>>;
    approvePendingPost: Thunk<UpvoteModel, ApprovePendingPostPayload, null, StoreModel, Promise<Post | null>>;
    approvePendingComment: Thunk<UpvoteModel, ApprovePendingCommentPayload, null, StoreModel, Promise<PostComment | null>>;
    reaction: Thunk<UpvoteModel, ReactionPayload & { replyId?: string }, null, StoreModel, Promise<void>>;

    deletePostComment: Thunk<UpvoteModel, DeletePostCommentPayload, null, StoreModel, Promise<PostComment>>;
    deleteUserContent: Thunk<UpvoteModel, DeleteUserContentPayload, null, StoreModel, Promise<string>>;
    editPostComment: Thunk<UpvoteModel, EditPostCommentPayload>;
    editPostCommentReply: Thunk<UpvoteModel, { parentCommentId: string } & EditPostCommentPayload>;
    deletePostCommentReply: Thunk<UpvoteModel, DeletePostCommentPayload & { replyToCommentId: string; }>;
    createPostComment: Thunk<UpvoteModel, CreatePostCommentPayload, null, StoreModel, Promise<PostComment>>;
    setCreatedPostComment: Thunk<UpvoteModel, PostComment, null, StoreModel, Promise<PostComment>>;
    loadPost: Thunk<UpvoteModel, string, null, StoreModel, Promise<Post>>;
    loadPostByIdOrSlugUrl: Thunk<UpvoteModel, string, null, StoreModel, Promise<Post>>;
    loadPostAndMyVote: Thunk<UpvoteModel, string, null, StoreModel, Promise<Post>>;

    loadPostComment: Thunk<UpvoteModel, { commentId: string; isReply?: boolean }, null, StoreModel, Promise<PostComment>>;
    loadPosts: Thunk<UpvoteModel, LoadPostsPayload, null, StoreModel, Promise<Pagination<Post>>>;
    loadPostsByIds: Thunk<UpvoteModel, { ids: string[] }, null, StoreModel, Promise<Post[]>>;
    getPostComment: Computed<UpvoteModel, (commentId: string) => PostComment | undefined>;
    getPostCommentReply: Computed<UpvoteModel, (commentId: string, replyId: string) => PostComment | undefined>;
    setPostCommentReplies: Action<UpvoteModel, { replyIds: string[]; commentId: string }>;
    // setPostCommentReplies: Action<UpvoteModel, { replyId: string; commentId: string; reply: PostComment }>;
    loadPostComments: Thunk<UpvoteModel, LoadPostsCommentsPayload, null, StoreModel, Promise<Pagination<PostComment>>>;
    loadPostCommentReplies: Thunk<UpvoteModel, LoadPostsCommentsRepliesPayload, null, StoreModel, Promise<Pagination<PostComment>>>;
    postCommentsSearchResult: string[];
    postCommentRepliesSearchResult: { [commentId: string]: string[] }
    addPostCommentSearchResult: Action<UpvoteModel, string[]>;
    concatPostCommentsSearchResult: Action<UpvoteModel, { isConcat: boolean; searchResult: string[] }>;
    concatPostCommentRepliesSearchResult: Action<UpvoteModel, { commentId: string; isConcat: boolean; searchResult: string[] }>;
    clearPostCommentSearchResult: Action<UpvoteModel>;
    clearPostCommentRepliesSearchResult: Action<UpvoteModel, string>;
    addPostCommentRepliesSearchResult: Action<UpvoteModel, { commentId: string; isConcat: boolean; searchResult: string[] }>;
}
const upvoteModel: UpvoteModel = {
    posts: {},
    postSearchResult: {},
    pinnedPostSearchResult: {},
    postSearchResultLastDoc: {},
    pendingPostSearchResult: {},
    recommendedPostSearchResult: {},
    partnerPostSearchResult: {},
    partnerPostSearchResultLastDoc: {},
    pendingPostSearchResultLastDoc: {},
    deletedPostSearchResult: {},
    deletedPostSearchResultLastDoc: {},
    userPostSearchResult: [],
    userCommentSearchResult: [],
    userPostSearchResultLastDoc: {},
    userCommentSearchResultLastDoc: {},
    getPost: computed(state => postId => state.posts[postId]),
    getPostBySlugUrlOrId: computed(state => postSlugOrId => Object.values(state.posts).find(post => post.id === postSlugOrId || post.slugUrl === postSlugOrId)),
    postComments: {},
    repliesToPostComments: {},
    getPostSearchResult: computed(state => tokenName => (state.postSearchResult[tokenName] || []).map(postId => state.getPost(postId))),
    getPendingPostSearchResult: computed(state => tokenName => (state.pendingPostSearchResult[tokenName] || []).map(postId => state.getPost(postId))),
    getRecommendedPostSearchResult: computed(state => tokenName => (state.recommendedPostSearchResult[tokenName] || []).map(postId => state.getPost(postId))),
    getPartnerPostSearchResult: computed(state => tokenName => (state.partnerPostSearchResult[tokenName] || []).map(postId => state.getPost(postId))),
    getDeletedPostSearchResult: computed(state => tokenName => (state.deletedPostSearchResult[tokenName] || []).map(postId => state.getPost(postId))),
    getPinnedPostSearchResult: computed(state => tokenName => (state.pinnedPostSearchResult[tokenName] || []).map(postId => state.getPost(postId))),
    getUserPostSearchResult: computed(state => state.userPostSearchResult.map(postId => state.getPost(postId))),
    getUserCommentSearchResult: computed(state => state.userCommentSearchResult.map(id => state.getPostComment(id))),
    getPostCommentsResult: computed(state => state.postCommentsSearchResult.map(commentId => state.getPostComment(commentId))),
    getPostCommentRepliesResult: computed(state => commentId => state.getPostCommentReplies(commentId)),
    getPostCommentReplies: computed(state => commentId => {
        if (!state.postCommentRepliesSearchResult[commentId]) return [];
        return state.postCommentRepliesSearchResult[commentId].map((replyId: string) => {
            return state.repliesToPostComments[commentId] ? state.postComments[replyId] : null;
        });
    }),
    getPostComment: computed(state => commentId => state.postComments[commentId]),
    getPostCommentReply: computed(state => (commentId, replyId) => state.postComments[replyId]),
    postCommentsSearchResult: [],
    postCommentRepliesSearchResult: {},

    createPost: thunk(async (actions, payload) => {
        const post = await creatorSdk.upvoteModule.createPost(payload);
        actions.setPost(post);
        return post;
    }),

    createAnonymousPost: thunk(async (actions, payload) => {
        const post = await creatorSdk.upvoteModule.createAnonymousPost(payload);
        actions.setPost(post);
        return post;
    }),

    createPostComment: thunk(async (actions, payload) => {
        const { content, postId, replyToCommentId, tokenName } = payload;

        let comment;
        if (!payload.publisher)
            comment = await creatorSdk.upvoteModule.createAnonymousPostComment({ content, postId, replyToCommentId, tokenName });
        else
            comment = await creatorSdk.upvoteModule.createPostComment(payload);

        return actions.setCreatedPostComment(comment);
    }),

    editPostComment: thunk(async (actions, payload) => {
        const comment = await creatorSdk.upvoteModule.editPostComment(payload);
        actions.updatePostCommentContent({ id: payload.commentId, content: payload.content, taggedUsers: comment.taggedUsers, linkMeta: comment.linkMeta });
        return comment;
    }),

    editPostCommentReply: thunk(async (actions, payload) => {
        const comment = await creatorSdk.upvoteModule.editPostComment(payload);
        actions.updatePostCommentReplyContent({ parentCommentId: payload.parentCommentId, id: payload.commentId, content: payload.content, taggedUsers: comment.taggedUsers, linkMeta: comment.linkMeta });
        return comment;
    }),

    editPost: thunk(async (actions, payload) => {
        const post = await creatorSdk.upvoteModule.editPost(payload);
        actions.setPost(post);
        return post;
    }),

    deletePost: thunk(async (actions, payload, helpers) => {
        const { postId } = payload;
        const post = await creatorSdk.upvoteModule.deletePost(payload);
        actions.setPost(post);
        if (post.postTypes?.includes('response'))
            helpers.getStoreActions().response.setResponseStatus({ responseId: postId, newStatus: post.status });

        return post;
    }),

    pinPost: thunk(async (actions, payload, helpers) => {
        const { isPinned, postId, tokenName } = payload;

        const res = await creatorSdk.upvoteModule.pinPost(payload);

        const oldPost = helpers.getState().getPost(postId);
        if (!oldPost) throw new Error('pinPost: invalid post');

        const newPost: Post = { ...oldPost, isPinned };

        const pinnedPostSearchResult = helpers.getState().pinnedPostSearchResult[tokenName] || [];
        const oldPinnedPostIds = pinnedPostSearchResult || [];
        let newPinnedPostIds: string[] = [];

        if (isPinned)
            newPinnedPostIds = [postId].concat(oldPinnedPostIds);
        else
            newPinnedPostIds = oldPinnedPostIds.filter(id => id !== postId);

        actions.setPost(newPost);
        actions.setPinnedPostSearchResult({ searchResult: newPinnedPostIds, tokenName });
        return res;
    }),

    syndicatePost: thunk(async (actions, payload, helpers) => {
        const post = await creatorSdk.upvoteModule.syndicatePost(payload);
        actions.setPost(post);
        return post;
    }),

    tipComment: thunk(async (actions, payload) => {
        const { commentId } = payload;
        const comment = await creatorSdk.upvoteModule.tipComment(payload);

        actions.setPostComment({ ...comment, id: commentId, commentId });

        return comment;
    }),

    tipPost: thunk(async (actions, payload) => {
        const { postId } = payload;
        const post = await creatorSdk.upvoteModule.tipPost(payload);

        actions.setPost({ ...post, id: postId });

        return post;
    }),

    approvePendingPost: thunk(async (actions, payload, helpers) => {
        const { postId, approve } = payload;

        const post = await creatorSdk.upvoteModule.approvePendingPost(payload);
        const oldPost = helpers.getState().getPost(postId);
        actions.setPost({ ...oldPost, status: post?.status ?? 1 }); // status 1 in case of deleted
        return post;
    }),

    approvePendingComment: thunk(async (actions, payload, helpers) => {
        const { commentId } = payload;

        const comment = await creatorSdk.upvoteModule.approvePendingComment(payload);
        const oldComment = helpers.getState().getPostComment(commentId);
        actions.setPostComment({ ...oldComment, status: comment?.status ?? 1 }); // status 1 in case of deleted
        return comment;
    }),


    reaction: thunk(async (actions, payload, helpers) => {
        const { postId, commentId, replyId, reactionName, userId, isSuperComment } = payload;

        let entity: Post | PostComment | SuperComment | undefined | null;
        if (postId)
            entity = getPost(postId);
        if (commentId)
            entity = replyId ? getPostCommentReply(commentId, replyId) : getPostComment(commentId);
        if (isSuperComment && commentId)
            entity = getSuperComment(commentId);

        if (!entity) return;

        const newEntity = { ...entity };
        newEntity.reactionsUsers = newEntity.reactionsUsers || {};
        newEntity.reactionsCount = newEntity.reactionsCount || {};
        // remove user from all reactions first
        Object.keys(newEntity.reactionsUsers).forEach(_reactionName => {
            if (newEntity && newEntity.reactionsUsers[_reactionName].includes(userId)) {
                newEntity.reactionsCount[_reactionName] -= 1;
                newEntity.reactionsUsers[_reactionName] = newEntity.reactionsUsers[_reactionName].filter(_userId => _userId !== userId);
            }
        });

        if (reactionName) { // if reacted
            if (!newEntity.reactionsCount[reactionName])
                newEntity.reactionsCount[reactionName] = 0;

            if (!newEntity.reactionsUsers[reactionName])
                newEntity.reactionsUsers[reactionName] = [];

            newEntity.reactionsCount[reactionName] += 1;
            newEntity.reactionsUsers[reactionName].push(userId);
        }

        if (postId)
            actions.setPost(newEntity);
        else if (isSuperComment && commentId)
            helpers.getStoreActions().superComment.setSuperComment(newEntity);
        else if (commentId)
            actions.setPostComment(newEntity);

        creatorSdk.upvoteModule.reaction({
            reactionName,
            userId,
            commentId: replyId || commentId,
            postId,
            isSuperComment
        });
    }),

    deletePostComment: thunk(async (actions, payload) => {
        const { commentId } = payload;
        const comment = await creatorSdk.upvoteModule.deletePostComment(payload);
        actions.setPostComments({ [commentId]: { ...comment, id: commentId } });
        return comment;
    }),


    deleteUserContent: thunk(async (actions, payload) => {
        const { tokenName, bannedUserId } = payload;
        // hide optimistically all posts and comments of user
        const userPosts = getPostsByPublisherId(bannedUserId, tokenName);

        const deletedUserPosts = userPosts.reduce((acc, post) => {
            acc[post.id] = { ...post, status: PostStatus.DELETED_BY_TOKEN_ADMIN };
            return acc;
        }, {});

        actions.setPosts(deletedUserPosts);

        await creatorSdk.upvoteModule.deleteUserContent(payload);
        return '';
    }),

    deletePostCommentReply: thunk(async (actions, payload) => {
        const comment = await creatorSdk.upvoteModule.deletePostComment(payload);
        actions.setPostComments({ [comment.commentId]: { ...comment, id: comment.commentId } });
        return comment;
    }),

    loadPost: thunk(async (actions, postId) => {
        const post = await creatorSdk.upvoteModule.getPost(postId);
        actions.setPost(post);
        return post;
    }),

    loadPostByIdOrSlugUrl: thunk(async (actions, postSlugOrId) => {
        // try to get post by slugUrl
        let post = await creatorSdk.upvoteModule.getPostBySlugUrl(postSlugOrId);
        // if not found, try to get post by id
        if (!post)
            post = await creatorSdk.upvoteModule.getPost(postSlugOrId);

        // if still not found, throw error
        if (!post) throw new Error(`post with id or slugUrl ${postSlugOrId} not found`);

        actions.setPost(post);
        return post;
    }),

    loadPostAndMyVote: thunk(async (actions, postId, helpers) => {
        const post = await creatorSdk.upvoteModule.getPost(postId);
        actions.setPost(post);

        const userId = getUserId();
        if (post.id && userId)
            await helpers.getStoreActions().voting.loadVote({ postId: postId });

        return post;
    }),

    loadPostComment: thunk(async (actions, { commentId, isReply }) => {
        const comment = await creatorSdk.upvoteModule.getPostComment(commentId);
        actions.setPostComments({ [comment.id]: comment });
        if (isReply) {
            actions.setPostCommentReplies({ replyIds: [commentId], commentId: comment.replyToCommentId });
            actions.concatPostCommentRepliesSearchResult({ isConcat: false, searchResult: [commentId], commentId: comment.replyToCommentId });
        }
        else {
            actions.concatPostCommentsSearchResult({ isConcat: false, searchResult: [commentId] });
        }

        return comment;
    }),

    loadPosts: thunk(async (actions, payload, helpers) => {
        const { tokenName, lowerBound, limit, filterBy, orderBy, saveTo, includeResponses, includeSuperComment } = payload;

        const { hasMore, items, lastDoc } = await creatorSdk.upvoteModule.getPosts(tokenName, lowerBound, limit, filterBy, orderBy);

        const ids: string[] = [];
        const posts = {};
        items.forEach(post => {
            posts[post.id] = post;
            ids.push(post.id);
        });

        actions.setPosts(posts);


        if (saveTo === 'user') {
            actions.concatUserPostSearchResult(ids);
            if (lastDoc)
                actions.setUserPostSearchResultLastDoc({ tokenName, lastDoc });
        }

        if (saveTo === 'pinnedPosts')
            actions.setPinnedPostSearchResult({ tokenName, searchResult: ids });

        if (saveTo === 'pendingPosts') {
            if (lowerBound)
                actions.concatPendingPostSearchResult({ tokenName, searchResult: ids });
            else
                actions.setPendingPostSearchResult({ tokenName, searchResult: ids });

            if (lastDoc)
                actions.setPendingPostSearchResultLastDoc({ tokenName, lastDoc });
        }

        if (saveTo === 'deletedPosts') {
            if (lowerBound)
                actions.concatDeletedPostSearchResult({ tokenName, searchResult: ids });
            else
                actions.setDeletedPostSearchResult({ tokenName, searchResult: ids });

            if (lastDoc)
                actions.setDeletedPostSearchResultLastDoc({ tokenName, lastDoc });
        }

        if (saveTo === 'recommendedPosts') {
            if (lowerBound)
                actions.concatRecommendedPostSearchResult({ tokenName, searchResult: ids });
            else
                actions.setRecommendedPostSearchResult({ tokenName, searchResult: ids });
        }

        if (saveTo === 'partnerPosts') {
            if (lowerBound)
                actions.concatPartnerPostSearchResult({ tokenName, searchResult: ids });
            else
                actions.setPartnerPostSearchResult({ tokenName, searchResult: ids });

            if (lastDoc)
                actions.setPartnerPostSearchResultLastDoc({ tokenName, lastDoc });

        }

        if (!saveTo) {
            if (lowerBound)
                actions.concatPostSearchResult({ tokenName, searchResult: ids });
            else
                actions.setPostSearchResult({ tokenName, searchResult: ids });

            if (lastDoc)
                actions.setPostSearchResultLastDoc({ tokenName, lastDoc });

        }

        if (includeResponses) {
            const tasks = items.map(post => isResponsePost(post) ? helpers.getStoreActions().response.loadResponse(post.id) : null);
            await Promise.all(tasks);
        }

        if (includeSuperComment) {
            const tasks = items.map(post => post.scTopId ? helpers.getStoreActions().superComment.loadSuperComment(post.scTopId) : null);
            await Promise.all(tasks);
        }

        return { hasMore, items, lastDoc };
    }),

    loadPostsByIds: thunk(async (actions, payload, helpers) => {
        const { ids } = payload;

        const includeSuperComment = true;
        const items = await creatorSdk.upvoteModule.getPostsByIds(ids);

        if (includeSuperComment) {
            const tasks = items.map(post => post.scTopId ? helpers.getStoreActions().superComment.loadSuperComment(post.scTopId) : null);
            await Promise.all(tasks);
        }

        const posts = {};
        items.forEach(post => {
            posts[post.id] = post;
        });


        actions.setPosts(posts);

        return ids.map(id => posts[id]); // keep original order
    }),

    loadPostComments: thunk(async (actions, payload) => {
        const { filterBy, lowerBound, limit, orderBy, saveTo } = payload;
        if (!lowerBound) actions.clearPostCommentSearchResult();

        const { hasMore, items, lastDoc } = await creatorSdk.upvoteModule.getPostComments(lowerBound, limit, filterBy, orderBy);
        const ids: string[] = [];
        const comments = {};

        items.forEach(postComment => {
            ids.push(postComment.id);
            comments[postComment.id] = postComment;
            // actions.concatPostCommentRepliesSearchResult({ commentId: postComment.id, searchResult: [], isConcat: false })
        });

        if (saveTo === 'user') {
            actions.concatUserCommentSearchResult(ids);
            if (lastDoc)
                actions.setUserCommentSearchResultLastDoc(lastDoc);
        }
        else {
            actions.concatPostCommentsSearchResult({ isConcat: lowerBound !== null, searchResult: ids });
        }
        actions.setPostComments(comments);

        return { hasMore, items, lastDoc };
    }),

    loadPostCommentReplies: thunk(async (actions, payload) => {
        const { filterBy, lowerBound, limit, orderBy, commentId } = payload;
        const { hasMore, items, lastDoc } = await creatorSdk.upvoteModule.getPostComments(lowerBound, limit, filterBy, orderBy);
        let itemsList = items;
        const ids: string[] = [];
        const comments = {};
        itemsList.forEach(async postComment => {
            ids.push(postComment.id);
            comments[postComment.id] = postComment;
            // actions.setPostCommentReplies({ replyId: postComment.id, commentId: commentId, reply: postComment });
        });
        actions.setPostComments(comments);
        actions.setPostCommentReplies({ replyIds: ids, commentId: commentId });
        itemsList = await Promise.all(itemsList);
        actions.concatPostCommentRepliesSearchResult({ isConcat: lowerBound ? true : false, searchResult: ids, commentId: payload.commentId });

        return { hasMore, items, lastDoc };
    }),

    setCreatedPostComment: thunk(async (actions, comment) => {
        if (comment.status === 5) return comment; // pending

        comment.id = comment.commentId;
        const { replyToCommentId } = comment;
        if (replyToCommentId) {
            actions.addPostCommentRepliesSearchResult({ commentId: replyToCommentId, searchResult: [comment.commentId], isConcat: false });
            actions.setPostCommentReplies({ replyIds: [comment.id], commentId: replyToCommentId });
            actions.setPostComments({ [comment.id]: comment });
            actions.updatePostCommentNumComments({ id: replyToCommentId });
        }
        else {
            // actions.setPostComments(comment);
            actions.setPostComments({ [comment.id]: comment });
            actions.addPostCommentSearchResult([comment.commentId]);
        }

        return comment;

    }),

    setPost: action((state, payload) => {
        state.posts[payload.id] = payload;
    }),

    setPosts: action((state, payload) => {
        state.posts = { ...state.posts, ...payload };
    }),

    setPostStatus: action((state, payload) => {
        state.posts[payload.id].status = payload.status;
    }),

    setPostComments: action((state, payload) => {
        state.postComments = { ...state.postComments, ...payload };
    }),

    setPostComment: action((state, payload) => {
        state.postComments[payload.commentId] = payload;
    }),

    setPostCommentStatus: action((state, payload) => {
        state.postComments[payload.id].status = payload.status;
    }),

    setPostCommentReplyStatus: action((state, payload) => {
        state.postComments[payload.id].status = payload.status;
    }),

    updatePostCommentNumComments: action((state, payload) => {
        let numComments = state.postComments[payload.id].numComments;
        if (numComments) numComments += 1;
        else numComments = 1;
        state.postComments[payload.id].numComments = numComments;
    }),

    updatePostCommentContent: action((state, payload) => {
        state.postComments[payload.id].content = payload.content;
        state.postComments[payload.id].taggedUsers = payload.taggedUsers;
        state.postComments[payload.id].linkMeta = payload.linkMeta;
    }),

    updatePostCommentReplyContent: action((state, payload) => {
        state.postComments[payload.id].content = payload.content;
        state.postComments[payload.id].taggedUsers = payload.taggedUsers;
        state.postComments[payload.id].linkMeta = payload.linkMeta;
    }),

    updatePostCommentReply: action((state, payload) => {
        state.postComments[payload.id] = payload.comment;
    }),

    concatPostSearchResult: action((state, { searchResult, tokenName }) => {
        state.postSearchResult[tokenName] = (state.postSearchResult[tokenName] || []).concat(searchResult);
    }),

    concatPendingPostSearchResult: action((state, { searchResult, tokenName }) => {
        state.pendingPostSearchResult[tokenName] = (state.pendingPostSearchResult[tokenName] || []).concat(searchResult);
    }),

    concatDeletedPostSearchResult: action((state, { searchResult, tokenName }) => {
        state.deletedPostSearchResult[tokenName] = (state.deletedPostSearchResult[tokenName] || []).concat(searchResult);
    }),

    concatRecommendedPostSearchResult: action((state, { searchResult, tokenName }) => {
        state.recommendedPostSearchResult[tokenName] = (state.recommendedPostSearchResult[tokenName] || []).concat(searchResult);
    }),

    concatPartnerPostSearchResult: action((state, { searchResult, tokenName }) => {
        state.partnerPostSearchResult[tokenName] = (state.partnerPostSearchResult[tokenName] || []).concat(searchResult);
    }),

    concatPostSearchResultToBeginning: action((state, { searchResult, tokenName }) => {
        state.postSearchResult[tokenName] = searchResult.concat(state.postSearchResult[tokenName] || []);
    }),

    concatUserPostSearchResult: action((state, searchResult) => {
        state.userPostSearchResult = state.userPostSearchResult.concat(searchResult);
    }),

    concatUserCommentSearchResult: action((state, searchResult) => {
        state.userCommentSearchResult = state.userCommentSearchResult.concat(searchResult);
    }),

    setPinnedPostSearchResult: action((state, { searchResult, tokenName }) => {
        state.pinnedPostSearchResult[tokenName] = searchResult;
    }),

    setPostSearchResult: action((state, { searchResult, tokenName }) => {
        state.postSearchResult[tokenName] = searchResult;
    }),

    setPendingPostSearchResult: action((state, { searchResult, tokenName }) => {
        state.pendingPostSearchResult[tokenName] = searchResult;
    }),

    setDeletedPostSearchResult: action((state, { searchResult, tokenName }) => {
        state.deletedPostSearchResult[tokenName] = searchResult;
    }),

    setRecommendedPostSearchResult: action((state, { searchResult, tokenName }) => {
        state.recommendedPostSearchResult[tokenName] = searchResult;
    }),

    setPartnerPostSearchResult: action((state, { searchResult, tokenName }) => {
        state.partnerPostSearchResult[tokenName] = searchResult;
    }),

    setUserPostSearchResult: action((state, searchResult) => {
        state.userPostSearchResult = searchResult;
    }),
    setUserCommentSearchResult: action((state, searchResult) => {
        state.userCommentSearchResult = searchResult;
    }),

    setPostSearchResultLastDoc: action((state, { tokenName, lastDoc }) => {
        state.postSearchResultLastDoc[tokenName] = lastDoc;
    }),

    setPartnerPostSearchResultLastDoc: action((state, { tokenName, lastDoc }) => {
        state.partnerPostSearchResultLastDoc[tokenName] = lastDoc;
    }),

    setPendingPostSearchResultLastDoc: action((state, { tokenName, lastDoc }) => {
        state.pendingPostSearchResultLastDoc[tokenName] = state.pendingPostSearchResultLastDoc[tokenName] || {};
        state.pendingPostSearchResultLastDoc[tokenName] = lastDoc;
    }),

    setDeletedPostSearchResultLastDoc: action((state, { tokenName, lastDoc }) => {
        state.deletedPostSearchResultLastDoc[tokenName] = lastDoc;
    }),

    setUserPostSearchResultLastDoc: action((state, { tokenName, lastDoc }) => {
        state.userPostSearchResultLastDoc[tokenName] = lastDoc;
    }),
    setUserCommentSearchResultLastDoc: action((state, lastDoc) => {
        state.userCommentSearchResultLastDoc = lastDoc;
    }),

    clearPostSearchResult: action((state, { tokenName }) => {
        state.postSearchResult[tokenName] = [];
        state.postSearchResultLastDoc[tokenName] = {};
        state.pinnedPostSearchResult[tokenName] = [];

        state.userPostSearchResult = [];
        state.userPostSearchResultLastDoc[tokenName] = {};
    }),

    setPostCommentReplies: action((state, payload) => {
        if (state.repliesToPostComments[payload.commentId])
            state.repliesToPostComments[payload.commentId] = state.repliesToPostComments[payload.commentId].concat(payload.replyIds);
        else
            state.repliesToPostComments[payload.commentId] = payload.replyIds;
    }),

    concatPostCommentRepliesSearchResult: action((state, payload) => {
        if (!payload.isConcat && payload.searchResult.length > 0) { state.postCommentRepliesSearchResult[payload.commentId] = payload.searchResult; }
        else {
            if (!state.postCommentRepliesSearchResult[payload.commentId])
                state.postCommentRepliesSearchResult[payload.commentId] = [];
            state.postCommentRepliesSearchResult[payload.commentId] = state.postCommentRepliesSearchResult[payload.commentId].concat(payload.searchResult);
        }
    }),

    addPostCommentRepliesSearchResult: action((state, payload) => {
        if (!state.postCommentRepliesSearchResult[payload.commentId])
            state.postCommentRepliesSearchResult[payload.commentId] = [];

        state.postCommentRepliesSearchResult[payload.commentId] = state.postCommentRepliesSearchResult[payload.commentId].concat(payload.searchResult);
    }),

    concatPostCommentsSearchResult: action((state, payload) => {
        if (!payload.isConcat && payload.searchResult.length > 0)
            state.postCommentsSearchResult = payload.searchResult;
        else
            state.postCommentsSearchResult = state.postCommentsSearchResult.concat(payload.searchResult);
    }),

    addPostCommentSearchResult: action((state, searchResult) => {
        state.postCommentsSearchResult = searchResult.concat(state.postCommentsSearchResult);
    }),

    clearPostCommentSearchResult: action(state => {
        state.postCommentsSearchResult = [];
    }),

    clearPostCommentRepliesSearchResult: action((state, commentId) => {
        state.postCommentRepliesSearchResult[commentId] = [];
    })
};

export default upvoteModel;