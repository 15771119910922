import React, { FC, memo } from 'react';
import PostStatsAndReactions from '../PostStatsAndReactions/PostStatsAndReactions';
import SuperCommentListItem from '@src/components/SuperComment/SuperCommentListItem';
import { cn } from '@creator/ui/utils/ui';
import { isPostDeleted } from '@src/model/upvote/helpers';
import { propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { useStoreState } from '@src/model/hooks';

export interface PostCardFooterProps {
    postId: string;
    tokenName: string;
    onResponseClick: (e: React.MouseEvent<HTMLElement>) => void;
    onStatsClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const PostCardFooter: FC<PostCardFooterProps> = props => {
    const { postId, tokenName, onResponseClick, onStatsClick } = props;

    const post = useStoreState(state => state.upvote.getPost(postId), storeEqualityFn);

    const renderFooter = () => {
        return (
            <div className={cn('relative flex items-center ')}>
                <PostStatsAndReactions
                    tokenName={tokenName}
                    isDisabled={isPostDeleted(postId)}
                    onStatsClick={onStatsClick}
                    onResponseClick={onResponseClick}
                    className="px-5"
                    postId={postId}
                />
            </div>
        );
    };

    const renderSuperCommentListItem = () => {
        if (!post || !post.scTopId) return;
        return (
            <SuperCommentListItem
                className="px-4"
                goToPostOnClick={true}
                lineClamp={true}
                superCommentId={post.scTopId}
            />
        );
    };


    return (
        <div className="pb-3 space-y-3">
            {renderFooter()}
            {renderSuperCommentListItem()}
        </div>
    );
};

export default memo(PostCardFooter, propsEqualityFn);