import { useState } from 'react';
import { useStoreActions } from '@src/model/hooks';

export interface ShareUrlPayload {
    useShortUrl?: boolean; // Whether to use short url or not
    url: string;
    text?: string; // A string representing text to be shared.
    title?: string; // A string representing a title to be shared. May be ignored by the target.
    files?: File[]; // An array of File objects representing files to be shared. See below for shareable file types.
}
export interface UseSocialShare {
    shortUrl: string;
    isCreatingShortUrl: boolean;
    createShortUrl: (url: string) => Promise<string>
    shareUrl: (payload: ShareUrlPayload) => Promise<void>
}

function useSocialShare(): UseSocialShare {
    const createShortUrlAction = useStoreActions(actions => actions.shortUrl.createShortUrl);

    const [shortUrl, setShortUrl] = useState('');
    const [isCreatingShortUrl, setIsCreatingShortUrl] = useState(false);

    async function createShortUrl(url: string): Promise<string> {
        setIsCreatingShortUrl(true);
        try {
            const { shortUrl } = await createShortUrlAction(url);
            setShortUrl(shortUrl);
            return shortUrl;
        } catch (error) {
            console.error('Error creating short url:', error);
            return url;
        }
        finally {
            setIsCreatingShortUrl(false);
        }
    }

    async function shareUrl(payload: ShareUrlPayload): Promise<void> {
        const { url, files, text, title } = payload;
        if (!window.navigator.share) throw new Error('Native sharing not supported');
        try {
            await window.navigator.share({ url, files, text, title });
        } catch (err) {
            const error = err as Error;
            if (error.name === 'AbortError') return;
            throw error;
        }
    }


    return { shareUrl, isCreatingShortUrl, createShortUrl, shortUrl };
}

export default useSocialShare;
