import { FacebookAuthProvider, GithubAuthProvider, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { AuthProviderId, OneTapAuthConfig } from '../account/account.model';
import { SignInProvider } from './config.model';

export function getAuthProviderIdBySignInProvider(signInProvider: SignInProvider): AuthProviderId {
    if (signInProvider === 'GOOGLE') return GoogleAuthProvider.PROVIDER_ID;
    if (signInProvider === 'FACEBOOK') return FacebookAuthProvider.PROVIDER_ID;
    if (signInProvider === 'TWITTER') return TwitterAuthProvider.PROVIDER_ID;
    if (signInProvider === 'GITHUB') return GithubAuthProvider.PROVIDER_ID;
    return 'email';
}

export function getGoogleOneTapToAuthConfig(googleClientID: string): OneTapAuthConfig {
    return {
        provider: GoogleAuthProvider.PROVIDER_ID,
        clientId: googleClientID
    };
}