// src/components/PostView/PostContent.tsx
import React, { memo, useCallback } from 'react';
import EditorViewOnly, { EditorViewOnlyProps } from '@src/basic-components/EditorViewOnly/EditorViewOnly';
import Poll from '@src/components/Poll/Poll/Poll';
import { getPostContent, isResponsePost } from '@src/model/upvote/helpers';
import { propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { useStoreState } from '@src/model/hooks';

interface PostContentProps {
    postId: string;
    tokenName: string;
    onResponseFullScreenButtonClick: (e: React.MouseEvent<HTMLElement>, postId: string) => void;
}

const PostContent: React.FC<PostContentProps> = props => {
    const { postId, tokenName, onResponseFullScreenButtonClick } = props;

    const post = useStoreState(state => state.upvote.getPost(postId), storeEqualityFn);

    // Memoize the callback to avoid recreating it on every render.
    const fullScreenHandler = useCallback(
        (e: React.MouseEvent<HTMLElement>) => onResponseFullScreenButtonClick(e, postId),
        [onResponseFullScreenButtonClick, postId]
    );

    function renderEditorViewOnly() {
        const content = getPostContent(postId);
        if (!content) return null;

        const editorProps: EditorViewOnlyProps = {
            tokenName,
            data: content,
            editorViewOnlyVideoToolProps: {
                onResponseFullScreenButtonClick: isResponsePost(postId) ? fullScreenHandler : undefined,
            },
            editorViewOnlyAudioToolProps: {
                onResponseFullScreenButtonClick: isResponsePost(postId) ? fullScreenHandler : undefined,
            },
        };

        return <EditorViewOnly {...editorProps} />;
    }

    function renderPoll() {
        if (!post || !post.pollOptions) return null;

        return <Poll tokenName={tokenName} postId={postId} className="pt-4 mb-4" />;
    }

    return (
        <div className="mt-4 editor-view-only">
            {renderPoll()}
            {renderEditorViewOnly()}
        </div>
    );
};

export default memo(PostContent, propsEqualityFn);
