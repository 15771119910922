import { action, Action, computed, Computed, Thunk, thunk } from 'easy-peasy';
import { CommentSearchResult, CommentSearchResultItem, PostSearchResult, PostSearchResultItem, SearchResultHit, UserSearchResultItem, UserSearchResult, TokenSearchResult, TokenSearchResultItem } from '@creator/sdk/modules/search/search.model';
import { searchComments, searchPosts, searchTokens, searchUsers } from './actions';
import { SearchPayload } from '@creator/sdk/modules/search';
import { QueryPayload } from '@creator/sdk/modules/search/search.service';
import { getSearchHashByPayload } from './helpers';

export interface SearchModel {
    searchHash: string; // combination of search query with query params, to save results in cache
    searchResult: string[];
    totalSearchResult: number | null;

    postHits: Record<string, SearchResultHit<PostSearchResultItem>>;
    commentHits: Record<string, SearchResultHit<CommentSearchResultItem>>;
    userHits: Record<string, SearchResultHit<UserSearchResultItem>>;
    tokenHits: Record<string, SearchResultHit<TokenSearchResultItem>>;

    getPostHit: Computed<SearchModel, (postId: string) => SearchResultHit<PostSearchResultItem> | undefined>;
    getUserHit: Computed<SearchModel, (userId: string) => SearchResultHit<UserSearchResultItem> | undefined>;
    getCommentHit: Computed<SearchModel, (commentId: string) => SearchResultHit<CommentSearchResultItem> | undefined>;
    getTokenHit: Computed<SearchModel, (tokenName: string) => SearchResultHit<TokenSearchResultItem> | undefined>;

    setSearchResult: Action<SearchModel, string[]>;

    setPostHits: Action<SearchModel, Record<string, SearchResultHit<PostSearchResultItem>>>;
    setCommentHits: Action<SearchModel, Record<string, SearchResultHit<CommentSearchResultItem>>>;
    setUserHits: Action<SearchModel, Record<string, SearchResultHit<UserSearchResultItem>>>;
    setSearchHash: Action<SearchModel, string>;
    setTokenHits: Action<SearchModel, Record<string, SearchResultHit<TokenSearchResultItem>>>;

    setTotalSearchResult: Action<SearchModel, number | null>;

    clearSearchResult: Action<SearchModel>;

    searchPosts: Thunk<SearchModel, SearchPayload, Promise<PostSearchResult>>;
    searchComments: Thunk<SearchModel, SearchPayload, Promise<CommentSearchResult>>;
    searchUsers: Thunk<SearchModel, SearchPayload, Promise<UserSearchResult>>;
    searchTokens: Thunk<SearchModel, SearchPayload, Promise<TokenSearchResult>>;
}

const searchModel: SearchModel = {
    searchHash: '',
    searchResult: [],
    totalSearchResult: null,

    postHits: {},
    commentHits: {},
    userHits: {},
    tokenHits: {},

    getPostHit: computed(state => postId => state.postHits[postId]),
    getCommentHit: computed(state => commentId => state.commentHits[commentId]),
    getUserHit: computed(state => userId => state.userHits[userId]),
    getTokenHit: computed(state => tokenName => state.tokenHits[tokenName]),

    searchPosts: thunk(async (actions, payload) => {
        const searchHash = getSearchHashByPayload('posts', payload);
        const _res = await searchPosts(payload);
        const ids: string[] = [];

        const postHits = {} as Record<string, SearchResultHit<PostSearchResultItem>>;
        _res.hits.forEach(postHit => {
            const id = postHit._id;
            ids.push(id);
            postHits[id] = postHit;
        });

        actions.setSearchHash(searchHash);
        actions.setSearchResult(ids);
        actions.setTotalSearchResult(_res.totalResults);
        actions.setPostHits(postHits);

        return _res;
    }),

    searchComments: thunk(async (actions, payload) => {
        const searchHash = getSearchHashByPayload('comments', payload);
        const _res = await searchComments(payload);
        const ids: string[] = [];

        const commentHits = {} as Record<string, SearchResultHit<CommentSearchResultItem>>;
        _res.hits.forEach(hit => {
            const id = hit._id;
            ids.push(id);
            commentHits[id] = hit;
        });

        actions.setSearchHash(searchHash);
        actions.setSearchResult(ids);
        actions.setTotalSearchResult(_res.totalResults);
        actions.setCommentHits(commentHits);

        return _res;
    }),

    searchUsers: thunk(async (actions, payload) => {
        const searchHash = getSearchHashByPayload('users', payload);
        const _res = await searchUsers(payload);
        const ids: string[] = [];

        const userHits = {} as Record<string, SearchResultHit<UserSearchResultItem>>;
        _res.hits.forEach(hit => {
            const id = hit._id;
            ids.push(id);
            userHits[id] = hit;
        });

        actions.setSearchHash(searchHash);
        actions.setSearchResult(ids);
        actions.setTotalSearchResult(_res.totalResults);
        actions.setUserHits(userHits);

        return _res;
    }),

    searchTokens: thunk(async (actions, payload) => {
        const searchHash = getSearchHashByPayload('tokens', payload);
        const _res = await searchTokens(payload);
        const ids: string[] = [];

        const tokenHits = {} as Record<string, SearchResultHit<TokenSearchResultItem>>;
        _res.hits.forEach(hit => {
            const id = hit._id;
            ids.push(id);
            tokenHits[id] = hit;
        });

        actions.setSearchHash(searchHash);
        actions.setSearchResult(ids);
        actions.setTotalSearchResult(_res.totalResults);
        actions.setTokenHits(tokenHits);

        return _res;
    }),

    setSearchResult: action((state, payload) => {
        state.searchResult = state.searchResult.concat(payload);
    }),

    setPostHits: action((state, payload) => {
        state.postHits = { ...state.postHits, ...payload };
    }),

    setCommentHits: action((state, payload) => {
        state.commentHits = { ...state.commentHits, ...payload };
    }),

    setUserHits: action((state, payload) => {
        state.userHits = { ...state.userHits, ...payload };
    }),

    setTokenHits: action((state, payload) => {
        state.tokenHits = { ...state.tokenHits, ...payload };
    }),

    setTotalSearchResult: action((state, payload) => {
        state.totalSearchResult = payload;
    }),

    setSearchHash: action((state, payload) => {
        state.searchHash = payload;
    }),

    clearSearchResult: action(state => {
        state.searchHash = '';
        state.searchResult = [];
        state.totalSearchResult = null;
    })
};

export default searchModel;

