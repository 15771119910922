import React, { FC, memo, ReactNode, useCallback } from 'react';
import { useStoreState } from '@src/model/hooks';
import { getPostContent, isPostDeleted } from '@src/model/upvote/helpers';
import Card from '@creator/ui/components/Card/CardV2';
import EditorViewOnlySummarizedView, { EditorViewOnlySummarizedViewProps } from '@src/basic-components/EditorViewOnly/SummarizedView/SummarizedView';
import { Post } from '@creator/sdk/modules/upvote/upvote.model';
import Poll from '@src/components/Poll/Poll/Poll';
import { useHistory } from 'react-router-dom';
import PostEmbeddedByResponse from '../EmbeddedPost/PostEmbeddedByResponse';
import { getUrlPath } from '@src/utils/url-utils/url-utils';
import { ensureTokenUrl } from '@src/model/config/helpers';
import PostCardHeader, { PostCardHeaderProps } from './PostCardHeader';
import PostCardFooter from './PostCardFooter';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
export interface PostCardProps {
    className?: string;
    tokenName?: string;
    postId: string;
    showFooter?: boolean;
    showPostBasicInfo?: boolean;
    showUpvoteAndVisits?: boolean;
    postCardHeaderProps?: Partial<PostCardHeaderProps>;
    onClick?: (e: React.MouseEvent<HTMLElement>, postId: string, post?: Post) => void;
    editorProps?: Partial<EditorViewOnlySummarizedViewProps>;
    bodyProps?: React.HTMLAttributes<HTMLDivElement>;
    selectedTopic?: string;
    children?: ReactNode;
    hideFooter?: boolean;
}

const PostCard: FC<PostCardProps> = props => {
    const { tokenName, editorProps = {}, postId, hideFooter, className, children } = props;

    const history = useHistory();
    const post = useStoreState(state => state.upvote.getPost(postId), storeEqualityFn);
    const response = useStoreState(state => state.response.getResponse(postId), storeEqualityFn);

    const _tokenName = tokenName || post?.tokenName || '';
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);

    const handlePostClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        props.onClick?.(e, postId);
    }, [postId]);

    const onResponseClick = useCallback(() => {
        if (!isLoggedIn) return history.push(`/bbs/login?next=${getUrlPath()}`);
        history.push(`/bbs/${ensureTokenUrl(_tokenName, false)}/posts/create?parentPostId=${postId}`);
    }, [isLoggedIn, _tokenName, postId, history]);

    const renderEditorViewOnlySummarizedView = () => {
        if (!post) return null;
        const postContent = getPostContent(post.id);
        if (!postContent) return null;
        const contentOpacity = isPostDeleted(postId) ? 'opacity-50' : 'opacity-100';

        const _props = mergeProps<[EditorViewOnlySummarizedViewProps, Partial<EditorViewOnlySummarizedViewProps>]>({
            className: `${contentOpacity}`,
            onClick: handlePostClick,
            data: postContent
        }, editorProps);

        return (
            <EditorViewOnlySummarizedView {..._props} />
        );
    };


    const renderPoll = () => {
        if (!post || !tokenName || !post.pollOptions || post.pollOptions.length === 0) return null;
        return <Poll tokenName={tokenName} postId={postId} />;
    };

    const renderPostCardHeader = () => {
        if (!post) return null;

        const { postCardHeaderProps = {} } = props;
        const _props = mergeProps<[PostCardHeaderProps, Partial<PostCardHeaderProps>]>({
            postId,
            onClick: handlePostClick,
            postBasicInfoProps: {
                postTitle: post.title
            }
        }, postCardHeaderProps);

        return (
            <PostCardHeader {..._props} />
        );
    };


    const renderPostEmbeddedByResponse = () => {
        if (!response?.parent || response.parent === 'root') return null;
        return (
            <PostEmbeddedByResponse
                className="text-sm"
                responseId={response.id}
            />
        );
    };

    const renderPostCardFooter = () => {
        if (!post || hideFooter) return null;

        return (
            <PostCardFooter
                postId={postId}
                tokenName={_tokenName}
                onStatsClick={handlePostClick}
                onResponseClick={onResponseClick}
            />
        );
    };


    function renderBody() {
        const { bodyProps = {} } = props;
        const _props = mergeProps<[React.HTMLAttributes<HTMLDivElement>, Partial<React.HTMLAttributes<HTMLDivElement>>]>({
            className: 'flex-1 p-3 space-y-4 lg:px-5'
        }, bodyProps);

        return <div {..._props}>
            {renderPostEmbeddedByResponse()}
            {renderEditorViewOnlySummarizedView()}
            {renderPoll()}
        </div>;
    }

    return (
        <Card className={`relative flex flex-col ${className}`}>
            {renderPostCardHeader()}
            {renderBody()}
            {renderPostCardFooter()}
            {children}
        </Card>
    );
};

export default memo(PostCard);