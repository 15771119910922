import React, { FC, memo } from 'react';
import { mergeProps } from '../../utils/merge-props/merge-props';
import Image, { ImageProps } from '../Image/Image';
import { cn } from '../../utils/ui';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

export interface CircularImageProps {
    imageProps: ImageProps;
}

const CircularImage: FC<CircularImageProps> = props => {
    const { imageProps } = props;

    function getBaseClassName() {
        const baseClassName = 'transition shadow dark:shadow rounded-full border border-gray-100 dark:border-gray-800';
        if (imageProps.isLoading) return baseClassName;
        return cn(baseClassName, 'bg-gray-50 dark:bg-gray-800');
    }

    const _props = mergeProps<[ImageProps, ImageProps]>({
        src: '',
        className: getBaseClassName()
    }, imageProps);

    return (
        <Image {..._props} />
    );
};

export default memo(CircularImage, propsEqualityFn);
