import { Action, Thunk, action, thunk } from 'easy-peasy';
import { DomainConfig, DomainMainMenu, DomainMainMenuItem } from '@creator/sdk/modules/config/config.model';
import { getDefaultDomainConfig } from './helpers';
import creatorSdk from '@src/services/creator-sdk';
import { updateMenu } from './actions';
import { deepMerge } from '@src/utils/object-utils/object-utils';

export interface ConfigModel {
    domainConfig: DomainConfig | null;
    mainMenu: DomainMainMenu | null;

    setDomainConfig: Action<ConfigModel, DomainConfig>;
    setDomainMainMenu: Action<ConfigModel, DomainMainMenu>;

    loadDomainConfig: Thunk<ConfigModel>;
    loadDomainMainMenu: Thunk<ConfigModel>;
    updateMenu: Thunk<ConfigModel, DomainMainMenuItem[]>;
}

const configModel: ConfigModel = {
    domainConfig: null,
    mainMenu: null,

    loadDomainConfig: thunk(async actions => {
        const config = window.__SSR_DATA__.domainConfig ? window.__SSR_DATA__.domainConfig : await creatorSdk.configModule.getDomainConfig();
        const defaultConfig = getDefaultDomainConfig();
        const newConfig = deepMerge<DomainConfig>(defaultConfig, config as DomainConfig);
        actions.setDomainConfig(newConfig);
        return newConfig;
    }),

    loadDomainMainMenu: thunk(async actions => {
        const mainMenu = await creatorSdk.configModule.getDomainMainMenu();
        actions.setDomainMainMenu(mainMenu);
        return mainMenu;
    }),

    updateMenu: thunk(async (actions, payload) => {
        const menu = await updateMenu(payload);
        actions.setDomainMainMenu(menu);
    }),

    setDomainConfig: action((state, payload) => {
        state.domainConfig = payload;
    }),

    setDomainMainMenu: action((state, payload) => {
        state.mainMenu = payload;
    })
};

export default configModel;