import { Computed, Action, Thunk, computed, thunk, action } from 'easy-peasy';
import { loadResponse, loadResponses } from './actions';
import { StoreModel, StoreModelSetPayload } from '..';
import { Response } from '@creator/sdk/modules/response/response.model';
import { Pagination, PaginationLastDoc } from '@creator/sdk/modules/db/db.model';
import { GetDocumentsPayload } from '@creator/sdk/modules/db';

export interface ResponseModel {
    responses: Record<string, Response | null>; // postId -> Response / null
    rootResponseSearchResult: string[];
    rootResponseSearchResultLastDoc: PaginationLastDoc<Response> | null;

    rootResponses: Computed<ResponseModel, Response[]>;
    getResponse: Computed<ResponseModel, (responseId: string) => Response | null>;

    set: Action<ResponseModel, StoreModelSetPayload<ResponseModel>>;
    setResponse: Action<ResponseModel, Response>;
    setResponseStatus: Action<ResponseModel, { responseId: string, newStatus: number }>;
    setRootResponseSearchResultLastDoc: Action<ResponseModel, PaginationLastDoc<Response>>;
    concatRootResponseSearchResult: Action<ResponseModel, string[]>;
    clearRootResponseSearchResult: Action<ResponseModel>;

    loadResponse: Thunk<ResponseModel, string, null, StoreModel, Promise<Response>>;
    loadResponses: Thunk<ResponseModel, GetDocumentsPayload<Response> & { includePosts?: boolean }, null, StoreModel, Promise<Pagination<Response>>>;
    loadRootResponses: Thunk<ResponseModel, GetDocumentsPayload<Response> & { relativeResponseId?: string }, null, StoreModel, Promise<Pagination<Response>>>;
    loadResponsesByIds: Thunk<ResponseModel, { ids: string[] }, null, StoreModel, Promise<Pagination<Response>>>;
}

const ResponseModel: ResponseModel = {
    responses: {},
    rootResponseSearchResult: [],
    rootResponseSearchResultLastDoc: null,

    getResponse: computed(state => id => state.responses[id]),
    rootResponses: computed(state => state.rootResponseSearchResult.map(responseId => state.getResponse(responseId)) as Response[]),

    loadRootResponses: thunk(async (actions, payload, helpers) => {
        const { relativeResponseId } = payload;
        const res = await actions.loadResponses(payload);
        const { items, lastDoc } = res;
        let ids = items.map(item => item.id);

        if (relativeResponseId && !ids.includes(relativeResponseId))
            ids = [relativeResponseId].concat(ids);

        actions.concatRootResponseSearchResult(ids);

        if (lastDoc)
            actions.setRootResponseSearchResultLastDoc(lastDoc);

        return res;
    }),

    loadResponses: thunk(async (actions, payload, helpers) => {
        const res = await loadResponses(payload);
        const { items } = res;

        if (!items.length) return res;

        const responses = {};
        const ids: string[] = [];
        items.forEach(response => {
            responses[response.id] = response;
            ids.push(response.id);
        });
        if (payload.includePosts)
            helpers.getStoreActions().upvote.loadPostsByIds({ ids });

        actions.set({ key: 'responses', value: { ...helpers.getState().responses, ...responses } });
        return res;
    }),

    loadResponsesByIds: thunk(async (actions, { ids }, helpers) => {
        return actions.loadResponses({ filterBy: [{ field: 'postId', opStr: 'in', value: [...ids] }] });
    }),

    loadResponse: thunk(async (actions, responseId, helpers) => {
        const res = await loadResponse(responseId);
        actions.setResponse(res);
        return res;
    }),

    concatRootResponseSearchResult: action((state, ids) => {
        const oldSearchResult = state.rootResponseSearchResult || [];
        state.rootResponseSearchResult = oldSearchResult.concat(ids);
    }),

    set: action((state, payload) => {
        const { key, value } = payload;
        state[key] = value;
    }),

    setResponse: action((state, response) => {
        state.responses[response.id] = response;
    }),

    setResponseStatus: action((state, { responseId, newStatus }) => {
        const oldResponse = state.responses[responseId];
        if (!oldResponse) return;

        const newResponse: Response = {
            ...oldResponse,
            status: newStatus
        };
        state.responses[responseId] = newResponse;
    }),

    setRootResponseSearchResultLastDoc: action((state, lastDoc) => {
        state.rootResponseSearchResultLastDoc = lastDoc;
    }),

    clearRootResponseSearchResult: action((state, postId) => {
        state.rootResponseSearchResult = [];
    })
};

export default ResponseModel;