import creatorSdk from '@src/services/creator-sdk';
import { SearchPayload } from '@creator/sdk/modules/search';
import { CommentSearchResult, PostSearchResult, TokenSearchResult, UserSearchResult } from '@creator/sdk/modules/search/search.model';

// export async function search<T = SearchResult>(payload: SearchPayload): Promise<Pagination<T>> {
//     return creatorSdk.searchModule.search<T>(payload);
// }

export async function searchPosts(payload: SearchPayload): Promise<PostSearchResult> {
    return creatorSdk.searchModule.searchPosts(payload);
}

export async function searchComments(payload: SearchPayload): Promise<CommentSearchResult> {
    return creatorSdk.searchModule.searchComments(payload);
}

export async function searchUsers(payload: SearchPayload): Promise<UserSearchResult> {
    return creatorSdk.searchModule.searchUsers(payload);
}

export async function searchTokens(payload: SearchPayload): Promise<TokenSearchResult> {
    return creatorSdk.searchModule.searchTokens(payload);
}
