import React, { FC, memo, ReactNode } from 'react';
import TokenSidebar, { TokenSidebarProps } from '@src/components/Token/TokenSidebar/TokenSidebar';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';
import { cn } from '@creator/ui/utils/ui';

export interface ContentLayoutProps {
    tokenName: string;
    showSidebar?: boolean;
    children?: ReactNode;
    className?: string;
    tokenSidebarProps?: Partial<TokenSidebarProps>;
}

const ContentLayout: FC<ContentLayoutProps> = props => {
    const { className, tokenName, showSidebar = true, children } = props;

    function renderTokenSidebar() {
        const { tokenSidebarProps = {} } = props;
        if (!showSidebar) return null;

        const _props = mergeProps<[TokenSidebarProps, Partial<TokenSidebarProps>]>({
            tokenName
        }, tokenSidebarProps);

        return <TokenSidebar {..._props} />;
    }

    return (
        <div className={cn('container lg:px-8 lg:space-x-0 lg:flex', className)}> 
            <div className="flex-grow-0 flex-shrink-0 hidden xl:block xl:w-1/12">
            </div>
            <div className="flex flex-grow-0 flex-shrink-0 w-full space-x-4 lg:w-12/12 xl:w-10/12">
                <div className="w-full space-y-4 lg:w-2/3">
                    {children}
                </div>
                <div className="hidden lg:w-1/3 lg:block">
                    {renderTokenSidebar()}
                </div>
            </div>
            <div className="flex-grow-0 flex-shrink-0 hidden xl:block xl:w-1/12">
            </div>
        </div>
    );
};

export default memo(ContentLayout, propsEqualityFn);
