import { useState } from 'react';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import { getUserId } from '@src/model/user/helpers';
import creatorSdk from '@src/services/creator-sdk';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { useHistory } from 'react-router-dom';

export interface UseJoinCommunityHookProps {
    tokenName: string;
    isRedirectToCommunity?: boolean;
    onError?: (error: Error) => void;
}

export const useJoinCommunity = ({ tokenName, isRedirectToCommunity, onError }: UseJoinCommunityHookProps) => {
    const joinCommunity = useStoreActions(actions => actions.user.joinCommunity);
    const myJoinedCommunities = useStoreState(state => state.user.myJoinedCommunities);

    const [isProcessing, setIsProcessing] = useState(false);
    const history = useHistory();
    const isJoined = myJoinedCommunities.includes(tokenName);

    const join = async (isJoin: boolean) => {
        setIsProcessing(true);
        try {
            await joinCommunity({
                isJoin,
                userId: getUserId() ?? '',
                tokenName
            });

            if (isRedirectToCommunity)
                history.push(`/${ensureTokenUrl(tokenName)}`);

        } catch (error: unknown) {
            if (onError) onError(error as Error);
            console.error(error);
        } finally {
            setIsProcessing(false);
        }
    };

    return { isJoined, isProcessing, join };
};
