import { cn } from '@creator/ui/utils/ui';
import useYahooAds from '@src/hooks/useYahooAds';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';
import React, { memo, useEffect, useRef } from 'react';

// Extend the Window interface to include googletag
declare global {
    interface Window {
        googletag: {
            cmd: Array<() => void>;
            defineSlot: (adUnitPath: string, sizes: [number, number] | [number, number][], divId: string) => any;
            pubads: () => any;
            display: (divId: string) => void;
            destroySlots: (slots: any[]) => void;
        };
    }
}

export type GooglaAdSlotSize = [number, number];

interface AdSlotProps {
    className?: string;
    adUnitPath: string; // e.g. '/22888152279,21802609212/us/ysports/thn/dt/us_ysports_thn_dt_top_right'
    sizes: GooglaAdSlotSize | GooglaAdSlotSize[]; // e.g. [300, 250] or [[300, 250], [300, 600]]
    divId: string; // e.g. 'div-gpt-ad-1733324763211-0'
    index?: number; // suffix added after the divId, e.g. 'div-gpt-ad-1733324763211-0' default is 0
    targeting?: { key: string; value: string };
}

const GoogleAdSlot: React.FC<AdSlotProps> = props => {
    const { className, adUnitPath, sizes, divId, index = 0, targeting } = props;
    const adRef = useRef<HTMLDivElement>(null);
    
    // Use our hook that loads the GPT script and exposes an isLoaded flag.
    const { isLoaded } = useYahooAds();
    // Append index to the div id if provided.
    const divIdWithSuffix = index ? `${divId.replace('-0', `-${index}`)}` : divId;
    
    function getSizeStyle() {
        if (!Array.isArray(sizes[0])) {
            // sizes is a single array of two numbers
            const [width, height] = sizes as GooglaAdSlotSize;
            return { width, height };
        }
        // sizes is an array of arrays; use the first size tuple
        const [width, height] = sizes[0];
        return { width, height };
    }
    
    useEffect(() => {
        // Only run if the GPT script has been loaded.
        if (!isLoaded) return;
        if (!window.googletag || !window.googletag.cmd) {
            console.warn('googletag / googletag.cmd is not defined');
            return;
        }
    
        window.googletag.cmd.push(() => {
            const slot = window.googletag.defineSlot(adUnitPath, sizes, divIdWithSuffix).addService(window.googletag.pubads());
            
            // Set targeting if provided.
            if (targeting) window.googletag.pubads().setTargeting(targeting.key, targeting.value);
            window.googletag.display(divIdWithSuffix);
            window.googletag.pubads().refresh([slot]);
        });

        // Cleanup: Destroy the slot when the component unmounts.
        return () => {
            if (window.googletag && window.googletag.destroySlots) {
                window.googletag.cmd.push(() => {
                    const slotToDestroy = window.googletag.pubads().getSlots().find(s => s.getSlotElementId() === divIdWithSuffix);
                    if (slotToDestroy) 
                        window.googletag.destroySlots([slotToDestroy]);
                    
                });
            }
        };
    }, [isLoaded, divIdWithSuffix]);


    return <div className={cn('cursor-pointer', className)} id={divIdWithSuffix} ref={adRef} style={getSizeStyle()} />;
};

export default memo(GoogleAdSlot, propsEqualityFn);
