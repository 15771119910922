import React, { FC, useRef } from 'react';
import { useStoreState } from '@src/model/hooks';
import { noop } from '@src/utils/utils';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';
import { memo } from 'react';
import { Flair } from '@creator/sdk/modules/flair/flair.model';
import { useOnClickOutside } from '@src/hooks/use-on-click-outside';
import TopicChip from '@src/basic-components/TopicChip/TopicChip';
import { cn } from '@creator/ui/utils/ui';

export interface FlairCardContentProps {
    tokenName: string;
    className?: string;
    onFlairChange: (index: number) => void;
    selectedFlair?: number;
    flairsCancelable?: boolean;
    onClickOutside?: () => void;
}

const FlairCardContent: FC<FlairCardContentProps> = props => {
    const { onClickOutside = () => noop, flairsCancelable, selectedFlair = -1, onFlairChange, tokenName, className } = props;

    const ref = useRef(null);
    useOnClickOutside(ref, onClickOutside);

    const token = useStoreState(state => state.token.getToken(tokenName));
    const flairs = token ? token.activeFlairs : [] as Flair[];

    function renderFlair(text: string, index: number) {
        if (flairsCancelable) return renderCancelableFlair(text, index);
        if (selectedFlair === index) return renderSelectedFlair(text);
        return <TopicChip onClick={() => onFlairChange(index)} key={text} topicId={text} />;
    }

    function renderSelectedFlair(topicId: string) {
        return <TopicChip showCloseIcon={true} isActive={true} onClick={() => onFlairChange(-1)} key={topicId} topicId={topicId} />;
    }

    function renderCancelableFlair(text: string, index: number) {
        return <TopicChip showCloseIcon={true} onClick={() => onFlairChange(index)} key={text} topicId={text} />;
    }

    function renderTokenFlairs() {
        if (!flairs || flairs.length === 0) return null;
        const renderedFlairs = flairs.map((flair, index) => renderFlair(flair.name, index));
        return renderedFlairs;
    }

    return (
        <div className={cn('flex flex-wrap gap-3', className)} ref={ref}>
            {renderTokenFlairs()}
        </div>
    );
};

export default memo(FlairCardContent, propsEqualityFn);
