import React, { FC } from 'react';
import { noop } from '@src/utils/utils';
import Text from '@creator/ui/components/Text/Text';
import { cleanHtml, cleanHtmlToPlainText } from '@src/utils/html-utils/html-utils';
import { ImageToolData } from '@editorjs/image/dist/types/types';
import Image from '@creator/ui/components/Image/Image';

export interface EditorViewOnlyImageToolProps {
    className?: string;
    blockData: ImageToolData;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const EditorViewOnlyImageTool: FC<EditorViewOnlyImageToolProps> = props => {
    const { onClick = noop, blockData, className } = props;

    function renderCaption() {
        const { caption } = blockData;
        if (!caption) return;
        return (
            <Text enableMultiline={true} className="block mt-1 text-sm text-gray-500" data-placeholder="Caption">
                {cleanHtmlToPlainText(caption)}
            </Text>
        );
    }

    function getWidth() {
        const { file } = blockData;
        if (!file || !file.width) return 'auto';
        return `${file.width}px`;
    }

    function getHeight() {
        const { file } = blockData;
        if (!file || !file.height) return 'auto';
        return `${file.height}px`;
    }
    
    function renderContent() {
        const { url = '', caption, file, stretched, withBackground, withBorder } = blockData;

        const withBackgroundClassName = withBackground ? 'bg-gray-200' : '';
        const withBorderClassName = withBorder ? 'border' : '';
        const stretchedClassName = stretched ? 'w-full' : '';
        
        return (
            <div className={`cdx-block ${withBackgroundClassName} ${withBorderClassName} ${stretchedClassName} overflow-hidden`}>
                <img width={getWidth()} height={getHeight()} className="mx-auto" src={cleanHtml(file?.url || url)} alt={cleanHtmlToPlainText(caption)} />
                {renderCaption()}
            </div>
        );
    }

    return (
        <div onClick={onClick} className={`image-tool ${className}`}>
            {renderContent()}
        </div>
    );
};

export default EditorViewOnlyImageTool;
