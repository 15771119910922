import React, { FC } from 'react';
import { useStoreState } from '@src/model/hooks';
import { noop } from '@src/utils/utils';
import anonymouseProfileImg from '@src/assets/images/profile.png';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import EthImg from '@src/assets/images/eth.png';
import EosImg from '@src/assets/images/eos.png';
import { getTokenImage } from '@src/model/token/helpers';
import CircularImage from '@creator/ui/components/CircularImage/CircularImage';
import { ImageProps } from '@creator/ui/components/Image/Image';
import { cn } from '@creator/ui/utils/ui';

export interface TokenImageProps {
    className?: string;
    tokenName: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    isLoading?: boolean;
}

const TokenImage: FC<TokenImageProps> = props => {
    const { onClick = noop, tokenName, className, isLoading } = props;

    const token = useStoreState(state => state.token.getToken(tokenName), storeEqualityFn);

    function getImageClassName(): string {
        const base = 'flex-shrink-0 w-10 h-10';
        return base;
    }

    function getImageProps(): ImageProps {
        return {
            src: getImageSrc(),
            fallbackSrc: getFallbackImageSrc(),
            className: cn(getImageClassName(), className),
            isLoading,
            onClick
        };
    }

    function getImageSrc(): string {
        if (tokenName === 'ETH') return EthImg;
        if (tokenName === 'EOS') return EosImg;
        if (token && !token.imageURI) return getFallbackImageSrc();
        if (token && token.imageURI) return token.imageURI;

        return getTokenImage(tokenName);
    }


    function getFallbackImageSrc(): string {
        return anonymouseProfileImg;
    }

    return (
        <CircularImage imageProps={getImageProps()} />
    );
};

export default TokenImage;
