
import VotingService, { VotePayload } from './voting.service';
import DbModule from '../db';
import { Vote } from './voting.model';
import AccountModule from '../account';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';

export default class VotingModule {
    accountModule: AccountModule;
    votingService: VotingService;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp, contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        this.dbModule = dbModule;
        this.votingService = new VotingService(contractName, domainName, appCheckModule, dbModule, accountModule);
        this.accountModule = accountModule;

        this.appCheckModule = appCheckModule;
    }


    async vote(payload: VotePayload): Promise<string> {
        const res = await this.votingService.vote(payload)
            .catch((e: Error) => {
                throw e;
            });

        return res.payload;
    }

    async getVote(postId: string): Promise<Vote | null> {
        return this.votingService.getVote(postId);
    }

}