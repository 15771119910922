import React, { FC } from 'react';
import Text from '@creator/ui/components/Text/Text';
import { useTranslation } from 'react-i18next';
import Button from '@creator/ui/components/Button/ButtonV2';
import Input from '@creator/ui/components/Form/Input/Input';

export interface SelectReasonProps {
    //type: 'Post' | 'Comment' | 'User' | 'Community';
    options: string[];
    optionSelected: string;
    setOptionSelected: (option: string) => void;
    otherReason: string;
    setOtherReason: (reason: string) => void;
    subTitle?: string;
    onCancle: () => void;
    onSendReson: (option: string, setOption?: boolean) => void;
    isLoading: boolean;
    className?: string;
}

const SelectReason: FC<SelectReasonProps> = props => {
    const { options, isLoading, onSendReson, optionSelected, setOptionSelected, otherReason, setOtherReason } = props;

    //const entity = type;
    const { t } = useTranslation();

    function renderOtherInput() {
        return (
            <Input maxLength={70} className="h-7" onChange={e => setOtherReason(e.target.value)} value={otherReason} />
        );
    }

    function renderOption(option: string, i: number) {
        if (option === t('other') && optionSelected === t('other'))
            return renderOtherInput();
        
        const click = option === t('other') ? () => setOptionSelected(t('other')) : () => onSendReson(option, true);
        return <Button className="w-full" key={i} isLoading={option === optionSelected && isLoading} color="primary-alternative" size="sm" onClick={click}><Text className="text-xs">{t(option)}</Text></Button>; 
    }

    function renderOptions() {
        const buttons = options.map(renderOption);
        return (
            <div className="space-y-4">
                {buttons}
                {renderOption(t('other'), options.length)}
            </div>
        );
    }

    function renderActionButton() {
        const { onCancle, onSendReson } = props;
        if (optionSelected === t('other')) {
            const isDisabled = !Boolean(otherReason);
            return <Button isDisabled={isDisabled} onClick={() => onSendReson(otherReason)} color="primary" size="sm" className="w-full"><Text>{t('send')}</Text></Button>;
        }
        return <Button onClick={onCancle} color="base" size="sm" className="w-full"><Text>{t('cancel')}</Text></Button>;
    }

    function renderSubtitle() {
        const { subTitle } = props;
        if (!subTitle) return;

        return <Text enableMultiline={true} className="mb-5 text-sm text-center text-gray-500 dark:text-gray-500">{props.subTitle}</Text>;
    }

    return (
        <div className="space-y-4 text-center">
            {renderSubtitle()}
            {renderOptions()}
            {renderActionButton()}
        </div>
    );
};

export default SelectReason;
