import Spinner from '@creator/ui/components/Spinner/Spinner';
import { EmbedData } from '@editorjs/embed';
import { useStoreState } from '@src/model/hooks';
import React, { FC, useEffect, useRef } from 'react';

interface TwitterEmbedProps {
    blockData: EmbedData;
    className?: string;
}

const TwitterEmbed: FC<TwitterEmbedProps> = props => {
    const { className, blockData } = props;
    const isDarkModeOn = useStoreState(state => state.appearanceSettings.isDarkModeOn);
    const theme = isDarkModeOn ? 'dark' : 'light';

    // Extract tweet ID from the embed URL
    function getTweetIdFromUrl(url: string): string {
        const parts = url.split('id=');
        return parts.length > 1 ? parts[1] : '';
    }

    const tweetId = getTweetIdFromUrl(blockData.embed);
    const tweetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!tweetId) return;

        // Ensure the Twitter widgets script is present
        let script = document.querySelector('script[src="https://platform.twitter.com/widgets.js"]') as HTMLScriptElement | null;
        if (!script) {
            script = document.createElement('script');
            script.async = true;
            script.src = 'https://platform.twitter.com/widgets.js';
            document.body.appendChild(script);
        }

        const renderTweet = () => {
            if ((window as any).twttr?.widgets && tweetRef.current) {
                // Clear existing content to avoid stacking multiple tweets
                tweetRef.current.innerHTML = '';

                (window as any).twttr.widgets.createTweet(tweetId, tweetRef.current, {
                    theme,
                    conversation: 'none',
                    width: '550'
                }).catch((err: any) => console.error('Error embedding tweet:', err));
            }
        };

        if ((window as any).twttr?.widgets) {
            renderTweet();
        } else {
            // Wait for the script to load before rendering
            script?.addEventListener('load', renderTweet);
            return () => {
                script?.removeEventListener('load', renderTweet);
            };
        }
    }, [tweetId, theme]);


    function renderCaption() {
        if (!blockData.caption) return null;

        return (
            <p className="text-sm text-gray-500">{blockData.caption}</p>
        );
    }

    return (
        <div className={className}>
            <div className="flex items-center justify-center" ref={tweetRef}>
                <Spinner className="mx-auto my-10" />
            </div>
            {renderCaption()}
        </div>
    );
};

export default TwitterEmbed;
