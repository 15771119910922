import { ModalProps } from '@src/basic-components/Modal/Modal';
import { ReactNode, useState } from 'react';

type HandleModalFn = (content, modalProps?: ModalProps) => void;
export interface UseModal {
    modal: boolean;
    handleModal: HandleModalFn;
    modalContent;
    modalProps: ModalProps;
}

export default function useModal(): UseModal {
    const [modal, setModal] = useState(false);
    const [modalProps, setModalProps] = useState<ModalProps>({});
    const [modalContent, setModalContent] = useState<ReactNode>(null);

    const handleModal: HandleModalFn = (content, modalProps = {}) => {
        setModal(Boolean(content));
        setModalContent(content);
        setModalProps(modalProps);
    };

    return { modal, handleModal, modalContent, modalProps };
}
