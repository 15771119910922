import { OutputBlockData } from '@editorjs/editorjs';
import { cleanEncodedString, stripHtml } from '../html-utils/html-utils';
import { ListItem } from '@editorjs/list/dist/types/ListParams';

export function isTextBasedBlockType(type: string): boolean {
    return type === 'paragraph' || type === 'header' || type === 'list' || type === 'quote' || type === 'code' || type === 'warning';
}

export function isParagraphBasedBlockType(type: string): boolean {
    return type === 'paragraph' || type === 'list' || type === 'quote'
}

export function getTextBasedBlockContent(block: OutputBlockData) {
    if (!isParagraphBasedBlockType(block.type)) return '';

    if (block.type === 'list') return getNestedListContent(block.data.items);

    return block.data.text as string;
}

export function isStyleBasedBlockType(type: string): boolean {
    return type === 'delimiter';
}

export function getTextBlocks(blocks: OutputBlockData[]): OutputBlockData[] {
    return blocks.filter((block, i) => isTextBasedBlockType(block.type));
}

export function summarizeBlocksContent(blocks: OutputBlockData[]): string {
    let text = '';

    blocks.forEach((block): void => {
        const { type, data } = block;
        if (!isTextBasedBlockType(type)) return; // ignore non-text blocks

        if (type === 'list')
            text += getNestedListContent(data.items);
        if (type === 'code') {
            text += cleanEncodedString(data.code);
            text += '\n';
        }
        if (type === 'warning') {
            text += cleanEncodedString(data.title + '\n' + data.message);
            text += '\n';
        } else {
            text += cleanEncodedString(block.data.text);
            text += '\n';
        }

        text = stripHtml(text);
    });

    return text;
}

function getNestedListContent(items: ListItem[] | string[], level: number = 0): string {
    let result = '';
    const prefix = level > 0 ? '\n' + '  '.repeat(level) + '- ' : '- ';

    items.forEach(item => {
        const content = item.content !== undefined ? item.content : item;
        result += `${prefix}${cleanEncodedString(content)}\n`;
        if (item.items && item.items.length > 0)
            result += getNestedListContent(item.items, level + 1);

    });

    return result;
}

export function getEmbedBlocks(blocks: OutputBlockData[]): OutputBlockData[] {
    return blocks.filter((block, i) => block.type === 'embed');
}

export function getVideoBlocks(blocks: OutputBlockData[]): OutputBlockData[] {
    return blocks.filter((block, i) => block.type === 'video' && block.data.url);
}

export function getAudioBlocks(blocks: OutputBlockData[]): OutputBlockData[] {
    return blocks.filter((block, i) => block.type === 'audio');
}

export function getLinkBlocks(blocks: OutputBlockData[]): OutputBlockData[] {
    return blocks.filter((block, i) => block.type === 'linkTool');
}

export function getFirstMediaBlock(blocks: OutputBlockData[]): OutputBlockData | undefined {
    return blocks.find((block, i) => !isTextBasedBlockType(block.type) && !isStyleBasedBlockType(block.type));
}

export function getVideoThumbnailUrl(videoBlock: OutputBlockData): string {
    if (!videoBlock.data.image) return '';
    return videoBlock.data.image.url;
}