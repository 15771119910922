import React, { FC, Fragment, ReactNode, useContext, useState } from 'react';
import { noop } from '@src/utils/utils';
import Text from '@creator/ui/components/Text/Text';
import { useTranslation } from 'react-i18next';
import MenuPopover from '@src/basic-components/MenuPopover/MenuPopover';
import Icon from '@src/basic-components/Icon/Icon';
import MenuItem from '@src/basic-components/MenuItem/MenuItem';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import { isUserJoinedToToken, getUserId, getMyUser } from '@src/model/user/helpers';
import { useHistory } from 'react-router-dom';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import ReportModal from '@src/components/ReportModal/ReportModal';
import ReactionsList from '../Reactions/ReactionsList/ReactionsList';
import ModActivityModal from '@src/components/ModActivityModal/ModActivityModal';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { canDeleteSuperComment, canEditSuperComment } from '@src/model/super-comment/permission';
import IconButton from '@src/basic-components/IconButton/IconButton';
import IconAndLabel from '@src/basic-components/IconAndLabel/IconAndLabel';

export interface SuperCommentMenuPopoverProps {
    className?: string;
    superCommentId: string;
    tokenName: string;
    onEditClick?: () => void;
}

const SuperCommentMenuPopover: FC<SuperCommentMenuPopoverProps> = props => {
    const { superCommentId, tokenName, className } = props;

    const { t } = useTranslation();
    const history = useHistory();

    const [isShareMenuActive, setIsShareMenuActive] = useState(false);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const superComment = useStoreState(state => state.superComment.getSuperComment(superCommentId), storeEqualityFn);
    const token = useStoreState(state => state.token.getToken(tokenName));
    const deleteSuperComment = useStoreActions(actions => actions.superComment.deleteSuperComment);

    const { handleModal } = useContext<UseModal>(ModalContext);

    function onOpenReportClick(): void {
        setIsMenuVisible(false);
        const options = [t('reportOptions.content.obscene'), t('reportOptions.content.violates'), t('reportOptions.content.suspected'), t('reportOptions.content.spam')];
        handleModal(<ReportModal type="SuperComment" commentId={superCommentId} tokenName={tokenName} options={options} />, { title: t('report'), size: 'sm' });
    }

    function mapError(error): void {
        if (error.message.includes('Bad nonce')) return alert(t('badNonceErrorMessage'));
        if (error.message.includes('banned')) return alert(t('errors.bannedFromCommunity'));

        alert(t('generalErrorMessage') + ' ' + error.message);
    }

    function onHideSuperCommentSelectReason(): void {
        // No need to select a reason if moderator is the publisher
        if (superComment?.publisherName === getMyUser()?.displayName) {
            onDelete(true, '', '');
            return;
        }
        const options = [t('modActivities.hideSuperComment.spam'), t('modActivities.hideSuperComment.hateful'), t('modActivities.hideSuperComment.nudity')];
        handleModal(<ModActivityModal options={options} onSendReason={onHideSuperComment} subTitle={t('modActivities.modalTitle', { action: t('hide'), entity: t('superComment') })} />, { title: t('modActivities.actions.hideSuperComment'), size: 'sm' });
    }

    function onHideSuperComment(reason?: string, reasonText?: string): Promise<void> {
        return onDelete(true, reason, reasonText);
    }

    async function onDelete(isDelete: boolean, reason?: string, reasonText?: string): Promise<void> {
        if (!superComment) return;

        setIsDeleting(true);

        try {
            await deleteSuperComment({
                hidden: isDelete,
                commentId: superCommentId,
                tokenName: superComment.tokenName,
                userId: getUserId() ?? '',
                reason: reason ? reason : '',
                reasonText: reasonText ? reasonText : '',
                isSuperComment: true
            });
            setIsMenuVisible(false);
            setIsDeleting(false);
        } catch (error) {
            setIsMenuVisible(false);
            setIsDeleting(false);
            mapError(error);
        }
    }

    function onEditClick(): void {
        const { onEditClick = noop } = props;
        setIsMenuVisible(false);
        onEditClick();
    }

    function onMenuPopoverTriggerClick(e: React.MouseEvent<HTMLElement>): void {
        setIsMenuVisible(!isMenuVisible);
    }

    function renderMenuPopoverTrigger() {
        return (
            <IconAndLabel
                iconName="dotsVertical"
                onClick={onMenuPopoverTriggerClick} />
        );
    }

    function renderEditMenuItem() {
        if (!superComment) return null;
        if (!canEditSuperComment(superCommentId) || !isUserJoinedToToken(tokenName)) return null;

        return (
            <MenuItem onClick={onEditClick}>
                <Icon svgFill={false} name="edit" className="mr-2 text-xl" />
                <Text>{t('superCommentMenuPopover.edit')}</Text>
            </MenuItem>
        );
    }

    function renderHideMenuItem() {
        if (!superComment || !token) return null;

        if (!canDeleteSuperComment(superCommentId, tokenName) || !isUserJoinedToToken(tokenName)) return null;

        if (superComment.status === 0) {
            return (
                <MenuItem onClick={() => onHideSuperCommentSelectReason()} isLoading={isDeleting}>
                    <Icon svgFill={false} name="hide" className="mr-2 text-xl" />
                    <Text>{t('superCommentMenuPopover.hide')}</Text>
                </MenuItem>
            );
        }

        return (
            <MenuItem onClick={() => onDelete(false)} isLoading={isDeleting}>
                <Icon svgFill={false} name="show" className="mr-2 text-xl" />
                <Text>{t('superCommentMenuPopover.unhide')}</Text>
            </MenuItem>
        );
    }

    function renderReportMenuItem() {
        if (!isLoggedIn) return null;
        return (
            <MenuItem onClick={() => onOpenReportClick()} isLoading={false} >
                <Icon name="error" className="mr-2 text-lg" />
                <Text>{t('report')}</Text>
            </MenuItem>
        );
    }

    function renderReactiosModalMenuItems() {
        return (
            <MenuItem onClick={() => {
                handleModal(<ReactionsList tokenName={tokenName} superCommentId={superCommentId} />, { size: 'sm' });
                setIsMenuVisible(false);
            }}>
                <Icon svgFill={false} name="thumbUp" className="mr-2 text-xl" />
                <Text>{t('showReactors')}</Text>
            </MenuItem>
        );
    }

    function renderMenuItems() {
        if (isShareMenuActive || !isMenuVisible) return null;

        return (
            <Fragment>
                {renderEditMenuItem()}
                {renderHideMenuItem()}
                {renderReactiosModalMenuItems()}
                {renderReportMenuItem()}
            </Fragment>
        );
    }

    return (
        <MenuPopover onClickOutside={() => setIsMenuVisible(false)} className={className} renderTrigger={renderMenuPopoverTrigger} popoverProps={{ isVisible: isMenuVisible }} >
            {renderMenuItems()}
        </MenuPopover>
    );

};

export default SuperCommentMenuPopover;
