import store from '@src/store';


export function didIVote(postId: string): boolean {
    const myVote = store.getState().voting.getMyVote(postId);
    return Boolean(myVote || myVote === 0);
}

export function getMyVote(postId: string): number | null {
    const myVote = store.getState().voting.getMyVote(postId);
    return myVote ? myVote : null;
}
