import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import { useHistory } from 'react-router-dom';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import Text from '@creator/ui/components/Text/Text';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import { cn } from '@creator/ui/utils/ui';
import { SuperComment } from '@creator/sdk/modules/super-comment/super-comment.model';
import SuperCommentListItem from './SuperCommentListItem';
import { PaginationLastDoc } from '@creator/sdk/modules/db/db.model';
import Button from '@creator/ui/components/Button/ButtonV2';

export interface SuperCommentsProps {
    className?: string;
    postId: string;
    tokenName: string;
}

const SuperComments: FC<SuperCommentsProps> = props => {
    const { tokenName, postId, className } = props;

    const { t } = useTranslation();
    const history = useHistory();

    const token = useStoreState(state => state.token.getToken(tokenName), storeEqualityFn);
    const post = useStoreState(state => state.upvote.getPost(postId), storeEqualityFn);

    const [isLoadingSuperComments, setIsLoadingSuperComments] = useState(true);
    const [isLoadingMoreSuperComments, setIsLoadingMoreSuperComments] = useState(false);
    const [hasMoreSuperComments, setHasMoreSuperComments] = useState(false);

    const loadSuperComments = useStoreActions(actions => actions.superComment.loadSuperComments);
    const clearSuperCommentSearchResult = useStoreActions(actions => actions.superComment.clearSuperCommentSearchResult);
    const supercomments = useStoreState(state => state.superComment.getSuperCommentSearchResult(postId), storeEqualityFn);
    const supercommentSearchResultLastDoc = useStoreState(state => state.superComment.superCommentSearchResultLastDoc, storeEqualityFn);

    useEffect(() => {
        _loadSuperComments();

        return () => {
            clearSuperCommentSearchResult(postId);
        };
    }, [postId]);

    async function _loadSuperComments(startAfter?: PaginationLastDoc<SuperComment>): Promise<void> {
        try {
            startAfter ? setIsLoadingMoreSuperComments(true) : setIsLoadingSuperComments(true);
            const res = await loadSuperComments({
                filterBy: [{ field: 'postId', opStr: '==', value: postId }],
                limit: 30,
                orderBy: [{ field: 'tipAmount', direction: 'desc' }],
                startAfter
            });

            setHasMoreSuperComments(res.hasMore);
        }
        catch (e: any) {
            console.error(e);
        }
        finally {
            startAfter ? setIsLoadingMoreSuperComments(false) : setIsLoadingSuperComments(false);
        }
    }

    const onLoadMore = useCallback(async () => {
        if (isLoadingSuperComments || isLoadingMoreSuperComments) return;

        _loadSuperComments(supercommentSearchResultLastDoc[postId]);
    }, [isLoadingSuperComments, isLoadingMoreSuperComments]);

    function renderSuperComment(supercomment: SuperComment) {
        const { commentId } = supercomment;
        return (
            <SuperCommentListItem className="" key={commentId} superCommentId={commentId} />
        );
    }

    function renderSuperComments() {
        return (
            <div className="space-y-4">
                {supercomments.map(renderSuperComment)}
                {renderLoadMoreButton()}
            </div>
        );
    }

    function renderLoadMoreButton() {
        if (!hasMoreSuperComments) return;

        return (
            <div onClick={onLoadMore} className="cursor-pointer" >
                <Button className="flex mx-auto" isLoading={isLoadingMoreSuperComments}>
                    <Text className="">{t('superComments.loadMore')}</Text>
                </Button>
            </div>
        );
    }

    function renderLoading() {
        if (!isLoadingSuperComments) return;
        return <Spinner className="mx-auto my-4 " />;
    }

    return (
        <div className={cn('', className)}>
            {/* <div className="mb-4 space-x-1 text-gray-500">
                <Text className="text-lg font-bold">{t('superComments.title')}</Text>
            </div> */}
            {renderLoading()}
            {renderSuperComments()}
        </div>
    );
};

export default memo(SuperComments);
