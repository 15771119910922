
import AppCheckModule from '../app-check';
import DbModule from '../db';
import { DomainPublicDbTableRow } from './domain.model';

export default class DomainService {
    //     // private contractName: string;
    // private domainName: string;
    private dbModule: DbModule;
    // private appCheckModule: AppCheckModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule) {
        // 
        // this.contractName = contractName;
        // this.domainName = domainName;
        this.dbModule = dbModule;
    }

    async getPublicData(id: string): Promise<DomainPublicDbTableRow> {
        return this.dbModule.getDocument<DomainPublicDbTableRow>('DomainPublic', id);
    }
}