import React, { FC } from 'react';
import { useHistory } from 'react-router';
import Icon from '../Icon/Icon';
import Text from '@creator/ui/components/Text/Text';

export interface BackLinkProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    label?: string;
    to?: string;
}

const BackLink: FC<BackLinkProps> = props => {
    const { label, to = '', className  } = props;

    const history = useHistory();


    function onClick(e: React.MouseEvent<HTMLElement>) {
        const { onClick } = props;

        if (onClick) onClick(e);
        if (to) return history.push(to);
    }

    return (
        <div onClick={onClick} className={`cursor-pointer flex items-center ${className}`} >
            <div className="flex items-center justify-center w-6 h-6 text-sm transition bg-gray-900 rounded-full shadow text-gray-50 dark:text-gray-900 dark:bg-gray-50 dark:shadow">
                <Icon svgFill={false} name="arrowLeft" />
            </div>
            <Text className="ml-2 text-sm font-bold leading-none text-gray-900 link dark:text-gray-50">{label}</Text>
        </div>
    );
};

export default BackLink;
