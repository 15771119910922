import React from 'react';
import { cn } from '../../utils/ui';

interface CardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
}

const CardHeader: React.FC<CardHeaderProps> = (props) => {
    const { className, children, ...rest } = props;

    return (
        <div className={cn('px-3 lg:px-5 py-2 bg-gray-50 dark:bg-gray-800', className)} {...rest} >
            {children}
        </div>
    );
};

export default CardHeader;