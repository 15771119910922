import store from '@src/store';
import { ensureHttpProtocol, getPathAndSearchFromUrl, getUrlHostname } from '@src/utils/url-utils/url-utils';
import { ExternalArticleDomainPathOverrides } from '@creator/sdk/modules/public-settings/public-settings.model';
import { ensureTokenName } from '../config/helpers';

export function getDomainNameBySymbol(domainSymbol: string): string {
    const publicSettings = store.getState().publicSettings;
    if (!publicSettings || !publicSettings.domainsSymbols || !publicSettings.domainsSymbols.symbolDomain) return '';

    return publicSettings.domainsSymbols.symbolDomain[domainSymbol];
}

function matchExternalArticlePathOverride(articleUrl: string, pathOverrides: ExternalArticleDomainPathOverrides[]): string | undefined {
    let matchedString: string | undefined;

    const pathFromArticleUrl = getPathAndSearchFromUrl(ensureHttpProtocol(articleUrl));

    for (const override of pathOverrides) {
        const { path, tokenName } = override;

        // Check from explicit path overrides first
        if (pathFromArticleUrl.startsWith(path)) {
            matchedString = tokenName;
            continue;
        }

        if (!path.includes('{tokenName}')) continue;

        // then search for param based overrides
        const regexPattern = path.replace('{tokenName}', '([^/]+)');
        const match = articleUrl.match(regexPattern);
        if (match && match[1]) {
            matchedString = match[1];
            continue;
        }
    }

    return matchedString;
}

export function getTokenNameFromExternalArticleUrl(articleUrl: string) {
    const publicSettings = store.getState().publicSettings;
    const domain = getUrlHostname(articleUrl);

    if (!publicSettings || !publicSettings.externalArticleDomain || !publicSettings.externalArticleDomain[domain]) return;

    const externalArticleDomain = publicSettings.externalArticleDomain[domain];
    const { tokenName, pathOverrides } = externalArticleDomain;

    if (pathOverrides) {
        const overrideTokenName = matchExternalArticlePathOverride(articleUrl, pathOverrides);
        if (overrideTokenName) return ensureTokenName(overrideTokenName);
    }

    return ensureTokenName(tokenName);
}

export function getDomainFromExternalArticleUrl(articleUrl: string) {
    const publicSettings = store.getState().publicSettings;
    const domain = getUrlHostname(articleUrl);

    if (!publicSettings || !publicSettings.externalArticleDomain || !publicSettings.externalArticleDomain[domain]) return;
    return publicSettings.externalArticleDomain[domain].domain;
}
