import React, { FC } from 'react';
import { noop } from '@src/utils/utils';
import Text from '@creator/ui/components/Text/Text';
import { cleanHtml } from '@src/utils/html-utils/html-utils';
import { useTranslation } from 'react-i18next';
import { ParagraphData } from '@editorjs/paragraph';

export interface EditorViewOnlyParagraphToolProps {
    className?: string;
    blockData: ParagraphData;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const EditorViewOnlyParagraphTool: FC<EditorViewOnlyParagraphToolProps> = props => {
    const { onClick = noop, blockData, className } = props;

    const { t } = useTranslation();
    function getDescription(): string {
        const { meta } = blockData;
        if (!meta || !meta.description) return '';

        if (meta.description === 'AI generated media summary') return t('post.autoMediaTranscriptionLabel');
        return meta.description;
    }

    function renderDescription() {
        const description = getDescription();
        if (!description) return;

        return <Text className="text-xs text-gray-500">{description}</Text>;
    }

    function renderContent() {
        const { text } = blockData;

        return (
            <div className="ce-paragraph cdx-block">
                <Text enableMultiline={true}>
                    <p dangerouslySetInnerHTML={{ __html: cleanHtml(text) }}></p>
                </Text>
                {renderDescription()}
            </div>
        );
    }

    return (
        <div className={`paragraph-tool ${className}`} onClick={onClick}>
            {renderContent()}
        </div>
    );
};

export default EditorViewOnlyParagraphTool;
