import React from 'react';
import { getYahooAdLocatioByTokenName, getYahooAdTargetingByTokenName, getYahooAdByLocation } from '@src/model/config/helpers';
import GoogleAdSlot from '@src/components/GoogleAdSlot/GoogleAdSlot';

export interface MobileMiddleCenterAdProps {
    tokenName: string;
}

function MobileMiddleCenterAd({ tokenName }: MobileMiddleCenterAdProps): JSX.Element | null {
    const location = getYahooAdLocatioByTokenName(tokenName, 'mid_center');
    if (!location) return null;

    const yahooAd = getYahooAdByLocation(location);
    if (!yahooAd) return null;

    const { adUnitPath, divId } = yahooAd;

    return (
        <GoogleAdSlot
            targeting={getYahooAdTargetingByTokenName(tokenName)}
            className="mx-auto"
            adUnitPath={adUnitPath}
            sizes={[320, 50]}
            divId={divId}
        />
    );
}

export default MobileMiddleCenterAd;
