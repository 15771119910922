import React, { FC, useContext, useEffect, useState } from 'react';
import Text from '@creator/ui/components/Text/Text';
import { isUserJoinedToToken, getUserId } from '@src/model/user/helpers';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import ConfirmModal from '@src/basic-components/ConfirmModal/ConfirmModal';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import { getUrlPath } from '@src/utils/url-utils/url-utils';
import { useHistory } from 'react-router-dom';
import { noop } from '@src/utils/utils';

export interface ConnectAccountButtonProps {
    tokenName: string;
    onCancel?: () => void;
}

const JoinButtonModal: FC<ConnectAccountButtonProps> = props => {
    const { tokenName, onCancel = noop } = props;
    const isJoined = isUserJoinedToToken(tokenName);
    const history = useHistory();
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const [isJoiningCommunity, setIsJoiningCommunity] = useState(false);
    const joinCommunity = useStoreActions(actions => actions.user.joinCommunity);
    const { t } = useTranslation();
    const { handleModal } = useContext<UseModal>(ModalContext);

    useEffect(() => {
        if (isJoined)
            handleModal(null);
    }, [isJoined]);

    function mapError(error): void {
        if (error.message.includes('banned')) return alert(t('errors.bannedFromCommunity'));
        alert(t('generalErrorMessage') + ' ' + error.message);
    }

    async function onClick(): Promise<void> {
        if (!isLoggedIn) {
            history.push(`/bbs/login?next=${getUrlPath()}`);
            handleModal(null);
            return;
        }

        setIsJoiningCommunity(true);
        try {
            const response = await joinCommunity({
                isJoin: !isJoined,
                userId: getUserId() ?? '',
                tokenName
            });
            setIsJoiningCommunity(false);

        } catch (error) {
            mapError(error);
            setIsJoiningCommunity(false);
            console.error(error);
        }

        setIsJoiningCommunity(false);
    }

    function renderDetails() {

        return (
            <Text className="flex-1 font-bold">{t('joinToken.text')}</Text>
        );
    }

    return (
        <ConfirmModal cancelButtonProps={{ onClick: onCancel }} okButtonProps={{ onClick: onClick, isLoading: isJoiningCommunity }} title={t('joinToken.title')} >
            <div className="flex flex-col items-center justify-center w-full mt-8 space-y-2">
                {renderDetails()}
            </div>
        </ConfirmModal>
    );
};

export default JoinButtonModal;
