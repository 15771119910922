// src/components/PostView/PostModScore.tsx
import React, { memo } from 'react';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

interface PostModScoreProps {
    moderationContent: string;
    moderationScore: number;
}

const PostModScore: React.FC<PostModScoreProps> = props => {
    const { moderationContent, moderationScore } = props;
    const score = moderationScore;
    let message = '';
    let styleClass = '';

    if (score === 0) {
        return null;
    } else if (score > 0 && score <= 50) {
        message = `Moderation Score: ${score}%`;
        styleClass = 'text-yellow-500';
    } else {
        message = `Moderation Score: ${score}%`;
        styleClass = 'text-red-500';
    }

    return (
        <div className="mt-1 text-xs">
            <span className={styleClass}>{message}</span>
            <span className="block text-xs text-gray-500">{moderationContent}</span>
        </div>
    );
};

export default memo(PostModScore, propsEqualityFn);
