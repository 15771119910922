import React, { FC, memo, ReactNode, useRef } from 'react';
import { noop } from '@src/utils/utils';
import Icon, { IconType } from '../Icon/Icon';
import Popover, { PopoverProps } from '../Popover/Popover';
import { useOnClickOutside } from '@src/hooks/use-on-click-outside';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

export interface MenuPopoverProps {
    className?: string;
    triggerIconName?: IconType;
    popoverProps: Omit<PopoverProps, 'trigger'>;
    renderTrigger?: () => ReactNode;
    onClickOutside?: () => void;
    onTriggerClick?: () => void;
    children?: ReactNode;
}

const MenuPopover: FC<MenuPopoverProps> = props => {
    const { children, className, onClickOutside = noop } = props;

    const ref = useRef();
    useOnClickOutside(ref, onClickOutside);

    function getTriggerClassName(): string {
        const base = 'transition h-6 w-6 flex justify-center items-center rounded cursor-pointer bg-gray-100 dark:bg-gray-800 text-gray-500 text-md';
        return `${base} `;
    }

    function renderPopover() {
        const { popoverProps } = props;
        const _props = mergeProps<[PopoverProps, Partial<PopoverProps>]>({
            trigger: renderTrigger()
        }, popoverProps);

        return (
            <Popover {..._props}>
                {children}
            </Popover>
        );
    }

    function renderTrigger() {
        const { onTriggerClick = noop, renderTrigger, triggerIconName = 'dotsVertical' } = props;
        if (renderTrigger) return renderTrigger();

        return (
            <div className={getTriggerClassName()} onClick={onTriggerClick}>
                <Icon name={triggerIconName} />
            </div>
        );
    }

    return (
        <div ref={ref} className={`${className}`}>
            {renderPopover()}
        </div>
    );
};

export default memo(MenuPopover, propsEqualityFn);
