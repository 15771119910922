import React, { FC, Fragment } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import UserDisplayName, { UserDisplayNameProps } from '@creator/ui/components/UserDisplayName/UserDisplayName';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';

export interface UserDisplayNameWithLinkProps {
    className?: string;
    tokenName: string;
    displayName: string;
    slugUrl?: string;
    profileName?: string;
    userDisplayNameProps?: Omit<UserDisplayNameProps, 'displayName'>;
    linkProps?: Partial<LinkProps>;
}

const UserDisplayNameWithLink: FC<UserDisplayNameWithLinkProps> = props => {
    const { userDisplayNameProps = {}, tokenName, displayName, profileName, slugUrl } = props;

    function renderContent() {
        const { linkProps = {} } = props;

        if (displayName === 'anonymous') return renderUserDisplayName();

        const _linkProps = mergeProps({
            to: `/${ensureTokenUrl(tokenName)}/author/${slugUrl || displayName}`,
            onClick: e => {
                e.stopPropagation();
            }
        }, linkProps);

        return (
            <Link {..._linkProps}>
                {renderUserDisplayName()}
            </Link>
        );
    }

    function renderUserDisplayName() {
        const _props = mergeProps<[UserDisplayNameProps, Partial<UserDisplayNameProps>]>({
            displayName: profileName || displayName
        }, userDisplayNameProps);
        return (
            <UserDisplayName {..._props}></UserDisplayName>
        );
    }

    return <Fragment>{renderContent()}</Fragment>;
};

export default UserDisplayNameWithLink;
