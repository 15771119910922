import React, { FC, Fragment, ReactNode, useRef, useEffect, useState } from 'react';
import { noop } from '@src/utils/utils';
import EditorViewOnlyEmbedTool from '../EmbedTool/EmbedTool';
import EditorViewOnlyLinkTool from '../LinkTool/LinkTool';
import ImageGridPostPreview, { ImageGridPostPreviewProps } from '@src/basic-components/ImageGridPostPreview/ImageGridPostPreview';
import Text from '@creator/ui/components/Text/Text';
import { OutputBlockData } from '@editorjs/editorjs';
import EditorViewOnlyVideoTool, { EditorViewOnlyVideoToolProps } from '../VideoTool/VideoTool';
import { useTranslation } from 'react-i18next';
import EditorViewOnlyAudioTool, { EditorViewOnlyAudioToolProps } from '../AudioTool/AudioTool';
import { getAudioBlocks, getEmbedBlocks, getLinkBlocks, getVideoBlocks, summarizeBlocksContent } from '@src/utils/editorjs-utils/editorjs-utils';
import { cleanHtml } from '@src/utils/html-utils/html-utils';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';

export interface EditorViewOnlySummarizedViewProps {
    className?: string;
    data: EditorJS.OutputData;
    renderMainText?: (summarizedText: string) => ReactNode;
    hideMainTextIfMediaExists?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    imagePreviewProps?: Partial<ImageGridPostPreviewProps>;
    editorViewOnlyVideoToolProps?: Partial<EditorViewOnlyVideoToolProps>;
    editorViewOnlyAudioToolProps?: Partial<EditorViewOnlyAudioToolProps>;
}

const EditorViewOnlySummarizedView: FC<EditorViewOnlySummarizedViewProps> = props => {
    const { editorViewOnlyAudioToolProps = {}, editorViewOnlyVideoToolProps = {}, imagePreviewProps = {}, onClick = noop, data, className } = props;
    const { t } = useTranslation();
    const textContainerRef = useRef<HTMLDivElement | null>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        if (textContainerRef.current) {
            const isOverflow = textContainerRef.current.scrollHeight > textContainerRef.current.clientHeight;
            setIsOverflowing(isOverflow);
        }
    }, [data]);

    function getImages(): string[] {
        const imageBlocks = data.blocks.filter((block, i) =>
            (block.type === 'image' || block.type === 'simpleImage') && block.data?.file?.url
        );
        return imageBlocks.map(block => cleanHtml(block.data.file.url));
    }

    function renderMainTextContent() {
        const { renderMainText } = props;
        const text = summarizeBlocksContent(data.blocks);

        if (!text) return null;
        if (renderMainText) return renderMainText(text);

        return (
            <Fragment>
                <div ref={textContainerRef} className="cursor-pointer text-container line-clamp-2" style={{ maxHeight: 'calc(2em * 2)', overflow: 'hidden' }}>
                    <Text enableMultiline={true}>{text}</Text>
                </div>
                {isOverflowing && <Text className="text-sm font-medium link">{t('seeMore')}</Text>}
            </Fragment>
        );
    }

    function renderMainLink() {
        // filter out twitter embeds
        const excludeEmbedServices = ['twitter'];
        const embedBlocks = getEmbedBlocks(data.blocks).filter(block => !excludeEmbedServices.includes(block.data.service));
        const linkBlocks = getLinkBlocks(data.blocks);
        const videoBlocks = getVideoBlocks(data.blocks);
        const audioBlocks = getAudioBlocks(data.blocks);

        if (audioBlocks.length) return renderAudio(audioBlocks[0]);
        if (videoBlocks.length) return renderVideo(videoBlocks[0]);
        if (embedBlocks.length) return renderEmbed(embedBlocks[0]);
        if (linkBlocks.length) return renderLink(linkBlocks[0]);

        return null; // Return null if no blocks are found
    }

    function renderVideo(block: OutputBlockData<string, any>) {
        const _props = mergeProps<[EditorViewOnlyVideoToolProps, Partial<EditorViewOnlyVideoToolProps>]>({
            showAsPreview: true,
            onClick: noop,
            blockData: block.data
        }, editorViewOnlyVideoToolProps);

        return <EditorViewOnlyVideoTool {..._props} />;
    }

    function renderAudio(block: OutputBlockData<string, any>) {
        const _props = mergeProps<[EditorViewOnlyAudioToolProps, Partial<EditorViewOnlyAudioToolProps>]>({
            showAsPreview: true,
            onClick: noop,
            blockData: block.data
        }, editorViewOnlyAudioToolProps);

        return <EditorViewOnlyAudioTool {..._props} />;
    }

    function renderEmbed(block: OutputBlockData<string, any>) {
        return <EditorViewOnlyEmbedTool showAsPreview={true} blockData={block.data} />;
    }

    function renderLink(block: OutputBlockData<string, any>) {
        return <EditorViewOnlyLinkTool onClick={noop} enablePreview={true} blockData={block.data} />;
    }

    function renderImageGrid() {
        const images = getImages();
        if (!images.length) return null;

        return <ImageGridPostPreview {...imagePreviewProps} images={images} />;
    }

    function renderBlocks() {
        const { hideMainTextIfMediaExists = false } = props;

        const imageGrid = renderImageGrid();
        const mainLink = renderMainLink();
        // Condition to decide whether to render mainText
        // Do not render mainText if hideMainTextIfMediaExists is true AND imageGrid exists AND mainLink exists
        const shouldRenderMainText = !(hideMainTextIfMediaExists && (imageGrid || mainLink));

        return (
            <div className="space-y-4">
                {shouldRenderMainText && renderMainTextContent()}
                {imageGrid}
                {!imageGrid && mainLink}
            </div>
        );
    }

    return (
        <div onClick={onClick} className={`cursor-pointer ${className}`}>
            {renderBlocks()}
        </div>
    );
};

export default EditorViewOnlySummarizedView;
