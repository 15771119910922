
import DbModule from '../db';
import AccountModule from '../account';
import { DocumentDataWithId, Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';
import { QueryDocumentSnapshot } from 'firebase/firestore/lite';
import FollowService, { FollowUserApiRequest, GetUserFollowersPayload } from './follow.service';
import { FollowDbTableRow, FollowersResponse } from './follow.model';

export default class FollowModule {
    accountModule: AccountModule;
    followService: FollowService;
    private dbModule: DbModule;
        private domainName: string;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        this.dbModule = dbModule;
        this.followService = new FollowService(contractName, domainName, appCheckModule, dbModule, accountModule);
        this.accountModule = accountModule;
        
        this.domainName = domainName;
        this.appCheckModule = appCheckModule;
    }

    async getUserFollowers(payload: GetUserFollowersPayload): Promise<FollowersResponse> {
        return this.followService.getUserFollowers(payload);
    }

    async followUser(payload: FollowUserApiRequest): Promise<string> {
        const res = await this.followService.followUser(payload);
        return res.payload;
    }
}