import React from 'react';
import useModal, { UseModal } from '@src/hooks/use-modal';
import { createContext } from 'react';
import Modal from '@src/basic-components/Modal/Modal';

const ModalContext = createContext<UseModal | null>(null);

const ModalProvider = ({ children }): JSX.Element => {
    const { modal, handleModal, modalContent, modalProps } = useModal();

    return (
        <ModalContext.Provider value={{ modal, handleModal, modalContent, modalProps }}>
            <Modal {...modalProps} />
            {children}
        </ModalContext.Provider>
    );
};

export { ModalContext, ModalProvider };
