
import DbModule from '../db';
import AppCheckModule from '../app-check';
import { OrderByDirection, WhereFilterOp } from 'firebase/firestore/lite';
import { post } from '../../network/api-service';
import { ApiResponse } from '../../creator-sdk';

export interface GetShortUrlOrderBy {
    field: 'timestamp' | 'index';
    direction: OrderByDirection;
}
export interface GetShortUrlFilterBy {
    field: 'tokenName'
    opStr: WhereFilterOp;
    value: string | number;
}

export default class ShortUrlService {
        private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule) {
        
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
    }

    async createShortUrl(originalUrl: string): Promise<ApiResponse<string>> {
        const res = await post<ApiResponse<any>>('/support/shortUrl', { url: originalUrl }, {
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return res.data;
    }
}