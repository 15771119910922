import React, { FC, useContext } from 'react';
import { noop } from '@src/utils/utils';
import { useTranslation } from 'react-i18next';
import ButtonV2, { ButtonProps } from '@creator/ui/components/Button/ButtonV2';
import Text from '@creator/ui/components/Text/Text';
import { useHistory } from 'react-router-dom';
import { getUrlPath } from '@src/utils/url-utils/url-utils';
import { ModalContext } from '@src/context/modal-context';
import LeaveTokenModal from '../LeaveTokenModal/LeaveTokenModal';
import { useJoinCommunity } from '@src/model/user/hooks/use-join-community';
import { useStoreState } from '@src/model/hooks';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';


export interface JoinButtonProps {
    className?: string;
    tokenName: string;
    buttonProps?: ButtonProps;
    isRedirectToCommunity?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const JoinButton: FC<JoinButtonProps> = (props: JoinButtonProps) => {
    const { className, tokenName, isRedirectToCommunity } = props;

    const { t } = useTranslation();
    const history = useHistory();
    const { handleModal } = useContext(ModalContext);

    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);

    const { isJoined, isProcessing, join } = useJoinCommunity({
        tokenName,
        isRedirectToCommunity,
        onError: mapError
    });

    function mapError(error: Error): void {
        if (error.message.includes('banned'))
            alert(t('errors.bannedFromCommunity'));
        else
            alert(`${t('generalErrorMessage')} ${error.message}`);

    }

    function handleClick(e: React.MouseEvent<HTMLElement>): void {
        const { onClick = noop } = props;

        onClick(e);

        if (!isLoggedIn)
            return history.push(`/bbs/login?next=${getUrlPath()}`);


        if (isJoined)
            return handleModal(<LeaveTokenModal tokenName={tokenName} />);


        join(true);
    }

    function getText(): string {
        if (isJoined)
            return t('joinButton.leave');

        return t('joinButton.join');
    }

    function getProps() {
        const { buttonProps = {} } = props;
        return mergeProps<[ButtonProps, ButtonProps]>(
            {
                className: `${className}`,
                color: 'primary',
                size: 'sm',
                variant: 'ghost',
                isLoading: isProcessing,
                onClick: handleClick
            },
            buttonProps
        );
    }

    return (
        <ButtonV2 {...getProps()}><Text>{getText()}</Text></ButtonV2>
    );
};

export default JoinButton;
