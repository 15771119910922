import DbModule from '../db';
import { RTBPrice } from './token-price.model';

export default class TokenPriceService {
    private dbModule: DbModule;
    constructor(dbModule: DbModule) {
        this.dbModule = dbModule;
    }

    async getRTBPrice(): Promise<RTBPrice> {
        return this.dbModule.getDocument('PublicSettings', 'RTBPrice');
    }
}
