import React, { FC, useContext, useState } from 'react';
import Text from '@creator/ui/components/Text/Text';
import { isUserJoinedToToken, getUserId } from '@src/model/user/helpers';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import ConfirmModal from '@src/basic-components/ConfirmModal/ConfirmModal';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import { getUrlPath } from '@src/utils/url-utils/url-utils';
import { useHistory } from 'react-router-dom';


export interface ConnectAccountButtonProps {
    tokenName: string;
}

const LeaveTokenModal: FC<ConnectAccountButtonProps> = props => {
    const { tokenName } = props;
    const isJoined = isUserJoinedToToken(tokenName);
    const history = useHistory();
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const [isJoiningCommunity, setIsJoiningCommunity] = useState(false);
    const joinCommunity = useStoreActions(actions => actions.user.joinCommunity);
    const { t } = useTranslation();
    const { handleModal } = useContext<UseModal>(ModalContext);

    function mapError(error): void {
        alert(t('generalErrorMessage') + ' ' + error.message);
    }

    async function onClick(): Promise<void> {
        if (!isLoggedIn) return history.push(`/bbs/login?next=${getUrlPath()}`);

        setIsJoiningCommunity(true);
        try {
            await joinCommunity({
                isJoin: !isJoined,
                userId: getUserId() ?? '',
                tokenName
            });
            setIsJoiningCommunity(false);

        } catch (error) {
            mapError(error);
            setIsJoiningCommunity(false);
            console.error(error);
        }

        setIsJoiningCommunity(false);
        handleModal(null);
    }

    function renderDetails() {

        return (
            <Text className="">{t('leaveTokenModal.text')}</Text>
        );
    }

    return (
        <ConfirmModal cancelButtonProps={{}} okButtonProps={{ onClick: onClick, isLoading: isJoiningCommunity }} title={t('leaveTokenModal.title')} >
            <div className="flex flex-col items-center justify-center w-full space-y-2">
                {renderDetails()}
            </div>
        </ConfirmModal>
    );
};

export default LeaveTokenModal;
