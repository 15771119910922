import { Computed, Action, Thunk, computed, thunk, action } from 'easy-peasy';
import { DocumentSnapshot } from '@creator/sdk/modules/db/db.model';
import { AttachFlairApiRequestPayload, CreateFlairApiRequestPayload, DeleteFlairApiRequestPayload } from '@creator/sdk/modules/flair/flair.service';
import { attachFlair, createFlair, deleteFlair } from './actions';

export interface LoadPostsCommentsRepliesPayload {
    lowerBound: DocumentSnapshot;
    limit: number;
    commentId: string;
    postId: string;
}

export interface FlairModel {
    createFlair: Thunk<FlairModel, CreateFlairApiRequestPayload>;
    deleteFlair: Thunk<FlairModel, DeleteFlairApiRequestPayload>;
    attachFlair: Thunk<FlairModel, AttachFlairApiRequestPayload>;
}

const flairModel: FlairModel = {
    createFlair: thunk(async (actions, payload) => {
        const flair = await createFlair(payload);

        return flair;
    }),

    deleteFlair: thunk(async (actions, payload) => {
        const flair = await deleteFlair(payload);

        return flair;
    }),

    attachFlair: thunk(async (actions, payload) => {
        const flair = await attachFlair(payload);

        return flair;
    })
};

export default flairModel;