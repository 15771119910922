import React, { Fragment } from 'react';
import { FC, PropsWithChildren } from 'react';
import Text from '@creator/ui/components/Text/Text';
import { useTranslation } from 'react-i18next';
import PostBasicInfo, { PostBasicInfoProps } from '../Upvote/PostBasicInfo/PostBasicInfo';
import { useHistory } from 'react-router-dom';
import MediaPreview, { MediaPreviewProps } from '@src/basic-components/Media/MediaPreview';
import Icon from '@src/basic-components/Icon/Icon';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { cn } from '@creator/ui/utils/ui';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { ensurePostUrl } from '@src/model/upvote/helpers';

export interface EmbeddedPostProps {
    className?: string;
    postId: string;
    title: string;
    tokenName: string;
    postTitle: string;
    postBasicInfoProps: PostBasicInfoProps;
    mediaPreviewProps: MediaPreviewProps;
    onClose?: () => void;
}

const EmbeddedPost: FC<PropsWithChildren<EmbeddedPostProps>> = props => {
    const { children, className = '', tokenName, postId, postTitle, onClose } = props;

    const { t } = useTranslation();
    const history = useHistory();

    function getBaseClassname(): string {
        return cn('p-4 border relative border-gray-300 rounded-lg dark:border-gray-700', className);
    }

    function onCloseButtonClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        e.preventDefault();
        e.stopPropagation();

        if (onClose)
            onClose();
    }

    function renderPostBasicInfo() {
        const { postBasicInfoProps } = props;

        const _props = mergeProps({
            className: 'mt-3 mb-3 text-xs',
            profileImageProps: { showBadge: false }
        }, postBasicInfoProps);

        return <PostBasicInfo {..._props} />;
    }

    function renderMediaPreview() {
        const { mediaPreviewProps } = props;
        if (!mediaPreviewProps) return;
        return <MediaPreview {...mediaPreviewProps} />;
    }

    function renderCloseButton() {
        if (!onClose) return;

        return (
            <div className="absolute top-0 right-0 p-1" onClick={onCloseButtonClick}>
                <Icon className="text-lg" name="close" />
            </div>
        );
    }

    function renderContent() {
        return (
            <div className={getBaseClassname()} onClick={() => history.push(`/${ensureTokenUrl(tokenName)}/${ensurePostUrl(postId)}`)}>
                {renderTitle()}
                {renderCloseButton()}
                <div className="flex space-x-2">
                    <div className="w-3/4">
                        {/* {renderTitle()} */}
                        {renderPostBasicInfo()}
                        {renderPostTitle()}
                    </div>
                    <div className="w-1/4">
                        {/* {renderPostContent()} */}
                        {renderMediaPreview()}
                    </div>
                </div>
                {children}
            </div>
        );
    }

    function renderTitle() {
        const { title = '' } = props;
        return <Text className="flex-1 font-bold">{title || t('embeddedPost.title')}</Text>;
    }

    function renderPostTitle() {
        return <Text enableMultiline={true} className="font-mediun link">{postTitle}</Text>;
    }

    return (
        <Fragment>
            {renderContent()}
        </Fragment>
    );
};

export default EmbeddedPost;
