import React, { FC } from 'react';
import { noop } from '@src/utils/utils';
import Text from '@creator/ui/components/Text/Text';
import { cleanHtmlToPlainText } from '@src/utils/html-utils/html-utils';
import { ListData, ListItem } from '@editorjs/list/dist/types/ListParams';

export interface EditorViewOnlyListToolProps {
    className?: string;
    blockData: ListData;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const EditorViewOnlyListTool: FC<EditorViewOnlyListToolProps> = props => {
    const { onClick = noop, blockData, className } = props;

    function renderListItems(items: ListItem[] | string[]) {
        return items.map((item, i) =>
            <li key={i} className="cdx-list__item">
                <Text className="leading-normal" enableMultiline={true}>{cleanHtmlToPlainText(item.content || item)}</Text>
                {item.items && item.items.length > 0 && renderNestedList(item.items)}
            </li>
        );
    }

    function renderNestedList(items: ListItem[]) {
        const listType = blockData.style === 'unordered' ? 'ul' : 'ol';
        const ListTag = listType as keyof JSX.IntrinsicElements;

        return (
            <ListTag className={blockData.style === 'unordered' ? 'cdx-list--unordered' : 'cdx-list--ordered'}>
                {renderListItems(items)}
            </ListTag>
        );
    }

    function renderContent() {
        const { items } = blockData;
        return (
            <Text enableMultiline={true} className="block ce-paragraph cdx-block cdx-list">
                {renderNestedList(items)}
            </Text>
        );
    }

    return (
        <div className={`list-tool ${className}`} onClick={onClick}>
            {renderContent()}
        </div>
    );
};

export default EditorViewOnlyListTool;