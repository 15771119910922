import { useState, useEffect } from 'react';

function useYahooAds() {
    const [isLoaded, setIsLoaded] = useState(isReady());
    const [error, setError] = useState<Error | null>(null);

    function isReady() {
        if (!window.googletag) return false;
        return window.googletag.apiReady
    }

    const initYahooAds = async () => {
        // Check if GPT is already initialized or the script exists in the DOM
        if (isReady()) return; 
        
        const script = document.createElement('script');
        script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
        script.async = true;
        script.id = 'gpt-script';
        
        script.onload = () => {
            window.googletag = window.googletag || { cmd: [] };
            window.googletag.cmd.push(() => {
                window.googletag.pubads().disableInitialLoad();
                window.googletag.pubads().enableSingleRequest();
                window.googletag.enableServices();
            });
            setIsLoaded(true);
        };

        script.onerror = () => {
            console.error('Failed to load the GPT script.');
            setError(new Error('Failed to load the GPT script.'));
        };

        document.head.appendChild(script);
    };

    useEffect(() => {
        initYahooAds();
    }, []);

    return { isLoaded, error };
}

export default useYahooAds;
