import React, { FC, useContext, useState } from 'react';
import PollOptions from '../PollOptions/PollOptions';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import { isUserJoinedToToken, getUserId } from '@src/model/user/helpers';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import JoinButtonModal from '@src/components/JoinButtonModal/JoinButtonModal';
import { useHistory } from 'react-router-dom';
import { getUrlPath } from '@src/utils/url-utils/url-utils';
import { ensurePostUrl, isPostDeleted } from '@src/model/upvote/helpers';
import { ensureTokenUrl } from '@src/model/config/helpers';

export interface PollOption {
    label: string;
    value: string;
    isLoading: boolean;
}

export interface PollProps {
    className?: string;
    tokenName: string;
    postId: string;
    isPin?: boolean;
}

const Poll: FC<PollProps> = props => {
    const { postId, tokenName, className, isPin = false } = props;

    const vote = useStoreActions(actions => actions.voting.vote);

    const [whatVoting, setWhatVoting] = useState<number>();
    const { handleModal } = useContext<UseModal>(ModalContext);
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const history = useHistory();

    async function onOptionClick(i: number): Promise<void> {
        if (!isLoggedIn) return history.push(`/bbs/login?next=${getUrlPath()}`);
        if (isPin) return history.push(`/${ensureTokenUrl(tokenName)}/${ensurePostUrl(postId)}`);
        if (!isUserJoinedToToken(tokenName))
            return handleModal(<JoinButtonModal tokenName={tokenName} />);
        if (isPostDeleted(postId)) return;

        if (whatVoting) return;

        setWhatVoting(i);

        try {
            await vote({
                userId: getUserId() || '',
                postId: postId || '',
                selectedOptionIndex: i
            });

            setWhatVoting(undefined);
        } catch (error) {
            setWhatVoting(undefined);
        }

    }

    return (
        <div className={className}>
            <PollOptions isVoting={whatVoting} tokenName={tokenName} onOptionClick={onOptionClick} postId={postId} isPin={isPin} />
        </div>
    );
};

export default Poll;
