import React, { FC } from 'react';
import { noop } from '@src/utils/utils';
import Text from '@creator/ui/components/Text/Text';
import { useTranslation } from 'react-i18next';
import { QuoteData } from '@editorjs/quote';
import Icon from '@src/basic-components/Icon/Icon';
import { cleanHtml } from '@src/utils/html-utils/html-utils';

export interface EditorViewOnlyQuoteToolProps {
    className?: string;
    blockData: QuoteData;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const EditorViewOnlyQuoteTool: FC<EditorViewOnlyQuoteToolProps> = props => {
    const { onClick = noop, blockData, className } = props;

    const { t } = useTranslation();

    function renderCaption() {
        const caption = blockData.caption;
        if (!caption) return;

        return <Text className="text-sm text-gray-500">{caption}</Text>;
    }

    function renderContent() {
        const { text } = blockData;

        return (
            <div className="p-4 bg-gray-100 border-l-4 border-gray-500 rounded-lg dark:bg-gray-700 ce-quote cdx-block">
                <Icon svgFill={true} name="quote" className="text-2xl text-gray-500" />
                <Text enableMultiline={true}>
                    <p
                        className="dark:text-gray-300"
                        dangerouslySetInnerHTML={{ __html: cleanHtml(text) }}
                    ></p>
                </Text>
            </div>
        );
    }
    return (
        <div className={`quote-tool ${className}`} onClick={onClick}>
            {renderContent()}
            {renderCaption()}
        </div>
    );
};

export default EditorViewOnlyQuoteTool;