import React, { FC, useState, useEffect, memo } from 'react';
import { useStoreState, useStoreActions } from '@src/model/hooks';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import PinnedPosts from '@src/components/Post/PostList/PinnedPosts/PinnedPosts';
import { GetPostsFilterBy, GetPostsOrderBy } from '@creator/sdk/modules/upvote/upvote.service';
import useDidUpdateEffect from '@src/hooks/use-did-update-effect';

interface PostsTabPinnedPostsProps {
    tokenName: string;
    className?: string;
    onPostClick?: (e: React.MouseEvent<HTMLElement>, postId: string) => void;
}

const PostsTabPinnedPosts: FC<PostsTabPinnedPostsProps> = props => {
    const { tokenName, onPostClick = () => { }, className = '' } = props;

    // Fetching state and actions from store
    const pinnedPostsSearchResult = useStoreState(state => state.upvote.getPinnedPostSearchResult(tokenName), storeEqualityFn);
    const loadPosts = useStoreActions(actions => actions.upvote.loadPosts);
    const setPinnedPostSearchResult = useStoreActions(actions => actions.upvote.setPinnedPostSearchResult);

    const [isLoadingPinnedPosts, setIsLoadingPinnedPosts] = useState(false);

    useDidUpdateEffect(() => {
        if (!pinnedPostsSearchResult || pinnedPostsSearchResult.length > 0) return;

        loadPinnedPosts();
    }, [tokenName]);

    // Load pinned posts on component mount
    useEffect(() => {
        if (!tokenName || !pinnedPostsSearchResult || pinnedPostsSearchResult.length > 0) return;
        loadPinnedPosts();
    }, [tokenName]);

    // Clear pinned posts search result
    function clearSearchResult() {
        setPinnedPostSearchResult({ searchResult: [], tokenName });
    }

    // Fetch pinned posts
    function loadPinnedPosts() {
        setIsLoadingPinnedPosts(true);
        clearSearchResult();  // Clear existing results before loading new ones

        const filterBy: GetPostsFilterBy[] = [{ field: 'isPinned', opStr: '==', value: true }, { field: 'status', 'opStr': '==', value: 0 }];
        const orderBy: GetPostsOrderBy[] = [{ field: 'pinnedTime', direction: 'desc' }];

        loadPosts({
            filterBy,
            orderBy,
            limit: 20,
            lowerBound: null,
            tokenName,
            saveTo: 'pinnedPosts'
        }).finally(() => setIsLoadingPinnedPosts(false));
    }


    function renderPinnedPosts() {
        if (isLoadingPinnedPosts) return <Spinner className="mx-auto my-10" />;

        return (
            <PinnedPosts onPostClick={onPostClick} pinnedPosts={pinnedPostsSearchResult} tokenName={tokenName} />
        );
    }

    if (!pinnedPostsSearchResult || !pinnedPostsSearchResult.length) return;
    return <div className={className}>{renderPinnedPosts()}</div>;
};

export default memo(PostsTabPinnedPosts);
