
import DbModule from '../db';
import AccountModule from '../account';
import StorageModule from '../storage';
import TokenModule, { SellTokenPayload } from '../token';
import AdminService, { GetUsersInfoFilterBy, GetUsersInfoOrderBy, BroadcastEmailApiRequestPayload, BroadcastEmailReceipt } from './admin.service';
import { UserInfo } from '../account/account.model';
import { Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';
import { QueryDocumentSnapshot } from 'firebase/firestore/lite';

export interface ClaimFundsPayload {
    tokenAmount: number;
    from: string;
    withdrawTo: string;
    memo: string;
    tokenName: string;
}

export interface BroadcastEmailPayload {
    userId: string;
    sender: string; // - the name that will appear in the "from"
    emailSubject: string;
    emailContent: string;
}

export default class AdminModule {
    accountModule: AccountModule;
    tokenModule: TokenModule;
    adminService: AdminService;
    domainName: string;
        private appCheckModule: AppCheckModule;
    // private dbModule: DbModule;
    // private storageModule: StorageModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule, accountModule: AccountModule, tokenModule: TokenModule) {
        
        // this.dbModule = dbModule;
        // this.storageModule = storageModule;
        this.domainName = domainName;
        this.adminService = new AdminService(contractName, domainName, appCheckModule, accountModule, dbModule);
        this.accountModule = accountModule;
        this.tokenModule = tokenModule;
        this.appCheckModule = appCheckModule;
    }

    async getUsersInfo(startAfter: QueryDocumentSnapshot<UserInfo> | null, limit: number, filterBy?: GetUsersInfoFilterBy[], orderBy?: GetUsersInfoOrderBy): Promise<Pagination<UserInfo>> {
        return this.adminService.getUsersInfo(startAfter, limit, filterBy, orderBy);
    }

    async claimFunds(privateKey: string, payload: ClaimFundsPayload): Promise<string> {
        const { memo, from, withdrawTo, tokenAmount, tokenName } = payload;

        const sellTokenPayload: SellTokenPayload = {
            tokenName,
            from,
            withdrawTo,
            tokenAmount,
            memo: memo
        };

        let res;
        try {
            res = await this.tokenModule.claimToken(privateKey, sellTokenPayload);
        } catch (error: any) {
            if (error.message.includes('withdraw_to is invalid') || error.message.includes('expected key different than recovered key')) throw new Error('invalidWithdrawTo');
            throw error;
        }

        return res;
    }

    async broadcastEmail(payload: BroadcastEmailPayload): Promise<BroadcastEmailReceipt> {
        const { emailSubject, emailContent, sender, userId } = payload;

        const boardcastEmailPayload: BroadcastEmailApiRequestPayload = {
            emailContent,
            emailSubject,
            sender,
            userId
        };

        let res;
        try {
            res = await this.adminService.broadcastEmail(boardcastEmailPayload);
        } catch (error) {
            throw error;
        }

        return res.payload;
    }

}
