import ConfigService from './config.service';
import StorageModule from '../storage';
import { DomainConfig, DomainMainMenu, LangCode } from './config.model';
import * as helpers from './config.helpers';
import { FirebaseApp } from 'firebase/app';

export default class ConfigModule {
    helpers = helpers;

    private configService: ConfigService;
    private storageModule: StorageModule;
    private domainName: string;
    constructor(app: FirebaseApp, domainName: string, storageModule: StorageModule) {
        this.storageModule = storageModule;
        this.configService = new ConfigService();
        this.domainName = domainName;
    }

    async getDomainConfig(): Promise<Partial<DomainConfig>> {
        const path = `${this.domainName}%2Fconfig.json?alt=media`;
        const url = this.storageModule.getFileUrlByPath(path);
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('failed to load domain config');
            const data = await response.json();
            return data;
        }
        catch (error) {
            console.warn('couldn\'t parse domain config ' + error);
            return {};
        }
    }

    async getDomainMainMenu(): Promise<DomainMainMenu> {
        const path = `${this.domainName}%2Fmain-menu.json?alt=media`;
        const url = this.storageModule.getFileUrlByPath(path);
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('failed to load domain config');
            const data = await response.json();
            return data;
        }
        catch (error) {
            console.warn('couldn\'t parse domain config ' + error);
            return [];
        }
    }

    async getDomainLanguage(lang: LangCode): Promise<{ [key: string]: string }> {
        const path = `${this.domainName}%2Flang%2F${lang}.json?alt=media`;
        const url = this.storageModule.getFileUrlByPath(path);
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('failed to load domain language');
            const data = await response.json();
            return data;
        }
        catch (error) {
            console.warn('couldn\'t parse domain language ' + error);
            return {};
        }
    }

    async getDomainCustomCss(): Promise<string> {
        const path = `${this.domainName}%2Fcustom.css?alt=media`;
        const url = this.storageModule.getFileUrlByPath(path);
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('failed to load domain custom css');
            const data = await response.text();
            return data;
        }
        catch (error) {
            return '';
        }
    }
}