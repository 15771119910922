import React, { FC, Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileImage, { ProfileImageProps } from '@src/components/ProfileImage/ProfileImage';
import { useHistory } from 'react-router-dom';
import Text from '@creator/ui/components/Text/Text';
import { convertFirebaseTimestampToDate, formatDate, toCustomLocaleDate } from '@creator/ui/utils/date-utils';
import { FirebaseTimestamp } from '@creator/sdk/modules/time/time.model';
import TokenImage, { TokenImageProps } from '@src/components/Token/TokenImage/TokenImage';
import { cn } from '@creator/ui/utils/ui';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';
import { ensureTokenUrl } from '@src/model/config/helpers';
import UserDisplayNameWithLink from '@src/basic-components/UserDisplayName/UserDisplayNameWithLink';

export interface PostBasicInfoProps {
    className?: string;
    createdAt?: string | FirebaseTimestamp;
    publisherName?: string;
    publisherProfileName?: string;
    publisherSlugUrl?: string;
    tokenName: string;
    showTokenImage?: boolean;
    communityName?: string;
    profileImageProps?: Partial<ProfileImageProps>;
    tokenImageProps?: Partial<TokenImageProps>;
    profileBackLinkUrl?: string;
    profileBackLinkLabel?: string;
    showFullCreatedAtDate?: boolean;
    showPartnerLabel?: boolean;
    isHighlighed?: boolean;
    postTitle?: string;
    moderationScore?: number;
}

const PostBasicInfo: FC<PostBasicInfoProps> = props => {
    const { showTokenImage = false, profileBackLinkUrl = '', publisherSlugUrl = '', profileBackLinkLabel = '', profileImageProps = {}, communityName = '', createdAt, tokenName, publisherName = ''} = props;

    const { t } = useTranslation();
    const history = useHistory();

    function getBaseClassName() {
        const { isHighlighed, className } = props;
        let baseClassName = 'flex items-center text-gray-900 dark:text-gray-50 text-sm';
        if (isHighlighed) baseClassName = cn(baseClassName, 'bg-gray-100 dark:bg-gray-800');

        return cn(baseClassName, className);
    }
    function getPostCreatedTime(): string {
        const { showFullCreatedAtDate = false } = props;
        if (!createdAt) return '';

        const _date = convertFirebaseTimestampToDate(createdAt);
        if (showFullCreatedAtDate) return toCustomLocaleDate(_date);
        return formatDate(_date);
    }

    function renderDisplayNameProfileImage(displayName = '', className = '') {
        const _props = mergeProps({
            tokenName,
            onClick: (e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`/${ensureTokenUrl(tokenName)}/author/${publisherSlugUrl || publisherName}?backLinkUrl=${profileBackLinkUrl}&backLinkLabel=${profileBackLinkLabel}`);
            },
            className: className,
            displayName
        }, profileImageProps);
        return <ProfileImage {..._props} />;
    }

    function renderTokenImage(className = '') {
        const { tokenImageProps = {} } = props;
        const _props = mergeProps({
            tokenName,
            onClick: () => history.push(`/${ensureTokenUrl(tokenName)}`),
            className
        }, tokenImageProps);

        return <TokenImage  {..._props} />;
    }

    function renderProfileImage() {
        if (!publisherName && communityName) return renderTokenImage('mr-3 w-7 h-7');
        if (showTokenImage) {
            return (
                <div className="flex items-end flex-shrink-0 mr-3">
                    {renderTokenImage('w-10 h-10')}
                    {publisherName && renderDisplayNameProfileImage(publisherName, '-ml-3 w-7 h-7')}
                </div>
            );
        }
        return (
            <div className="flex items-end flex-shrink-0 mr-3">
                {publisherName && renderDisplayNameProfileImage(publisherName, 'w-10 h-10')}
            </div>
        );
    }

    function renderPostTitle() {
        const { postTitle } = props;
        if (!postTitle) return;
        return <Text enableMultiline={true} className="block overflow-hidden font-medium line-clamp-2">{postTitle}</Text>;
    }

    function renderPublisher() {
        const { publisherName = '', publisherProfileName } = props;
        return (
            <UserDisplayNameWithLink
                slugUrl={publisherSlugUrl}
                tokenName={tokenName}
                profileName={publisherProfileName}
                displayName={publisherName} 
                userDisplayNameProps={{ className: 'font-bold' }} />
        );
    }

    function renderTime() {
        if (!createdAt) return;
        return (
            <Fragment>
                <Text>·</Text>
                <Text>{getPostCreatedTime()}</Text>
            </Fragment>
        );
    }

    function renderPatnerLabel() {
        const { showPartnerLabel } = props;
        if (!showPartnerLabel) return;

        return (
            <Fragment>
                <Text>·</Text>
                <Text className="flex text-primary-400">{t('postBasicInfo.partnerLabel')}</Text>
            </Fragment>
        );
    }


    function renderContent() {
        return (
            <div className="flex flex-col justify-center overflow-hidden">
                <div className="relative flex flex-wrap items-center space-x-1">
                    {renderPublisher()}
                    {renderTime()}
                    {renderPatnerLabel()}
                </div>
                {renderPostTitle()}
            </div>
        );
    }

    return (
        <div className={getBaseClassName()}>
            {renderProfileImage()}
            {renderContent()}
        </div>
    );
};

export default memo(PostBasicInfo, propsEqualityFn);
