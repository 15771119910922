import { getBaseUrl, getFirebaseStorageBucket } from '@src/config';
import { getMediaPoster } from '../upvote/helpers';
import { Response, ResponseType } from '@creator/sdk/modules/response/response.model';
import store from '@src/store';
import { findNextItems, findPreviousItems } from '@src/utils/array-utils/array-utils';
import { getUserId } from '../user/helpers';
export function getResponse(responseId: string): Response | null {
    return store.getState().response.getResponse(responseId);
}

export function getRootResponsesIds(): string[] {
    return store.getState().response.rootResponseSearchResult;
}

export function getResponseMediaUrl(responseId: string): string {
    const storageBucket = getFirebaseStorageBucket();
    return `https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/media%2F${responseId}?alt=media`;
}

export function getResponseMediaPosterUrl(responseId: string): string {
    return getMediaPoster(getResponseMediaUrl(responseId));
}

export function getResponseGrandparentId(response: string | Response): string | undefined {
    const _response = typeof response === 'string' ? getResponse(response) : response;
    if (!_response) return;

    const { path } = _response;
    const nodes = path.split('/');
    return nodes[nodes.length - 3];
}

export function getResponseFirstChildrenId(response: string | Response): string | undefined {
    const _response = typeof response === 'string' ? getResponse(response) : response;
    if (!_response) return;

    const { sortedChildren = [] } = _response;
    if (!sortedChildren.length) return;

    return sortedChildren[0];
}

export function getResponseNextSiblingIdsIds(response: string | Response, parentResponse?: Response): string[] {
    const _response = typeof response === 'string' ? getResponse(response) : response;
    if (!_response) return [];
    const { parent, id } = _response;

    if (parent === 'root')
        return findNextItems(getRootResponsesIds(), id);

    const _parentResponse = parentResponse || getResponse(parent);
    if (!_parentResponse) return [];

    const { sortedChildren = [] } = _parentResponse;

    return findNextItems(sortedChildren, id);
}

export function getResponsePreviousSiblingIdsIds(response: string | Response, parentResponse?: Response): string[] {
    const _response = typeof response === 'string' ? getResponse(response) : response;
    if (!_response) return [];
    const { parent, id } = _response;

    if (parent === 'root')
        return findPreviousItems(getRootResponsesIds(), id);

    const _parentResponse = parentResponse || getResponse(parent);
    if (!_parentResponse) return [];

    const { sortedChildren = [] } = _parentResponse;

    return findPreviousItems(sortedChildren, id);
}

export function getResponseNextSiblingId(response: string | Response, parentResponse?: Response): string | undefined {
    const nextSiblings = getResponseNextSiblingIdsIds(response, parentResponse) || [];
    if (!nextSiblings.length) return;
    return nextSiblings[0];
}

export function getResponsePreviousSiblingId(response: string | Response, parentResponse?: Response): string | undefined {
    const previousSiblings = getResponsePreviousSiblingIdsIds(response, parentResponse) || [];
    if (!previousSiblings.length) return;
    return previousSiblings[previousSiblings.length - 1];
}

export function getShareResponseUrl(responseIdId: string): string {
    const baseUrl = `${getBaseUrl()}/bbs/response/${responseIdId}`;
    if (getUserId()) return `${baseUrl}?refBy=${getUserId()}`;
    return baseUrl;
}

export function getShareResponseThreadUrl(responseIdId: string): string {
    const baseUrl = `${getBaseUrl()}/bbs/thread/${responseIdId}`;
    if (getUserId()) return `${baseUrl}?refBy=${getUserId()}`;
    return baseUrl;
}

// return true for playable media like Video and audio
export function isMediaType(type: ResponseType): type is 'audio' | 'video' {
    return ['video', 'audio'].includes(type);
}