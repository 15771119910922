import React, { memo, type FC, type PropsWithChildren } from 'react';
import { mergeProps } from '../../utils/merge-props/merge-props';
import { cn } from '../../utils/ui';
import LoadingSkeleton, { LoadingSkeletonProps } from '../LoadingSkeleton/LoadingSkeleton';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

export type ElementProps = React.HTMLAttributes<HTMLElement>;

export interface TextProps extends PropsWithChildren {
    as?: keyof JSX.IntrinsicElements;
    className?: string;
    enableMultiline?: boolean;
    elementProps?: ElementProps;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    isLoading?: boolean;
    loadingSkeletonProps?: Partial<LoadingSkeletonProps>;
}

const Text: FC<TextProps> = props => {
    const {
        as: Component = 'span',
        className = '',
        onClick,
        children,
        isLoading = false,
        enableMultiline = false,
        elementProps = {},
    } = props;

    function getBaseClassname() {
        let baseClassname = 'leading-none whitespace-nowrap text-ellipsis overflow-hidden';
        if (enableMultiline)
            baseClassname = cn(baseClassname, 'leading-tight whitespace-normal break-normal');
        return baseClassname;
    }

    function renderLoadingSkeleton() {
        const { loadingSkeletonProps = {} } = props;
        if (!isLoading) return null;

        const _props = mergeProps<[Partial<LoadingSkeletonProps>, Partial<LoadingSkeletonProps>]>(
            {
                className: 'w-full min-w-16 h-[0.8lh] my-[0.2lh]',
                count: 1,
            },
            loadingSkeletonProps,
        );

        return <LoadingSkeleton {..._props} />;
    }

    function renderContent() {
        if (isLoading) return renderLoadingSkeleton();
        return children;
    }

    const _props = mergeProps<[Partial<ElementProps>, Partial<ElementProps>]>(
        {
            className: '',
        },
        elementProps,
    );

    return (
        <Component {..._props} onClick={onClick} className={cn(getBaseClassname(), className)}>
            {renderContent()}
        </Component>
    );
};

export default memo(Text, propsEqualityFn);
