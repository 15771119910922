import TokenPriceService, { } from './token-price.service';
import { RTBPrice, DisplayCurrency } from './token-price.model';

export default class TokenPriceModule {
    tokenPriceService: TokenPriceService;

    constructor(dbModule) {
        this.tokenPriceService = new TokenPriceService(dbModule);
    }

    getCommunityTokenToFiatPrice(tokenName: string, displayCurrency: DisplayCurrency = 'USD'): number {
        return 1;
    }

    async getRTBPrice(): Promise<RTBPrice> {
        return this.tokenPriceService.getRTBPrice();
    }
}
