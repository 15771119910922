import React, { FC, Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '@src/model/hooks';
import TokenMenuPopover from '@src/components/Token/TokenMenuPopover/TokenMenuPopover';
import Text from '@creator/ui/components/Text/Text';
import { useHistory } from 'react-router-dom';
import JoinButton from '@src/components/JoinButton/JoinButton';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import TokenImage from '@src/components/Token/TokenImage/TokenImage';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { getTokenShareUrl } from '@src/model/token/helpers';
import SocialShare, { SocialShareProps } from '@src/components/SocialShare/SocialShare';
import Button from '@creator/ui/components/Button/ButtonV2';
import { abbreviatedFormatter } from '@creator/ui/utils/number-utils';
import Icon from '@src/basic-components/Icon/Icon';
import { cn } from '@creator/ui/utils/ui';
import Image from '@creator/ui/components/Image/Image';

export interface HeaderProps {
    tokenName: string;
    className?: string;
}

const TokenPageHeader: FC<HeaderProps> = props => {
    const { tokenName, className } = props;

    const { t } = useTranslation();
    const history = useHistory();

    const isAuthenticating = useStoreState(state => state.user.isAuthenticating);
    const token = useStoreState(state => state.token.getToken(tokenName), storeEqualityFn);

    function renderMenuPopover() {
        if (!token) return null;

        return <TokenMenuPopover className="ml-3" tokenName={tokenName} />;
    }

    function renderTokenInfo() {
        if (!token) return null;

        return (
            <div className="flex-1 space-y-1">
                <Text as="h1" enableMultiline={true} className="block text-base font-bold leading-none lg:text-lg lg:leading-none line-clamp-2">{token?.title} </Text> 
                <div className="flex">
                    {renderTokenStats()}
                    {renderActionButtons('lg:hidden')}
                </div>
            </div>
        );
    }

    function onHoldersClick(): void {
        history.push(`/${ensureTokenUrl(tokenName)}/tab/holders`);
    }

    function onPostsClick(): void {
        history.push(`/${ensureTokenUrl(tokenName)}`);
    }

    function renderTokenStats() {
        if (!token) return null;

        const { numberOfMembers = 0, totalPosts = 0 } = token ?? {};

        return (
            <div className="flex items-center flex-1 space-x-1 text-xs text-gray-500 ">
                <div className="cursor-pointer link" onClick={onHoldersClick}>
                    <Text className="font-bold lg:hidden">{abbreviatedFormatter(numberOfMembers)}</Text>
                    <Text className="hidden font-bold lg:inline">{numberOfMembers.toLocaleString()}</Text>
                    <Text className="flex-1 ml-1">{t('tokenPageHeader.stats.totalMembers')}</Text>
                </div>
                <Text className="font-bold">·</Text>
                <div className="cursor-pointer link" onClick={onPostsClick}>
                    <Text className="font-bold lg:hidden">{abbreviatedFormatter(totalPosts)}</Text>
                    <Text className="hidden font-bold lg:inline">{totalPosts.toLocaleString()}</Text>
                    <Text className="flex-1 ml-1">{t('tokenPageHeader.stats.totalPosts')}</Text>
                </div>
            </div >
        );
    }

    function renderJoinButton() {
        if (isAuthenticating) return null;
        return <JoinButton buttonProps={{ size: 'xs', variant: 'ghost', className: 'mt-0.5' }} tokenName={tokenName} />;
    }

    function renderShareButton() {
        if (!token) return null;

        const props: SocialShareProps = {
            useShortUrl: true,
            shareUrlPayload: {
                url: getTokenShareUrl(tokenName),
                title: token.inviteTitle,
                text: token.inviteText
            },
            renderTrigger: ctx =>
                <Fragment>
                    <Button className="hidden lg:flex mt-0.5" size="xs" variant="ghost" isLoading={ctx.isCreatingShortUrl}>
                        <Text>{t('shareBbsButton')}</Text>
                    </Button>
                    <div className="w-4">
                        <Icon name="share" className={`transition flex lg:hidden cursor-pointer ${ctx.isCreatingShortUrl ? 'opacity-50' : 'opacity-100'}`} />
                    </div>
                </Fragment>
        };

        return <SocialShare {...props} className="flex-1" />;
    }

    function renderTokenImage() {
        return (
            <TokenImage className="w-16 h-16 mr-2 -mt-7 lg:w-20 lg:h-20 lg:mr-3 lg:-mt-10 z-1" tokenName={tokenName} />
        );
    }

    function renderCover() {
        const alt = `${token?.title} channel cover image`;
        return (
            <div className="aspect-[5/1] overflow-hidden">
                <Image src={token?.coverImageURI || ''} className="object-cover object-center w-full h-full border-none" alt={alt} />
            </div>
        );
    }

    function renderActionButtons(className = '') {
        return (
            <div className={cn('flex items-start space-x-2 lg:space-x-3', className)}>
                {renderJoinButton()}
                {renderShareButton()}
                {renderMenuPopover()}
            </div>
        );
    }

    return (
        <div className={className}>
            {renderCover()}
            <div className="flex transition">
                <div className="container lg:flex">
                    <div className="flex flex-1 px-2 pt-2 lg:px-0 lg:w-12/12">
                        {renderTokenImage()}
                        {renderTokenInfo()}
                        {renderActionButtons('hidden lg:flex')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(TokenPageHeader);
