import { ValueOf } from '@src/custom';
import { FirebaseApp } from 'firebase/app';
import { CollectionReference, DocumentData, DocumentReference, DocumentSnapshot, getDoc, OrderByDirection, startAfter as firebaseStartAfter, QueryDocumentSnapshot, QuerySnapshot, limit as firebaseLimit, orderBy as firebaseOrderBy, where, WhereFilterOp, QueryConstraint, query, getDocs } from 'firebase/firestore/lite';
import { DocumentDataWithId } from './db.model';
import DbService from './db.service';
import { Pagination } from './db.model';

export interface OrderBy<T = DocumentData> {
    field: keyof T & string;
    direction: OrderByDirection;
}

export interface FilterBy<T = DocumentData> {
    field: keyof T & string;
    opStr: WhereFilterOp;
    value: ValueOf<T>;
}

export interface GetDocumentsPayload<T = DocumentData> {
    startAfter?: QueryDocumentSnapshot<T>;
    limit?: number;
    filterBy?: FilterBy<T>[];
    orderBy?: OrderBy<T>[];
}

export default class DbModule {
    dbService: DbService;
    constructor(app: FirebaseApp) {
        this.dbService = new DbService(app);
    }

    getCollectionReference<T>(collection: string): CollectionReference<T> {
        return this.dbService.getCollectionReference(collection);
    }

    getSubCollectionReference<T>(collectionName: string, docName: string, subCollectionName: string): CollectionReference<T> {
        return this.dbService.getSubCollectionReference(collectionName, docName, subCollectionName);
    }

    getDocReference<T = DocumentData>(collection: string, docName: string): DocumentReference<T> {
        return this.dbService.getDocReference<T>(collection, docName);
    }

    getDocReferenceByPath<T = DocumentData>(path: string): DocumentReference<T> {
        return this.dbService.getDocReferenceByPath<T>(path);
    }

    async getDocument<T = DocumentData>(collectionName: string, docName: string): Promise<T> {
        const docRef = this.getDocReference<T>(collectionName, docName);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) throw new Error(`${docName} was not found in ${collectionName} collection`);

        return this.normalizeDocumentSnapshot<T>(docSnap);
    }

    async getDocumentByPath<T = DocumentData>(path: string): Promise<T> {
        const docRef = this.getDocReferenceByPath<T>(path);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) throw new Error(`doc was not found in ${path}`);

        return this.normalizeDocumentSnapshot<T>(docSnap);
    }

    async getDocuments<T = DocumentData>(collection: string, payload: GetDocumentsPayload<T>): Promise<Pagination<T>> {
        const { filterBy, limit = 20, orderBy, startAfter } = payload;

        const colRef = this.getCollectionReference<T>(collection);
        const queryConstraints: QueryConstraint[] = [];

        filterBy?.forEach(filter => {
            const { field, opStr, value } = filter;
            queryConstraints.push(where(field, opStr, value));
        });

        orderBy?.forEach(_orderBy => {
            queryConstraints.push(firebaseOrderBy(_orderBy.field, _orderBy.direction));
        });

        if (startAfter)
            queryConstraints.push(firebaseStartAfter(startAfter));

        queryConstraints.push(firebaseLimit(limit));

        const q = query(colRef, ...queryConstraints);

        const querySnapshot = await getDocs(q);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const data = this.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);

        return { hasMore: items.length === limit, items, lastDoc };
    }

    normalizeQuerySnapshot<T = DocumentData>(querySnapshot: QuerySnapshot<T>): Record<string, DocumentDataWithId<T>> {
        const data: Record<string, DocumentDataWithId<T>> = {};
        querySnapshot.forEach(doc => {
            const docData = this.normalizeDocumentSnapshot(doc);
            if (!docData) return;

            data[doc.id] = docData;
        });

        return data;
    }

    normalizeDocumentSnapshot<T = DocumentData>(docSnap: DocumentSnapshot<T>): DocumentDataWithId<T> {
        const docData = docSnap.data();
        if (!docData) throw new Error(`Coundln't normalize document snapshot ${docSnap.id}`);
        return { id: docSnap.id, ...docData };
    }

    generateDocId(collectionName: string): string {
        return this.dbService.generateDocId(collectionName);
    }
}