import React, { FC, PropsWithChildren } from 'react';

export interface AlertBoxProps extends PropsWithChildren {
    className?: string;
    type: 'normal' | 'info' | 'warning' | 'error' | 'success';
}
const AlertBox: FC<AlertBoxProps> = props => {
    const { type, children, className } = props;


    function getClassName(): string {
        const base = 'border transition rounded-md text-sm font-medium py-2 p-4';
        if (type === 'info') return `${base} bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-gray-400`;
        if (type === 'error') return `${base} text-red-500  border-red2 dark:border-red-500`;
        if (type === 'success') return `${base} text-green2 dark:text-green border-green2 dark:border-green`;
        if (type === 'warning') return `${base} border border-gray-500 text-gray-700 rounded-lg bg-yellow-100 dark:bg-gray-800 dark:text-yellow-200`;
        return base;
    }

    return (
        <div className={`${getClassName()} ${className}`}>
            {children}
        </div>
    );
};

export default AlertBox;
