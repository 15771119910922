
import { Pagination as PaginationNew } from '../db/db.model';
import { ApiResponse } from '../../creator-sdk';
import { post } from '../../network/api-service';
import DbModule from '../db';
import { Token, TokenBlockchainTableRow, TokenDbTableRow } from './token.model';
import { GetTokensOrderBy } from '.';
import AppCheckModule from '../app-check';
import { PostListOrderBy } from '../config/config.model';
import { where, getDocs, QueryConstraint, orderBy, startAfter, query, limit, QueryDocumentSnapshot, WhereFilterOp } from 'firebase/firestore/lite';
import AccountModule from '../account';

export interface GetTokensFilterBy {
    field: 'tokenName' | 'createdAt' | 'creatorId';
    opStr: WhereFilterOp;
    value: any;
}
export default class TokenService {
    private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private accountModule: AccountModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.accountModule = accountModule;
    }

    async getTokensCache(_startAfter: QueryDocumentSnapshot<TokenDbTableRow> | null, _limit: number, _orderBy?: GetTokensOrderBy, _filterBy?: GetTokensFilterBy[]): Promise<PaginationNew<TokenDbTableRow>> {
        const colRef = this.dbModule.getCollectionReference<TokenDbTableRow>('Token');
        const queryConstraints: QueryConstraint[] = [where('status', '==', 1)];

        _filterBy?.forEach((filter): void => {
            const { field, opStr, value } = filter;
            queryConstraints.push(where(field, opStr, value));
        });

        if (!_orderBy) { // default
            queryConstraints.push(orderBy('numberOfHolders', 'desc'));
            queryConstraints.push(orderBy('cashierBalance', 'desc'));
        }

        if (_orderBy) // createdAt will be added in the end anyway
            queryConstraints.push(orderBy(_orderBy.field, _orderBy.direction));

        if (_startAfter)
            queryConstraints.push(startAfter(_startAfter));

        queryConstraints.push(limit(_limit));

        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);

        return { hasMore: items.length === _limit, items, lastDoc };
    }

    async getToken(tokenName: string): Promise<TokenDbTableRow | undefined> {
        return this.dbModule.getDocument<TokenDbTableRow>('Token', tokenName);
    }
}
