import React, { FC } from 'react';
import { noop } from '@src/utils/utils';
import AlertBox from '@src/basic-components/AlertBox/AlertBox';
import Text from '@creator/ui/components/Text/Text';
import { cleanEncodedString } from '@src/utils/html-utils/html-utils';
import Icon from '@src/basic-components/Icon/Icon';

type BlockData = {
    title: string;
    message: string;
};
export interface EditorViewOnlyWarningToolProps {
    className?: string;
    blockData: BlockData;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const EditorViewOnlyWarningTool: FC<EditorViewOnlyWarningToolProps> = props => {
    const { onClick = noop, blockData, className } = props;


    function renderContent() {
        const { message, title } = blockData;

        return (
            <AlertBox type="warning" className="text-gray-900">
                <div className="flex items-center space-x-2 text-lg">
                    <Icon name="warning" className="w-5 h-5 text-xl" />
                    <Text enableMultiline={true} className="block font-bold">{cleanEncodedString(title)}</Text>
                </div>
                <div className="block mt-1" />
                <Text className="block mt-2" enableMultiline={true}>{cleanEncodedString(message)}</Text>
            </AlertBox>
        );

    }

    return (
        <div className={`warning-tool ${className}`} onClick={onClick}>
            {renderContent()}
        </div>
    );
};

export default EditorViewOnlyWarningTool;
