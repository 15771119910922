import { getMyUser, getUserId } from '../user/helpers';
import { getToken } from '../token/helpers';
import { hasTokenPermission, isPerrmisioned } from '../user/permission';
import { getDomainName } from '@src/config';
import { getSuperComment } from './helpers';

export function canEditSuperComment(commentId: string): boolean {
    const userId = getUserId();
    const myUser = getMyUser();
    if (!userId) return false;

    const comment = getSuperComment(commentId);

    if (!comment) return false;
    if (myUser && myUser.ForwardedFromArray && myUser.ForwardedFromArray.includes(comment.publisherId)) return true;
    if (userId !== comment.publisherId) return false;

    return true;
}

export function canDeleteSuperComment(commentId: string, tokenName: string): boolean {
    const userId = getUserId();
    const myUser = getMyUser();

    if (!userId) return false;

    const comment = getSuperComment(commentId);
    if (!comment) return false;

    if (myUser && myUser.ForwardedFromArray && myUser.ForwardedFromArray.includes(comment.publisherId)) return true;

    const token = getToken(tokenName);
    if (!token) return false;

    if (isPerrmisioned(tokenName, 'hideContent')) return true;
    if (userId === comment.publisherId) return true;

    const user = getMyUser();
    if (!user) return false;

    // if (tokenName && user.moderator && user.moderator[tokenName]) return true;
    if (user.domain === getDomainName() && user.domainAdmin) return true;
    return false;
}

