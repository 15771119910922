import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import Button from '@creator/ui/components/Button/ButtonV2';
import { cn } from '@creator/ui/utils/ui';

export interface HorizontalScrollableProps {
    className?: string;
    scrollContainerClassname?: string;
    arrowClassName?: string;
    scrollOffset: number;
    isLoadingContent?: boolean;
}

const HorizontalScrollable: FC<PropsWithChildren<HorizontalScrollableProps>> = props => {
    const { isLoadingContent = false, scrollOffset = 100, children, className = '', arrowClassName = '', scrollContainerClassname = '' } = props;

    const ref = useRef<HTMLDivElement>(null);

    const scrollLeft = ref.current?.scrollLeft || 0;
    const scrollWidth = ref.current?.scrollWidth || 0;

    const [isScrollPositionAtStart, setIsScrollPositionAtStart] = useState(true);
    const [isScrollPositionAtEnd, setIsScrollPositionAtEnd] = useState(false);

    useEffect(() => {
        onScroll(scrollLeft);
    }, [scrollWidth]);

    useEffect(() => {
        onScroll(0);
    }, [isLoadingContent]);

    function getBaseClassname(): string {
        return cn('flex relative', className);
    }

    function getScrollContainerClassname(): string {
        return cn('flex items-center w-full overflow-auto scroll-smooth [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]', scrollContainerClassname);
    }

    function getArrowBaseClassname(): string {
        return cn('absolute text-xl shadow z-10 shrink-0 w-10 h-10 top-1/2 -translate-y-1/2', arrowClassName);
    }

    function onScroll(scrollLeft: number): void {
        const scrollWidth = ref.current?.scrollWidth || 0;
        const clientWidth = ref.current?.clientWidth || 0;

        const _isScrollPositionAtStart = scrollLeft === 0;
        const _isScrollPositionAtEnd = scrollLeft >= scrollWidth - clientWidth - 10;

        if (_isScrollPositionAtStart)
            setIsScrollPositionAtStart(true);
        if (!_isScrollPositionAtStart && isScrollPositionAtStart)
            setIsScrollPositionAtStart(false);
        if (_isScrollPositionAtEnd)
            setIsScrollPositionAtEnd(true);
        if (!_isScrollPositionAtEnd && isScrollPositionAtEnd)
            setIsScrollPositionAtEnd(false);
    }

    function onPreviousArrowClick(): void {
        const el = ref.current;
        if (!el) return;
        const { scrollLeft } = el;

        el.scrollTo({
            left: scrollLeft - scrollOffset,
            behavior: 'smooth'
        });
    }

    function onNextArrowClick(): void {
        const el = ref.current;
        if (!el) return;
        const { scrollLeft } = el;

        el.scrollTo({
            left: scrollLeft + scrollOffset,
            behavior: 'smooth'
        });
    }

    function renderNextArrow() {
        if (isScrollPositionAtEnd) return null;
        return (
            <Button
                className={cn(getArrowBaseClassname(), 'right-0')}
                onClick={onNextArrowClick}
                variant="filled"
                color="base"
                size="md"
                type="round"
            >
                <Icon name="caretRight" />
            </Button>
        );
    }

    function renderPreviousArrow() {
        if (isScrollPositionAtStart) return null;
        return (
            <Button
                className={cn(getArrowBaseClassname(), 'left-0')}
                onClick={onPreviousArrowClick}
                variant="filled"
                color="base"
                type="round"
                size="md"
            >
                <Icon name="caretLeft" />
            </Button>
        );
    }

    return (
        <div className={getBaseClassname()}>
            {renderPreviousArrow()}
            <div className={getScrollContainerClassname()} onScroll={e => onScroll(e.currentTarget.scrollLeft)} ref={ref}>
                {children}
            </div>
            {renderNextArrow()}
        </div>
    );
};

export default HorizontalScrollable;
