
import { Pagination } from '../db/db.model';
import { ApiResponse } from '../../creator-sdk';
import { post } from '../../network/api-service';
import DbModule from '../db';
import AppCheckModule from '../app-check';
import AccountModule from '../account';
import { DecryptReportPayload, GetReportsOrderBy, Report, ReportDB, UpdateReportPayload } from './report.model';
import { getDocs, limit, orderBy, query, QueryConstraint, QueryDocumentSnapshot, startAfter, where } from 'firebase/firestore/lite';


export default class ReportService {
        private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private accountModule: AccountModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.accountModule = accountModule;
    }

    async report(payload: Report): Promise<ApiResponse<Report>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/report/create', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async decryptReport(payload: DecryptReportPayload): Promise<ApiResponse<Report>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/report/get', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async updateReport(payload: UpdateReportPayload): Promise<ApiResponse<Report>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/report/update', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async getReports(tokenName: string, _startAfter: QueryDocumentSnapshot<ReportDB> | null, _limit: number, _filterBy?: any[], _orderBy?: GetReportsOrderBy): Promise<Pagination<ReportDB>> {
        const colRef = this.dbModule.getCollectionReference<ReportDB>('Report');
        const queryConstraints: QueryConstraint[] = [where('tokenName', '==', tokenName)];

        _filterBy?.forEach((filter): void => {
            const { field, opStr, value } = filter;
            queryConstraints.push(where(field, opStr, value));
        });

        if (_orderBy)
            queryConstraints.push(orderBy(_orderBy.field, _orderBy.direction));

        if (_startAfter)
            queryConstraints.push(startAfter(_startAfter));

        queryConstraints.push(limit(_limit));

        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);

        return { hasMore: items.length === _limit, items, lastDoc };
    }
}
