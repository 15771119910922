import React, { FC } from 'react';
import { noop } from '@src/utils/utils';
import { cleanHtmlToPlainText } from '@src/utils/html-utils/html-utils';
import Text from '@creator/ui/components/Text/Text';
import Media, { MediaProps } from '@src/basic-components/Media/Media';
import MediaPreview from '@src/basic-components/Media/MediaPreview';
import ResponseFullScreenButton from '@src/basic-components/ResponseFullScreenButton/ResponseFullScreenButton';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';

type AudioToolFile = {
    url: string;
};
type BlockData = {
    url: string;
    withBorder: boolean;
    stretched: boolean;
    file: AudioToolFile;
    caption: string;
    image: { url: string };
};
export interface EditorViewOnlyAudioToolProps {
    className?: string;
    showAsPreview?: boolean;
    blockData: BlockData;
    onResponseFullScreenButtonClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    mediaProps?: Partial<MediaProps>;
}

const EditorViewOnlyAudioTool: FC<EditorViewOnlyAudioToolProps> = props => {
    const { showAsPreview, onClick = noop, blockData, className } = props;

    function renderMedia() {
        const { mediaProps = {} } = props;

        const { url, image } = blockData;

        const _props = mergeProps<[MediaProps, Partial<MediaProps>]>({
            poster: image.url,
            src: url,
            type: 'audio'
        }, mediaProps);

        return <Media {..._props} />;
    }

    function renderCaption() {
        const { caption } = blockData;
        if (!caption) return;
        return (
            <Text enableMultiline={true} className="block mt-1 text-sm text-gray-500" data-placeholder="Caption">
                {cleanHtmlToPlainText(caption)}
            </Text>
        );
    }

    function renderContent() {
        if (showAsPreview) return renderAudioPreview();

        return (
            <div className={'cdx-block audio-tool audio-tool--filled'}>
                <div className="audio-tool__audio">
                    <div className="audio-tool__audio-preloader"></div>
                    {renderMedia()}
                </div>
                {renderCaption()}
            </div>
        );
    }

    function renderResponseFullScreenButton() {
        const { onResponseFullScreenButtonClick } = props;
        if (!onResponseFullScreenButtonClick) return;

        return <ResponseFullScreenButton className="absolute left-4 top-4 z-1" onClick={onResponseFullScreenButtonClick} />;
    }

    function renderAudioPreview() {
        const { url } = blockData;
        return <MediaPreview className="aspect-video" audioIconProps={{ className: 'text-[160px]' }} type="audio" url={url} />;
    }

    return (
        <div onClick={onClick} className={`relative audio-tool ${className}`}>
            {renderContent()}
            {renderResponseFullScreenButton()}
        </div>
    );
};

export default EditorViewOnlyAudioTool;
