
import { ApiResponse } from '../../creator-sdk';
import { post } from '../../network/api-service';
import DbModule from '../db';
import { SuperComment } from './super-comment.model';
import AppCheckModule from '../app-check';
import AccountModule from '../account';

export interface ReactionApiRequestPayload {
    userId: string;
    superCommentId: string;
    reactionName: string;
}

export interface DeleteSuperCommentApiRequestPayload {
    userId: string;
    commentId: string;
    tokenName: string;
    hidden: boolean;
    reason?: string;
    reasonText?: string;
    isSuperComment: boolean;
}

export interface EditSuperCommentApiRequestPayload {
    userId: string;
    tokenName: string;
    commentId: string;
    content: string;
    isSuperComment: boolean;
    // taggedUsers?: { username: string; id: string; }[];
}

export default class SuperCommentService {
        private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private accountModule: AccountModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.accountModule = accountModule;
    }

    async editSuperComment(payload: EditSuperCommentApiRequestPayload) {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<SuperComment>>('/comment/edit', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async deleteSuperComment(payload: DeleteSuperCommentApiRequestPayload) {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<SuperComment>>('/comment/delete', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data;
    }

    async reaction(payload: ReactionApiRequestPayload) {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<string>>('/post/reaction', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }
}
