import React, { FC } from 'react';
import { noop } from '@src/utils/utils';
import Icon from '@src/basic-components/Icon/Icon';
import Text from '@creator/ui/components/Text/Text';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '@src/model/hooks';
import { canSellTokens } from '@src/model/user/permission';
import { getUserId } from '@src/model/user/helpers';
import Button from '@creator/ui/components/Button/ButtonV2';

export interface PostListEmptyStateProps {
    className?: string;
    onCreatePostButtonClick?: () => void;
    tokenName: string;
    isStory?: boolean;
}

const PostListEmptyState: FC<PostListEmptyStateProps> = props => {
    const { isStory, tokenName, onCreatePostButtonClick = noop, className } = props;

    const token = useStoreState(state => state.token.getToken(tokenName));
    const { t } = useTranslation();

    function getTitle() {
        if (isStory) return t('storyListEmptyStateTitle');
        return t('postListEmptyStateTitle');
    }
    function getCreatePostText() {
        if (isStory) return t('createStoryButton');
        return t('createPostButton');
    }

    function renderCreatePostButton() {
        if (!token) return null;
        if (isStory && !canSellTokens(getUserId(), tokenName)) return;

        return (
            <Button className="mt-2" size="sm" onClick={onCreatePostButtonClick}>
                <Text className="text-sm">{t(getCreatePostText())}</Text>
            </Button>
        );
    }

    return (
        <div className={`py-4 flex border border-gray-200 dark:border-gray-700 flex-col items-center justify-center ${className}`}>
            <Icon svgFill={false} className="text-6xl text-gray-500" name="post" />
            <Text className="text-2xl font-bold">{getTitle()}</Text>
            {renderCreatePostButton()}
        </div>
    );
};

export default PostListEmptyState;
