import React, { FC, ChangeEvent, useRef } from 'react';
import { noop } from '@src/utils/utils';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import CharacterCounter from '@creator/ui/components/CharacterCounter/CharacterCounter';
import { useTranslation } from 'react-i18next';
import ProfileImage from '@src/components/ProfileImage/ProfileImage';
import { getDisplayName } from '@src/model/user/helpers';
import TextareaInput, { TextareaInputProps } from '@creator/ui/components/Form/TextareaInput/TextareaInput';
import Icon from '../Icon/Icon';
import { Prompt } from 'react-router-dom';
import { useStoreState } from '@src/model/hooks';
import { UsersSearchDropDownProps } from './UsersSearchDropDown/UsersSearchDropDown';
import TagSearchDropdown from './UsersSearchDropDown/UsersSearchDropDown';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { cn } from '@creator/ui/utils/ui';
import { LinkMeta } from '@creator/sdk/modules/upvote';
import { TagUser } from '@creator/sdk/modules/account/account.model';

export interface CommentTextareaProps {
    value: string;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    className?: string;
    placeholder?: string;
    onClick?: () => void;
    isSending?: boolean;
    isDisabled?: boolean;
    onDivClick?: () => void;
    hideSendButton?: boolean;
    hideProfileImage?: boolean;
    isTaggingEnabled?: boolean;
    usersSearchDropDownProps?: Partial<UsersSearchDropDownProps>;
    initialLinkMeta?: LinkMeta;
    textareaProps?: Partial<TextareaInputProps>;
}

const MAX_CHARS_IN_COMMENT = 3000;

const CommentInput: FC<CommentTextareaProps> = props => {
    const { isSending, value = '', onChange = noop } = props;
    const { t } = useTranslation();
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);

    function onUserClick(usernames: TagUser[]): void {
        if (props.usersSearchDropDownProps?.onUserClick)
            props.usersSearchDropDownProps.onUserClick(usernames);
        if (usernames.length > 0)
            onChange({ target: { value: value + ' ' } } as React.ChangeEvent<HTMLTextAreaElement>);
    }

    function shouldPrompLeaveConfirmation(): boolean {
        if (!value || value === '') return false;

        return true;
    }

    function getClassName(): string {
        const { className = '' } = props;
        const base = 'transition rounded-lg bg-gray-50 dark:bg-gray-800 text-base relative flex items-center pl-4 pr-3 py-3 w-full';
        return cn(base, className);
    }

    function renderSendingSpinner() {
        if (!props.isSending) return null;

        return <Spinner />;
    }

    function renderCommentButton() {
        if (props.hideSendButton || props.isSending) return null;

        return (
            <div className="flex p-1 cursor-pointer" onClick={Boolean(props.value) ? props.onClick : noop}>
                <Icon name="sendTransparent" />
            </div>
        );
    }

    function getInputPlaceholder(): string {
        const { placeholder = '' } = props;
        if (isSending) return t('commentInputPlaceholderSending');
        if (!isLoggedIn) return t('commentInputPlaceholderAnonymous');
        return placeholder || t('commentInputPlaceholder');
    }

    function renderTextarea() {
        const { textareaProps = {} } = props;
        const _props = mergeProps<[TextareaInputProps, Partial<TextareaInputProps>]>({
            value: isSending ? '' : props.value,
            onChange,
            placeholder: getInputPlaceholder(),
            maxLength: MAX_CHARS_IN_COMMENT,
            className: 'pr-2 flex-1 resize-none bg-transparent dark:bg-transparent outline-none px-0 border-none',
            enableAutosize: true,
            rows: 1
        }, textareaProps);

        return <TextareaInput {..._props} />;
    }

    function renderUsersTagging() {
        if (!props.isTaggingEnabled || !isLoggedIn) return null;
        return <TagSearchDropdown {...props.usersSearchDropDownProps} onChange={onChange} onUserClick={onUserClick} value={value} />;
    }

    function renderCommentWordCount() {
        return <CharacterCounter
            value={value}
            maxChars={MAX_CHARS_IN_COMMENT}
            className="pr-2 text-xs text-gray-400 transition bg-transparent dark:bg-transparent dark:text-gray-500" />;
    }

    function renderProfileImage() {
        const { hideProfileImage } = props;
        if (hideProfileImage) return;
        return <ProfileImage className="w-8 h-8 mr-4" displayName={getDisplayName() || 'anonymous'} />;
    }

    return (
        <div onClick={props.onDivClick ? props.onDivClick : noop} className={getClassName()}>
            {renderUsersTagging()}
            {renderProfileImage()}
            {renderTextarea()}
            {renderCommentWordCount()}
            {renderCommentButton()}
            {renderSendingSpinner()}
            <Prompt
                when={shouldPrompLeaveConfirmation()}
                message={t('createCommentAreYouSureWantToLeavePrompt')}
            />
        </div>
    );
};

export default CommentInput;
