import { FirebaseApp } from 'firebase/app';
import AccountModule from '../account';

export default class StorageModule {
    private storageBucket: string;
    private domainName: string;
    private accountModule: AccountModule;

    constructor(app: FirebaseApp, domainName: string, storageBucket: string, accountModule: AccountModule) {
        this.domainName = domainName;
        this.storageBucket = storageBucket;
        this.accountModule = accountModule;
    }

    getFileUrlByPath(path: string): string {
        const baseUrl = `https://firebasestorage.googleapis.com/v0/b/${this.storageBucket}/o/`;
        return baseUrl + path;
    }
}