import { DocumentSnapshot, getDocs, limit, orderBy, query, QueryConstraint, startAfter, where } from 'firebase/firestore/lite';
import { ApiResponse } from '../../creator-sdk';
import { post } from '../../network/api-service';
import AccountModule from '../account';
import AppCheckModule from '../app-check';
import DbModule from '../db';
import { Pagination } from '../db/db.model';
import { Accounting, BannerDetails, Bid, Lease, PostStats, Stats, TopBid } from './ad-server.model';

export interface ServeAdPayload {
    viewerId: string;
    postId: string;
    tokenName: string;
}

export interface SetAdImpressionPayload {
    id: string;
    viewerId: string;
    postId: string;
    tokenName: string;
}

export type PostType = 'incomePost' | 'paidPost' | 'potentialIncomePost';
export interface GetPostsStatsPayload {
    userId: string;
    tokenName: string;
    limit: number;
    isLeasing?: boolean;
    type: PostType;
    startAfter?: DocumentSnapshot | null;
}

export type LoadLeaseByOwner = {
    userId: string;
    tokenName: string;
}

export type AdvertiserDeposit = {
    advertiserId: string;
    tokenName: string;
    quantity: number;
}
export interface SetLeasePayload {
    tokenName: string;
    minAmount: string;
    isForLease: boolean;
}
export interface SetBidForLeasePayload {
    tokenName: string;
    costPerImpression: number;
    isCancel?: boolean;
}

export type LeaseDetails = {
    isActive: boolean;
    minAmount: number;
    ownerId: string;
    tokenName: string;
}

export default class AdServerService {
    private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private accountModule: AccountModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.accountModule = accountModule;
    }

    async serveAd(payload: ServeAdPayload): Promise<ApiResponse<{ bannerDetails: BannerDetails }>> {
        const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/manager/getHeader', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data;
    }
}