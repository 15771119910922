import store from '@src/store';
import storageService from '@src/services/storage-service';
import { AuthProviderId, FirebaseUser, Provider, User, UserInfo } from '@creator/sdk/modules/account/account.model';
import { getFirebaseStorageBucket } from '@src/config';
import { getDomainSymbol, getSignleTokenName, isSingleTokenDomain } from '../domain/helpers';
import { getRandomTokenName, getToken } from '../token/helpers';
import { ensureTokenUrl } from '../config/helpers';
import { getDomainNameBySymbol } from '../publicSettings/helpers';
import Long from 'long';
export interface BasicUserInfo {
    username: string;
    userId: string;
}

export function getUserBySlugOrDisplayName(userSlugOrDisplayName: string): User | undefined {
    return store.getState().user.getUserBySlugOrDisplayName(userSlugOrDisplayName);
}

export function getMyUser(): User | null {
    return store.getState().user.myUser;
}

export function getMyUserInfo(): UserInfo | null {
    return store.getState().user.myUserInfo;
}

export function getMyFirebaseUser(): FirebaseUser | null {
    return store.getState().user.myFirebaseUser;
}

export function getMyJoinedCommunities(): string[] {
    const myUser = getMyUser();
    if (!myUser || !myUser.joinedCommunities) return [];

    return myUser.joinedCommunities;
}

export function getMyForwardedFromUserIds(): string[] {
    const myUser = getMyUser();
    if (!myUser || !myUser.ForwardedFrom || !myUser.ForwardedFrom.length) return [];

    return myUser.ForwardedFrom.map(user => user.userId);
}

export function getIsUserDMBlocked(userId: string): boolean {
    const myUser = store.getState().user.myUser;
    return myUser && myUser.dmBlockUsers ? myUser.dmBlockUsers.includes(userId) : false;
}

export function getIsUserTaggingNotificationBlocked(userId: string): boolean {
    const myUser = store.getState().user.myUser;
    if (!myUser) return false;
    const tagBlockUsers = myUser.tagBlockUsers || [];
    return tagBlockUsers.includes(userId);
}

export function isUserJoinedToToken(tokenName: string): boolean {
    const myUser = store.getState().user.myUser;
    if (!myUser) return false;
    const myJoinedCommunities = myUser.joinedCommunities || [];
    return myJoinedCommunities.includes(tokenName);
}

export function getUsername(): string {
    return store.getState().user.myUsername;
}

export function getDisplayName(): string {
    return store.getState().user.myDisplayName;
}

export function getUserId(): string {
    return store.getState().user.myUserId;
}

export function getFirebaseUid(): string {
    const myUser = store.getState().user.myUser;
    if (!myUser) return '';
    return myUser.firebaseUID;
}

export function getMyProfileLink(tokenName?: string): string {
    const myUser = getMyUser();
    if (!myUser) return '';

    const lastVisitedProfile = storageService.localStorage.get('lastProfileVisited');
    const joinedCommunities = getMyJoinedCommunities();

    if (!tokenName && !isSingleTokenDomain() && lastVisitedProfile) return lastVisitedProfile;
    if (!tokenName && !isSingleTokenDomain() && !lastVisitedProfile && joinedCommunities.length > 0 && myUser) return `/${joinedCommunities[0]}/author/${myUser.displayName}`;

    const _tokenName = tokenName || getSignleTokenName() || getRandomTokenName();
    return `/${ensureTokenUrl(_tokenName)}/author/${myUser.slugUrl || myUser.displayName}`;
}

export function getUser(userId: string): User | undefined {
    return store.getState().user.getUser(userId);
}

export function getUserFromSearchResult(index: number): User | undefined {
    return store.getState().user.userSearchResult[index];
}

export function getPrivateKey(): string | null {
    return storageService.localStorage.get('privateKey');
}

export function getSavedUserInfo(): BasicUserInfo | null {
    const username = storageService.localStorage.get('username');
    const userId = storageService.localStorage.get('userId');

    if (!username || !userId) return null;

    return { username, userId };
}

export function getProfileImage(displayName: string): string {
    const storageBucket = getFirebaseStorageBucket();

    const domainName = getDomainNameByDisplayName(displayName);
    const username = getUsernameByDisplayName(displayName);
    return `https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/profileImages%2F${domainName}%2F${username}?alt=media`;
}

export function getProfileBadgeImage(displayName: string, tokenName: string): string {
    const storageBucket = getFirebaseStorageBucket();

    const domainName = getDomainNameByDisplayName(displayName);
    const username = getUsernameByDisplayName(displayName);
    return `https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/profileBadges%2F${domainName}%2F${username}_${tokenName}.svg?alt=media`;
}

export function getProfileNameMaxLength(): number {
    return 30;
}

export function getProfileAboutMaxLength(): number {
    return 300;
}

export function getActiveAuthProviders(): Provider[] {
    const userInfo = getMyUserInfo();
    if (!userInfo || !userInfo.providers) return [];

    return userInfo.providers.filter(provider => provider.isActive !== false); // note, isActive undefined means it is active..
}

export function isAuthProviderConnected(authProviderId: AuthProviderId): boolean {
    const activeProviders = getActiveAuthProviders();
    return activeProviders.some(provider => provider.providerId === authProviderId);
}

export function getAuthProviderData(authProviderId: AuthProviderId): Provider | undefined {
    return getActiveAuthProviders().find(provider => provider.providerId === authProviderId);
    // const firebaseUser = getMyFirebaseUser();
    // if (!firebaseUser) return null;

    // const providerData = firebaseUser.providerData.find(data => data?.providerId === authProviderId);
    // if (!providerData) return null;

    // return providerData;
}

// helper to support during migration - https://trello.com/c/B1VdHjD1/2236-front-cross-domain-phase-1
export function ensureDisplayName(displayName: string): string {
    if (displayName.includes('@')) return displayName;

    return `${displayName}@${getDomainSymbol()}`;
}

export function getDomainNameByDisplayName(displayName: string): string {
    const nameSplit = displayName.split('@');
    const domainSymbol = nameSplit[1];
    return getDomainNameBySymbol(domainSymbol);
}

export function getUsernameByDisplayName(displayName: string): string {
    const nameSplit = displayName.split('@');
    const username = nameSplit[0].toLowerCase();
    return username;
}

export function getUsernameByDisplayNameNoLowerCase(displayName: string): string {
    const nameSplit = displayName.split('@');
    const username = nameSplit[0];
    return username;
}

export function getDomainSymbolByDisplayName(displayName: string): string {
    const nameSplit = displayName.split('@');
    const domain = nameSplit[1] ? nameSplit[1] : '';
    return domain;
}

export function getMergeableUsers(users: User[]): User[] {
    return users.filter(user => !user.forwardTo && !user.mainAccount);
}

export function isUserTokenOwner(userId: string, tokenName: string): boolean {
    const user = getUser(userId);
    if (!user) return false;
    if (!user.tokenOwner) return false;

    return user.tokenOwner.includes(tokenName);
}

export function isUserModerator(userId: string, tokenName: string): boolean {
    const user = getUser(userId);
    const token = getToken(tokenName);

    if (!user || !token) return false;
    if (user.networkAdmin) return true;  // network admin has all permissions by default
    if (userId === token.creatorID) return true;  // token creator has all permissions by default

    return Boolean(user?.tokenPermissions?.[tokenName]?.length);
}

export function isUserWhitelisted(userId: string, tokenName: string): boolean {
    const user = getUser(userId);
    if (!user) return false;
    if (!user.enableSell) return false;

    return user.enableSell[tokenName];
}

export function isUserBanned(userId: string, tokenName: string): boolean {
    const user = getUser(userId);
    if (!user) return false;
    if (!user.bannedCommunities) return false;

    return user.bannedCommunities[tokenName];
}

export function ensureUserUrl(userSlugOrDisplayName: string){
    const user = getUserBySlugOrDisplayName(userSlugOrDisplayName);
    if (!user ) return userSlugOrDisplayName;
    if (!user.slugUrl) return user.displayName;
    return user.slugUrl;
};

export const stringToHash = (str: string): string => {
    const hash = Long.fromInt(5381, true);
    const res = str.split('').reduce((prev, curr) => {
        prev = prev.shiftLeft(5).add(prev).add(Long.fromInt(curr.charCodeAt(0)));
        return prev;
    }, hash);
    return res.toString();
};

export function getUserIdByUsername(username: string, domainName: string): string {
    return stringToHash(username + '@' + domainName);
}

export function getUserIdByDisplayName(displayName: string): string {
    const domainName = getDomainNameByDisplayName(displayName);
    const username = getUsernameByDisplayName(displayName);

    if (!username || !domainName)
        return '';

    return getUserIdByUsername(username, domainName);
}
