
import DbModule from '../db';
import AppCheckModule from '../app-check';
import { ModActivityDbTableRow } from './mod-activity.model';
import { getDocs, limit, orderBy, OrderByDirection, query, QueryConstraint, QueryDocumentSnapshot, startAfter, where, WhereFilterOp } from 'firebase/firestore/lite';
import { Pagination } from '../db/db.model';

export interface GetModActivityOrderBy {
    field: 'timestamp' | 'index';
    direction: OrderByDirection;
}
export interface GetModActivityFilterBy {
    field: 'tokenName' 
    opStr: WhereFilterOp;
    value: string | number;
}

export default class ModActivityService {
        private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule) {
        
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
    }

    async getModActivity(tokenName: string, _startAfter: QueryDocumentSnapshot<ModActivityDbTableRow> | null, _limit: number, _filterBy?: GetModActivityFilterBy[], _orderBy?: GetModActivityOrderBy): Promise<Pagination<ModActivityDbTableRow>> {
        const colRef = this.dbModule.getCollectionReference<ModActivityDbTableRow>('ModActivity');
        const queryConstraints: QueryConstraint[] = [where('tokenName', '==', tokenName)];

        _filterBy?.forEach((filter): void => {
            const { field, opStr, value } = filter;
            queryConstraints.push(where(field, opStr, value));
        });

        if (_orderBy)
            queryConstraints.push(orderBy(_orderBy.field, _orderBy.direction));

        if (_startAfter)
            queryConstraints.push(startAfter(_startAfter));

        queryConstraints.push(limit(_limit));

        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);

        return { hasMore: items.length === _limit, items, lastDoc };

    }
}