import DbModule from '../db';
import AccountModule from '../account';
import StorageModule from '../storage';
import TokenModule from '../token';
import TimeModule from '../time';
import AdServerService, { ServeAdPayload } from './ad-server.service';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';

export default class AdServerModule {
    accountModule: AccountModule;
    tokenModule: TokenModule;
    adServerService: AdServerService;
    timeModule: TimeModule;
    domainName: string;
    private dbModule: DbModule;
    private storageModule: StorageModule;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp, contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule, accountModule: AccountModule, tokenModule: TokenModule, timeModule: TimeModule) {
        this.dbModule = dbModule;
        this.storageModule = storageModule;
        this.domainName = domainName;
        this.adServerService = new AdServerService(contractName, domainName, appCheckModule, dbModule, accountModule);
        this.accountModule = accountModule;
        this.tokenModule = tokenModule;
        this.timeModule = timeModule;
        this.appCheckModule = appCheckModule;
    }

    async serveAd(payload: ServeAdPayload) {
        const res = await this.adServerService.serveAd(payload);
        return res.payload.bannerDetails;
    }
}

