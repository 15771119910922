import React, { FC, Fragment, memo } from 'react';
import Text, { TextProps } from '@creator/ui/components/Text/Text';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';
import { displayTokenFiatPrice } from '@src/model/token-price/helpers';
import { useStoreState } from '@src/model/hooks';
import { DisplayCurrency } from '@creator/sdk/modules/token-price/token-price.model';

export interface TokenPriceProps {
    className?: string;
    textProps?: TextProps;
    tokenName: string;
    tokenAmount: number;
    displayCurrency?: DisplayCurrency;
}

const TokenPrice: FC<TokenPriceProps> = props => {
    const { tokenAmount, tokenName, displayCurrency = 'USD', textProps = {} } = props;

    const price = useStoreState(state => state.tokenPrice.getPrice(tokenName));

    function getDisplayPrice(): string {
        return displayTokenFiatPrice(price, tokenAmount, displayCurrency);
    }

    function renderContent() {
        return (
            <Text translate="no" {...textProps}>
                {getDisplayPrice()}
            </Text>
        );
    }

    return (
        <Fragment>
            {renderContent()}
        </Fragment>
    );
};

export default memo(TokenPrice, propsEqualityFn);
