import React, { Fragment } from 'react';
import { useStoreState } from '@src/model/hooks';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { cn } from '@creator/ui/utils/ui';
import { useTranslation } from 'react-i18next';
import { PostBasicInfoProps } from '../Upvote/PostBasicInfo/PostBasicInfo';
import { getResponseMediaUrl, isMediaType } from '@src/model/response/helpers';
import { useHistory } from 'react-router-dom';
import { MediaPreviewProps } from '@src/basic-components/Media/MediaPreview';
import EmbeddedPost, { EmbeddedPostProps } from './EmbeddedPost';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { RecursivePartial } from '@src/custom';

export interface PostEmbeddedByResponseProps {
    className?: string;
    responseId: string;
    isLoading?: boolean;
    embeddedPostProps?: RecursivePartial<EmbeddedPostProps>;
}

const PostEmbeddedByResponse: FC<PropsWithChildren<PostEmbeddedByResponseProps>> = props => {
    const { children, isLoading, className = '', responseId } = props;

    const { t } = useTranslation();
    const history = useHistory();

    const response = useStoreState(state => state.response.getResponse(responseId), storeEqualityFn);
    const parentPostId = response?.parent || '';

    function getBaseClassname(): string {
        return cn('cursor-pointer', className);
    }

    function getPostBasicInfoProps(): PostBasicInfoProps | undefined {
        if (!response) return;
        const { parentPublisherName = '', parentCreatedAt, parentTokenName } = response;

        return {
            communityName: parentTokenName,
            publisherName: parentPublisherName,
            createdAt: parentCreatedAt,
            tokenName: parentTokenName,
            showTokenImage: true
        };
    }


    function getMediaPreviewProps(): MediaPreviewProps | undefined {
        if (!response) return;

        if (!isMediaType(response.parentType)) return;

        return {
            type: response.parentType,
            url: getResponseMediaUrl(parentPostId),
            className: 'flex',
            imageProps: {
                className: ''
            }
        };
    }

    function getPostTitle(): string {
        if (!response) return '';
        const { parentTitle } = response;
        return parentTitle;
    }

    function renderContent() {
        const { embeddedPostProps = {} } = props;
        if (!response) return;

        const _props = mergeProps<[EmbeddedPostProps, RecursivePartial<EmbeddedPostProps>]>({
            title: t('postEmbeddedByPost.title'),
            className: getBaseClassname(),
            postTitle: getPostTitle(),
            mediaPreviewProps: getMediaPreviewProps() as MediaPreviewProps,
            postId: parentPostId,
            postBasicInfoProps: getPostBasicInfoProps() as PostBasicInfoProps,
            tokenName: response.parentTokenName
        }, embeddedPostProps);

        return <EmbeddedPost {..._props} />;
    }

    return (
        <Fragment>
            {renderContent()}
        </Fragment>
    );
};

export default PostEmbeddedByResponse;
