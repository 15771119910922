import { useStoreState } from '@src/model/hooks';
import React, { FC, PropsWithChildren } from 'react';
import Comment, { CommentProps } from '../Post/Comment/Comment';
import { useHistory } from 'react-router-dom';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { cn } from '@creator/ui/utils/ui';
import { ensurePostUrl } from '@src/model/upvote/helpers';

export interface SuperCommentListItemProps {
    className?: string;
    superCommentId: string;
    lineClamp?: boolean;
    goToPostOnClick?: boolean;
}

const SuperCommentListItem: FC<PropsWithChildren<SuperCommentListItemProps>> = props => {
    const { children, goToPostOnClick = false, lineClamp = false, className = '', superCommentId } = props;

    const superComment = useStoreState(state => state.superComment.getSuperComment(superCommentId));

    const history = useHistory();

    function getBaseClassname(): string {
        return cn('', className);
    }

    function onCommentBubleClick(): void {
        if (!superComment || !goToPostOnClick) return;
        const { postId, tokenName } = superComment;
        return history.push(`/${ensureTokenUrl(tokenName)}/${ensurePostUrl(postId)}`);
    }

    function renderComment() {
        if (!superComment) return;

        const { createdAt, content, status, publisherName, tokenName, tipAmount } = superComment;

        const commentProps: CommentProps = {
            commentBubbleProps: {
                onClick: () => onCommentBubleClick(),
                className: `bg-yellow-100 dark:bg-[#0e686d] dark:bg-opacity-40 ${goToPostOnClick && 'cursor-pointer'}`,
                commentContentclassName: `text-gray-900 dark:text-gray-50 ${lineClamp && 'line-clamp-2'}`,
                userDisplayNameWithLinkProps: {
                    userDisplayNameProps: {
                        textProps: {
                            className: 'text-gray-900 dark:text-gray-50'
                        }
                    }
                }
            },
            superCommentId,
            createdAt,
            publisherName,
            content,
            status,
            tipAmount,
            tokenName
        };

        return (
            <Comment {...commentProps} />
        );
    }

    return (
        <div className={getBaseClassname()}>
            {renderComment()}
        </div>
    );
};

export default SuperCommentListItem;
