export function getTotalDecimalDigits(string: string): number {
    if (string.indexOf('.') > 0)
        return string.toString().split('.')[1].length;
    return 0;
}

export function roundDownToDecimalDigits(number: number, decimalDigits: number): number {
    const factor = Math.pow(10, decimalDigits);
    return Math.floor(number * factor) / factor;
}

export function roundToDecimalDigits(num: number, decimalDigits: number): number {
    return +(Math.round(Number(num + `e+${decimalDigits}`)) + `e-${decimalDigits}`);
}

export function roundToDecimalDigitsAmounts(num: number): number {
    return trancateToDecimalDigitsAmounts(num, num < 0.1);
}

export function trancateToDecimalDigitsAmounts(num: number, use4Digits = false): number {
    const power = use4Digits ? 10000 : 100;
    return Math.floor(num * power) / power;
}

export function roundUp(x: number): number {
    return Math.ceil(parseFloat(x.toFixed(1)));
}

export function abbreviatedFormatter(count: number, decimals = 1): string {
    if (count < 1) return count.toString();
    const COUNT_ABBRS = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    const i = count === 0 ? count : Math.floor(Math.log(count) / Math.log(1000));
    const result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
    return `${result}${COUNT_ABBRS[i]}`;
}

export function ordinalSuffixOf(i: number): string {
    const j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11)
        return i + 'st';

    if (j === 2 && k !== 12)
        return i + 'nd';

    if (j === 3 && k !== 13)
        return i + 'rd';

    return i + 'th';
}

export function isNumber(value: string | number): boolean {
    return value !== null && !isNaN(Number(value.toString()));
}


export function padTwoDigits(n: number): string {
    return (n < 10 ? '0' : '') + n;
}

export function relativeDiff(a: number, b: number): number {
    return 100 * Math.abs((a - b) / ((a + b) / 2));
}

export function displayBalance(num: string): string {
    if (Number(num) === 0) return '0';
    if (parseFloat(num) % 1 === 0) return Number(toFixedNoRounding(parseFloat(num).toString(), 0)).toLocaleString();
    if (parseFloat(num) > 1) return Number(toFixedNoRounding(parseFloat(num).toString(), 2)).toLocaleString();

    const numberOfZeroes = Math.floor(Math.abs(Math.log10(Number(num))));
    return toFixedNoRounding(parseFloat(num).toString(), numberOfZeroes + 4).toLocaleString();
}

export function toFixedNoRounding(num: string, fixed: number): string {
    const TO_FIXED_MAX = 100;
    num = parseFloat(num).toFixed(TO_FIXED_MAX);
    const dotIndex = num.indexOf('.');
    num = num.substring(0, dotIndex + fixed + 1);
    return scientificToDecimal(Number.parseFloat(num));
}


function scientificToDecimal(num: number): string {
    const nsign = Math.sign(num);
    //remove the sign
    num = Math.abs(num);
    let number = num.toString();
    //if the number is in scientific notation remove it
    if (/\d+\.?\d*e[\+\-]*\d+/i.test(num.toString())) {
        const zero = '0';
        const parts = String(num).toLowerCase().split('e'); //split into coeff and exponent
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const e: any = parts.pop(); //store the exponential part
        let l = Math.abs(e); //get the number of zeros
        const sign = e / l;
        const coeffArray = parts[0].split('.');
        if (sign === -1) {
            l = l - coeffArray[0].length;
            if (l < 0)
                number = coeffArray[0].slice(0, l) + '.' + coeffArray[0].slice(l) + (coeffArray.length === 2 ? coeffArray[1] : '');

            else
                number = zero + '.' + new Array(l + 1).join(zero) + coeffArray.join('');

        }
        else {
            const dec = coeffArray[1];
            if (dec)
                l = l - dec.length;
            if (l < 0)
                number = coeffArray[0] + dec.slice(0, l) + '.' + dec.slice(l);
            else
                number = coeffArray.join('') + new Array(l + 1).join(zero);

        }
    }

    return nsign < 0 ? '-' + number : number;
}

export function isNearBy(reference: number, value: number, difference: number): boolean {
    return value >= reference + difference || value <= reference - difference;
}