import React, { FC, memo, PropsWithChildren, useRef } from 'react';
import Icon, { IconProps } from '../Icon/Icon';
import { cn } from '@creator/ui/utils/ui';
import { getYahooVideoAdByLocation, getYahooVideoAdTagUrl } from '@src/model/config/helpers'; // Import the new helper
import { isMobile } from '@src/utils/utils';
import Image from '@creator/ui/components/Image/Image';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { useGoogleIMA } from '@src/hooks/useGoogleIMA';

export type EditorJSMediaBlock = 'video' | 'audio';

export interface MediaProps {
    className?: string;
    type?: EditorJSMediaBlock;
    poster?: string;
    src: string;
    audioIconProps?: IconProps;
    autoplay?: boolean;
    muted?: boolean;
    adTargeting?: { key: string; value: string }; // Custom targeting key-value pair
}

const Media: FC<PropsWithChildren<MediaProps>> = props => {
    const { className = '', src, poster = '', type = 'video', audioIconProps = {}, adTargeting } = props;

    const hasAds = Boolean(getAdTagUrl());
    const videoRef = useRef<HTMLVideoElement>(null);
    const adContainerRef = useRef<HTMLDivElement>(null);
    const playButtonRef = useRef<HTMLDivElement>(null);

    // Initialize the hook. It will automatically set up IMA once the script is ready.
    useGoogleIMA({ videoRef, adContainerRef, playButtonRef, adTagUrl: getAdTagUrl() });

    function getAdTagUrl() {
        // Get the base ad URL for desktop and mobile
        const videoAdLocation = isMobile() ? 'main_mw_video' : 'main_dt_video';
        const videoAdBaseUrl = getYahooVideoAdByLocation(videoAdLocation);
        if (!videoAdBaseUrl) return null;
        return getYahooVideoAdTagUrl(videoAdBaseUrl, adTargeting);
    }

    const renderVideo = () => {
        // if (hasAds) return <IMAPlayer />
        return (
            <div className="">
                <div className="relative z-10 mt-4 aspect-video">
                    <video
                        autoPlay={hasAds ? undefined : true}
                        muted={true}
                        playsInline={true}
                        ref={videoRef}
                        className="w-full h-full overflow-hidden"
                        controls
                        poster={poster}
                        src={src}
                    />
                    <div ref={adContainerRef} className="absolute top-0 left-0 w-full h-full"></div>
                    <div className="absolute top-0 left-0 items-center justify-center hidden w-full h-full" ref={playButtonRef}>
                    </div>
                </div>
            </div>
        );
    };

    const renderAudioPoster = () => {
        if (poster) return <Image className="w-full mb-2" src={poster} />;

        const mergedIconProps = mergeProps<[IconProps, Partial<IconProps>]>(
            {
                svgFill: false,
                name: 'volume',
                className: 'text-[120px]'
            },
            audioIconProps
        );

        return (
            <div className="flex items-center justify-center w-full text-gray-50 bg-primary-500 aspect-video">
                <Icon {...mergedIconProps} />
            </div>
        );
    };

    const renderAudio = () => {
        return (
            <div className="relative">
                {renderAudioPoster()}
                <audio className="w-full" controls src={src} />
            </div>
        );
    };

    const renderContent = () => {
        if (type === 'audio') return renderAudio();

        return renderVideo();
    };

    return <div className={cn('relative', className)}>{renderContent()}</div>;
};

export default memo(Media, propsEqualityFn);