import React, { FC, Fragment, useEffect, useState } from 'react';
import { useStoreState } from '@src/model/hooks';
import { isExternal } from '@src/utils/url-utils/url-utils';
import { Redirect, useHistory } from 'react-router-dom';
import { getDomainName } from '@src/config';

const HomepageWrapper: FC = () => {
    const domainConfig = useStoreState(state => state.config.domainConfig);
    const history = useHistory();

    const domainPublicData = useStoreState(state => state.domain.getPublicData(getDomainName()));
    const [shouldRenderHomepage, setShouldRenderHomepage] = useState(false);

    useEffect(() => {
        if (!domainConfig) return;

        if (window.location.pathname === '/' && domainConfig.homepageRedirect) {
            if (isExternal(domainConfig.homepageRedirect)) return window.location.assign(domainConfig.homepageRedirect);
            return history.replace(domainConfig.homepageRedirect);
        }

        setShouldRenderHomepage(true);
    }, [domainConfig]);

    function renderContent() {
        if (!domainConfig || !shouldRenderHomepage || !domainPublicData) return null;
        return <Redirect to={'/bbs/?tab=topBbs'} />;
    }

    return (
        <Fragment>
            {renderContent()}
        </Fragment>
    );
};

export default HomepageWrapper;
