
// useGoogleIMA.ts
import { useEffect, useLayoutEffect, useRef } from 'react';
import useResizeObserver, { Size } from '@creator/ui/hooks/useResizeObserver';
import { useScript } from './use-script';

export interface UseGoogleIMAProps {
    videoRef: React.RefObject<HTMLVideoElement>;
    adContainerRef: React.RefObject<HTMLDivElement>;
    playButtonRef: React.RefObject<HTMLDivElement>;
    adTagUrl: string | null;
}

export const useGoogleIMA = ({ videoRef, adContainerRef, playButtonRef, adTagUrl, }: UseGoogleIMAProps) => {
    const scriptStatus = useScript('https://imasdk.googleapis.com/js/sdkloader/ima3.js');
    const videoSize = useResizeObserver(videoRef);

    // Persist IMA objects across renders using useRef.
    const adsManagerRef = useRef<any>(null);
    const adsLoaderRef = useRef<any>(null);
    const adDisplayContainerRef = useRef<any>(null);
    const adsInitializedRef = useRef<boolean>(false);
    const autoplayAllowedRef = useRef<boolean>(false);
    const autoplayRequiresMutedRef = useRef<boolean>(false);

    const initDesktopAutoplayExample = () => {
        if (!videoRef.current || !playButtonRef.current) return;

        playButtonRef.current.addEventListener('click', () => {
            if (adDisplayContainerRef.current)
                adDisplayContainerRef.current.initialize();

            adsInitializedRef.current = true;
            videoRef.current?.load();
            playAds();
        });

        setUpIMA();
        checkAutoplaySupport();
    };

    const checkAutoplaySupport = () => {
        if (!videoRef.current) return;
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined)
            playPromise.then(onAutoplayWithSoundSuccess).catch(onAutoplayWithSoundFail);

    };

    const onAutoplayWithSoundSuccess = () => {
        videoRef.current?.pause();
        autoplayAllowedRef.current = true;
        autoplayRequiresMutedRef.current = false;
        autoplayChecksResolved();
    };

    const onAutoplayWithSoundFail = () => {
        checkMutedAutoplaySupport();
    };

    const checkMutedAutoplaySupport = () => {
        if (!videoRef.current) return;
        // videoRef.current.volume = 0;
        videoRef.current.muted = true;
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined)
            playPromise.then(onMutedAutoplaySuccess).catch(onMutedAutoplayFail);
    };

    const onMutedAutoplaySuccess = () => {
        videoRef.current?.pause();
        autoplayAllowedRef.current = true;
        autoplayRequiresMutedRef.current = true;
        autoplayChecksResolved();
    };

    const onMutedAutoplayFail = () => {
        if (!videoRef.current) return;
        // videoRef.current.volume = 1;
        videoRef.current.muted = false;
        autoplayAllowedRef.current = false;
        autoplayRequiresMutedRef.current = false;
        autoplayChecksResolved();
    };

    const setUpIMA = () => {
        createAdDisplayContainer();
        // Create ads loader.
        adsLoaderRef.current = new window.google.ima.AdsLoader(adDisplayContainerRef.current);
        // Listen for ads loaded and error events.
        adsLoaderRef.current.addEventListener(window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, onAdsManagerLoaded, false);
        adsLoaderRef.current.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, onAdError, false);
        // Listen for content ended event.
        if (videoRef.current)
            videoRef.current.onended = contentEndedListener;

    };

    const contentEndedListener = () => {
        if (videoRef.current)
            videoRef.current.onended = null;

        if (adsLoaderRef.current)
            adsLoaderRef.current.contentComplete();

    };

    const autoplayChecksResolved = () => {
        const adsRequest = new window.google.ima.AdsRequest();
        adsRequest.adTagUrl = adTagUrl

        const videoWidth = videoRef.current?.offsetWidth;
        const videoHeight = videoRef.current?.offsetHeight;

        adsRequest.linearAdSlotWidth = videoWidth;
        adsRequest.linearAdSlotHeight = videoHeight;
        // For non-linear ads, you might want different dimensions, or calculate based on your layout.
        adsRequest.nonLinearAdSlotWidth = videoWidth;
        adsRequest.nonLinearAdSlotHeight = videoHeight * 0.25; // e.g., 25% of the container height

        adsRequest.setAdWillAutoPlay(autoplayAllowedRef.current);
        adsRequest.setAdWillPlayMuted(autoplayRequiresMutedRef.current);
        adsLoaderRef.current.requestAds(adsRequest);
    };

    const createAdDisplayContainer = () => {
        if (!adContainerRef.current || !videoRef.current) return;
        adDisplayContainerRef.current = new window.google.ima.AdDisplayContainer(adContainerRef.current, videoRef.current);
    };

    const playAds = () => {
        try {
            if (!adsInitializedRef.current && adDisplayContainerRef.current) {
                adDisplayContainerRef.current.initialize();
                adsInitializedRef.current = true;
            }

            const videoWidth = videoRef.current?.offsetWidth;
            const videoHeight = videoRef.current?.offsetHeight;


            // Initialize ads manager.
            adsManagerRef.current.init(videoWidth, videoHeight, window.google.ima.ViewMode.NORMAL);
            adsManagerRef.current.start();
        } catch (adError) {
            videoRef.current?.play();
        }
    };

    const onAdsManagerLoaded = (adsManagerLoadedEvent: any) => {
        const adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
        adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
        adsRenderingSettings.enablePreloading = true;

        adsManagerRef.current = adsManagerLoadedEvent.getAdsManager(videoRef.current, adsRenderingSettings);
        const adVolume = autoplayAllowedRef.current && autoplayRequiresMutedRef.current ? 0 : 1;
        adsManagerRef.current.setVolume(adVolume);
        adsManagerRef.current.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, onAdError);
        adsManagerRef.current.addEventListener(window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, onContentPauseRequested);
        adsManagerRef.current.addEventListener(window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, onContentResumeRequested);
        adsManagerRef.current.addEventListener(window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED, onAdEvent);
        adsManagerRef.current.addEventListener(window.google.ima.AdEvent.Type.LOADED, onAdEvent);
        adsManagerRef.current.addEventListener(window.google.ima.AdEvent.Type.STARTED, onAdEvent);
        adsManagerRef.current.addEventListener(window.google.ima.AdEvent.Type.COMPLETE, onAdEvent);

        if (autoplayAllowedRef.current)
            playAds();
        else
            playButtonRef.current?.classList.remove('hidden');

    };

    const onAdEvent = (adEvent: any) => {
        const ad = adEvent.getAd();
        if (adEvent.type === window.google.ima.AdEvent.Type.LOADED) {
            if (ad && !ad.isLinear())
                videoRef.current?.play();
        }
        if (adEvent.type === window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED)
            adContainerRef.current!.style.pointerEvents = 'none';

    };

    const onAdError = (adErrorEvent?: any) => {
        console.error(adErrorEvent.getError());
        destroyAds();
    };

    const destroyAds = () => {
        if (adsManagerRef.current)
            adsManagerRef.current.destroy();
        
        adContainerRef.current?.remove();
        
        videoRef.current?.play();
    };

    const onContentPauseRequested = () => {
        videoRef.current?.pause();
        if (videoRef.current)
            videoRef.current.onended = null;

    };

    const onContentResumeRequested = () => {
        videoRef.current?.play();
        if (videoRef.current)
            videoRef.current.onended = contentEndedListener;

    };

    const onVideoResize = (videoSize: Size) => {
        if (!adsManagerRef.current) return;
        const { width, height } = videoSize;
        adsManagerRef.current.resize(width, height, window.google.ima.ViewMode.NORMAL);
    };

    useEffect(() => {
        if (scriptStatus === 'error') return destroyAds();
        if (scriptStatus !== 'ready') return;
        initDesktopAutoplayExample();
        return () => {
            playButtonRef.current?.removeEventListener('click', () => { });
        };
    }, [scriptStatus]);

    useLayoutEffect(() => {
        if (!videoSize) return;
        onVideoResize(videoSize);
    }, [videoSize]);

    // Expose the playAds function so it can be triggered externally if needed.
    return { playAds };
};
