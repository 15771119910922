import React, { FC, PropsWithChildren } from 'react';
import { noop } from '@src/utils/utils';

export interface ProgressBarProps extends PropsWithChildren {
    className?: string;
    progress: number; // e.g. 0.2
    onClick: () => void;
    progressFillClasName?: string;
}

const ProgressBar: FC<ProgressBarProps> = props => {
    const { onClick = noop, children, className = '', progress, progressFillClasName = '' } = props;

    // Container: relative positioning, full width, fixed height (32px = h-8), rounded corners, overflow-hidden,
    // plus shadow, border and background classes for light/dark modes.
    const containerClasses = `relative w-full h-8 rounded-lg overflow-hidden transition shadow dark:shadow bg-white dark:bg-gray-900 border border-gray-100 dark:border-gray-700 text-gray-800 dark:text-gray-50 ${className}`;

    // Progress fill: absolute positioning on the left, full height, transition for smooth animation.
    // Use "rounded-l-lg" for the left side, and conditionally "rounded-r-lg" when progress is complete.
    const fillClasses = `absolute left-0 top-0 h-full transition-all duration-300 ease rounded-l-lg ${progress === 1 ? 'rounded-r-lg' : ''} ${progressFillClasName}`;

    return (
        <div className={containerClasses} onClick={onClick}>
            <div
                className={fillClasses}
                style={{
                    width: `${progress * 100}%`,
                    transform: progress === 0 ? 'translate3d(-100%, 0, 0)' : 'translate3d(0, 0, 0)'
                }}
            />
            {children}
        </div>
    );
};

export default ProgressBar;
