import React, { FC, PropsWithChildren, useContext, useRef, useEffect } from 'react';
import Icon from '../Icon/Icon';
import Text from '@creator/ui/components/Text/Text';
import { noop } from '@src/utils/utils';
import { ModalContext } from '@src/context/modal-context';
import { createPortal } from 'react-dom';
import { UseModal } from '@src/hooks/use-modal';
import { useOnClickOutside } from '@src/hooks/use-on-click-outside';

export type ModalSize = 'sm' | 'md' | 'lg' | 'xl' | 'auto';

export interface ModalProps {
    title?: string;
    size?: ModalSize;
    onClose?: () => void;
    disableClose?: boolean;
}

function getSizeClass(size: ModalSize) {
    const sizes: Record<ModalSize, string> = {
        sm: 'lg:max-w-lg',
        md: 'lg:max-w-xl',
        lg: 'max-w-2xl',
        xl: 'lg:max-w-3xl',
        auto: 'lg:max-w-full'
    };
    return `max-w-full ${sizes[size]}`;
}

const Modal: FC<PropsWithChildren<ModalProps>> = props => {
    const {
        disableClose = false,
        onClose = noop,
        title = '',
        size = 'md'
    } = props;

    const { modalContent, handleModal, modal } = useContext<UseModal>(ModalContext);
    const ref = useRef(null);

    useOnClickOutside(ref, onClickOutside);

    useEffect(() => {
        if (modal)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = '';

        return () => {
            document.body.style.overflow = '';
        };
    }, [modal]);

    if (!modal) return null;

    function onClickOutside(): void {
        if (disableClose) return;
        handleModal(null);
    }

    function renderCloseIcon() {
        if (disableClose) return null;
        return (
            <div
                className="absolute p-1 text-xl text-gray-900 transition cursor-pointer top-1 right-2 dark:text-gray-50 hover:text-gray-600 dark:hover:text-gray-200"
                onClick={() => { handleModal(null); onClose(); }}>
                <Icon
                    className=""
                    name="close"
                />
            </div>
        );
    }

    return createPortal(
        <div className="fixed inset-0 z-50 flex justify-center overflow-auto bg-black bg-opacity-60">
            <div ref={ref} className={`w-full p-4 py-8 relative h-fit transition lg:mt-16 lg:mb-2  bg-white dark:bg-gray-900 shadow-md rounded-lg ${getSizeClass(size)}`}>
                <Text className="block text-lg font-bold text-center text-black transition dark:text-white" enableMultiline={true}>
                    {title}
                </Text>
                {renderCloseIcon()}
                <div className="flex-1 px-4 transition-all">
                    {modalContent}
                </div>
            </div>
        </div>,
        document.querySelector('#modal-root') as Element
    );
};

export default Modal;