import React, { type FC } from 'react'
import { cn } from '../../utils/ui';
import { PropsWithChildren } from 'react';

export interface CardProps extends PropsWithChildren {
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Card: FC<CardProps> = (props) => {
    const { children, onClick, className = '' } = props;

    return (
        <div onClick={onClick} className={cn('transition bg-white dark:bg-gray-950 text-gray-900 dark:text-gray-50 border border-gray-200 dark:border-gray-700', className)}>
            {children}
        </div>
    )
}

export default Card;