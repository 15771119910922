import { bp } from '@src/sizes/sizes';

export function noop(): void {
    //
}

export function prop<T, K extends keyof T>(obj: T, key: K): T[K] {
    return obj[key];
}

export type ValueOf<T> = T[keyof T];

export function isMobile(): boolean {
    return window.innerWidth < parseInt(bp.lg);
}

// utils that check if device is phisycal 
export function isNativeMobile(): boolean {
    return 'ontouchstart' in window && window.screen.availWidth < parseInt(bp.lg);
}

export function sleep(ms): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

