
import DbModule from '../db';
import AccountModule, {  } from '../account';
import StorageModule from '../storage';
import TokenModule from '../token';
import TimeModule from '../time';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';
import FlairService, { AttachFlairApiRequestPayload, CreateFlairApiRequestPayload, DeleteFlairApiRequestPayload } from './flair.service';
import { Flair } from './flair.model';

export interface CreatePostPayload {
    title: string;
    tokenName: string;
    publisher: string;
    content?: string;
    flairName?: string;
}

export default class FlairModule {
    accountModule: AccountModule;
    tokenModule: TokenModule;
    flairService: FlairService;
    timeModule: TimeModule;
    domainName: string;
        private dbModule: DbModule;
    private storageModule: StorageModule;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule, accountModule: AccountModule, tokenModule: TokenModule, timeModule: TimeModule) {
        
        this.dbModule = dbModule;
        this.storageModule = storageModule;
        this.domainName = domainName;
        this.flairService = new FlairService(contractName, domainName, appCheckModule, dbModule, accountModule);
        this.accountModule = accountModule;
        this.tokenModule = tokenModule;
        this.timeModule = timeModule;
        this.appCheckModule = appCheckModule;
    }


    async createFlair(payload: CreateFlairApiRequestPayload): Promise<Flair> {
        const res = await this.flairService.createFlair(payload)
            .catch((e: Error) => {
                // if (e.message.includes('Token with this name already exists')) throw new Error('tokenAlreadyExists');
                throw e;
            });

        return res.payload;
    }

    async deleteFlair(payload: DeleteFlairApiRequestPayload): Promise<Flair> {
        const res = await this.flairService.deleteFlair(payload)
            .catch((e: Error) => {
                // if (e.message.includes('Token with this name already exists')) throw new Error('tokenAlreadyExists');
                throw e;
            });

        return res.payload;
    }

    async attachFlair(payload: AttachFlairApiRequestPayload): Promise<Flair> {
        const res = await this.flairService.attachFlair(payload)
            .catch((e: Error) => {
                // if (e.message.includes('Token with this name already exists')) throw new Error('tokenAlreadyExists');
                throw e;
            });

        return res.payload;
    }
}
