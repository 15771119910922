import { thunk, Action, Thunk, action, Computed, computed } from 'easy-peasy';
import { Pagination } from '@creator/sdk/utils/utils';
import { GetUsersInfoOrderBy } from '@creator/sdk/modules/admin/admin.service';
import { loadUsersInfo, loadOwnerRBalance, claimFunds, broadcastEmail } from './actions';
import { getUnique } from '@src/utils/array-utils/array-utils';
import { StoreModel, StoreModelSetPayload } from '..';
import { ClaimFundsPayload, BroadcastEmailPayload } from '@creator/sdk/modules/admin';
import { UserInfo } from '@creator/sdk/modules/account/account.model';
import { DocumentSnapshot } from '@creator/sdk/modules/db/db.model';

export interface LoadUsersInfoPayload {
    lowerBound: DocumentSnapshot | null;
    limit: number;
    filterBy: any[];
    orderBy: GetUsersInfoOrderBy;
    setUserEach?: boolean;
}

export interface AdminModel {
    usersInfo: { [username: string]: UserInfo };
    usersInfoSearchResultLastDoc: Record<string, unknown>;

    set: Action<AdminModel, StoreModelSetPayload>;
    setUserInfo: Action<AdminModel, UserInfo>;
    setUsersInfoSearchResult: Action<AdminModel, string[]>;
    setUsersInfoSearchResultLastDoc: Action<AdminModel, Record<string, unknown> | DocumentSnapshot>;

    getUserInfo: Computed<AdminModel, (userId: string) => UserInfo | undefined>;
    getUsersInfoSearchResult: Computed<AdminModel, (UserInfo | undefined)[]>;

    usersInfoSearchResult: string[];
    concatUsersInfoSearchResult: Action<AdminModel, string[]>;
    loadUsersInfo: Thunk<AdminModel, LoadUsersInfoPayload, any, AdminModel, Promise<Pagination<UserInfo>>>;
    claimFunds: Thunk<AdminModel, ClaimFundsPayload, null, StoreModel>;
    broadcastEmail: Thunk<AdminModel, BroadcastEmailPayload>;
}

const AdminModel: AdminModel = {
    usersInfo: {},
    usersInfoSearchResult: [],
    usersInfoSearchResultLastDoc: {},

    getUserInfo: computed(state => userId => state.usersInfo[userId]),
    getUsersInfoSearchResult: computed(state => state.usersInfoSearchResult.map(userId => state.getUserInfo(userId))),

    setUserInfo: action((state, payload) => {
        state.usersInfo[payload.id] = payload;
    }),

    setUsersInfoSearchResult: action((state, lastDoc) => {
        state.usersInfoSearchResult = lastDoc;
    }),

    setUsersInfoSearchResultLastDoc: action((state, lastDoc) => {
        state.usersInfoSearchResultLastDoc = lastDoc;
    }),

    set: action((state, payload) => {
        const { key, value } = payload;
        state[key] = value;
    }),

    concatUsersInfoSearchResult: action((state, searchResult) => {
        state.usersInfoSearchResult = getUnique(state.usersInfoSearchResult.concat(searchResult));
    }),

    loadUsersInfo: thunk(async (actions, payload) => {
        const { lowerBound, limit, filterBy, orderBy } = payload;
        const { hasMore, items, lastDoc } = await loadUsersInfo(lowerBound, limit, filterBy, orderBy);
        const ids: string[] = [];
        items.forEach(userInfo => {
            actions.setUserInfo(userInfo);

            ids.push(userInfo.id);
        });

        actions.concatUsersInfoSearchResult(ids);

        if (lastDoc)
            actions.setUsersInfoSearchResultLastDoc(lastDoc);

        return { hasMore, items, lastDoc };
    }),

    claimFunds: thunk(async (actions, payload, helpers) => {
        const receipt = await claimFunds(payload);
        return receipt;
    }),

    broadcastEmail: thunk(async (actions, payload) => {
        const receipt = await broadcastEmail(payload);
        return receipt;
    })
};

export default AdminModel;
