
import { Pagination as PaginationNew } from '../db/db.model';
import TokenService, { GetTokensFilterBy } from './token.service';
import DbModule from '../db';
import StorageModule from '../storage';
import { BbsToken, Token, TokenDbTableRow } from './token.model';
import AccountModule from '../account';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';
import { OrderByDirection, QueryDocumentSnapshot } from 'firebase/firestore/lite';

export interface GetTokensOrderBy {
    field: 'numberOfHolders' | 'cashierBalance.USDT' | 'cashierBalance.USDB' | 'cashierBalance.BBS' | 'createdAt';
    direction: OrderByDirection;
}

export default class TokenModule {
    tokenService: TokenService;
    private domainName: string;
    private storageModule: StorageModule;
    private accountModule: AccountModule;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp, contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule, accountModule: AccountModule) {
        this.tokenService = new TokenService(contractName, domainName, appCheckModule, dbModule, accountModule);
        this.storageModule = storageModule;
        this.accountModule = accountModule;

        this.domainName = domainName;
        this.appCheckModule = appCheckModule;
    }


    async getBbsToken(url: string): Promise<BbsToken> {
        const response = await fetch(url);

        if (!response.ok)
            throw new Error(`Error fetching BBS token: ${response.statusText}`);

        try {
            const data = await response.json();
            return { ...data, bbsLink: url };
        } catch (error) {
            console.error(error);
            throw new Error(`Error fetching BBS token: ${response.statusText}`);
        }
    }

    async getToken(tokenName: string): Promise<Token> {
        const token = await this.tokenService.getToken(tokenName);
        if (!token) throw new Error('tokenNotFound');
        return token;
    }

    async getTokenAbout(tokenName: string): Promise<string> {
        const token = await this.getToken(tokenName);
        if (!token.aboutURI) return '';

        if (!token.aboutURI.startsWith('http')) return token.aboutURI;

        const path = this.storageModule.getFileUrlByPath(token.aboutURI);
        try {
            const response = await fetch(path);
            if (!response.ok) throw new Error('failed to load token about');
            const data = await response.text();
            return data;
        } catch (error) {
            console.error('failed to load token about', token.aboutURI);
            return '';
        }
    }

    async getTokens(startAfter: QueryDocumentSnapshot<TokenDbTableRow> | null, limit = 10, orderBy?: GetTokensOrderBy, filterBy?: GetTokensFilterBy[]): Promise<PaginationNew<Token>> {
        const { hasMore, items, lastDoc } = await this.tokenService.getTokensCache(startAfter, limit, orderBy, filterBy);

        return { hasMore, items, lastDoc };
    }
}