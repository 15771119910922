import React, { FC, Fragment, memo, PropsWithChildren } from 'react';
import { getMediaPoster } from '@src/model/upvote/helpers';
import Icon, { IconProps } from '../Icon/Icon';
import { EditorJSMediaBlock } from './Media';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { cn } from '@creator/ui/utils/ui';
import Image, { ImageProps } from '@creator/ui/components/Image/Image';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

export interface MediaPreviewProps {
    className?: string;
    url: string;
    image?: string;
    animation?: string;
    type?: EditorJSMediaBlock;
    imageProps?: Partial<ImageProps>;
    audioIconProps?: Partial<IconProps>;
}

const MediaPreview: FC<PropsWithChildren<MediaPreviewProps>> = props => {
    const { url, className = '', type = 'video' } = props;

    function getBaseClassname(): string {
        return cn('relative w-full h-full group overflow-hidden', className);
    }

    function renderImage() {
        const { imageProps = {}, image } = props;
        if (type === 'audio') return renderAudioImage();

        const _imageProps = mergeProps<[ImageProps, Partial<ImageProps>]>({
            src: image ? image : getMediaPoster(url),
            className: ''
        }, imageProps);

        return (
            <Image {..._imageProps} />
        );
    }

    function renderPreviewAnimation() {
        const { animation } = props;
        if (!animation) return null;

        return <Image className="absolute top-0 left-0 hidden w-full h-full transition opacity-0 z-1 group-hover:opacity-100" src={animation} />;
    }

    function renderAudioImage() {
        const { audioIconProps = {} } = props;
        const iconProps = mergeProps<[IconProps, Partial<IconProps>]>({
            svgFill: false,
            name: 'volume',
            className: 'text-[80px]'
        }, audioIconProps);

        return (
            <div className="flex items-center justify-center w-full h-full text-gray-50 bg-primary-500">
                <Icon {...iconProps} />
            </div>
        );
    }

    function renderPlayIcon() {
        if (type === 'audio')
            return <Icon svgFill={false} name="play" className="z-10 text-xl transition group-hover:scale-125 text-gray-50 drop-shadow" />;

        return (
            <Icon svgFill={false} name="play" className="z-10 text-4xl transition group-hover:scale-125 text-gray-50 drop-shadow" />
        );
    }

    function renderVideoPreview() {
        return (
            <Fragment>
                {renderImage()}
                {renderPreviewAnimation()}
                <div className="absolute flex text-6xl translate-x-1/2 -translate-y-1/2 top-1/2 right-1/2">
                    {renderPlayIcon()}
                </div>
            </Fragment>
        );
    }


    return (
        <div className={getBaseClassname()}>
            {renderVideoPreview()}
        </div>
    );
};

export default memo(MediaPreview, propsEqualityFn);
