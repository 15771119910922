import React, { FC, ChangeEvent, useState, useEffect, createRef } from 'react';
import ProfileImage from '@src/components/ProfileImage/ProfileImage';
import useKeyPress from '@src/hooks/use-key-press';
import { User } from '@creator/sdk/modules/account/account.model';
import UserDisplayName from '@creator/ui/components/UserDisplayName/UserDisplayName';

export interface UsersSearchDropDownProps {
    className?: string;
    users: User[];
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onUserClick: (user: User) => void;
    tokenName: string;
}

const UsersSearchDropDown: FC<UsersSearchDropDownProps> = props => {
    const { tokenName, users = [], onUserClick } = props;
    
    const ref = createRef<HTMLDivElement>();

    const [targetedUser, setTargetedUser] = useState(0);
    const arrowUp = useKeyPress({ key: 'ArrowUp' });
    const arrowDown = useKeyPress({ key: 'ArrowDown' });
    const enter = useKeyPress({ key: 'Enter' });
    const esc = useKeyPress({ key: 'Escape' });

    useEffect(() => {
        if (arrowUp)
            onArrowUp();
        if (arrowDown)
            onArrowDown();
        if (enter && users[targetedUser])
            onUserClick(users[targetedUser]);
        if (esc)
            onEsc();

    }, [arrowUp, arrowDown, enter]);

    function onArrowUp(): void {
        if (targetedUser <= 0) return;
        const number = targetedUser - 1;
        setTargetedUser(number);
        const node = ref.current?.childNodes[number] as HTMLElement | null;
        if (node)
            node.focus();
    }

    function onArrowDown(): void {
        const number = targetedUser + 1;
        if (number > users.length - 1) return;
        setTargetedUser(number);
        const node = ref.current?.childNodes[number] as HTMLElement | null;
        if (node)
            node.focus();
    }

    function renderUser(user: User, index: number) {
        const { displayName } = user;
        if (!displayName) return null;
        return (
            <div tabIndex={index} onClick={() => onUserClick(user)} key={user.id} className={`flex px-4 py-2 space-x-2 items-center cursor-pointer hover:bg-primary-100 dark:hover:bg-primary-800 ${targetedUser === index ? 'bg-primary-100 dark:bg-primary-800 dark:hover:bg-primary-800' : ''}`}>  
                <ProfileImage tokenName={tokenName} className="w-8 h-8" displayName={user.displayName} />
                <UserDisplayName textProps={{ className: 'text-sm font-medium' }} displayName={user.displayName} />
            </div>
        );
    }

    function renderUsers() {
        if (!users || users.length === 0) return null;

        return users.map(renderUser);
    }

    return (
        <div ref={ref} className={`w-full text-sm bg-white shadow dark:bg-gray-900 rounded-md max-h-48 overflow-scroll ${props.className}`}>
            {renderUsers()}
        </div>
    );
};

export default UsersSearchDropDown;
