// import { AppCheck, initializeAppCheck, onTokenChanged, ReCaptchaEnterpriseProvider } from '@firebase/app-check';
import { FirebaseApp } from 'firebase/app';
// import { AppCheckTokenResult, getToken } from 'firebase/app-check';

export default class AppCheckModule {
    // appCheck: AppCheck;
    // appCheckTokenResult: AppCheckTokenResult | null;

    constructor(app: FirebaseApp, appCheckRecaptchaKey: string, enableDebugToken: boolean) {
        if (enableDebugToken)
            self['FIREBASE_APPCHECK_DEBUG_TOKEN'] = enableDebugToken;

        // // this.appCheckTokenResult = null;
        // const appCheck = initializeAppCheck(app, {
        //     provider: new ReCaptchaEnterpriseProvider(appCheckRecaptchaKey),
        //     isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
        // });

        // this.appCheck = appCheck;
    }

    async init(): Promise<void> {
        // this.getAppCheckToken();
        // await this.getToken(false);
    }

    // async getToken(forceRefresh: boolean): Promise<AppCheckTokenResult | void> {
    //     let appCheckTokenResponse: AppCheckTokenResult;
    //     try {
    //         appCheckTokenResponse = await getToken(this.appCheck, forceRefresh);
    //     } catch (err) {
    //         console.error('App check token was not retrieved', err);
    //         // Handle any errors if the token was not retrieved.
    //         throw err;
    //     }

    //     return appCheckTokenResponse;
    // }

    async getAppCheckToken(): Promise<string> {
        return '';
        // return this.appCheckTokenResult;
        // const res = await this.getToken(false);
        // if (!res) return '';
        // return res.token;
    }
}