import React, { FC, memo } from 'react';
import { useStoreState } from '@src/model/hooks';
import { getLogoDarkSrc, getLogoSrc } from '@src/model/config/helpers';
import Image from '@creator/ui/components/Image/Image';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

export interface LogoProps {
    className?: string;
}

const Logo: FC<LogoProps> = props => {
    const { className  } = props;

    const isDarkModeOn = useStoreState(state => state.appearanceSettings.isDarkModeOn);

    function getSrc(): string {
        const src = isDarkModeOn ? getLogoDarkSrc() : getLogoSrc();
        return src;
    }

    return (
        <Image className={className} src={getSrc()} />
    );
};

export default memo(Logo, propsEqualityFn);
