
import { ApiResponse } from '../../creator-sdk';
import { post } from '../../network/api-service';
import { NotificationDbTableRow, NotificationType } from './notification.model';
import DbModule from '../db';
import { Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import { getDocs, limit, orderBy, OrderByDirection, query, QueryConstraint, QueryDocumentSnapshot, startAfter, where, WhereFilterOp } from 'firebase/firestore/lite';
import AccountModule from '../account';

export interface BlockUserNotificationsApiRequest {
    userId: string;
    blockedUserId: string;
    blockTagging?: boolean;
}

export interface SetNotificationsSettingsApiRequest {
    userId: string;
    notificationSettings: Partial<Record<NotificationType, boolean>>;
}

export interface SetNotificationsReadApiRequest {
    userId: string;
    notificationId: string;
    isRead: boolean;
}

export interface DeleteNotificationsApiRequest {
    userId: string;
    notificationId: string;
}

export interface GetNotificationsOrderBy {
    field: 'createdAt';
    direction: OrderByDirection;
}

export interface GetNotificationsFilterBy {
    field: 'commentId' | 'postId' | 'type' | 'createdAt' | 'tokenName';
    opStr: WhereFilterOp;
    value: NotificationType | NotificationType[] | string;
}

export default class NotificationService {
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private accountModule: AccountModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, accountModule: AccountModule) {
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.accountModule = accountModule;
    }

    async getNotifications(userId: string, _startAfter: QueryDocumentSnapshot<NotificationDbTableRow> | null, _limit: number, _filterBy?: GetNotificationsFilterBy[], _orderBy?: GetNotificationsOrderBy): Promise<Pagination<NotificationDbTableRow>> {
        const colRef = this.dbModule.getCollectionReference<NotificationDbTableRow>('Notifications');
        const queryConstraints: QueryConstraint[] = [where('recipientIds', 'array-contains', userId)];

        _filterBy?.forEach((filter): void => {
            const { field, opStr, value } = filter;
            queryConstraints.push(where(field, opStr, value));
        });

        if (_orderBy)
            queryConstraints.push(orderBy(_orderBy.field, _orderBy.direction));

        if (_startAfter)
            queryConstraints.push(startAfter(_startAfter));

        queryConstraints.push(limit(_limit));

        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);

        return { hasMore: items.length === _limit, items, lastDoc };
    }

    async blockUserNotifications(payload: BlockUserNotificationsApiRequest): Promise<ApiResponse<string>> {
        const token = await this.accountModule.getIdToken();
        const res = await post<ApiResponse<any>>('/notification/block', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return res.data;
    }

    async setNotificationsSettings(payload: SetNotificationsSettingsApiRequest): Promise<ApiResponse<string>> {
        const token = await this.accountModule.getIdToken();
        const res = await post<ApiResponse<any>>('/notification/settings', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return res.data;
    }

    async setNotificationRead(payload: SetNotificationsReadApiRequest): Promise<ApiResponse<string>> {
        const token = await this.accountModule.getIdToken();
        const res = await post<ApiResponse<any>>('/notification/read', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return res.data;
    }

    async deleteNotification(payload: DeleteNotificationsApiRequest): Promise<ApiResponse<string>> {
        const token = await this.accountModule.getIdToken();
        const res = await post<ApiResponse<any>>('/notification/delete', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return res.data;
    }
}