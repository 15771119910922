
import AppCheckModule from '../app-check';
import DbModule from '../db';

export default class PublicSettings {
    private dbModule: DbModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule) {
        this.dbModule = dbModule;
    }

    async getPublicSettings<T>(settingsId: string): Promise<T> {
        return this.dbModule.getDocument('PublicSettings', settingsId);
    }
}