import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useStoreState } from '@src/model/hooks';
import { getQueryParam, getUrlPath } from '@src/utils/url-utils/url-utils';

const NoAuthRequiredRoute: FC<any> = ({ component: Component, ...rest }) => {
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const isAuthenticating = useStoreState(state => state.user.isAuthenticating);

    return (
        <Route {...rest} render={props => {

            const navigateTo = getQueryParam('next');
            const isLoginRoute = getUrlPath() === '/bbs/login';

            if (isLoggedIn && isLoginRoute && navigateTo) return <Component {...props} />;
            if (isAuthenticating === false && isLoggedIn) return <Redirect to={'/'} />;

            return <Component {...props} />;
        }} />
    );
};

export default NoAuthRequiredRoute;