import creatorSdk from '@src/services/creator-sdk';
import { getUserId } from '../user/helpers';
import { GetNotificationsFilterBy, GetNotificationsOrderBy } from '@creator/sdk/modules/notification/notification.service';
import { Pagination } from '@creator/sdk/modules/db/db.model';
import { DocumentSnapshot } from 'firebase/firestore/lite';
import { BlockUserNotificationsPayload, SetNotificationsReadPayload } from '@creator/sdk/modules/notification';

export async function loadNotifications(startAfter: DocumentSnapshot | null, limit: number | null, filterBy?: GetNotificationsFilterBy[], orderBy?: GetNotificationsOrderBy): Promise<Pagination<Notification>> {
    const userId = getUserId();
    if (!userId) throw new Error('invalid userId');
    return creatorSdk.notificationModule.getNotifications(userId, startAfter, limit, filterBy, orderBy);
}

export function blockUserNotifications(payload: BlockUserNotificationsPayload): Promise<string> {
    const userId = getUserId();
    if (!userId) throw new Error('invalid userId');
    return creatorSdk.notificationModule.blockUserNotifications(payload);
}

export async function setNotificationRead(payload: SetNotificationsReadPayload): Promise<string> {
    const res = await creatorSdk.notificationModule.setNotificationRead(payload);
    return res;
}

export async function deleteNotification(payload: NotificationsDeletePayload): Promise<string> {
    const res = await creatorSdk.notificationModule.deleteNotification(payload);
    return res;
}