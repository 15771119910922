import creatorSdk from '@src/services/creator-sdk';
import { DecryptReportPayload, GetReportsOrderBy, Report, ReportDB, UpdateReportPayload } from '@creator/sdk/modules/report/report.model';
import { DocumentSnapshot } from '@creator/sdk/modules/db/db.model';
import { Pagination } from '@creator/sdk/utils/utils';

export async function sendReport(payload: Report): Promise<void> {
    const res = await creatorSdk.reportModule.report(payload);
}

export async function decryptReport(payload: DecryptReportPayload): Promise<ReportDB> {
    const res = await creatorSdk.reportModule.decryptReport(payload) as unknown as ReportDB;
    return res;
}

export async function updateReport(payload: UpdateReportPayload): Promise<ReportDB> {
    const res = await creatorSdk.reportModule.updateReport(payload) as unknown as ReportDB;
    return res;
}

export async function getReports(tokenName: string, startAfter: DocumentSnapshot | null, limit: number, filterBy?: any[], orderBy?: GetReportsOrderBy): Promise<Pagination<ReportDB>> {
    return creatorSdk.reportModule.getReports(tokenName, startAfter, limit, filterBy, orderBy);
}