import { Computed, Action, Thunk, computed, thunk, action } from 'easy-peasy';
import { vote, loadVote } from './actions';
import { StoreModel } from '..';
import { VotePayload } from '@creator/sdk/modules/voting/voting.service';
import { getUserId } from '../user/helpers';
import { getMyVote } from './helpers';

export interface VotingModel {
    userVotes: { [postId: string]: number };
    vote: Thunk<VotingModel, VotePayload, null, StoreModel>;
    getMyVote: Computed<VotingModel, (postId: string) => number | undefined>;
    loadVote: Thunk<VotingModel, { postId: string }, null, StoreModel, Promise<any>>
    setVote: Action<VotingModel, { postId: string, userVote: number }>
}


const votingModel: VotingModel = {
    userVotes: {},

    vote: thunk(async (actions, payload, helpers) => {
        const { postId, selectedOptionIndex } = payload;

        // Set user vote in store -
        // If user already voted for this option - set to -1 to unselect
        const myPrevVote = getMyVote(postId);
        if (myPrevVote !== selectedOptionIndex)
            actions.setVote({ postId: postId, userVote: selectedOptionIndex });
        else
            actions.setVote({ postId: postId, userVote: -1 });

        const res = await vote(payload);

        helpers.getStoreActions().upvote.loadPost(postId);

        return res;
    }),

    loadVote: thunk(async (actions, payload) => {
        const { postId } = payload;
        const userId = getUserId();
        const vote = await loadVote(postId);
        if (vote && vote.votes[userId] !== undefined)
            actions.setVote({ postId: postId, userVote: vote.votes[userId] });

    }),

    setVote: action((state, { postId, userVote }) => {
        state.userVotes[postId] = userVote;
    }),

    getMyVote: computed(state => postId => state.userVotes[postId])
};

export default votingModel;