import React, { FC, useEffect } from 'react';
import { useStoreState } from '@src/model/hooks';
import { getUrlPath } from '@src/utils/url-utils/url-utils';
import { getGoogleOneTapToAuthConfig } from '@src/model/config/helpers';
import storageService from '@src/services/storage-service';
import creatorSdk from '@src/services/creator-sdk';
import { GoogleAuthProvider, signInWithCredential } from 'firebase/auth';

const OneTapOnly: FC = () => {
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const isAuthenticating = useStoreState(state => state.user.isAuthenticating);

    useEffect(() => {
        const forbiddenUrls = ['/bbs/email-change-password', '/bbs/email-sign-in', '/bbs/login'];
        if (forbiddenUrls.includes(getUrlPath()) || isAuthenticating || isLoggedIn) return;

        startFirebaseOneTapOnly();
    }, [isLoggedIn, isAuthenticating]);

    async function onGoogleOneTapResponse(response) {
        const { credential } = response;
        const firebaseAuthResult = await signInWithCredential(creatorSdk.getAuth(), GoogleAuthProvider.credential(credential));
        const { user } = firebaseAuthResult;
        if (!user) throw new Error('onSignInSuccessWithAuthResult - invalid user');
    }

    function startFirebaseOneTapOnly(): void {
        if (!window.google || storageService.localStorage.get('mobileAppInstalled')) return;

        const oneTapAuthConfig = getGoogleOneTapToAuthConfig();
        window.google.accounts.id.initialize({
            client_id: oneTapAuthConfig.clientId,
            callback: onGoogleOneTapResponse,
            auto_select: true,
            context: 'use',
            native_callback: console.debug
        });

        window.google.accounts.id.prompt(notification => {
            console.log(`[google-one-tap] notification is: ${notification.getMomentType()}`);
            if (notification.isSkippedMoment())
                console.debug('[google-one-tap] skipped because:', notification.getSkippedReason());

            if (notification.isDismissedMoment())
                console.debug('[google-one-tap] dismissed because:', notification.getDismissedReason());
        });
    }

    return (
        <div id="oneTap" className="hidden"></div>
    );
};

export default OneTapOnly;
