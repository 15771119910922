
import DbModule from '../db';
import { Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import ModActivityService, { GetModActivityFilterBy, GetModActivityOrderBy } from './mod-activity.service';
import { ModActivity } from './mod-activity.model';
import { FirebaseApp } from 'firebase/app';
import { QueryDocumentSnapshot } from 'firebase/firestore/lite';


import TokenModule from '../token';
import TimeModule from '../time';
import AccountModule from '../account';
import StorageModule from '../storage';

export default class CashierModule {
    accountModule: AccountModule;
    tokenModule: TokenModule;
    modActivityService: ModActivityService;
    timeModule: TimeModule;
    private dbModule: DbModule;
        private domainName: string;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule, accountModule: AccountModule, tokenModule: TokenModule, timeModule: TimeModule) {
        this.dbModule = dbModule;
        this.modActivityService = new ModActivityService(contractName, domainName, appCheckModule, dbModule);
        this.accountModule = accountModule;
        
        this.domainName = domainName;
        this.appCheckModule = appCheckModule;
        this.tokenModule = tokenModule;
        this.timeModule = timeModule;
    }

    async getModActivity(tokenName: string, startAfter: QueryDocumentSnapshot<ModActivity> | null, limit: number, filterBy?: GetModActivityFilterBy[], orderBy?: GetModActivityOrderBy): Promise<Pagination<ModActivity>> {
        return this.modActivityService.getModActivity(tokenName, startAfter, limit, filterBy, orderBy);
    }
}