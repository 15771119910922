import React, { FC, ChangeEvent, useRef, useEffect } from 'react';
import { cn } from '../../../utils/ui';

export interface TextareaInputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    className?: string;
    enableAutosize?: boolean;
    hasError?: boolean;
}

const TextareaInput: FC<TextareaInputProps> = props => {
    const {
        className,
        enableAutosize = false,
        hasError = false,
        value = '',
        onChange,
        placeholder,
        name,
        ...rest
    } = props;

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    function getClassName(): string {
        // Base classes for the textarea.
        const base =
            'transition w-full px-4 py-2 rounded-lg border bg-white dark:bg-gray-900';
        // Standard focus styling
        const focusStyle = 'focus:border-gray-500 dark:focus:border-gray-500';
        // Standard border styling when no error is present.
        const normalBorder = 'border-gray-200 dark:border-gray-700';
        // Error styling applied when hasError is true.
        const errorBorder = 'border-red-500 dark:border-red-500 focus:border-red-500 dark:focus:border-red-500';

        return cn(
            base,
            enableAutosize && 'overflow-hidden', // Ensure no scrollbar when autosizing
            hasError ? errorBorder : normalBorder,
            focusStyle,
            className
        );
    }

    // Adjusts the textarea height based on its content.
    function resizeTextareaInput(): void {
        if (textareaRef.current && enableAutosize) {
            textareaRef.current.style.height = 'auto'; // Reset height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }

    // Adjust height when the component mounts or when the value changes.
    useEffect(() => {
        resizeTextareaInput();
    }, [value, enableAutosize]);

    function handleChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        if (onChange) onChange(e);
        if (enableAutosize) resizeTextareaInput();
    }

    return (
        <textarea
            ref={textareaRef}
            className={getClassName()}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            name={name}
            {...rest}
        />
    );
};

export default TextareaInput;
