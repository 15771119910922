import React, { FC } from 'react';
import { noop } from '@src/utils/utils';
import Text from '@creator/ui/components/Text/Text';
import { cleanEncodedString } from '@src/utils/html-utils/html-utils';
import { HeadingTag } from '@src/custom';

type BlockData = {
    level: number;
    text: string;
};
export interface EditorViewOnlyHeaderToolProps {
    className?: string;
    blockData: BlockData;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const EditorViewOnlyHeaderTool: FC<EditorViewOnlyHeaderToolProps> = props => {
    const { onClick = noop, blockData, className } = props;

    function renderContent() {
        const { level, text } = blockData;


        const HTag = `h${level}` as HeadingTag;
        return (
            <Text enableMultiline={true} className="block ce-paragraph cdx-block">
                <HTag>{cleanEncodedString(text)}</HTag>
            </Text>
        );
    }

    return (
        <div className={`header-tool ${className}`} onClick={onClick}>
            {renderContent()}
        </div>
    );
};

export default EditorViewOnlyHeaderTool;
