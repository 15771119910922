import React, { FC, PropsWithChildren, useState } from 'react';
import { cn } from '@creator/ui/utils/ui';
import CommentBubble, { CommentBubbleProps } from './Bubble';
import ProfileImageWithLink from '@src/components/ProfileImage/ProfileImageWithLink';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import Reactions from '../Reactions/Reactions';
import SuperCommentMenuPopover from './SuperComentMenuPopover';
import { FirebaseTimestamp } from '@creator/sdk/modules/time/time.model';
import CommentInput from '@src/basic-components/CommentInput/CommentInput';
import { useTranslation } from 'react-i18next';
import { cleanHtml } from '@src/utils/html-utils/html-utils';
import { getUserId } from '@src/model/user/helpers';
import { useStoreActions } from '@src/model/hooks';

export interface CommentProps {
    className?: string;
    tokenName: string;
    publisherName: string;
    superCommentId: string;
    commentBubbleProps?: Partial<CommentBubbleProps>;
    createdAt: string | FirebaseTimestamp;
    content: string;
    status: number;
    tipAmount?: number;
}

const Comment: FC<PropsWithChildren<CommentProps>> = props => {
    const { content, className = '', tokenName, superCommentId, publisherName, createdAt, status } = props;

    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(false);
    const [isSendingComment, setIsSendingComment] = useState(false);
    const [commentInputValue, setCommentInputValue] = useState(content);

    const editSuperComment = useStoreActions(actions => actions.superComment.editSuperComment);

    function getBaseClassname(): string {
        return cn('', className);
    }

    function onEditClick(): void {
        setIsEdit(!isEdit);
    }

    function mapError(error): void {
        if (error.message.includes('banned')) return alert(t('errors.bannedFromCommunity'));
        if (error.message.includes('Bad nonce')) return alert(t('badNonceErrorMessage'));
        if (error.message.includes('is hidden by domain admin')) return alert(t('unableToUndelete'));
        if (error.message.includes('rate limit')) return alert(t('rateLimitError'));
        if (error.message.includes('is hidden')) return alert(error.message);
        // if (error.message.includes('is hidden')) return alert(t('cantCommentPostHidden'));
        alert(t('generalErrorMessage') + ' ' + error.message);
    }

    async function onSendCommentClick(): Promise<void> {
        setIsSendingComment(true);
        try {
            await editSuperComment({
                tokenName,
                userId: getUserId() ?? '',
                content: cleanHtml(commentInputValue),
                commentId: superCommentId,
                isSuperComment: true
            });
        } catch (error) {
            mapError(error);
        } finally {
            setIsEdit(!isEdit);
            setIsSendingComment(false);
        }
    }

    function renderProfileImage() {
        return (
            <ProfileImageWithLink profileImageProps={{ tokenName, showBadge: true, displayName: publisherName, className: 'mr-4 h-8 w-8' }} />
        );
    }

    function renderCommentBuble() {
        if (isEdit) return;
        const { commentBubbleProps = {}, tipAmount } = props;

        const _props = mergeProps<[CommentBubbleProps, Partial<CommentBubbleProps>]>({
            className: 'flex-1 text-gray-900 dark:text-gray-900',
            tokenName,
            content,
            publisherName,
            createdAt,
            status,
            tipAmount
        }, commentBubbleProps);

        return (
            <CommentBubble {..._props} />
        );
    }

    function renderMenuPopover() {
        return <SuperCommentMenuPopover tokenName={tokenName} superCommentId={superCommentId} onEditClick={onEditClick} />;
    }

    function renderActions() {
        return (
            <div className="flex items-center mt-2 space-x-2">
                <Reactions tokenName={tokenName} superCommentId={superCommentId} />
                {renderMenuPopover()}
            </div>
        );
    }

    function renderCommentInput() {
        if (!isEdit) return;
        return (
            <CommentInput
                isTaggingEnabled={false}
                isSending={isSendingComment}
                onClick={onSendCommentClick}
                placeholder={t('comment.commentInput.placeholder')}
                hideProfileImage={true}
                value={commentInputValue}
                onChange={e => setCommentInputValue(e.target.value)} />
        );
    }

    function renderContent() {
        return (
            <div className="flex">
                {renderProfileImage()}
                <div className="flex-1">
                    {renderCommentBuble()}
                    {renderCommentInput()}
                    {renderActions()}
                </div>
            </div>
        );
    }

    return (
        <div className={getBaseClassname()}>
            {renderContent()}
        </div>
    );
};

export default Comment;
