import { thunk, Action, Thunk, action, Computed, computed } from 'easy-peasy';
import { StoreModel, StoreModelSetPayload } from '..';
import { DomainPublic, FeaturedToken } from '@creator/sdk/modules/domain/domain.model';
import { loadPublicData } from './actions';
import { getDomainName } from '@src/config';

export interface DomainModel {
    publicData: { [id: string]: DomainPublic };
    set: Action<DomainModel, StoreModelSetPayload<string>>;
    setPublicData: Action<DomainModel, DomainPublic>;

    getPublicData: Computed<DomainModel, (id: string) => DomainPublic | undefined>;
    getFeaturedTokens: Computed<DomainModel, () => FeaturedToken[]>;

    loadPublicData: Thunk<DomainModel, string, any, StoreModel, Promise<DomainPublic | undefined>>;
}

const DomainModel: DomainModel = {
    publicData: {},

    getPublicData: computed(state => id => state.publicData[id]),
    getFeaturedTokens: computed(state => () => state.publicData[getDomainName()]?.featuredTokens || []),

    setPublicData: action((state, payload) => {
        state.publicData[payload.id] = payload;
    }),

    set: action((state, payload) => {
        const { key, value } = payload;
        state[key] = value;
    }),

    loadPublicData: thunk(async (actions, id, helpers) => {
        const publicData = await loadPublicData(id);
        if (!publicData) return;

        actions.setPublicData(publicData);

        if (publicData.featuredTokens) {
            const tokenNames = publicData.featuredTokens.map(featuredToken => featuredToken.tokenName);
            helpers.getStoreActions().token.loadTokensByTokenNames(tokenNames);
        }

        return publicData;
    })
};

export default DomainModel;
