import React, { FC, PropsWithChildren } from 'react';

export interface AProps extends PropsWithChildren {
    target?: string;
    href?: string;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const A: FC<AProps> = props => {
    const { onClick, children, target, href, className } = props;

    const _props = {
        target: target,
        className: className,
        href: href,
        rel: 'noopener noreferrer nofollow',
        onClick
    };

    return (
        <a {..._props}>
            {children}
        </a>
    );
};

export default A;
