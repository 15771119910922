
import DbModule, { GetDocumentsPayload } from '../db';
import AccountModule from '../account';
import StorageModule from '../storage';
import TokenModule from '../token';
import TimeModule from '../time';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';
import { Pagination } from '../db/db.model';
import { Response } from './response.model';

export default class ResponseModule {
    accountModule: AccountModule;
    tokenModule: TokenModule;
    timeModule: TimeModule;
    domainName: string;
        private dbModule: DbModule;
    private storageModule: StorageModule;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule, accountModule: AccountModule, tokenModule: TokenModule, timeModule: TimeModule) {
        
        this.dbModule = dbModule;
        this.storageModule = storageModule;
        this.domainName = domainName;
        this.accountModule = accountModule;
        this.tokenModule = tokenModule;
        this.timeModule = timeModule;
        this.appCheckModule = appCheckModule;
    }

    async getResponses(payload: GetDocumentsPayload<Response>): Promise<Pagination<Response>> {
        return this.dbModule.getDocuments<Response>('Response', payload);
    }

    async getResponse(responseId: string): Promise<Response> {
        const response = await this.dbModule.getDocument<Response>('Response', responseId);
        if (!response) throw new Error(`response with id ${responseId} not found`);

        return response;
    }
}
