import React, { FC } from 'react';
import { noop } from '@src/utils/utils';
import ExternalLinkPreview from '@src/basic-components/ExternalLinkPreview/ExternalLinkPreview';
import { cleanEncodedString, cleanHtml } from '@src/utils/html-utils/html-utils';
import TwitterEmbed from './TwitterEmbed';
import { EmbedData } from '@editorjs/embed';

export interface EditorViewOnlyEmbedToolProps {
    className?: string;
    blockData: EmbedData;
    showAsPreview?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const EditorViewOnlyEmbedTool: FC<EditorViewOnlyEmbedToolProps> = props => {
    const { blockData, className, showAsPreview, onClick = noop } = props;

    // Extract information from blockData
    const { embed = '', service, caption, source, meta } = blockData;
    const height = blockData.height || 315; // default to a standard height if none provided

    /**
     * Extracts a YouTube video ID from a given URL.
     * Expected embed URL format: https://www.youtube.com/embed/<videoId>
     */
    function getVideoIdFromYoutubeUrl(url: string): string {
        const parts = url.split('/embed/');
        if (parts.length < 2) return '';
        return parts[1].split('&')[0].replace('?', '');
    }

    function getExternalLinkCaption(): string {
        if (caption) return caption;
        if (meta?.description) return meta.description;
        return source;
    }
    function getExternalLinkTitle(): string {
        if (meta?.title) return meta.title;
        return source;
    }

    function getThumbnail(url: string): string {
        if (meta?.image?.url) return meta.image.url;
        if (service === 'youtube') {
            const videoId = getVideoIdFromYoutubeUrl(url);
            return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
        }

        return 'https://awlights.com/wp-content/uploads/sites/31/2017/05/placeholder_featured_image.svg';
    }

    function renderEmbed() {
        if (service === 'twitter') {
            return (
                <div className="ce-block">
                    <div className="ce-block__content">
                        <div className="cdx-block tweet-tool">
                            <TwitterEmbed blockData={blockData} />
                        </div>
                    </div>
                </div>
            );
        }

        // Render a general embed (e.g., YouTube, Vimeo, etc.)
        return (
            <div className="ce-block">
                <div className="ce-block__content">
                    <div className="cdx-block embed-tool">
                        <iframe
                            className="w-full aspect-video embed-tool__content"
                            height={height}
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            // Clean the embed URL to prevent XSS attacks
                            src={cleanEncodedString(cleanHtml(embed))}
                            title={getExternalLinkTitle()} // Provide a title for accessibility
                        ></iframe>
                    </div>
                </div>
            </div>
        );
    }

    function renderLinkPreview() {
        return (
            <ExternalLinkPreview
                onClick={onClick}
                hostname={service}
                title={getExternalLinkTitle()}
                caption={getExternalLinkCaption()}
                image={getThumbnail(embed)}
            />
        );
    }

    function renderContent() {
        if (showAsPreview) return renderLinkPreview();
        return renderEmbed();
    }

    return (
        <div className={`embed-tool ${className || ''}`}>
            {renderContent()}
        </div>
    );
};

export default EditorViewOnlyEmbedTool;
