export function getTotalDecimalDigits(string: string): number {
    if (string.indexOf('.') > 0)
        return string.toString().split('.')[1].length;
    return 0;
}

export function roundDownToDecimalDigits(number: number, decimalDigits: number): number {
    const factor = Math.pow(10, decimalDigits);
    return Math.floor(number * factor) / factor;
}

export function roundToDecimalDigits(num: number, decimalDigits: number): number {
    return +(Math.round(Number(num + `e+${decimalDigits}`)) + `e-${decimalDigits}`);
}

export function roundToDecimalDigitsAmounts(num: number): number {
    return trancateToDecimalDigitsAmounts(num, num < 0.1);
}

export function trancateToDecimalDigitsAmounts(num: number, use4Digits = false): number {
    const power = use4Digits ? 10000 : 100;
    return Math.floor(num * power) / power;
}

export function roundUp(x: number): number {
    return Math.ceil(parseFloat(x.toFixed(1)));
}

export function abbreviatedFormatter(count: number, decimals = 2): string {
    if (count < 1) return count.toString();
    const COUNT_ABBRS = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    const i = count === 0 ? count : Math.floor(Math.log(count) / Math.log(1000));
    const result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
    return `${result}${COUNT_ABBRS[i]}`;
}

export function ordinalSuffixOf(i: number): string {
    const j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11)
        return i + 'st';

    if (j === 2 && k !== 12)
        return i + 'nd';

    if (j === 3 && k !== 13)
        return i + 'rd';

    return i + 'th';
}

export function isNumber(value: string | number): boolean {
    return value !== null && !isNaN(Number(value.toString()));
}


export function padTwoDigits(n: number): string {
    return (n < 10 ? '0' : '') + n;
}

export function isNearBy(reference: number, value: number, difference: number): boolean {
    return value >= reference + difference || value <= reference - difference;
}