import { action, Action, computed, Computed, Thunk, thunk } from 'easy-peasy';
import { blockUserNotifications, deleteNotification, loadNotifications, setNotificationRead } from './actions';
import { StoreModel, StoreModelSetPayload } from '..';
import { getUnique } from '@src/utils/array-utils/array-utils';
import { getNotification } from './helpers';
import { DocumentSnapshot } from 'firebase/firestore/lite';
import { Pagination } from '@creator/sdk/modules/db/db.model';
import { BlockUserNotificationsPayload, SetNotificationsReadPayload } from '@creator/sdk/modules/notification';
import { GetNotificationsFilterBy, GetNotificationsOrderBy } from '@creator/sdk/modules/notification/notification.service';

export interface NotificationModel {
    notifications: { [id: string]: Notification };
    notificationsSearchResultLastDoc: Record<string, unknown>;
    hasNewNotifications: boolean;
    hasNewDm: boolean;

    set: Action<NotificationModel, StoreModelSetPayload>;
    setNewNotifications: Action<NotificationModel, boolean>;
    setHasNewDm: Action<NotificationModel, boolean>;
    setNotification: Action<NotificationModel, Notification>;
    setNotificationsSearchResult: Action<NotificationModel, string[]>;
    setNotificationsSearchResultLastDoc: Action<NotificationModel, Record<string, never> | DocumentSnapshot>;
    updateNotificationsSearchResult: Action<NotificationModel, string[]>;

    getNotification: Computed<NotificationModel, (id: string) => Notification | undefined>;
    getNotificationsSearchResult: Computed<NotificationModel, (Notification | undefined)[]>;

    notificationsSearchResult: string[];
    concatNotificationsSearchResult: Action<NotificationModel, string[]>;
    loadNotifications: Thunk<NotificationModel, LoadNotificationsPayload, any, NotificationModel, Promise<Pagination<Notification>>>;
    setNotificationRead: Thunk<NotificationModel, SetNotificationsReadPayload>;
    deleteNotification: Thunk<NotificationModel, NotificationsDeletePayload>;
    blockUserNotifications: Thunk<NotificationModel, BlockUserNotificationsPayload, null, StoreModel, Promise<string>>;
}

export interface LoadNotificationsPayload {
    lowerBound: DocumentSnapshot | null;
    limit: number;
    filterBy: GetNotificationsFilterBy[];
    orderBy: GetNotificationsOrderBy;
}

const notificationModel: NotificationModel = {
    notifications: {},
    notificationsSearchResult: [],
    notificationsSearchResultLastDoc: {},
    hasNewNotifications: false,
    hasNewDm: false,

    getNotification: computed(state => id => state.notifications[id]),
    getNotificationsSearchResult: computed(state => state.notificationsSearchResult.map(username => state.getNotification(username))),

    setNotification: action((state, payload) => {
        state.notifications[payload.id] = payload;
    }),

    setNotificationsSearchResult: action((state, lastDoc) => {
        state.notificationsSearchResult = lastDoc;
    }),

    setNotificationsSearchResultLastDoc: action((state, lastDoc) => {
        state.notificationsSearchResultLastDoc = lastDoc;
    }),

    setNewNotifications: action((state, hasNewNotifications) => {
        state.hasNewNotifications = hasNewNotifications;
    }),

    setHasNewDm: action((state, hasNewDm) => {
        state.hasNewDm = hasNewDm;
    }),

    loadNotifications: thunk(async (actions, payload) => {
        const { lowerBound, limit, filterBy, orderBy } = payload;
        const { hasMore, items, lastDoc } = await loadNotifications(lowerBound, limit, filterBy, orderBy);

        const ids: string[] = [];
        items.forEach(Notification => {
            actions.setNotification(Notification);

            ids.push(Notification.id);
        });

        actions.concatNotificationsSearchResult(ids);

        if (lastDoc)
            actions.setNotificationsSearchResultLastDoc(lastDoc);

        return { hasMore, items, lastDoc };
    }),

    setNotificationRead: thunk(async (actions, payload) => {
        const { userId, notificationId, isRead } = payload;
        const notification = getNotification(notificationId);
        if (!notification) return;

        const newRecipients = [...notification.readRecipients];
        const index = newRecipients.indexOf(userId);
        isRead ? newRecipients.push(userId) : newRecipients.splice(index, 1);
        actions.setNotification({ ...notification, readRecipients: newRecipients });
        const res = await setNotificationRead(payload);
        return res;
    }),

    deleteNotification: thunk(async (actions, payload, helpers) => {
        const state = helpers.getState();
        const searchResults = [...state.notificationsSearchResult];
        searchResults.splice(searchResults.indexOf(payload.notificationId), 1);
        actions.updateNotificationsSearchResult(searchResults);
        const res = await deleteNotification(payload);
        return res;
    }),

    blockUserNotifications: thunk(async (actions, payload, helpers) => {
        const { userId, blockedUserId, blockTagging } = payload;

        const myOldUser = helpers.getStoreState().user.getUser(userId);
        if (myOldUser) {
            let tagBlockUsersArr = myOldUser.tagBlockUsers || [];
            if (blockTagging)
                tagBlockUsersArr.push(blockedUserId);
            else
                tagBlockUsersArr = tagBlockUsersArr.filter(_userId => _userId !== blockedUserId);

            const newUser = { ...myOldUser, tagBlockUsers: tagBlockUsersArr } as User;

            helpers.getStoreActions().user.setUser(newUser);
        }

        const res = await blockUserNotifications(payload);
        return res;
    }),

    set: action((state, payload) => {
        const { key, value } = payload;
        state[key] = value;
    }),

    concatNotificationsSearchResult: action((state, searchResult) => {
        state.notificationsSearchResult = getUnique(state.notificationsSearchResult.concat(searchResult));
    }),

    updateNotificationsSearchResult: action((state, searchResult) => {
        state.notificationsSearchResult = getUnique(searchResult);
    })
};

export default notificationModel;

