import React, { FC, PropsWithChildren } from 'react';
import { cn } from '@creator/ui/utils/ui';
import { FirebaseTimestamp } from '@creator/sdk/modules/time/time.model';
import UserDisplayNameWithLink, { UserDisplayNameWithLinkProps } from '@src/basic-components/UserDisplayName/UserDisplayNameWithLink';
import { convertFirebaseTimestampToDate, formatDate } from '@creator/ui/utils/date-utils';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import Text from '@creator/ui/components/Text/Text';
import { PostCommentStatus } from '@creator/sdk/modules/upvote/upvote.model';
import { useTranslation } from 'react-i18next';
import Linkify from 'linkify-react';
import { roundToDecimalDigitsAmounts } from '@src/utils/number-utils/number-utils';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import Markdown from '@src/basic-components/Markdown/Markdown';
import { noop } from '@src/utils/utils';
import { LinkMeta } from '@creator/sdk/modules/upvote';

export interface CommentBubbleProps {
    className?: string;
    commentContentclassName?: string;
    userDisplayNameWithLinkProps?: Partial<UserDisplayNameWithLinkProps>;
    tokenName: string;
    publisherName: string;
    createdAt?: string | FirebaseTimestamp;
    status: PostCommentStatus;
    content: string;
    linkMeta?: LinkMeta;
    isEditMode?: boolean;
    isDeleting?: boolean;
    tipAmount?: number;
    onClick?: () => void;
}

const CommentBubble: FC<PropsWithChildren<CommentBubbleProps>> = props => {
    const { onClick = noop, children, className = '', publisherName, createdAt, tokenName, isDeleting = false, status } = props;

    const { t } = useTranslation();

    const isDeleted = [1, 2, 3, 4].includes(status);

    function getBaseClassname(): string {
        return cn('px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-800 shadow dark:shadw-s4', className);
    }

    function getCommentContentClassName(): string {
        const { commentContentclassName = '' } = props;
        const base = 'transition text-sm mt-2 block';
        if (isDeleted) return `${base} text-gray-500 italic`;

        return cn(`${base} text-black dark:text-gray-50`, commentContentclassName);
    }

    function renderStatus() {
        return <Text className={getCommentContentClassName()}>{t(`comment.status.${status}`)}</Text>;
    }

    function renderCommentText() {
        const { content } = props;
        if (isDeleting) return null;

        if (isDeleted) return renderStatus();

        return (
            <Linkify>
                <Text className={`${getCommentContentClassName()}`} enableMultiline={true}>
                    {content}
                </Text>
            </Linkify>
        );
    }

    function renderTipAmount() {
        const { tipAmount } = props;
        if (!tipAmount) return;

        const txt = t('comment.tipAmount', { tipAmount: `$${roundToDecimalDigitsAmounts(tipAmount)}` });
        return <Markdown source={txt} className="text-xs font-bold text-green-500" />;
    }

    function renderUserDisplayNameWithLink() {
        const { userDisplayNameWithLinkProps = {} } = props;

        const _props = mergeProps<[UserDisplayNameWithLinkProps, Partial<UserDisplayNameWithLinkProps>]>({
            tokenName,
            linkProps: {
                className: 'flex'
            },
            userDisplayNameProps: {
                displayName: publisherName,
                textProps: {
                    className: 'text-sm font-bold',
                    enableAutoDir: false
                }
            }
        }, userDisplayNameWithLinkProps);

        return <UserDisplayNameWithLink {..._props} />;
    }

    function renderCreatedAt() {
        if (!createdAt) return;
        return <Text className="text-gray-500">{formatDate(convertFirebaseTimestampToDate(createdAt))}</Text>;
    }

    function renderBasicInfo() {
        return (
            <div className="flex-1 leading-none" >
                <div className="flex items-center text-sm">
                    {renderUserDisplayNameWithLink()}
                    <Text className="px-1 font-bold text-gray-500">{'·'}</Text>
                    {renderCreatedAt()}
                </div>
                {renderTipAmount()}
            </div>
        );
    }

    function renderContent() {
        return (
            <div className="flex items-center justify-between w-full">
                {renderCommentText()}
                {isDeleting ? <Spinner className="mt-2 " /> : null}
            </div>
        );
    }

    return (
        <div onClick={onClick} className={getBaseClassname()}>
            {renderBasicInfo()}
            {renderContent()}
        </div>
    );
};

export default CommentBubble;
