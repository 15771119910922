import app, { AppModel } from './app';
import user, { UserModel } from './user';
import token, { TokenModel } from './token';
import tokenPrice, { TokenPriceModel } from './token-price';
import voting, { VotingModel } from './voting';
import upvote, { UpvoteModel } from './upvote';
import config, { ConfigModel } from './config';
import notificationSettings, { NotificationSettingsModel } from './settings/notification-settings';
import admin, { AdminModel } from './admin';
import domain, { DomainModel } from './domain';
import notification, { NotificationModel } from './notification';
import appearanceSettings, { AppearanceSettingsModel } from './settings/appearance';
import publicSettings, { PublicSettingsModel } from './publicSettings';
import wallet, { WalletModel } from './wallet';
import search, { SearchModel } from './search';
import cashier, { CashierModel } from './cashier';
import report, { ReportModel } from './report';
import follow, { FollowModel } from './follow';
import flair, { FlairModel } from './flair';
import response, { ResponseModel } from './response';
import modActivity, { ModActivityModel } from './modActivity';
import postDraft, { PostDraftModel } from './post-draft';
import superComment, { SuperCommentModel } from './super-comment';
import shortUrl, { ShortUrlModel } from './short-url';
import ssrData, { SSRDataModel } from './ssr-data';

export interface StoreModel {
    app: AppModel;
    user: UserModel;
    token: TokenModel;
    tokenPrice: TokenPriceModel;
    voting: VotingModel;
    upvote: UpvoteModel;
    flair: FlairModel;
    config: ConfigModel;
    notification: NotificationModel;
    appearanceSettings: AppearanceSettingsModel;
    notificationSettings: NotificationSettingsModel;
    admin: AdminModel;
    domain: DomainModel;
    publicSettings: PublicSettingsModel;
    cashier: CashierModel;
    wallet: WalletModel;
    search: SearchModel;
    report: ReportModel;
    follow: FollowModel;
    modActivity: ModActivityModel;
    response: ResponseModel;
    postDraft: PostDraftModel;
    superComment: SuperCommentModel;
    shortUrl: ShortUrlModel;
    ssrData: SSRDataModel;
}

export interface StoreModelSetPayload<T> {
    key: keyof T;
    value: unknown;
}

const storeModel: StoreModel = {
    app,
    user,
    token,
    tokenPrice,
    voting,
    upvote,
    flair,
    config,
    notification,
    appearanceSettings,
    admin,
    domain,
    publicSettings,
    notificationSettings,
    cashier,
    wallet,
    search,
    report,
    follow,
    modActivity,
    response,
    postDraft,
    superComment,
    shortUrl,
    ssrData
};

export default storeModel;
