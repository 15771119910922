import * as timeago from 'timeago.js';

function setTimeAgoLocale(): void {
    const locale = (diff: number, idx: number) => {
        // number: the time ago / time in number;
        // index: the index of array below;
        // totalSec: total seconds between date to be formatted and today's date;

        return [
            ['just now', 'right now'],
            ['just now', 'in %s seconds'],
            ['1m', 'in 1 minute'],
            ['%sm', 'in %s minutes'],
            ['1h', 'in 1 hour'],
            ['%sh', 'in %s hours'],
            ['1d', 'in 1 day'],
            ['%sd', 'in %s days'],
            ['1w', 'in 1 week'],
            ['%sw', 'in %s weeks'],
            ['1m', 'in 1 month'],
            ['%sm', 'in %s months'],
            ['1y', 'in 1 year'],
            ['%sy', 'in %s years']
        ][idx] as [string, string];
    };
    timeago.register('en_US', locale);
}

export function toCustomLocaleDate(date: number | string | Date): string {
    return new Date(date).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    });
}

export function toCustomLocaleDateHour(date: number | string | Date): string {
    const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    };

    return new Date(date).toLocaleTimeString(undefined, options);
}

export function toCustomLocaleDateMonthAndDay(date: number | string | Date): string {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
    return new Date(date).toLocaleDateString(undefined, options);
}

export function toCustomLocaleDateYearMonthDay(date: number | string | Date): string {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
}

export function convertFirebaseTimestampToDate(
    timestamp: string | { seconds?: number; nanoseconds?: number; _seconds?: number; _nanoseconds?: number }
): Date {
    if (typeof timestamp === 'string') return new Date(timestamp);

    const secs = timestamp.seconds || (timestamp._seconds as number);
    const nanosecs = timestamp.nanoseconds || timestamp._nanoseconds || 0;
    return new Date(secs * 1000 + nanosecs / 100000);
}

export function formatDate(date: number | string | Date): string {
    const oneWeekAgo = getDateBeforeXDays(7);
    if (oneWeekAgo.getTime() > new Date(date).getTime()) return toCustomLocaleDateYearMonthDay(date);

    return timeago.format(date, 'en_US');
}

export function getDateBeforeXDays(days: number): Date {
    return new Date(new Date().setDate(new Date().getDate() - days));
}

// https://stackoverflow.com/a/7937257
export function getDateBeforeXMonths(months: number): Date {
    const d = new Date();
    const month = d.getMonth();
    d.setMonth(d.getMonth() - months);
    while (d.getMonth() === month) d.setDate(d.getDate() - months);

    return d;
}

export function getFutureDateDelta(dateFuture: Date): { days: number; hours: number; minutes?: number; seconds?: number } {
    // get total seconds between the times
    const timeBetweenDates = dateFuture.valueOf() - new Date().valueOf();
    let delta = Math.abs(timeBetweenDates) / 1000;
    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    // what's left is seconds
    const seconds = delta % 60; // in theory the modulus is not required

    return { days: days, hours, minutes, seconds };
    // return { months: Math.floor(days / 30), days: days % 30, hours, minutes, seconds };
}

setTimeAgoLocale();

export function msToTime(msDuration: number): string {
    const h = Math.floor(msDuration / 1000 / 60 / 60);
    const m = Math.floor((msDuration / 1000 / 60 / 60 - h) * 60);
    const s = Math.floor(((msDuration / 1000 / 60 / 60 - h) * 60 - m) * 60);

    // To get time format 00:00:00
    const seconds: string = s < 10 ? `0${s}` : `${s}`;
    const minutes: string = m < 10 ? `0${m}` : `${m}`;
    const hours: string = h < 10 ? `0${h}` : `${h}`;
    if (h) return `${hours}:${minutes}:${seconds}`;
    return `${minutes}:${seconds}`;
}

export function formatSeconds(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours > 0 ? hours.toString().padStart(2, '0') + ':' : '';
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return formattedHours + formattedMinutes + ':' + formattedSeconds;
}
