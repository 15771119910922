
import DbModule from '../db';
import { Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import ShortUrlService, { GetShortUrlFilterBy, GetShortUrlOrderBy } from './short-url.service';
import { ShortUrl } from './short-url.model';
import { FirebaseApp } from 'firebase/app';
import { QueryDocumentSnapshot } from 'firebase/firestore/lite';


import TokenModule from '../token';
import TimeModule from '../time';
import AccountModule from '../account';
import StorageModule from '../storage';

export default class ShortUrlModule {
    accountModule: AccountModule;
    tokenModule: TokenModule;
    shortUrlService: ShortUrlService;
    timeModule: TimeModule;
    private dbModule: DbModule;
        private domainName: string;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp,contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule, accountModule: AccountModule, tokenModule: TokenModule, timeModule: TimeModule) {
        this.dbModule = dbModule;
        this.shortUrlService = new ShortUrlService(contractName, domainName, appCheckModule, dbModule);
        this.accountModule = accountModule;
        
        this.domainName = domainName;
        this.appCheckModule = appCheckModule;
        this.tokenModule = tokenModule;
        this.timeModule = timeModule;
    }

    getShortUrlId(shortUrl:string): string {
        const pathArr = shortUrl.split('/');
        return pathArr[pathArr.length - 1];
    }

    async createShortUrl(originalUrl: string): Promise<ShortUrl> {
        const res = await this.shortUrlService.createShortUrl(originalUrl);
        const shortUrl = res.payload;
        return {
            shortUrl,
            originalUrl,
            id: this.getShortUrlId(shortUrl)
        };
    }
}