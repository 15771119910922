import React, { FC } from 'react';
import { noop } from '@src/utils/utils';
import Icon from '../Icon/Icon';
import Text from '@creator/ui/components/Text/Text';
import ProfileImage from '@src/components/ProfileImage/ProfileImage';
import { getDisplayName, getUserId } from '@src/model/user/helpers';
import { useTranslation } from 'react-i18next';
import { cn } from '@creator/ui/utils/ui';
import { canSellTokens } from '@src/model/user/permission';

export interface CreateFeedItemInputProps {
    className?: string;
    onIinputClick?: () => void;
    onIconClick?: () => void;
    tokenName: string;
}

const CreateFeedItemInput: FC<CreateFeedItemInputProps> = props => {
    const { tokenName, className } = props;

    const { t } = useTranslation();

    function getBaseClassName(): string {
        return cn('flex relative space-x-3', className);
    }
    function renderProfileImage() {
        return <ProfileImage tokenName={tokenName} className="w-10 h-10" displayName={getDisplayName() || ''} />;
    }

    function renderInput() {
        const { onIinputClick = noop } = props;

        const _props = {
            onClick: onIinputClick,
            className: 'h-10 group cursor-pointer flex items-center text-sm flex-1 rounded-lg px-4 transition border border-gray-200 dark:border-gray-700 hover:border-gray-500 dark:hover:border-gray-500'
        };

        const text = canSellTokens(getUserId(), tokenName) ? t('tokenPage.createPostInput.craeteStory') : t('tokenPage.createPostInput.craetePost');

        return (
            <div {..._props}>
                <Text className="flex-1">{text}</Text>
                {renderIcons()}
            </div>
        );
    }

    function onIconClick(): void {
        const { onIconClick = noop } = props;
        onIconClick();
    }

    function renderIcons() {
        return (
            <Icon
                svgFill={false}
                onClick={onIconClick}
                className="text-xl text-gray-500 transition group-hover:text-gray-900 dark:group-hover:text-gray-50"
                name="image" />
        );
    }

    return (
        <div className={getBaseClassName()}>
            {renderProfileImage()}
            {renderInput()}
        </div>
    );
};

export default CreateFeedItemInput;
