import { FirebaseApp } from 'firebase/app';
import { Auth, getAdditionalUserInfo, onAuthStateChanged, signInWithCustomToken, signOut, UserCredential } from 'firebase/auth';
import { getDocs, query, QueryConstraint, Timestamp, where } from 'firebase/firestore/lite';

import AppCheckModule from '../app-check';
import DbModule from '../db';
import { Pagination, PaginationLastDoc } from '../db/db.model';
import { AuthProviderId, DeWebAccount, FirebaseUser, User, UserDbTableRow, UserInfo, UserStats } from './account.model';
import AccountService, {
    BanApiRequestPayload,
    DeleteAccountApiRequestPayload,
    EditProfileApiRequestPayload,
    ForwardAccountApiRequestPayload,
    ForwardAccountApiResponse,
    GetUserEmailsApiRquestPayload,
    GetUsersFilterBy,
    GetUsersOrderBy,
    JoinCommunityApiRequestPayload,
    SetPermissionsApiRequestPayload,
    UserBlockchainTableRow
} from './account.service';

export interface SetNotificationsViewedPayload {
    userId: string;
}

export interface EditBannerPayload {
    userId: string;
    editedUserId: string;
    bannerLink?: string;
    bannerTitle?: string;
    bannerImage?: string;
}

export interface EditProfilePayload extends EditBannerPayload {
    userId: string;
    editedUserId: string;
    image?: string;
    about?: string;
    name?: string;
}

export interface ApproveSellPayload {
    userId: string;
    tokenName: string;
    approveSell: boolean;
    editedUserId: string;
}

export type BanUserPayload = BanApiRequestPayload;
export type DeleteAccountPayload = DeleteAccountApiRequestPayload;

export interface ApproveModeratorPayload {
    userId: string;
    tokenName: string;
    approveModerator: boolean;
    editedUserId: string;
}


export interface JoinCommunityPayload {
    isJoin: boolean;
    userId: string;
    tokenName: string;
}

export type ForwardAccountPayload = ForwardAccountApiRequestPayload;
export type GetUserEmailsPayload = GetUserEmailsApiRquestPayload;
export type SetPermissionsPayload = SetPermissionsApiRequestPayload;
export type BanPayload = BanApiRequestPayload;
export type ForwardAccountResult = ForwardAccountApiResponse;

export default class AccountModule {
    private dbModule: DbModule;
    accountService: AccountService;
    private appCheckModule: AppCheckModule;
    constructor(app: FirebaseApp, contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule) {
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;

        this.accountService = new AccountService(app, contractName, domainName, appCheckModule, dbModule);
    }

    getCurrentFirebaseUser(): FirebaseUser | null {
        return this.accountService.getCurrentFirebaseUser();
    }

    async getIdToken(forceRefresh?: boolean): Promise<string> {
        return this.accountService.getIdToken(forceRefresh);
    }

    getAuth(): Auth {
        return this.accountService.getAuth();
    }

    async getUserInfo(userId: string): Promise<UserInfo> {
        const userInfo = await this.dbModule.getDocument<UserInfo>('UserInfo', userId);
        if (!userInfo) throw new Error(`User info with id ${userInfo} not found`);
        return userInfo;
    }

    async getUserTableRowsByFirebaseUid(uid: string): Promise<UserDbTableRow[]> {
        const colRef = this.dbModule.getCollectionReference<UserDbTableRow>('User');
        const queryConstraints: QueryConstraint[] = [where('firebaseUID', '==', uid)];
        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);
        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);
        return items;
    }

    async getUsersByFirebaseUid(uid: string): Promise<User[]> {
        const userDbTableRows = await this.getUserTableRowsByFirebaseUid(uid);
        if (!userDbTableRows || !userDbTableRows.length) throw new Error(`couldnt find user with firebase uid ${uid}`);
        return userDbTableRows;
    }

    async getBlockchainUser(userId: string): Promise<UserBlockchainTableRow> {
        return this.accountService.getBlockchainUser(userId);
    }

    async getBlockchainUserById(id: string): Promise<UserBlockchainTableRow> {
        return this.accountService.getBlockchainUserById(id);
    }

    async getUsersByProviderEmail(email: string) {
        const { userList = [] } = await this.accountService.getUsesrByEmail(email);
        return userList.map(user => ({ ...user, id: user.blockchainId }));
    }

    async getUserEmails(payload: GetUserEmailsPayload): Promise<string[]> {
        const { emails = [] } = await this.accountService.getUserEmails(payload);
        return emails;
    }

    async linkAccount(providerId: AuthProviderId): Promise<DeWebAccount> {
        const userCredential = await this.accountService.linkAccount(providerId);
        const additionalUserInfo = getAdditionalUserInfo(userCredential);
        // If the linking is successful, the returned result will contain the user and the provider's credential.
        if (!userCredential.user || !additionalUserInfo?.providerId) throw new Error(`couldn\'t link account, ${providerId}`);

        const { payload } = await this.accountService.updateAuthProvider(providerId, true);
        return { firebaseUser: userCredential.user, providers: payload.providers };
    }

    async unlinkAccount(providerId: AuthProviderId): Promise<DeWebAccount> {
        const firebaseUser = await this.accountService.unlinkAccount(providerId);
        const { payload } = await this.accountService.updateAuthProvider(providerId, false);
        return { firebaseUser: firebaseUser, providers: payload.providers };
    }

    // For external use
    async firebaseSignInWithCustomToken(customToken: string): Promise<UserCredential> {
        return signInWithCustomToken(this.accountService.getAuth(), customToken);
    }

    async logout(): Promise<void> {
        return signOut(this.accountService.getAuth());
    }

    async getUser(userId: string): Promise<User> {
        const dbUser = await this.accountService.getUser(userId);
        if (!dbUser) throw new Error('invalidUserId');

        return dbUser;
    }

    async getUserBySlugUrl(slugUrl: string) {
        const res = await this.dbModule.getDocuments<User>('User', { limit: 1, filterBy: [{ field: 'slugUrl', opStr: '==', value: slugUrl }] });
        if (!res.items.length) return;
        return res.items[0];
    }

    async getUserStats(userId: string, tokenName: string): Promise<UserStats> {
        const dbUser = await this.accountService.getUserStats(userId, tokenName);
        if (!dbUser) throw new Error('invalidUserId');

        return dbUser;
    }

    async getUsers(limit: number, filterBy?: GetUsersFilterBy[], orderBy?: GetUsersOrderBy, _startAfter?: PaginationLastDoc<User>): Promise<Pagination<User>> {
        return this.accountService.getUsers(limit, filterBy, orderBy, _startAfter);
    }

    onAuthStateChanged(cb: (firebaseUser: FirebaseUser | null) => void): void {
        onAuthStateChanged(this.accountService.getAuth(), firebaseUser => {
            cb(firebaseUser);
        });
    }

    async setNotificationsViewed(payload: SetNotificationsViewedPayload): Promise<Timestamp> {
        // NotificationViewedReceipt
        const { userId } = payload;
        const res = await this.accountService.setNotificationsViewed({ userId });

        const user = await this.getUser(userId);
        if (!user || !user.notificationViewedAt) throw new Error('setNotificationsViewed - invalid user');

        return user.notificationViewedAt;
    }

    async joinCommunity(payload: JoinCommunityPayload): Promise<{ hash: string }> {
        const { isJoin, userId, tokenName } = payload;

        const data: JoinCommunityApiRequestPayload = {
            tokenName,
            userId,
            join: isJoin
        };

        const res = await this.accountService.joinCommunity(data);

        return res.payload;
    }

    async visitCommunity(tokenName: string, userId: string): Promise<User> {
        const res = await this.accountService.visitCommunity(tokenName, userId).catch((e: Error) => {
            // if (e.message.includes('Token with this name already exists')) throw new Error('tokenAlreadyExists');
            throw e;
        });

        return res.payload;
    }

    async editProfile(payload: EditProfilePayload): Promise<User> {
        const { userId, name, image, about, bannerLink, bannerImage, bannerTitle, editedUserId } = payload;

        const editProfileData: EditProfileApiRequestPayload = {
            editedUserId,
            userId,
            bannerLink,
            bannerTitle
        };

        if (name || name === '') editProfileData.profileName = name;

        if (about || about === '') editProfileData.profileAbout = about;

        if (image || image === '') editProfileData.profileImage = image;

        if (bannerImage || bannerImage === '') editProfileData.bannerImage = bannerImage;

        const res = await this.accountService.editProfile(editProfileData);
        return { ...res.payload, id: userId };
    }

    async banUser(payload: BanUserPayload): Promise<User> {
        const res = await this.accountService.banUser(payload);
        return res.payload;
    }

    async deleteAccount(payload: DeleteAccountPayload): Promise<string> {
        const res = await this.accountService.deleteAccount(payload);
        return res.payload;
    }

    async approveSell(payload: ApproveSellPayload): Promise<User> {
        const { userId, tokenName, approveSell, editedUserId } = payload;

        const editProfileData: EditProfileApiRequestPayload = {
            editedUserId,
            userId,
            approveSell,
            tokenName
        };

        const res = await this.accountService.editProfile(editProfileData);

        return { ...res.payload, id: res.payload.blockchainId };
    }

    async approveModerator(payload: ApproveModeratorPayload): Promise<User> {
        const { userId, tokenName, approveModerator, editedUserId } = payload;

        const editProfileData: EditProfileApiRequestPayload = {
            editedUserId,
            userId,
            addModerator: approveModerator,
            tokenName
        };

        const res = await this.accountService.editProfile(editProfileData);
        return res.payload;
    }

    async forwardAccount(payload: ForwardAccountPayload): Promise<ForwardAccountResult> {
        const res = await this.accountService.forwardAccount(payload);
        return res.payload;
    }

    async setPermissions(payload: SetPermissionsPayload): Promise<string> {
        const res = await this.accountService.setPermissions(payload);
        return res.payload;
    }
}
