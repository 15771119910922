import React, { FC, Fragment, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@creator/ui/components/Text/Text';
import MenuPopover, { MenuPopoverProps } from '@src/basic-components/MenuPopover/MenuPopover';
import Icon from '@src/basic-components/Icon/Icon';
import MenuItem from '@src/basic-components/MenuItem/MenuItem';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import { isUserJoinedToToken, getUserId, getMyUser } from '@src/model/user/helpers';
import { useHistory } from 'react-router-dom';
import { canDeleteContent, canDeletePost, canEditPost, canPinPost, PostStatus } from '@src/model/upvote/permission';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import ReportModal from '@src/components/ReportModal/ReportModal';
import ReactionsList from '../Reactions/ReactionsList/ReactionsList';
import EditFlairModal from '@src/components/EditFlairModal/EditFlairModal';
import { getTokenFlairs } from '@src/model/flair/helper';
import { isPerrmisioned } from '@src/model/user/permission';
import ModActivityModal from '@src/components/ModActivityModal/ModActivityModal';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { cn } from '@creator/ui/utils/ui';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { summarizeBlocksContent } from '@src/utils/editorjs-utils/editorjs-utils';
import { MIN_CHARS_TO_SYNDICATE } from '@src/model/upvote/config';
import { OutputBlockData } from '@editorjs/editorjs';
import Markdown from '@src/basic-components/Markdown/Markdown';
import { abbreviatedFormatter } from '@src/utils/number-utils/number-utils';
import { ensurePostUrl } from '@src/model/upvote/helpers';

export interface PostMenuPopoverProps {
    className?: string;
    postId: string;
    tokenName: string;
    hidePinPost?: boolean;
    menuPopoverProps?: Partial<MenuPopoverProps>;
    renderMenuPopoverTrigger?: () => ReactNode;
    onHideTokenClick?: (postId: string, tokenName: string) => void;
    children?: ReactNode;
}

const PostMenuPopover: FC<PostMenuPopoverProps> = props => {
    const { hidePinPost = false, postId, tokenName, className, children } = props;

    const { t } = useTranslation();
    const history = useHistory();

    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isPinning, setIsPinning] = useState(false);
    const [isSyndicating, setIsSyndicating] = useState(false);

    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const post = useStoreState(state => state.upvote.getPost(postId));
    const token = useStoreState(state => state.token.getToken(tokenName));
    const deletePost = useStoreActions(actions => actions.upvote.deletePost);
    const pinPost = useStoreActions(actions => actions.upvote.pinPost);
    const syndicatePost = useStoreActions(actions => actions.upvote.syndicatePost);

    const { handleModal } = useContext<UseModal>(ModalContext);

    function getBaseClassname(): string {
        return cn('', className);
    }

    function onOpenReportClick(): void {
        setIsMenuVisible(false);
        const options = [t('reportOptions.content.obscene'), t('reportOptions.content.violates'), t('reportOptions.content.suspected'), t('reportOptions.content.spam')];
        handleModal(<ReportModal type="Post" postId={postId} tokenName={tokenName} options={options} />, { title: t('report'), size: 'sm' });
    }

    function onEditFlairClick(): void {
        setIsMenuVisible(false);
        handleModal(<EditFlairModal postId={postId} tokenName={tokenName} />, { size: 'sm' });
    }

    function mapError(error): void {
        if (error.message.includes('Bad nonce')) return alert(t('badNonceErrorMessage'));
        if (error.message.includes('Too many pinned posts')) return alert(t('tooManyPinnedPostsErrorMessage'));
        if (error.message.includes('banned')) return alert(t('errors.bannedFromCommunity'));

        alert(t('generalErrorMessage') + ' ' + error.message);
    }

    function onHidePostSelectReason(): void {
        // No need to select a reason if moderator is the publisher
        if (post?.publisherName === getMyUser()?.displayName) {
            onDelete(true, '', '');
            return;
        }
        const options = [t('modActivities.hidePost.spam'), t('modActivities.hidePost.hateful'), t('modActivities.hidePost.nudity')];
        handleModal(<ModActivityModal options={options} onSendReason={onHidePost} subTitle={t('modActivities.modalTitle', { action: t('hide'), entity: 'post' })} />, { title: t('modActivities.actions.hidePost'), size: 'sm' });
    }

    function onHidePost(reason?: string, reasonText?: string): Promise<void> {
        return onDelete(true, reason, reasonText);
    }

    async function onDelete(isDelete: boolean, reason?: string, reasonText?: string): Promise<void> {
        if (!post) return;

        setIsDeleting(true);

        try {
            await deletePost({
                isDelete,
                postId: post.id,
                tokenName: post.tokenName,
                userId: getUserId() ?? '',
                reason: reason ? reason : '',
                reasonText: reasonText ? reasonText : ''
            });
            setIsMenuVisible(false);
            setIsDeleting(false);
        } catch (error) {
            setIsMenuVisible(false);
            setIsDeleting(false);
            mapError(error);
        }
    }

    async function onPin(isPin: boolean): Promise<void> {
        if (!post) return;

        setIsPinning(true);

        try {
            await pinPost({
                postId: post.id,
                tokenName: post.tokenName,
                userId: getUserId() ?? '',
                isPinned: isPin
            });
            setIsPinning(false);
            setIsMenuVisible(false);
        } catch (error) {
            setIsPinning(false);
            setIsMenuVisible(false);
            mapError(error);
        }
    }
    async function onSyndicatePost(syndicate: boolean): Promise<void> {
        if (!post) return;

        setIsSyndicating(true);

        try {
            await syndicatePost({
                postId: post.id,
                tokenName: post.tokenName,
                userId: getUserId() ?? '',
                syndicate
            });
            setIsSyndicating(false);
            setIsMenuVisible(false);
        } catch (error) {
            setIsSyndicating(false);
            setIsMenuVisible(false);
            mapError(error);
        }
    }

    function onEditClick(): void {
        const path = `/bbs/${ensureTokenUrl(tokenName, false)}/posts/${ensurePostUrl(postId, false)}/edit`;
        console.log('path', path);

        history.push(path);
    }

    function onMenuPopoverTriggerClick(e: React.MouseEvent<HTMLElement>): void {
        e.stopPropagation();
        e.preventDefault();

        setIsMenuVisible(!isMenuVisible);
    }

    function renderMenuPopoverTrigger() {
        const { renderMenuPopoverTrigger } = props;

        return (
            <div className="p-1 cursor-pointer" onClick={onMenuPopoverTriggerClick}>
                {!renderMenuPopoverTrigger && <Icon className="text-lg" name="dotsVertical"  />}
                {renderMenuPopoverTrigger && renderMenuPopoverTrigger()}
            </div>
        );
    }

    function renderEditMenuItem() {
        if (!post) return null;
        if (!canEditPost(postId, tokenName) || !isUserJoinedToToken(tokenName)) return null;

        return (
            <MenuItem onClick={onEditClick}>
                <Icon svgFill={false} name="edit" className="mr-2 text-xl" />
                <Text>{t('editPostLabel')}</Text>
            </MenuItem>
        );
    }

    function renderHideMenuItem() {
        if (!post || !token || post.isPinned) return null;

        if (!canDeletePost(post.id) || !isUserJoinedToToken(tokenName)) return null;

        if (post.status === PostStatus.PUBLISHED || post.status === PostStatus.PUBLISHED_UNLISTED) {
            return (
                <MenuItem onClick={/*() => onDelete(true)*/() => onHidePostSelectReason()} isLoading={isDeleting}>
                    <Icon svgFill={false} name="hide" className="mr-2 text-xl" />
                    <Text>{t('hidePostLabel')}</Text>
                </MenuItem>
            );
        }

        return (
            <MenuItem onClick={() => onDelete(false)} isLoading={isDeleting}>
                <Icon svgFill={false} name="show" className="mr-2 text-xl" />
                <Text>{t('unhidePostLabel')}</Text>
            </MenuItem>
        );
    }

    function renderReportMenuItem() {
        if (!isLoggedIn) return null;
        return (
            <MenuItem onClick={() => onOpenReportClick()} isLoading={false} >
                <Icon name="error" className="mr-2 text-xl" />
                <Text>{t('report')}</Text>
            </MenuItem>
        );
    }

    function renderPinMenuItem() {
        if (!post || !token || hidePinPost || post.status !== 0) return null; // && post.status !== 7
        if (!canPinPost(post.id)) return null;

        if (post.isPinned) {
            return (
                <MenuItem onClick={() => onPin(false)} isLoading={isPinning} >
                    <Icon name="unpin" className="mr-2 text-xl" />
                    <Text>{t('unPinPostLabel')}</Text>
                </MenuItem>
            );
        }

        return (
            <MenuItem onClick={() => onPin(true)} isLoading={isPinning} >
                <Icon name="pin" className="mr-2 text-xl" />
                <Text>{t('pinPostLabel')}</Text>
            </MenuItem>
        );
    }

    function renderSyndicateMenuItem() {
        if (!post || !token || post.status !== 0) return null;
        if (!canDeleteContent(post.tokenName) || !post.createdByWhitelistedUser) return null;
        const { blocks } = JSON.parse(post.postContent);
        const syndicate = !post.isSyndicated;
        const textContentLength = summarizeBlocksContent(blocks as OutputBlockData[]).length;

        // Allow to syndicate only if the post has at least 500 characters. always allow to desyndicate
        if (syndicate && textContentLength < MIN_CHARS_TO_SYNDICATE) return null;
        return (
            <MenuItem onClick={() => onSyndicatePost(syndicate)} isLoading={isSyndicating} >
                <Icon svgFill={false} name="paperClip" className="mr-2 text-xl" />
                <Text>{syndicate ? t('syndicatePostLabel') : t('desyndicatePostLabel')}</Text>
            </MenuItem>
        );
    }

    function renderReactiosModalMenuItems() {
        return (
            <MenuItem onClick={() => {
                handleModal(<ReactionsList tokenName={tokenName} postId={postId} />, { size: 'sm' });
                setIsMenuVisible(false);
            }}>
                <Icon svgFill={false} name="thumbUp" className="mr-2 text-xl" />
                <Text>{t('showReactors')}</Text>
            </MenuItem>
        );
    }

    function renderEditTag() {
        if (!post) return null;
        const flairs = getTokenFlairs(tokenName);

        if (!flairs || flairs.length === 0) return null;
        if (!canEditPost(postId, tokenName) && !isPerrmisioned(tokenName, 'hideContent') || !isUserJoinedToToken(tokenName)) return null;

        return (
            <MenuItem onClick={() => onEditFlairClick()} isLoading={false} >
                <Icon svgFill={false} name="tag" className="mr-2 text-xl" />
                <Text>{t('editFlair')}</Text>
            </MenuItem>
        );
    }

    function renderHideTokenMenuItem() {
        const { onHideTokenClick } = props;
        if (!onHideTokenClick || !isLoggedIn) return;

        return (
            <MenuItem onClick={() => onHideTokenClick(postId, tokenName)} isLoading={false} >
                <Icon svgFill={false} name="hide" className="mr-2 text-xl" />
                <Text>{t('postMenuPopover.hideToken')}</Text>
            </MenuItem>
        );
    }

    function renderStats() {
        if (!post) return null;

        const { totalViews = 0 } = post;

        return (
            <div className="px-4 py-2 text-sm text-gray-500">
                <Markdown source={t('postMenuPopover.totalViews', { totalViews: abbreviatedFormatter(totalViews) })} />
            </div>
        );
    }

    function renderMenuItems() {
        if (!isMenuVisible) return null;

        return (
            <Fragment>
                {children}
                {renderPinMenuItem()}
                {renderSyndicateMenuItem()}
                {renderEditMenuItem()}
                {renderHideMenuItem()}
                {renderReactiosModalMenuItems()}
                {renderEditTag()}
                {renderHideTokenMenuItem()}
                {renderReportMenuItem()}
                {renderStats()}
            </Fragment>
        );
    }

    function renderContent() {
        const { menuPopoverProps = {} } = props;

        const _props = mergeProps<[MenuPopoverProps, Partial<MenuPopoverProps>]>({
            className: getBaseClassname(),
            renderTrigger: renderMenuPopoverTrigger,
            onClickOutside: () => setIsMenuVisible(false),
            popoverProps: {
                className: 'overflow-visible',
                isVisible: isMenuVisible
            }
        }, menuPopoverProps);

        return (
            <MenuPopover {..._props} >
                {renderMenuItems()}
            </MenuPopover>
        );
    }

    return (
        <Fragment>
            {renderContent()}
        </Fragment>
    );

};

export default PostMenuPopover;
