import React, { FC, Fragment, memo, PropsWithChildren } from 'react';
import A from '../A/A';
import { ensureHttpProtocol, isExternal } from '@src/utils/url-utils/url-utils';
import { Link as ReactRouterLink } from 'react-router-dom';
import { noop } from '@src/utils/utils';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

export interface LinkProps {
    className?: string;
    to?: string;
    onClick?: (arg?: any) => void;
    externalLinkTarget?: string;
}

const Link: FC<PropsWithChildren<LinkProps>> = props => {
    const { onClick, to = '', children, className = '' } = props;

    function renderExternalLink() {
        const { externalLinkTarget = '_blank' } = props;

        return <A target={externalLinkTarget} className={className} href={ensureHttpProtocol(to)}>{children}</A>;
    }

    if (onClick && !to) return <a className={className}>{children}</a>;
    if (isExternal(to)) return <Fragment>{renderExternalLink()}</Fragment>;

    function renderLinkComponent() {
        return (
            <ReactRouterLink onClick={onClick} className={className} to={to}>
                {children}
            </ReactRouterLink>
        );
    }

    return <Fragment>{renderLinkComponent()}</Fragment>;
};

export default memo(Link, propsEqualityFn);
