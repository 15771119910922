import { thunk, Action, Thunk, action, computed, Computed } from 'easy-peasy';
import { getBbsTokenNameSByBbsLinksOrder } from './helpers';
import { GetTokensFilterBy } from '@creator/sdk/modules/token/token.service';
import { StoreModel } from '..';
import { DocumentSnapshot } from '@creator/sdk/modules/db/db.model';
import { BbsToken, Token } from '@creator/sdk/modules/token/token.model';
import { GetUsersFilterBy } from '@creator/sdk/modules/account/account.service';
import { GetTokensOrderBy } from '@creator/sdk/modules/token';
import { loadBbsToken, loadToken, loadTokenAbout, loadTokens } from './actions';

export interface SetMyBalancePayload {
    tokenName: string;
    balance: number;
}

export interface SetBalancesPayload {
    tokenName: string;
    balances: { [userId: string]: number };
}

export interface SetTokenAboutPayload {
    tokenName: string;
    about: string;
}

export interface SetTokenTitlePayload {
    tokenName: string;
    title: string;
}

export interface LoadTokensPayload {
    lowerBound: DocumentSnapshot | null;
    limit: number;
    orderBy?: GetTokensOrderBy;
    filterBy?: GetTokensFilterBy[];
}
export interface LoadTokenPartnersPayload {
    tokenName: string;
    lowerBound?: string | null;
    limit?: number;
}

export interface LoadTokenBalancesPayload {
    tokenName: string;
    lowerBound: string | null;
    limit: number;
}

export interface TokenModel {
    tokenPartners: Record<string, string[]>;
    tokens: Record<string, Token>;
    bbsTokens: Record<string, BbsToken>;
    abouts: Record<string, string>;
    titles: Record<string, string>;
    searchResult: string[];
    searchResultLastDoc: DocumentSnapshot | null;

    getToken: Computed<TokenModel, (tokenName: string) => Token | undefined, StoreModel>;
    getBbsToken: Computed<TokenModel, (tokenName: string) => BbsToken | undefined, StoreModel>;
    getTokens: Computed<TokenModel, (tokenNames: string[]) => (Token | undefined)[], StoreModel>;
    getTokenSearchResult: Computed<TokenModel, (Token | undefined)[], StoreModel>;
    getBbsTokenSearchResult: Computed<TokenModel, (bbsLinks: string[]) => (BbsToken | undefined)[], StoreModel>;
    getAbout: Computed<TokenModel, (tokenName: string) => string | undefined, StoreModel>;
    getTitle: Computed<TokenModel, (tokenName: string) => string | undefined, StoreModel>;

    setTokenPartners: Action<TokenModel, Record<string, string[]>>;
    setToken: Action<TokenModel, Token>;
    setBbsToken: Action<TokenModel, BbsToken>;
    setTokens: Action<TokenModel, Record<string, Token>>;
    setBbsTokens: Action<TokenModel, Record<string, BbsToken>>;
    setTokenAbout: Action<TokenModel, SetTokenAboutPayload>;
    setTokensAbout: Action<TokenModel, Record<string, string>>;
    setTokenApprovePosts: Action<TokenModel, { tokenName: string, approvePosts: boolean }>;
    setTokenTitle: Action<TokenModel, SetTokenTitlePayload>;
    setSearchResult: Action<TokenModel, string[]>;
    setSearchResultLastDoc: Action<TokenModel, DocumentSnapshot | null>;
    concatSearchResult: Action<TokenModel, string[]>;

    loadToken: Thunk<TokenModel, string>;
    loadTokensByTokenNames: Thunk<TokenModel, string[], null, StoreModel>;
    loadBbsToken: Thunk<TokenModel, string>;
    loadBbsTokensByBbsLinks: Thunk<TokenModel, string[]>;
    loadTokenAbout: Thunk<TokenModel, string>;
    loadTokens: Thunk<TokenModel, LoadTokensPayload>;
    loadTokenPartners: Thunk<TokenModel, LoadTokenPartnersPayload, null, StoreModel>;
}

const tokenModel: TokenModel = {
    tokens: {},
    bbsTokens: {},
    abouts: {},
    titles: {},
    searchResult: [],
    searchResultLastDoc: null,

    // Computed properties
    getToken: computed(state => tokenName => state.tokens[tokenName]),
    getBbsToken: computed(state => tokenName => state.bbsTokens[tokenName]),
    getTokens: computed(state => tokenNames => Object.entries(state.tokens).filter(([tokenName]) => tokenNames.includes(tokenName)).map(([, token]) => token)),
    getTokenSearchResult: computed(state => state.searchResult.map(tokenName => state.tokens[tokenName])),
    getBbsTokenSearchResult: computed(state => bbsLinks =>
        getBbsTokenNameSByBbsLinksOrder(bbsLinks).map(tokenName => state.bbsTokens[tokenName])
    ),
    getAbout: computed(state => tokenName => state.abouts[tokenName]),
    getTitle: computed(state => tokenName => state.titles[tokenName]),

    // Actions
    setToken: action((state, token) => {
        state.tokens[token.tokenName] = token;
    }),

    setTokens: action((state, tokens) => {
        state.tokens = { ...state.tokens, ...tokens };
    }),

    setTokenPartners: action((state, tokenPartners) => {
        state.tokenPartners = { ...state.tokenPartners, ...tokenPartners };
    }),

    setBbsToken: action((state, bbsToken) => {
        state.bbsTokens[bbsToken.tokenName] = bbsToken;
    }),

    setBbsTokens: action((state, bbsTokens) => {
        state.bbsTokens = { ...state.bbsTokens, ...bbsTokens };
    }),

    setTokenAbout: action((state, { tokenName, about }) => {
        state.abouts[tokenName] = about;
    }),

    setTokensAbout: action((state, tokensAbouts) => {
        state.abouts = { ...state.abouts, ...tokensAbouts };
    }),

    setTokenApprovePosts: action((state, { tokenName, approvePosts }) => {
        if (state.tokens[tokenName])
            state.tokens[tokenName].approvePosts = approvePosts;

    }),

    setTokenTitle: action((state, { tokenName, title }) => {
        state.titles[tokenName] = title;
    }),

    concatSearchResult: action((state, searchResult) => {
        state.searchResult.push(...searchResult);
    }),

    setSearchResult: action((state, searchResult) => {
        state.searchResult = searchResult;
    }),

    setSearchResultLastDoc: action((state, lastDoc) => {
        state.searchResultLastDoc = lastDoc;
    }),

    loadToken: thunk(async (actions, tokenName) => {
        const token = await loadToken(tokenName);
        actions.setToken(token);
        return token;
    }),

    loadTokensByTokenNames: thunk(async (actions, tokenNames) => {
        const tokens = await Promise.all(tokenNames.map(tokenName => loadToken(tokenName)));

        const newTokens = tokens.reduce((acc, token) => {
            acc[token.tokenName] = token;
            return acc;
        }, {} as Record<string, Token>);

        actions.setTokens(newTokens);

        return tokens;
    }),

    loadBbsToken: thunk(async (actions, url) => {
        const bbsToken = await loadBbsToken(url);
        actions.setBbsToken(bbsToken);
        return bbsToken;
    }),

    loadBbsTokensByBbsLinks: thunk(async (actions, bbsLinks) => {
        const bbsTokensArray = await Promise.all(bbsLinks.map(url => loadBbsToken(url)));
        const newBbsTokens = bbsTokensArray.reduce((acc, bbsToken) => {
            acc[bbsToken.tokenName] = bbsToken;
            return acc;
        }, {} as Record<string, BbsToken>);
        actions.setBbsTokens(newBbsTokens);
        return newBbsTokens;
    }),

    loadTokenAbout: thunk(async (actions, tokenName, helpers) => {
        const state = helpers.getState();
        let token = state.tokens[tokenName];

        if (!token) {
            token = await loadToken(tokenName);
            actions.setToken(token);
        }

        if (token.aboutURI) {
            try {
                const response = await fetch(token.aboutURI);
                if (!response.ok)
                    throw new Error('failed to load token about');
                const about = await response.text();

                actions.setTokenAbout({ tokenName, about });
                return about;
            } catch (error) {
                console.error('failed to load token about', token.aboutURI);
            }
        }

        const about = await loadTokenAbout(tokenName);
        actions.setTokenAbout({ tokenName, about });
        return about;
    }),

    loadTokens: thunk(async (actions, payload) => {
        const { lowerBound, limit, orderBy, filterBy } = payload;
        const res = await loadTokens(lowerBound, limit, orderBy, filterBy);

        res.items.forEach(token => {
            actions.setToken(token);
            actions.loadTokenAbout(token.tokenName);
        });

        const tokenNames = res.items.map(token => token.tokenName);
        actions.concatSearchResult(tokenNames);

        actions.setSearchResultLastDoc(res.lastDoc || null);
        return res;
    }),

    loadTokenPartners: thunk(async (actions, payload, helpers) => {
        const { tokenName, limit = 100 } = payload;
        const filterBy: GetUsersFilterBy[] = [{ field: `enableSell.${tokenName}`, 'opStr': '==', value: true }];

        const res = await helpers.getStoreActions().user.loadUsers({ filterBy, limit });  
        const parnters = res.items;
        const shuffledParnters = parnters.sort(() => 0.5 - Math.random());
        actions.setTokenPartners({ [tokenName]: shuffledParnters.map(user => user.id) });
        return shuffledParnters;
    })
};

export default tokenModel;
