import { action, Action, computed, Computed, Thunk, thunk } from 'easy-peasy';
import { createShortUrl } from './actions';
import { ShortUrl } from '@creator/sdk/modules/short-url/short-url.model';


export interface ShortUrlModel {
    shortUrl: Record<string, ShortUrl>;

    createShortUrl: Thunk<ShortUrlModel, string, Promise<ShortUrl>>;
    // loadShortUrl: Thunk<ShortUrlModel, LoadShortUrlPayload, Promise<Pagination<ShortUrl>>>;

    setShortUrl: Action<ShortUrlModel, ShortUrl>;

    getShortUrl: Computed<ShortUrlModel, (id: string) => ShortUrl | undefined>;
    getShortUrlByOriginalUrl: Computed<ShortUrlModel, (originalUrl: string) => ShortUrl | undefined>;
}

const shortUrlModel: ShortUrlModel = {
    shortUrl: {},

    getShortUrl: computed(state => id => state.shortUrl[id]),
    getShortUrlByOriginalUrl: computed(state => originalUrl => Object.values(state.shortUrl).find(shortUrl => shortUrl.originalUrl === originalUrl)),

    createShortUrl: thunk(async (actions, payload) => {
        const shortUrl = await createShortUrl(payload);
        actions.setShortUrl(shortUrl);
        return shortUrl;
    }),

    // loadShortUrl: thunk(async (actions, payload) => {
    //     const { lowerBound, limit, filterBy, orderBy, tokenName } = payload;

    //     const { hasMore, items, lastDoc } = await loadShortUrl(tokenName, lowerBound, limit, filterBy, orderBy);
    //     const ids: string[] = [];
    //     const shortUrl: Record<string, ShortUrl> = {};
    //     items.forEach(item => {
    //         shortUrl[item.id] = item;
    //         ids.push(item.id);
    //     });

    //     if (lastDoc)
    //         actions.setShortUrlLasDoc(lastDoc);

    //     actions.setShortUrl(shortUrl);
    //     actions.setShortUrlSearchResult({ result: ids, isConcat: Boolean(lowerBound) });
    //     return { hasMore, items, lastDoc };
    // }),

    setShortUrl: action((state, payload) => {
        const { id } = payload;
        state.shortUrl[id] = payload;
    })
};

export default shortUrlModel;