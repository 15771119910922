import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '@src/model/hooks';
import { noop } from '@src/utils/utils';
import { abbreviatedFormatter } from '@src/utils/number-utils/number-utils';
import { getPostShareUrl, isResponseAblePost } from '@src/model/upvote/helpers';
import { getUserId } from '@src/model/user/helpers';
import SocialShare, { SocialShareProps } from '@src/components/SocialShare/SocialShare';
import Reactions from '../Reactions/Reactions';
import IconAndLabel from '@src/basic-components/IconAndLabel/IconAndLabel';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';
import Icon from '@src/basic-components/Icon/Icon';

export type ShareUrlType = 'post' | 'response' | 'responseThread';

export interface PostStatsAndReactionsProps {
    className?: string;
    postId: string;
    tokenName: string;
    isDisabled?: boolean;
    onStatsClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onResponseClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const PostStatsAndReactions: FC<PostStatsAndReactionsProps> = props => {
    const { tokenName, postId, className = '', isDisabled, onStatsClick = noop, onResponseClick = noop } = props;
    const post = useStoreState(state => state.upvote.getPost(postId));
    const response = useStoreState(state => state.response.getResponse(postId));

    // Memoize the callback to avoid unnecessary re-renders
    const renderStats = () => {
        if (!post) return null;
        const count = abbreviatedFormatter(post.numComments);
        return (
            <IconAndLabel
                iconProps={{ className: 'text-xl' }}
                iconName="chat"
                label={count}
                onClick={onStatsClick}
                isDisabled={isDisabled}
            />
        );
    };

    const renderResponse = () => {
        if (!isResponseAblePost(postId) || !post) return null;
        const count = response
            ? abbreviatedFormatter(Math.max(response.totalDescendants - 1, 0))
            : '0';
        const _isDisabled = post.publisherId === getUserId() || isDisabled;

        return (
            <IconAndLabel
                iconProps={{ className: 'text-xl' }}
                iconName="response"
                label={count}
                onClick={onResponseClick}
                isDisabled={_isDisabled}
            />
        );
    };

    const renderShareIcon = () => {
        // Prepare props for SocialShare with the memoized callback
        const props: SocialShareProps = {
            shareUrlPayload: {
                url: getPostShareUrl(tokenName, postId)
            },
            useShortUrl: true,
            renderTrigger: () => <Icon className="text-xl" name="share" /> 
            
        };

        return <SocialShare {...props} />;
    };

    return (
        <div className={`flex items-center justify-between w-full ${className}`}>
            <Reactions tokenName={tokenName} isDisabled={isDisabled} postId={postId} />
            {renderStats()}
            {renderResponse()}
            <div className="relative mr-1 text-gray-500 hover:text-gray-900 dark:hover:text-gray-50">
                {renderShareIcon()}
            </div>
        </div>
    );
};

export default React.memo(PostStatsAndReactions, propsEqualityFn);
